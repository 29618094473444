import React, { ReactNode } from 'react';
import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BoxProps } from '@mui/material/Box';
import { ToolButtonProps } from '@components/Viewport/ToolButton';

export const TOOLBAR_MIN_SIZE = 48; //px
export const TOOLBAR_MIN_SIZE_WITH_TEXT = 60;

interface RoundBoxProps extends BoxProps {
  includeText: boolean;
  vertical?: boolean;
  subToolbar?: boolean;
}

const notForwardedProps: PropertyKey[] = ['includeText', 'vertical', 'subToolbar'];

const RoundBox = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => !notForwardedProps.includes(propName),
})<RoundBoxProps>(({ theme, includeText, vertical = false, subToolbar = false }) => ({
  display: 'flex',
  flexDirection: vertical ? 'column' : 'row',
  alignItems: subToolbar ? 'start' : 'center',
  padding: 0,
  width: vertical && !subToolbar ? (includeText ? TOOLBAR_MIN_SIZE_WITH_TEXT : TOOLBAR_MIN_SIZE) : 'auto',
  height: vertical ? 'auto' : includeText ? TOOLBAR_MIN_SIZE_WITH_TEXT : TOOLBAR_MIN_SIZE,
  borderRadius: 24,
  color: 'primary.contrastText',
  backgroundColor: theme.palette.secondary.lighter,
  boxShadow: '0px 2px 8px rgba(17, 17, 17, 0.1)',
  zIndex: theme.zIndex.composeToolbar,
  pointerEvents: 'auto',
  overflow: 'hidden',
  '& > div:first-of-type': {
    paddingLeft: vertical ? 0 : theme.spacing(1.5),
    paddingTop: vertical ? theme.spacing(1.5) : 0,
  },

  '& > div:last-of-type': {
    paddingRight: vertical ? 0 : theme.spacing(1.5),
    paddingBottom: vertical ? theme.spacing(1.5) : 0,
  },
}));

interface ToolBarBoxProps {
  name?: string;
  includeText?: boolean;
  vertical?: boolean;
  label?: string;
  subToolbar?: boolean;
  className?: string;
  children?: ReactNode;
}

const ToolBarBox = ({
  name = 'quick',
  includeText,
  label,
  vertical = false,
  subToolbar = false,
  className,
  children,
}: ToolBarBoxProps) => {
  const { t } = useTranslation();
  const ariaLabel = label ?? t('tools.ariaLabel.toolbar');

  return (
    <ToolbarWrapper
      className={`${className}${subToolbar ? ' .subToolbar' : ''}`}
      vertical={vertical}
      role="toolbar"
      aria-label={ariaLabel}>
      <RoundBox
        className={`${name}_toolbar_box toolbar_box`}
        includeText={includeText}
        vertical={vertical}
        subToolbar={subToolbar}>
        {children}
      </RoundBox>
    </ToolbarWrapper>
  );
};

const ToolbarWrapper = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => !notForwardedProps.includes(propName),
})<{ vertical: boolean }>`
  display: flex;
  width: ${(props) => (props.vertical ? 'auto' : '100%')};
  justify-content: center;
  align-items: center;
  min-height: 60px;
  padding: 0;
  pointer-events: none;
`;

interface ToolBarProps {
  name?: string;
  includeText?: boolean;
  vertical?: boolean;
  label?: string;
  value?: string | number | boolean;
  disabled?: boolean;
  subToolbar?: boolean;
  className?: string;
  children?: ReactNode;
}

const ToolBar = ({
  name = 'quick',
  includeText = false,
  vertical = false,
  label,
  value = false,
  disabled = false,
  subToolbar = false,
  className,
  children = [],
}: ToolBarProps) => {
  const tools = () => {
    if (!Array.isArray(children) && (children as any)?.type?.name !== 'ToolButton') {
      return children;
    } else {
      return React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        const activated = value === child.props.name;

        return React.cloneElement(child, {
          activated,
          disabled: child.props.disabled || disabled,
        } as Partial<ToolButtonProps>);
      });
    }
  };

  return (
    <ToolBarBox
      className={className}
      vertical={vertical}
      name={name}
      label={label}
      includeText={includeText}
      subToolbar={subToolbar}>
      {tools()}
    </ToolBarBox>
  );
};

export default ToolBar;
