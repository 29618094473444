import { ExtractedCutCanvasProperties } from '@components/Viewport/CutCanvasExtractorHacks';
import React, { createContext, ReactNode } from 'react';

type Props = {
  cut: ExtractedCutCanvasProperties;
  children: ReactNode;
};

export interface CutCanvasGrabberHacksContext {
  cut: any;
}

export const CutCanvasGrabberHacksContext = createContext(null as CutCanvasGrabberHacksContext);
const CutCanvasGrabberHacksProvider = ({ cut, children }: Props) => {
  const value = { cut };

  return <CutCanvasGrabberHacksContext.Provider value={value}>{children}</CutCanvasGrabberHacksContext.Provider>;
};

export default CutCanvasGrabberHacksProvider;
