import { Box, ImageList, ImageListItem, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';
import { SharedImage } from '@libs/SharedImage/SharedImage';
import { Link } from '@components/Ui/Link';
import Routing from '@root/routes';

interface TrendingImagesProps {
  images: SharedImage[];
  className?: string;
}

export const TrendingImages = ({ images, className }: TrendingImagesProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'imageSharing.trendingImages' });
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Wrapper className={className}>
      <StyledTypography variant="h4" fontWeight={500}>
        {t('title')}
      </StyledTypography>

      <ImageList variant="masonry" cols={isSmall ? 3 : 4} gap={12}>
        {images.map((image) => (
          <StyledLink href={Routing.imageSharing.image(image.ref)} key={image.ref}>
            <StyledImageListItem key={image.ref}>
              <img src={image.imageUrl} alt={image.title} />
            </StyledImageListItem>
          </StyledLink>
        ))}
      </ImageList>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  ${(props) => props.theme.breakpoints.down('md')} {
    align-items: center;
  }
`;

const StyledImageListItem = styled(ImageListItem)`
  box-shadow: ${(props) => props.theme.elevation.canvas};
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;

const StyledTypography = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;

const StyledLink = styled(Link)`
  text-transform: none;
  border-bottom: none;
`;
