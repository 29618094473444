import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const TitleTypography = styled(Typography)(({ theme, color, align = 'center' }) => ({
  color: color === 'dark' ? theme.palette.secondary.dark : 'white',

  textAlign: align,
  fontWeight: 500,
}));

const MarkTypography = styled(Typography)(() => ({
  color: 'white',
  fontSize: 15,
}));

const PanelTitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: 17,
  fontWeight: 500,
}));

export { PanelTitleTypography, TitleTypography, MarkTypography };
