import { AudienceType } from '@libs/Profile/Audience';
import { Box, Radio, RadioGroup, Typography, useRadioGroup } from '@mui/material';
import { Link } from '@components/Ui/Link';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';
import {
  BusinessCenter,
  BusinessCenterOutlined,
  FamilyRestroom,
  FamilyRestroomOutlined,
  Palette,
  PaletteOutlined,
  PhoneIphone,
  PhoneIphoneOutlined,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { SelectOption } from '@components/Ui/Form/Select/SelectOption';

interface AudienceSelectProps {
  audiences: AudienceType[];
  selectedAudience?: AudienceType;
  onChange: (audience?: AudienceType) => void;
  className?: string;
}

const icons = {
  [AudienceType.E_COMMERCE]: <BusinessCenterOutlined />,
  [AudienceType.SOCIAL_MEDIA]: <PhoneIphoneOutlined />,
  [AudienceType.PERSONAL]: <FamilyRestroomOutlined />,
  [AudienceType.CREATIVE]: <PaletteOutlined />,
};

const checkedIcons = {
  [AudienceType.E_COMMERCE]: <BusinessCenter />,
  [AudienceType.SOCIAL_MEDIA]: <PhoneIphone />,
  [AudienceType.PERSONAL]: <FamilyRestroom />,
  [AudienceType.CREATIVE]: <Palette />,
};

export const AudienceSelectStep = ({ audiences, selectedAudience, onChange, className }: AudienceSelectProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'onboarding.audienceSelect' });

  function handleChange(_, value) {
    onChange(value);
  }

  return (
    <>
      <Typography variant="h5" fontWeight={500} textAlign="center">
        {t('title')}
      </Typography>

      <AudienceList value={selectedAudience || ''} className={className} onChange={handleChange}>
        {audiences.map((audience) => (
          <AudienceOption key={audience} value={audience} name={t(`options.${audience}`)} />
        ))}
      </AudienceList>

      <Typography variant="body2" color="greyInfo.main" textAlign="center">
        <Link onClick={() => onChange(AudienceType.UNSPECIFIED)}>{t('options.unspecified')}</Link>
      </Typography>
    </>
  );
};

const AudienceList = styled(RadioGroup)`
  margin: ${(props) => props.theme.spacing(3, 0)};
`;

interface AudienceOptionProps {
  value: AudienceType;
  name: string;
  className?: string;
}

const AudienceOption = ({ value, name, className }: AudienceOptionProps) => {
  const radioGroup = useRadioGroup();

  return (
    <SelectOption
      key={value}
      value={value}
      name={name}
      selected={radioGroup.value == value}
      control={<Radio icon={icons[value]} checkedIcon={checkedIcons[value]} className={className} />}
    />
  );
};
