import { GetObjectsQuery } from '@api/graphql';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { OBJECTS } from '@libs/DepixApi';
import { useGraphqlErrorHandler } from '@hooks/useGraphqlErrorHandler';
import { DepixObject } from '@libs/DepixObject';

type OnCompletedCallbackProp = (objects: DepixObject[]) => any;

type QueryableFields =
  | 'withId'
  | 'withImage'
  | 'withMask'
  | 'withOriginalMask'
  | 'withNormals'
  | 'withResidualShading'
  | 'withAlbedo'
  | 'withLighting';

interface UseQueryObjects {
  /**
   * All fields are default false if not specified.
   *
   * Example use:
   * getObjectRequest('abc123', true, {withMask: true})
   * query only mask with id abc123 in full resolution
   */
  queryObjects: (
    ids: string[],
    isPreview: boolean,
    withFields: { [p in QueryableFields]?: boolean },
    maskId?: string | null
  ) => Promise<DepixObject[]>;
  isError: ApolloError;
  isLoading: boolean;
  data: GetObjectsQuery;
}
function useQueryObjects(onCompletedCallback: OnCompletedCallbackProp): UseQueryObjects {
  const { displayError } = useGraphqlErrorHandler();
  const [objects, { loading: isLoading, error: isError, data }] = useLazyQuery(OBJECTS, {
    onCompleted({ objects }) {
      Promise.all(objects.map((object) => DepixObject.create(object))).then(onCompletedCallback);
    },
    onError(error) {
      displayError(error);
    },
    fetchPolicy: 'no-cache',
  });

  const queryObjects = (ids, isPreview: boolean, withFields, maskId: string = null) => {
    const variables = { ids, isPreview, maskId };
    const fieldParams = [
      'withId',
      'withImage',
      'withMask',
      'withOriginalMask',
      'withNormals',
      'withResidualShading',
      'withAlbedo',
      'withLighting',
    ];
    for (const field of fieldParams) {
      if (withFields[field] !== undefined && typeof withFields[field] === 'boolean') {
        variables[field] = !!withFields[field];
      }
    }
    return objects({ variables: variables }).then((x) => Promise.all(x.data.objects.map(DepixObject.create)));
  };

  return { queryObjects, isLoading, isError, data };
}

export default useQueryObjects;
