import { SegmentationMode } from './SegmentationMode';

export const DEFAULT_STATE: SegmentationState = {
  debounceTime: 1000,
  preview: true,
  mode: false,
  actionsDisabled: false,
  clickStack: [],
  isLoadedRecord: true,
  segmentationObjectID: null,
  mask: null,
  maskId: null,
  loading: false,
  autoSegmented: false,
};

export enum SegmentationActionType {
  SEGMENTATION_ENABLED = 'segmentation-enabled',
  TOOLS_DISABLED = 'tools-disabled',
  MODE_CHANGED = 'mode-changed',
  SEGMENTED_OBJECT_CHANGED = 'segmented-object-changed',
  CLICK_ADDED = 'click-added',
  SEGMENTATION_FAILED = 'segmentation-failed',
  MASK_APPLIED = 'mask-applied',
  LAST_CLICK_UNDONE = 'last-click-undone',
  LOAD_MASK = 'undo-mask',
  CLICKS_CLEARED = 'clicks-cleared',
  MASK_CLEARED = 'mask-cleared',
  SEGMENTATION_RESET = 'segmentation-reset',
  EDIT_SEGMENTATION = 'edit-segmentation',
}

export type SegmentationClick = {
  x: number;
  y: number;
  isForeground: boolean;
};

export type SegmentationState = {
  segmentationObjectID: string;
  clickStack: SegmentationClick[];
  isLoadedRecord: boolean;
  mask: string | null;
  maskId: string | null;
  debounceTime: number;
  mode: SegmentationMode | false;
  preview: boolean;
  actionsDisabled: boolean;
  enabled?: boolean;
  loading: boolean;
  autoSegmented?: boolean;
};

type SegmentationAction = {
  type: SegmentationActionType;
  payload?: {
    actionsDisabled?: boolean;
    enabled?: boolean;
    mode?: SegmentationMode;
    object?: string;
    mask?: {
      url: string;
      id: string;
    };
    click?: SegmentationClick;
    clickStack?: SegmentationClick[];
  };
};

export const segmentationReducer = (state: SegmentationState, action: SegmentationAction): SegmentationState => {
  const { type, payload } = action;

  switch (type) {
    case SegmentationActionType.TOOLS_DISABLED: {
      return { ...state, actionsDisabled: payload.actionsDisabled };
    }
    case SegmentationActionType.SEGMENTATION_ENABLED: {
      return { ...state, enabled: payload.enabled };
    }
    case SegmentationActionType.MODE_CHANGED: {
      return { ...state, mode: payload.mode };
    }
    case SegmentationActionType.SEGMENTED_OBJECT_CHANGED: {
      return {
        ...state,
        segmentationObjectID: payload.object,
        mask: payload.mask?.url,
        maskId: payload.mask?.id,
        isLoadedRecord: false,
        autoSegmented: !!payload.mask?.url,
      };
    }
    case SegmentationActionType.CLICK_ADDED: {
      return { ...state, loading: true, clickStack: [payload.click, ...state.clickStack], isLoadedRecord: false };
    }
    case SegmentationActionType.SEGMENTATION_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case SegmentationActionType.MASK_APPLIED: {
      return {
        ...state,
        mask: payload.mask?.url,
        maskId: payload.mask?.id,
        isLoadedRecord: false,
        loading: false,
      };
    }
    case SegmentationActionType.LAST_CLICK_UNDONE: {
      return { ...state, loading: true, clickStack: state.clickStack.slice(1) };
    }
    case SegmentationActionType.LOAD_MASK: {
      return {
        ...state,
        clickStack: payload.clickStack,
        mask: payload.mask?.url,
        maskId: payload.mask?.id,
        isLoadedRecord: true,
      };
    }
    case SegmentationActionType.CLICKS_CLEARED: {
      return { ...state, clickStack: [], mask: null, maskId: null };
    }
    case SegmentationActionType.MASK_CLEARED: {
      return {
        ...state,
        mask: null,
        maskId: null,
        isLoadedRecord: false,
      };
    }
    case SegmentationActionType.SEGMENTATION_RESET: {
      return { ...state, ...DEFAULT_STATE };
    }
    case SegmentationActionType.EDIT_SEGMENTATION: {
      return { ...state, autoSegmented: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
