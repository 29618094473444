import { Box, styled, Typography } from '@mui/material';
import { CheckIcon } from '@components/Ui/Icons/CheckIcon';
import { ReactNode } from 'react';

interface SubscriptionTermProps {
  label: ReactNode;
  className?: string;
}

export const SubscriptionTerm = ({ label, className }: SubscriptionTermProps) => {
  return (
    <Wrapper className={className}>
      <StyledCheckIcon />
      <StyledTypography>{label}</StyledTypography>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing(2)};
`;

const StyledCheckIcon = styled(CheckIcon)`
  color: ${(props) => props.theme.palette.success.main};
`;

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: 500;
  font-size: 0.975rem;
  text-align: left;
`;
