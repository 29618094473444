import React from 'react';

import Box from '@mui/material/Box';

type Direction = 'vertical' | 'horizontal';

interface DividerProps {
  direction?: Direction;
  color?: string;
  className?: string;
}

const Divider = ({ direction = 'vertical', color = 'divider', className }: DividerProps) => {
  const isVertical = direction === 'vertical';

  const width = isVertical ? '1px' : '80%';
  const height = isVertical ? '80%' : '1px';
  const mx = isVertical ? 0.5 : 0;
  const my = isVertical ? 0 : 0.5;

  return (
    <Box
      role="divider"
      sx={{
        borderRadius: '1px',
        backgroundColor: color,
        width,
        height,
        minWidth: '2px',
        minHeight: '2px',
        mx,
        my,
      }}
      className={className}
    />
  );
};

export default Divider;
