import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SearchBar from '@components/Ui/SearchBar';
import Pagination from '@components/Ui/Pagination';
import ImageMasonry from '@components/Ui/ImageMasonry';

/**
 * @param {func} onSearch When provided, enables the search bar
 * and, on summit, pass the event to onSearch
 * @param {func} onBackward When provided, enables the backward button
 * and, on click, calls onBackward
 * @param {func} onForward When provided, enables the forward button
 * and, on click, calls onForward
 * @param {func} onSelectImage When provided, calls onSelectImage with
 * image id on selecting image
 * @param {object} imgs list of images' info.
 * the object shape should be as descripted in ImageGallery.propTypes
 * @param {int} currentPage current page number
 * @param {int} totalPage total number of pages
 * @return {*}
 */
export default function ImageGallery({
  onSearch,
  onBackward,
  onForward,
  onSelectImage,
  onDragImage,
  imgs,
  currentPage,
  totalPage,
  errorMessage,
  sx = {},
}) {
  const [searchBarValue, setSearchBarValue] = useState('');

  const onSearchBarChanged = (value) => {
    setSearchBarValue(value);
  };

  const onSubmitSearch = () => {
    onSearch(searchBarValue);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        justifyItems: 'center',
        alignContent: 'center',
        alignItems: 'center',
        overflowY: 'auto',
        ...sx,
      }}
      role="imagegallery">
      {onSearch && (
        <SearchBar onChange={onSearchBarChanged} onSubmit={onSubmitSearch} sx={{ flex: '0 0 auto', my: 3 }} />
      )}
      {errorMessage && (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            maxWidth: '450px',
            display: 'flex',
            justifySelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            m: 5,
          }}>
          <Typography sx={{ color: 'secondary.medium', fontSize: 20 }}>{errorMessage}</Typography>
        </Box>
      )}
      {!errorMessage && imgs && imgs.length !== 0 && (
        <ImageMasonry
          cols={2}
          images={imgs}
          onSelectImage={onSelectImage}
          onDragImage={onDragImage}
          sx={{ flex: '1 1 auto', overflowY: 'auto' }}
        />
      )}
      {!errorMessage && totalPage > 0 && (
        <Pagination
          onNextPage={onForward}
          onPreviousPage={onBackward}
          currentPage={currentPage}
          totalPage={totalPage}
          sx={{ flex: '0 0 auto' }}
        />
      )}
    </Box>
  );
}

ImageGallery.propTypes = {
  onSearch: PropTypes.func,
  onBackward: PropTypes.func,
  onForward: PropTypes.func,
  onSelectImage: PropTypes.func,
  onDragImage: PropTypes.func,
  imgs: PropTypes.arrayOf(
    PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      description: PropTypes.string,
      url: PropTypes.string.isRequired,
      subtitle: PropTypes.element,
    })
  ),
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  errorMessage: PropTypes.string,
  sx: PropTypes.object,
};
