export class CampaignData {
  constructor(
    public readonly source: string,
    public readonly medium: string,
    public readonly term: string,
    public readonly content: string,
    public readonly campaignName: string
  ) {}
}

const COOKIE_NAME = 'campaign_tracking';

export const useCampaign = (): { campaign: CampaignData | undefined } => {
  let campaign: CampaignData | undefined = undefined;

  const cookie = getCookies()[COOKIE_NAME];

  if (cookie) {
    const parsedCookie = decodeURIComponent(cookie);
    const params = new URLSearchParams(parsedCookie);

    if (params?.has('utm_campaign') || params?.has('utm_source')) {
      campaign = mapCampaignFromSearchParams(params);
    }
  }

  return { campaign };
};

export const mapCampaignFromSearchParams = (params: URLSearchParams) => {
  return new CampaignData(
    params.get('utm_source'),
    params.get('utm_medium'),
    params.get('utm_term'),
    params.get('utm_content'),
    params.get('utm_campaign')
  );
};

export const storeCampaignParams = (params) => {
  setCookie(COOKIE_NAME, encodeURIComponent(params));
};

const getCookies = () => {
  const cookies = [];
  document.cookie.split(';').forEach((cookie) => {
    const [key, value] = cookie.split('=');
    cookies[key.trim()] = value;
  });

  return cookies;
};

const setCookie = (name, value, ageInDays = 30) => {
  if (value) {
    document.cookie = `${name}=${value};max-age=${ageInDays * 60 * 60 * 24};SameSite=lax;Secure`;
  }
};
