import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import HelpIcon from '../Ui/Icons/HelpIcon';
import HelpDrawer from './HelpDrawer';
import { useTranslation } from 'react-i18next';
import useInstrumentation from '@hooks/UseInstrumentation';
import { useMediaQuery } from '@mui/material';
import HeaderButton from '../Header/HeaderButton';

const HelpButton = () => {
  const { t } = useTranslation();
  const { helpMenuOpened } = useInstrumentation();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      helpMenuOpened();
    }
  }, [open]);

  return (
    <>
      <HeaderButton label={t('help.ariaLabel.button')} onClick={handleOpen}>
        <HelpIcon fontSize={isSmallDevice ? 'small' : 'medium'} />
      </HeaderButton>
      <HelpDrawer open={open} onClose={handleClose} />
    </>
  );
};

export default HelpButton;
