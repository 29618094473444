type CaptchaToken = string;

export const secureCall = (action: string): Promise<CaptchaToken> => {
  return new Promise((resolve, reject) => {
    grecaptcha.enterprise.ready(function () {
      grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action }).then(
        (token) => resolve(token),
        (e) => reject(e)
      );
    });
  });
};
