import React from 'react';
import { useTranslation } from 'react-i18next';
import useInstrumentation, { CroppedTag } from '@hooks/UseInstrumentation';

import FooterButton from '@root/Components/Ui/FooterButton';
import ApplyIcon from '@root/Components/Ui/Icons/ApplyIcon';
import useCropTool from '@hooks/UseCropTool';

export const ApplyCropButton = () => {
  const { t } = useTranslation(null, { keyPrefix: 'tools.compose' });
  const { enabled, setApplied } = useCropTool();
  const instrumentation = useInstrumentation();

  const onApplyCrop = () => {
    setApplied(true);
    instrumentation.imageCropped(CroppedTag.APPLY);
  };

  return (
    <>{enabled && <FooterButton tooltip={t('tooltips.applyCrop')} onClick={onApplyCrop} icon={<ApplyIcon />} />}</>
  );
};
