import './DepixImage';

import { cropToAlpha } from '@libs/ImgUtils';

export const INFILL_IMAGE_TYPE = 'infillImage';
export const SHOW_OPACITY = 1;
export const HIDE_OPACITY = 0;

/**
 * InfillImage subclass
 * @class fabric.InfillImage
 * @extends fabric.InfillImage
 * @return {fabric.InfillImage} thisArg
 *
 */
fabric.InfillImage = class extends fabric.DepixImage {
  constructor(depixObject, id, padding, options) {
    super(depixObject, id, padding, true, options);
    this.type = INFILL_IMAGE_TYPE;
    this.repeat = 'no-repeat';
    this.fill = 'transparent';
    this.angleCache = 0;
    this.paddingRatio = 1;
    this.isFloating = false;

    this.perPixelTargetFind = true;
    this.paddingRatio = padding;

    this.hasBorders = true;

    this.setControlsVisibility({
      float: false,
      delete: true,
      light: false,
      tr: false,
      br: false,
      bl: false,
      tl: false,
      ml: false,
      mt: false,
      mr: false,
      mb: false,
      mtr: false,
    });

    this.lockMovement();
  }

  static create(depixObject, id, padding = 1, options = {}) {
    const infillImage = new fabric.InfillImage(depixObject, id, padding, options);

    return new Promise((resolve) => {
      cropToAlpha(depixObject.infillFragment, padding).then((image) => {
        infillImage.updateElement(image);
        resolve(infillImage);
      });
    });
  }

  setHide(hide) {
    this.opacity = hide ? HIDE_OPACITY : SHOW_OPACITY;
    this.selectable = !hide;
  }

  lockMovement() {
    this.lockMovementX = true;
    this.lockMovementY = true;
    this.lockScalingX = true;
    this.lockScalingY = true;
    this.lockRotation = true;
  }
};
