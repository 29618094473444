import { Checkbox, FormControlLabel, styled } from '@mui/material';
import React from 'react';

interface LabeledCheckBoxProps {
  className?: string;
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
  size?: 'medium' | 'small';
}

export const LabeledCheckBox = ({ className, isChecked, onChange, label, size = 'medium' }: LabeledCheckBoxProps) => {
  return (
    <StyledFormControlLabel
      size={size}
      className={className}
      control={<StyledCheckbox size={size} value={isChecked} onChange={(e) => onChange(e.target.checked)} />}
      label={label}
    />
  );
};

const StyledFormControlLabel = styled(FormControlLabel)<{ size: 'medium' | 'small' }>`
  width: 100%;
  margin: 0;
  text-align: left;
  gap: ${(props) => props.theme.spacing(0.5)};

  & span.MuiFormControlLabel-label {
    font-size: ${(props) => (props.size == 'small' ? 'small' : 'medium')};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`;
