import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { ApolloProvider } from '@apollo/client';
import ConfigureApolloClient from '@contexts/ConfigureApolloClient';
import apolloClient from '@libs/ApolloClient';

import LegacyApp from './LegacyApp';
import { UserProfileProvider } from '@contexts/UserProfileContext';

import theme from './theme';

import './index.css';
import { ErrorBoundary } from '@sentry/react';
import Loading from '@components/Loading/Loading';
import { UserPilotProvider } from '@contexts/UserPilotContext';
import { AlertProvider } from '@contexts/AlertContext';
import { GraphqlErrorProvider } from '@contexts/GraphqlErrorContext';
import { GlobalInstrumentation } from '@components/Instrumentation/GlobalInstrumentation';
import { AuthV2Provider } from '@contexts/AuthV2';
import { CheckoutProvider } from '@hooks/Checkout/Checkout';
import { PageLoadingScreen } from '@components/ProtectedRoute/PageLoadingScreen';
import { HelmetProvider } from 'react-helmet-async';

const renderApp = (container) => {
  ReactDOM.render(
    <Suspense fallback={<Loading />}>
      <ErrorBoundary>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <AlertProvider>
              <GraphqlErrorProvider>
                <BrowserRouter>
                  <React.StrictMode>
                    <ApolloProvider client={apolloClient}>
                      <AuthV2Provider>
                        <ConfigureApolloClient client={apolloClient} />

                        <GlobalInstrumentation />

                        <CssBaseline />
                        <UserProfileProvider>
                          <UserPilotProvider>
                            <CheckoutProvider>
                              <LegacyApp />
                            </CheckoutProvider>
                          </UserPilotProvider>
                        </UserProfileProvider>
                      </AuthV2Provider>
                    </ApolloProvider>
                  </React.StrictMode>
                </BrowserRouter>
              </GraphqlErrorProvider>
            </AlertProvider>
          </ThemeProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </Suspense>,
    container
  );
};

export default { renderApp };
