/* eslint-disable react-hooks/exhaustive-deps */
import SegmentationClicks from '@components/Segmentation/SegmentationClicks';
import { EXTRACT_PANEL_NAME } from '@libs/ExtractPanelName';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CanvasContainer from './CanvasContainer';
import UploadCanvas from './UploadCanvas';

const CutCanvas = () => {
  const { t } = useTranslation();

  return (
    <StyledCanvasContainer stopContextMenu={true} transparent={false}>
      <StyledGrid
        item
        flexGrow={1}
        sx={{ display: 'flex', background: `url('/checkboardSquare.png') repeat top left`, overflow: 'hidden' }}>
        <StyledUploadCanvas
          id={EXTRACT_PANEL_NAME}
          description={t('canvas.cut.description')}
          loadingMessage={t('canvas.cut.loadingMessage')}
          fireRightClick="true"
          enableCrop={false}
          stopContextMenu={true}
          showInstruction={true}>
          <SegmentationClicks />
        </StyledUploadCanvas>
      </StyledGrid>
    </StyledCanvasContainer>
  );
};

const StyledGrid = styled(Grid)`
  overflow: hidden;
`;

const StyledCanvasContainer = styled(CanvasContainer)`
  overflow: hidden;
`;

const StyledUploadCanvas = styled(UploadCanvas)`
  z-index: 10;
`;

export default CutCanvas;
