import { SvgIconProps } from '@mui/material/SvgIcon';
import { SvgIcon } from '@mui/material';

export const CheckIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M5 13L9.5 17.5L19.5 7"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
