import { AuthError } from '@hooks/AuthV2Api';
import { DEFAULT_NS } from '@libs/i18n-config';
import { Checkbox, FormControlLabel, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {
  AuthField,
  AuthFormControlLabel,
  AuthFormErrorMessage,
  AuthFormWrapper,
  AuthSubmitButton,
  AuthTitle,
  EmailAdornment,
  PasswordAdornment,
} from '@pages/Auth/AuthForm';
import React, { useCallback, useEffect, useState, KeyboardEvent } from 'react';
import { validate as validateEmail } from 'email-validator';
import { isEqual } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '@components/Ui/Link';

interface FormState {
  usernameValid: string | null;
  emailValid: string | null;
  passwordValid: string | null;
  passwordConfirmValid: string | null;
  canSend: boolean;
  submitted: boolean;
}

const INITIAL_FORM_STATE: FormState = {
  usernameValid: null,
  emailValid: null,
  passwordValid: null,
  passwordConfirmValid: null,
  canSend: false,
  submitted: false,
};

interface CreateAccountFormProps {
  className?: string;
  onAccountCreation: (email: string, password: string, agreeTerms: boolean, consentNewsletter: boolean) => void;
  error: AuthError | undefined;
  isLoading: boolean;
}

export const CreateAccountForm = ({ className, onAccountCreation, error, isLoading }: CreateAccountFormProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [consent, setConsent] = useState(false);
  const [formState, setFormState] = useState(INITIAL_FORM_STATE);
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'auth.v2.createAccount' });

  const changeFormState = useCallback(
    (error: Partial<FormState>) => {
      const newState: FormState = { ...formState, ...error };
      if (!isEqual(newState, formState)) {
        setFormState(newState);
      }
    },
    [formState]
  );

  const resetError = useCallback(
    (field: keyof Omit<FormState, 'canSend'>) => {
      if (formState[field] != null) {
        setFormState({
          ...formState,
          [field]: null,
        });
      }
    },
    [formState]
  );

  useEffect(() => {
    if (email && !validateEmail(email)) {
      changeFormState({
        emailValid: t('validation.email'),
      });
    } else {
      resetError('emailValid');
    }
  }, [email]);

  useEffect(() => {
    if (password && !PASSWORD_LETTER.test(password)) {
      changeFormState({ passwordValid: t('validation.passwordOneLetter') });
    } else if (password && !PASSWORD_NUMBER.test(password)) {
      changeFormState({ passwordValid: t('validation.passwordOneNumber') });
    } else if (password && password.length < PASSWORD_MIN_LENGTH) {
      changeFormState({ passwordValid: t('validation.passwordLength', { length: PASSWORD_MIN_LENGTH }) });
    } else {
      resetError('passwordValid');
    }
  }, [password]);

  useEffect(() => {
    const fields = [email, password];
    const fieldErrors = [
      formState.passwordConfirmValid,
      formState.passwordValid,
      formState.usernameValid,
      formState.emailValid,
    ];

    if (fields.some((x) => x.length == 0) || fieldErrors.some((x) => x != null) || !agreeTerms) {
      changeFormState({ canSend: false });
    } else {
      changeFormState({ canSend: true });
    }
  }, [email, password, formState, agreeTerms]);

  const onSubmit = () => {
    changeFormState({ submitted: true });
    onAccountCreation(email, password, agreeTerms, consent);
  };

  const submitOnEnter = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key == 'Enter' && formState.canSend) {
        onSubmit();
      }
    },
    [formState.canSend]
  );

  return (
    <AuthFormWrapper className={className}>
      <AuthTitle variant="h4">{t('title')}</AuthTitle>

      {error && <AuthFormErrorMessage>{error.message}</AuthFormErrorMessage>}

      <CreateUserFormWrapper>
        <AuthField
          name="create-email"
          label={t('label.email')}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={(e) => submitOnEnter(e)}
          error={formState.emailValid != null}
          helperText={formState.emailValid || ''}
          InputProps={{
            endAdornment: <EmailAdornment position="end" />,
          }}
        />
        <AuthField
          name="create-password"
          type="password"
          label={t('label.password')}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={(e) => submitOnEnter(e)}
          error={formState.passwordValid != null}
          helperText={formState.passwordValid || ''}
          InputProps={{
            endAdornment: <PasswordAdornment position="end" />,
          }}
        />
      </CreateUserFormWrapper>

      <Box>
        <AuthFormControlLabel
          control={<StyledCheckbox onChange={(e) => setAgreeTerms(e.target.checked)} />}
          label={
            <Trans
              i18nKey="label.agreeTerms"
              t={t}
              components={{
                termsLink: <Link href="https://depix.ai/terms-of-use" target="_blank" />,
              }}
            />
          }
        />

        <AuthFormControlLabel
          control={<StyledCheckbox onChange={(e) => setConsent(e.target.checked)} />}
          label={t('label.consent')}
        />
      </Box>

      <AuthSubmitButton onClick={onSubmit} disabled={!formState.canSend || isLoading}>
        {t('label.submit')}
      </AuthSubmitButton>
    </AuthFormWrapper>
  );
};

const CreateUserFormWrapper = styled(Box)`
  --one-field-width: 300px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: calc(2 * var(--one-field-width) + ${(props) => props.theme.spacing(2)});
  justify-content: center;
  gap: ${(props) => props.theme.spacing(2)};

  ${(props) => props.theme.breakpoints.down('sm')} {
    max-width: calc(var(--one-field-width) + ${(props) => props.theme.spacing(2)});
    gap: ${(props) => props.theme.spacing(1)};
  }

  & > * {
    flex: 1 0 50%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: ${(props) => props.theme.spacing(0.5, 1)};
`;

// Yeah that's nice, but the PasswordValidator in user-manager has the last word.
const PASSWORD_LETTER = /[a-zA-Z]/;
const PASSWORD_NUMBER = /[0-9]/;
const PASSWORD_MIN_LENGTH = 8;
