const fabric = window.fabric;

class GaussianBlurFilter extends fabric.Image.filters.BaseFilter {
  /**
   * Filter type (name).
   * @type {string}
   */
  type = 'GaussianBlurFilter';

  /**
   * Standard deviation of Gaussian kernel.
   * @type {number}
   */
  blur;

  /**
   * Main parameter of the filter.
   * @type {string}
   */
  mainParameter = 'blur';

  /**
   * @private
   * Inverse square root of 2 pi.
   *
   * @type {number}
   * @static
   */
  static #INVERSE_SQRT_2_PI = 0.3989422804; // = 1 / sqrt(2 * pi)

  /**
   * Cached size of the texture size.
   * @type {Array.<number>}
   */
  #texureSize = [0, 0];

  /**
   * The size of the Gaussian kernel.
   * @type {number}
   */
  kernelSize;

  /**
   * Cached size of the last used Gaussian kernel.
   * @type {number}
   */
  #cachedKernelSize;

  /**
   * @private
   * Sigma value responsive to resolution. (20 is maximum sigma per 1080 pixel resoulution.)
   *
   * @type {number}
   * @static
   */
  static #SIGMA_PER_PIXEL = 10 / 1080;

  /**
   * Constructor
   * @param {Object} [options] Options object
   */
  constructor(options) {
    super();
    this.blur = 0.1;

    if (options) {
      this.setOptions(options);
    }
  }

  /**
   * Fragment source for the Temperature program
   */
  fragmentSource = `precision highp float;
        uniform sampler2D uTexture;
        uniform float uSigma;
        uniform vec2 uDirection;
        varying vec2 vTexCoord;
        const float kernelSize = float(__KERNEL_SIZE_PLACEHOLDER__);
  
        void main() {
            highp vec4 summedColor = vec4(0.0);
            highp float overallAlpha = 0.0;
            highp float overallScale = 0.0;

            for (float i = -kernelSize; i <= kernelSize; i++) {
                vec4 fetchedColor = texture2D(uTexture, vTexCoord + uDirection * i);
                float weight = (float(${
                  GaussianBlurFilter.#INVERSE_SQRT_2_PI
                }) / uSigma) * exp(-((i * i)/(2.0 * uSigma * uSigma)));
                
                float scaleWithAlpha = fetchedColor.a * weight;
                overallScale += weight;
                summedColor += fetchedColor * scaleWithAlpha;
                overallAlpha += scaleWithAlpha;
            }
            
            if (kernelSize == -1.0) {
                gl_FragColor = texture2D(uTexture, vTexCoord);
            } else {
                overallAlpha /= overallScale;
                summedColor /= overallAlpha * overallScale;
                gl_FragColor = vec4(summedColor.rgb, overallAlpha);
            }
        }      
      `;

  applyTo(options) {
    this.#texureSize = [options.sourceWidth, options.sourceHeight];
    this.webgl = options.webgl;

    if (options.webgl) {
      options.passes++;
      this._setupFrameBuffer(options);
      this.horizontal = false;
      this.applyToWebGL(options);
      this._swapTextures(options);
      this._setupFrameBuffer(options);
      this.horizontal = true;
      this.applyToWebGL(options);
      this._swapTextures(options);
    } else {
      this.applyTo2d(options);
    }
  }

  /**
   * @returns {number} Maximum sigma for current resolution.
   */
  getMaxSigma() {
    return this.#texureSize[0] * GaussianBlurFilter.#SIGMA_PER_PIXEL;
  }

  /**
   * @returns {number} Sigma value.
   */
  getSigma() {
    return this.blur * this.getMaxSigma();
  }

  /**
   * @returns {number} - Optimal kernel size.
   */
  getOptimalKernelSize() {
    if (this.webgl && this.getSigma() === 0) {
      return -1;
    }
    const sigma = parseInt(this.webgl ? this.getMaxSigma() : this.getSigma());

    return sigma * 3 + (sigma % 2 === 0);
  }

  /**
   * Retrieves the cached shader.
   * @param {Object} options
   * @param {WebGLRenderingContext} options.context The GL context used for rendering.
   * @param {Object} options.programCache A map of compiled shader programs, keyed by filter type.
   */
  retrieveShader(options) {
    if (!options.programCache.hasOwnProperty(this.type) || this.getOptimalKernelSize() !== this.#cachedKernelSize) {
      this.updateKernelSize();
      options.programCache[this.type] = this.createProgram(options.context);
    }
    return options.programCache[this.type];
  }

  updateKernelSize() {
    const kernelSize = this.getOptimalKernelSize();
    const kernelSizeDefine = 'const float kernelSize = float(';
    const kernelSizeDefineStartPosition = this.fragmentSource.indexOf(kernelSizeDefine);
    const kernelSizeDefineEndPosition = this.fragmentSource.indexOf(');', kernelSizeDefineStartPosition);
    this.fragmentSource =
      this.fragmentSource.slice(0, kernelSizeDefineStartPosition + kernelSizeDefine.length) +
      kernelSize.toString() +
      this.fragmentSource.slice(kernelSizeDefineEndPosition);
    this.#cachedKernelSize = kernelSize;
  }

  /**
   * Return WebGL uniform locations for this filter's shader.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {WebGLShaderProgram} program This filter's compiled shader program.
   */
  getUniformLocations(gl, program) {
    return {
      sigma: gl.getUniformLocation(program, 'uSigma'),
      direction: gl.getUniformLocation(program, 'uDirection'),
    };
  }

  /**
   * Send data from this filter to its shader program's uniforms.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
   */
  sendUniformData(gl, uniformLocations) {
    gl.uniform1f(uniformLocations.sigma, this.getSigma(this.#texureSize[0]));
    gl.uniform2f(
      uniformLocations.direction,
      this.horizontal * 1.0 * (1.0 / this.#texureSize[0]),
      !this.horizontal * 1.0 * (1.0 / this.#texureSize[1])
    );
  }

  applyTo2d(options) {
    const cv = window.cv;

    const sigma = this.getSigma();
    const kernelSize = this.getOptimalKernelSize();
    if (sigma === 0 || kernelSize === 0) {
      return;
    }

    const src = cv.matFromImageData(options.imageData);

    // Split into channels.
    const rgbaPlanes = new cv.MatVector();
    cv.split(src, rgbaPlanes);
    const R = rgbaPlanes.get(0);
    const G = rgbaPlanes.get(1);
    const B = rgbaPlanes.get(2);
    const A = rgbaPlanes.get(3);

    R.convertTo(R, cv.CV_16UC1);
    G.convertTo(G, cv.CV_16UC1);
    B.convertTo(B, cv.CV_16UC1);
    A.convertTo(A, cv.CV_16UC1);

    // Multiply by alpha channel.
    cv.multiply(R, A, R);
    cv.multiply(G, A, G);
    cv.multiply(B, A, B);

    // Apply Gaussian Blur.
    const horizontalKernelSize = new cv.Size(kernelSize, 1);
    const verticalKernelSize = new cv.Size(1, kernelSize);
    cv.GaussianBlur(R, R, verticalKernelSize, sigma, sigma);
    cv.GaussianBlur(G, G, verticalKernelSize, sigma, sigma);
    cv.GaussianBlur(B, B, verticalKernelSize, sigma, sigma);
    cv.GaussianBlur(A, A, verticalKernelSize, sigma, sigma);

    cv.GaussianBlur(R, R, horizontalKernelSize, sigma, sigma);
    cv.GaussianBlur(G, G, horizontalKernelSize, sigma, sigma);
    cv.GaussianBlur(B, B, horizontalKernelSize, sigma, sigma);
    cv.GaussianBlur(A, A, horizontalKernelSize, sigma, sigma);

    // Divide back by blurred alpha channel.
    cv.divide(R, A, R);
    cv.divide(G, A, G);
    cv.divide(B, A, B);

    R.convertTo(R, cv.CV_8UC1);
    G.convertTo(G, cv.CV_8UC1);
    B.convertTo(B, cv.CV_8UC1);
    A.convertTo(A, cv.CV_8UC1);

    // Prepare output.
    const output = new cv.MatVector();
    output.push_back(R);
    output.push_back(G);
    output.push_back(B);
    output.push_back(A);

    const result = new cv.Mat();
    cv.merge(output, result);

    options.imageData = new ImageData(new Uint8ClampedArray(result.data), result.cols, result.rows);

    // Free all allocated memory.
    src.delete();
    rgbaPlanes.delete();
    output.delete();
    R.delete();
    G.delete();
    B.delete();
    A.delete();
    result.delete();
  }
}

fabric.Image.filters.GaussianBlurFilter = GaussianBlurFilter;
fabric.Image.filters.GaussianBlurFilter.fromObject = fabric.Image.filters.BaseFilter.fromObject;

export default GaussianBlurFilter;
