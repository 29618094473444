export interface SerializedVector3D {
  x: number;
  y: number;
  z: number;
}

export default class Vector3D {
  public x: number;
  public y: number;
  public z: number;

  constructor(x?: number, y?: number, z?: number) {
    this.x = x || 0;
    this.y = y || 0;
    this.z = z || 0;
  }

  serialize(): SerializedVector3D {
    return { x: this.x, y: this.y, z: this.z };
  }

  static unserialize(state: SerializedVector3D): Vector3D {
    return new Vector3D(state.x, state.y, state.z);
  }

  getMagnitude(): number {
    // use pythagoras theorem to work out the magnitude of the vector
    return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
  }

  getNormalized(): Vector3D {
    const magnitude = this.getMagnitude();
    return new Vector3D(this.x / magnitude, this.y / magnitude, this.z / magnitude);
  }

  cross(otherVector: Vector3D): Vector3D {
    return new Vector3D(
      this.y * otherVector.z - this.z * otherVector.y,
      this.z * otherVector.x - this.x * otherVector.z,
      this.x * otherVector.y - this.y * otherVector.x
    );
  }

  dot(vector: Vector3D): number {
    return this.x * vector.x + this.y * vector.y + this.z * vector.z;
  }

  mulScalar(scalar: number): Vector3D {
    return new Vector3D(this.x * scalar, this.y * scalar, this.z * scalar);
  }

  mul(vector: Vector3D): Vector3D {
    return new Vector3D(this.x * vector.x, this.y * vector.y, this.z * vector.z);
  }

  div(vector: Vector3D): Vector3D {
    return new Vector3D(this.x / vector.x, this.y / vector.y, this.z / vector.z);
  }

  sub(vector: Vector3D): Vector3D {
    return new Vector3D(this.x - vector.x, this.y - vector.y, this.z - vector.z);
  }

  add(vector: Vector3D): Vector3D {
    return new Vector3D(this.x + vector.x, this.y + vector.y, this.z + vector.z);
  }

  abs(): Vector3D {
    return new Vector3D(Math.abs(this.x), Math.abs(this.y), Math.abs(this.z));
  }

  array(): number[] {
    return [this.x, this.y, this.z];
  }
}
