import React from 'react';
import PropTypes from 'prop-types';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import { Popper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Placement = {
  AUTO_END: 'auto-end',
  AUTO_START: 'auto-start',
  AUTO: 'auto',
  BOTTOM_END: 'bottom-end',
  BOTTOM_START: 'bottom-start',
  BOTTOM: 'bottom',
  LEFT_END: 'left-end',
  LEFT_START: 'left-start',
  LEFT: 'left',
  RIGHT_END: 'right-end',
  RIGHT_START: 'right-start',
  RIGHT: 'right',
  TOP_END: 'top-end',
  TOP_START: 'top-start',
  TOP: 'top',
};

const Placeable = ({ children, position, onClickAway = () => {}, placement = Placement.BOTTOM, className }) => {
  const virtualRef = {
    getBoundingClientRect() {
      return {
        top: position.y,
        left: position.x,
        bottom: position.y,
        right: position.x,
        width: 0,
        height: 0,
      };
    },
  };

  return (
    <>
      {position && (
        <ClickAwayListener onClickAway={onClickAway} mouseEvent="onMouseDown" touchEvent="onTouchStart">
          <Popper
            className={className}
            placement={placement}
            disablePortal={false}
            open={true}
            anchorEl={virtualRef}
            modifiers={[
              {
                name: 'preventOverflow',
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: false,
                  tether: false,
                  rootBoundary: 'document',
                },
              },
            ]}>
            <Box>{children}</Box>
          </Popper>
        </ClickAwayListener>
      )}
      {!position && <Box>{children}</Box>}
    </>
  );
};

Placeable.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
  onClickAway: PropTypes.func,
  placement: PropTypes.oneOf(Object.values(Placement)),
};

export default Placeable;
