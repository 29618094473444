import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import ImageGallery from '@components/ImageGallery/ImageGallery';
import UploadImageContext from '@contexts/UploadImageContext';

import useInstrumentation from '@hooks/UseInstrumentation';
import useStockImages from '@hooks/UseQueryStockImages';

export const STOCK_IMAGE_APIS = {
  UNSPLASH: 'UNSPLASH',
  PIXABAY: 'PIXABAY',
};

type Image = {
  id: string;
  height: number;
  width: number;
  description: string;
  url: string;
  subtitle: string;
};

const MAX_SIZE = 1920;

/**
 * Search images from unsplash api and display from image gallery
 * @param {func} closeGallery Function to close the gallery
 * @param {func} setImgURL Function to set the selected image url
 * @param {string} target Where the uploaded image will be used (e.g.: compose, cut, ...)
 * @return {*}
 */
export default function StockImages({ closeGallery, target, sx = {} }) {
  const { t } = useTranslation();
  const instrumentation = useInstrumentation();
  const [queryResult, setQueryResult] = useState([]);
  const [queryParam, setQueryParam] = useState({
    keyword: '', // search keyword
    totalPages: null, // total number of pages of results from keyword
    pageNum: 1, // current page number
  });
  const [totalPages, setTotalPages] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { uploadImageFromStock } = useContext(UploadImageContext);

  const queryStockImageCallback = (result) => {
    setQueryResult(result.imageList);
    setTotalPages(result.totalPages);
  };

  const { searchStockImages, isError: queryError } = useStockImages(queryStockImageCallback);

  useEffect(() => {
    if (queryError) {
      setErrorMessage(t('stockImages.errorMessage.failToLoad'));
    } else {
      setErrorMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryError]);

  useEffect(() => {
    searchStockImages(queryParam.keyword, queryParam.pageNum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam]);

  const onSearchSubmit = (keyword) => {
    setQueryParam({ ...queryParam, keyword: keyword.trim(), pageNum: 1 });
    setTotalPages(null);
  };

  const handlePreviousPage = () => {
    if (queryParam.pageNum >= 1) {
      setQueryParam({ ...queryParam, pageNum: queryParam.pageNum - 1 });
    }
  };

  const handleNextPage = () => {
    if (queryParam.pageNum <= totalPages) {
      setQueryParam({ ...queryParam, pageNum: queryParam.pageNum + 1 });
    }
  };

  const restrictedSize = (image: Image): number => {
    const largerSide = Math.max(image.width, image.height);
    if (largerSide <= MAX_SIZE) return null;

    return MAX_SIZE;
  };

  const handleSelectImage = (id: string) => {
    const img = queryResult.find((img) => {
      return img.id === id;
    });
    const maxSize = restrictedSize(img);

    uploadImageFromStock(img.apiName, img.downloadUrl, maxSize);
    closeGallery();
    instrumentation.imageUploaded(target, img.apiName);
  };

  const handleDragImage = (e, id: string) => {
    const img = queryResult.find((img) => {
      return img.id === id;
    });
    const maxSize = restrictedSize(img);

    const image = { source: img.apiName, downloadUrl: img.downloadUrl, maxSize };
    e.dataTransfer.setData('text', JSON.stringify(image));
    closeGallery();
  };

  const imgs = queryResult.map((img) => {
    const subtitle = () => {
      return (
        <div>
          {t('general.by') + ' '}
          <a href={img.copyright.authorLink} target="_blank" rel="noreferrer">
            {img.copyright.authorName}
          </a>
          {' ' + t('general.on') + ' '}
          <a href={img.copyright.sourceLink} target="_blank" rel="noreferrer">
            {img.copyright.sourceName}
          </a>
        </div>
      );
    };
    return {
      id: img.id,
      height: img.height,
      width: img.width,
      description: img.description,
      url: img.previewUrl,
      subtitle: subtitle(),
    };
  });

  return (
    <ImageGallery
      onSearch={onSearchSubmit}
      onBackward={handlePreviousPage}
      onForward={handleNextPage}
      onSelectImage={handleSelectImage}
      onDragImage={handleDragImage}
      imgs={imgs}
      currentPage={queryParam.pageNum}
      totalPage={totalPages}
      errorMessage={errorMessage}
      sx={sx}
    />
  );
}

StockImages.propTypes = {
  closeGallery: PropTypes.func,
  setImgURL: PropTypes.func,
  target: PropTypes.string.isRequired,
  sx: PropTypes.object,
};
