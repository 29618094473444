import { DepixObject, MaskOption } from '@libs/DepixObject';

interface FabricLoadableImageFields {
  type: string;
  repeat: string;
  fill: string;
  uiElements: any[];
  child: any[];
  parent: any | null;
  name: string;
  depixObject: DepixObject;
  fitToMask?: boolean;
}

export interface LoadImageOptions {
  invertMask?: boolean;
  ignoreMask?: MaskOption;
  keepSize?: boolean;
  fullResolution?: boolean;
}

/**
 * fabric subclass
 * @class fabric.LoadableImage
 * @extends fabric.Image
 * @return {fabric.LoadableImage} thisArg
 *
 */
class LoadableImage extends fabric.Image implements FabricLoadableImageFields {
  public type: string;
  public repeat: string;
  public fill: string;
  public uiElements: any[];
  public child: any[];
  public parent: any | null;
  public name: string;
  public depixObject: DepixObject;
  public fitToMask?: boolean;

  constructor(id, type, depixObject, options) {
    super(options);
    this.type = type;
    this.repeat = 'no-repeat';
    this.fill = 'transparent';
    this.uiElements = [];
    this.child = [];
    this.parent = null;

    if (options) this.setOptions(options);

    this.name = id;
    this.depixObject = depixObject;
  }

  updateElement(element) {
    (this as any)._initElement(element);
    this.setCoords();
  }

  updateOptions(options) {
    this.setOptions(options);
  }

  /**
   * Will recompute the image from the depixObject
   */
  loadImage({ invertMask, ignoreMask, keepSize, fullResolution }: LoadImageOptions = {}) {
    return new Promise((resolve, reject) => {
      this.depixObject
        ?.getAlphaImage(this.padding, this.fitToMask, invertMask, ignoreMask, fullResolution)
        .then((image) => {
          const [originalW, originalH] = (this as any).getSize();
          this.updateElement(image);
          if (keepSize) {
            const [newSizeW, newSizeH] = (this as any).getSize();
            this.scaleX = (this.scaleX / newSizeW) * originalW;
            this.scaleY = (this.scaleY / newSizeH) * originalH;
          }
          resolve(image);
        })
        .catch(reject);
    });
  }
}

(fabric as any).LoadableImage = LoadableImage;

export type FabricLoadableImage = LoadableImage;
