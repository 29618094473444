import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@components/Ui/Icons/CloseIcon';
import VideoHelpIcon from '@components/Ui/Icons/VideoHelpIcon';
import MenuList from '@mui/material/MenuList';
import Drawer from '@mui/material/Drawer';

import { useTranslation } from 'react-i18next';

import Button from '../Ui/Button';
import useInstrumentation from '@hooks/UseInstrumentation';
import { AppVersion } from '@components/Help/AppVersion';
import VideoDialog from '../VideoPlayer/VideoDialog';
import { Videos } from '../VideoPlayer/VideoPlayer';
import { UserPilotToken } from '@contexts/UserPilotContext';
import { HelpItem } from '@components/Help/HelpItem';
import TourHelpIcon from '@components/Ui/Icons/TourHelpIcon';
import { styled } from '@mui/material/styles';

export const CONTACT_FORM = 'https://depix.ai/support#contact-form';

const HelpDrawer = ({ open, onClose }) => {
  const [openVideo, setOpenVideo] = useState(null);

  const { t } = useTranslation();
  const { getSupportButtonClicked } = useInstrumentation();

  const handleCloseVideo = () => {
    setOpenVideo(null);
  };

  const videoTitle = () => {
    switch (openVideo) {
      case Videos.SEGMENTATION:
        return t('help.intro.steps.video.title');
      case Videos.COMPOSITION:
        return t('help.composition.steps.video.title');
      case Videos.ADVANCED_FEATURES:
        return t('help.advancedFeature.steps.video.title');
      default:
        return '';
    }
  };

  const onClickIntroductionVideo = () => {
    onClose();
    setOpenVideo(Videos.SEGMENTATION);
  };

  const onClickCompositionVideo = () => {
    onClose();
    setOpenVideo(Videos.COMPOSITION);
  };

  const onClickAdvancedFeatureVideo = () => {
    onClose();
    setOpenVideo(Videos.ADVANCED_FEATURES);
  };

  return (
    <>
      <Drawer open={open} onClose={onClose} anchor="right" PaperProps={{ sx: { justifyContent: 'space-between' } }}>
        <Box sx={{ m: 3 }} role="drawer" aria-label={t('help.ariaLabel.drawer')}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: '1.75rem', fontWeight: 500, textAlign: 'left' }}>
              {t('general.help')}
            </Typography>
            <IconButton aria-label={t('help.ariaLabel.closeIcon')} sx={{ height: 'auto' }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <MenuList aria-label={t('help.ariaLabel.segmentationVideo')}>
            <HelpItem
              onClick={onClickIntroductionVideo}
              icon={<VideoHelpIcon />}
              title={t('help.intro.title')}
              description={t('help.intro.description')}
            />
          </MenuList>
          <MenuList aria-label={t('help.ariaLabel.compositionVideo')}>
            <HelpItem
              onClick={onClickCompositionVideo}
              icon={<VideoHelpIcon />}
              title={t('help.composition.title')}
              description={t('help.composition.description')}
            />
          </MenuList>
          <MenuList aria-label={t('help.ariaLabel.advancedFeatureVideo')}>
            <HelpItem
              onClick={onClickAdvancedFeatureVideo}
              icon={<VideoHelpIcon />}
              title={t('help.advancedFeature.title')}
              description={t('help.advancedFeature.description')}
            />
          </MenuList>
          <MenuList aria-label={t('help.cutOut.aria')}>
            <HelpItem
              userPilotToken={UserPilotToken.CUT_OUT}
              onClick={onClose}
              icon={<TourHelpIcon />}
              title={t('help.cutOut.title')}
              description={t('help.cutOut.description')}
            />
          </MenuList>
          <MenuList aria-label={t('help.lightAndShadow.aria')}>
            <HelpItem
              userPilotToken={UserPilotToken.LIGHT_AND_SHADOW}
              onClick={onClose}
              icon={<TourHelpIcon />}
              title={t('help.lightAndShadow.title')}
              description={t('help.lightAndShadow.description')}
            />
          </MenuList>
          <MenuList aria-label={t('help.changingOrder.aria')}>
            <HelpItem
              userPilotToken={UserPilotToken.CHANGING_ORDER}
              onClick={onClose}
              icon={<TourHelpIcon />}
              title={t('help.changingOrder.title')}
              description={t('help.changingOrder.description')}
            />
          </MenuList>
          <MenuList aria-label={t('help.createBackgroundLayer.aria')}>
            <HelpItem
              userPilotToken={UserPilotToken.CREATE_BACKGROUND_LAYER}
              onClick={onClose}
              icon={<TourHelpIcon />}
              title={t('help.createBackgroundLayer.title')}
              description={t('help.createBackgroundLayer.description')}
            />
          </MenuList>
          <MenuList aria-label={t('help.cutProjectedShadow.aria')}>
            <HelpItem
              userPilotToken={UserPilotToken.CUT_PROJECTED_SHADOW}
              onClick={onClose}
              icon={<TourHelpIcon />}
              title={t('help.cutProjectedShadow.title')}
              description={t('help.cutProjectedShadow.description')}
            />
          </MenuList>
          <MenuList aria-label={t('help.proTips.aria')}>
            <HelpItem
              userPilotToken={UserPilotToken.PRO_TIPS}
              onClick={onClose}
              icon={<TourHelpIcon />}
              title={t('help.proTips.title')}
              description={t('help.proTips.description')}
            />
          </MenuList>
        </Box>

        <Box sx={{ width: '100%', p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ width: '100%', py: 2 }}>
            <Typography fontWeight={500}>{t('help.support.FAQ1')}</Typography>
            <Typography fontWeight={500}>{t('help.support.FAQ2')}</Typography>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <a
              style={{ width: '100%', padding: 0, margin: 0 }}
              href={CONTACT_FORM}
              target="_blank"
              rel="noreferrer noopener"
              onClick={getSupportButtonClicked}>
              <Button
                variant="contained"
                aria-label={t('help.ariaLabel.supportButton')}
                sx={{ width: '100%', borderRadius: '20px', textTransform: 'none' }}>
                <Typography fontWeight={500}>{t('help.support.button')}</Typography>
              </Button>
            </a>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <AppVersion />
          </Box>
        </Box>
      </Drawer>
      <VideoDialog videoName={openVideo} title={videoTitle()} open={!!openVideo} onClose={handleCloseVideo} />
    </>
  );
};

HelpDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default HelpDrawer;
