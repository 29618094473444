import { DepixObject } from '@libs/DepixObject';
import Camera from '@libs/Geometry/Camera';
import { ScreenCorners } from '@libs/Geometry/ScreenCorners';
import Transformation2D from '@libs/Geometry/Transformation2D';
import Transformation3D from '@libs/Geometry/Transformation3D';
import { useState, useCallback } from 'react';
import SceneComponent from '../Libs/Geometry/SceneComponent';
import Vector3D from '../Libs/Geometry/Vector3D';
import { v4 as uuidv4 } from 'uuid';
import Vector2D from '@root/Libs/Geometry/Vector2D';

export type ComponentId = string;

export interface UseSceneComponents {
  componentCount: number;
  addComponent: (
    depixObject: DepixObject,
    transform: Transformation3D,
    dimensions: Vector3D,
    isFix?: boolean,
    isInfill?: boolean
  ) => ComponentId;
  removeComponent: (id: ComponentId) => void;
  transformComponent: (id: ComponentId, transform: Transformation3D) => void;
  scaleComponent: (id: ComponentId, scaleVector: Vector3D) => void;
  getFixState: (id: ComponentId) => boolean | null;
  hasId: (id: ComponentId) => boolean;
  getIds: () => ComponentId[];
  getMaskedFragment2DTransform: (id: ComponentId, camera: Camera) => Transformation2D;
  getInfillFragment2DTransform: (id: ComponentId, camera: Camera) => Transformation2D;
  getScreenCorners: (id: ComponentId, camera: Camera) => ScreenCorners;
  getDimensions: (id: ComponentId) => Vector3D;
  setDimensions: (id: ComponentId, newDimensions: Vector3D) => void;
  getScreenDimensions: (id: ComponentId, camera: Camera) => [number, number];
  get3DTransform: (id: ComponentId) => Transformation3D;
  set3DTransform: (id: ComponentId, transform: Transformation3D) => void;
  getOrderedComponents: () => ComponentId[];
  getBottomTopVertex: (id: ComponentId) => [Vector3D, Vector3D];
  getDepixObject: (id: ComponentId) => DepixObject;
  setDepixObject: (id: ComponentId, depixObject: DepixObject) => void;
  removeFixAndInfillComponents: () => void;
  save: () => any;
  load: (state: any) => Promise<void>;
  hasShadow: () => boolean;
  hasRelighting: () => boolean;
  countInfilledComponents: () => number;
  countOcclusionComponents: () => number;
  countErasedComponents: () => number;
}

/**
 * Handle position and assets of components present
 * in a scene
 * @return {*}
 */
function useSceneComponents(): UseSceneComponents {
  const [components, setComponents] = useState<Map<ComponentId, SceneComponent>>(new Map());

  const save = useCallback(() => {
    const state = [];
    for (const [key, value] of components.entries()) {
      state.push({ name: key, component: value.serialize() });
    }
    return state;
  }, [components]);

  const load = (state: any) => {
    const promises = [];
    const componentMap = new Map();
    for (const componentState of state) {
      promises.push(SceneComponent.unserialize(componentState.component));
    }
    return new Promise<void>((resolve) => {
      Promise.all(promises).then((components) => {
        for (let i = 0; i < components.length; ++i) {
          componentMap.set(state[i].name, components[i]);
        }
        setComponents(componentMap);
        resolve();
      });
    });
  };

  const addComponent = useCallback(
    (depixObject, transform, dimensions, isFix = false, isInfill = false) => {
      const componentId = uuidv4();
      const newComponent = new SceneComponent(depixObject, transform, dimensions, isFix, isInfill);
      const updateMap = new Map(components.set(componentId, newComponent));
      setComponents(updateMap);
      return componentId;
    },
    [components]
  );

  const removeComponent = useCallback(
    (id) => {
      if (components.delete(id)) {
        setComponents(new Map(components));
      }
    },
    [components]
  );

  const removeFixAndInfillComponents = useCallback(() => {
    let changed = false;
    for (const [key, value] of components.entries()) {
      if (value.isFix || value.isInfill) {
        components.delete(key);
        changed = true;
      }
    }
    if (changed) {
      setComponents(new Map(components));
    }
  }, [components]);

  const transformComponent = useCallback(
    (id: ComponentId, transform: Transformation3D) => {
      const component = components.get(id);
      if (component) {
        component.setTransformation(transform);
      }
    },
    [components]
  );

  const scaleComponent = useCallback(
    (id: ComponentId, scaleVector: Vector3D) => {
      const component = components.get(id);
      if (component) {
        const newDimensions = component.dimensions.mul(scaleVector).abs();
        newDimensions.x = scaleVector.x < 0 ? -newDimensions.x : newDimensions.x;
        newDimensions.y = scaleVector.y < 0 ? -newDimensions.y : newDimensions.y;
        component.setDimensions(newDimensions);
      }
    },
    [components]
  );

  const getMaskedFragment2DTransform = useCallback(
    (id: ComponentId, camera: Camera) => {
      const component = components.get(id);
      if (component) {
        const [objectWidth, objectHeight] = component.getMaskFragmentSize();
        return component.transform2D(camera, objectWidth, objectHeight);
      }
      return null;
    },
    [components]
  );

  const getInfillFragment2DTransform = useCallback(
    (id: ComponentId, camera: Camera) => {
      const component = components.get(id);
      if (component) {
        const [objectWidth, objectHeight] = component.getInfillFragmentSize();
        return component.transform2D(camera, objectWidth, objectHeight);
      }
      return null;
    },
    [components]
  );

  const getScreenCorners = useCallback(
    (id: ComponentId, camera: Camera): ScreenCorners => {
      const component = components.get(id);
      if (component) {
        const [width, height] = component.getMaskFragmentSize();
        const transformation = component.transform2D(camera, width, height);
        const boundingBox = transformation.dot([
          [-width / 2, -height / 2, 1],
          [width / 2, -height / 2, 1],
          [-width / 2, height / 2, 1],
          [width / 2, height / 2, 1],
        ]);
        return {
          topLeft: new Vector2D(boundingBox[0][0], boundingBox[0][1]),
          topRight: new Vector2D(boundingBox[1][0], boundingBox[1][1]),
          bottomLeft: new Vector2D(boundingBox[2][0], boundingBox[2][1]),
          bottomRight: new Vector2D(boundingBox[3][0], boundingBox[3][1]),
        };
      }
      return null;
    },
    [components]
  );

  const getScreenDimensions = useCallback(
    (id: ComponentId, camera: Camera) => {
      const component = components.get(id);
      if (component) {
        return component.getScreenDimensions(camera);
      }
      return null;
    },
    [components]
  );

  const get3DTransform = useCallback(
    (id: ComponentId) => {
      const component = components.get(id);
      if (component) {
        return component.transform.clone();
      }
      return null;
    },
    [components]
  );

  const getDimensions = useCallback(
    (id: ComponentId) => {
      const component = components.get(id);
      if (component) {
        return component.dimensions;
      }
      return null;
    },
    [components]
  );

  const setDimensions = useCallback(
    (id: ComponentId, newDimensions: Vector3D) => {
      const component = components.get(id);
      if (component) {
        component.dimensions = newDimensions;
      }
    },
    [components]
  );

  const getDepixObject = useCallback(
    (id: ComponentId) => {
      const component = components.get(id);
      if (component) {
        return component.asset;
      }
      return null;
    },
    [components]
  );

  const setDepixObject = useCallback(
    (id: ComponentId, depixObject: DepixObject) => {
      const component = components.get(id);
      if (component) {
        component.setAsset(depixObject);
        return;
      }
    },
    [components]
  );

  const getFixState = useCallback(
    (id: ComponentId) => {
      const component = components.get(id);
      if (component) {
        return component.isFix;
      }
      return null;
    },
    [components]
  );

  const set3DTransform = useCallback(
    (id: ComponentId, transform: Transformation3D) => {
      const component = components.get(id);
      if (component) {
        component.transform = transform;
      }
    },
    [components]
  );

  const getOrderedComponents = useCallback((): ComponentId[] => {
    // Order based on z distance w.r.t. the camera
    const mapAsc = new Map(
      [...components.entries()].sort((a, b) => {
        return a[1].transform.t.z > b[1].transform.t.z ? 1 : -1;
      })
    );
    return [...mapAsc.keys()];
  }, [components]);

  const getIds = useCallback((): ComponentId[] => {
    return [...components.keys()];
  }, [components]);

  const hasId = useCallback(
    (id: ComponentId) => {
      return components.has(id);
    },
    [components]
  );

  const getBottomTopVertex = useCallback(
    (id: ComponentId): [Vector3D, Vector3D] => {
      // Get object bottom ground position
      const objectTransform = get3DTransform(id);
      const objectDimensions = getDimensions(id);
      const centerPose = objectTransform.t;
      const halfHeight = objectDimensions.y / 2;
      const bottomVertex = new Vector3D(centerPose.x, centerPose.y - halfHeight, centerPose.z);
      const topVertex = new Vector3D(centerPose.x, centerPose.y + halfHeight, centerPose.z);

      return [bottomVertex, topVertex];
    },
    [get3DTransform, getDimensions]
  );

  const hasRelighting = () => {
    let relighting = false;
    for (const component of components.values()) {
      relighting = relighting || component?.asset?.prop?.relight;
    }

    return relighting;
  };

  const hasShadow = () => {
    let shadow = false;
    for (const component of components.values()) {
      shadow = shadow || component?.asset?.prop?.shadowVisible;
    }

    return shadow;
  };

  const countInfilledComponents = () => {
    let infilledComponents = 0;
    for (const component of components.values()) {
      if (component?.asset?.isInfilled && !component?.asset?.isErased) {
        infilledComponents++;
      }
    }

    return infilledComponents;
  };

  const countErasedComponents = () => {
    let erasedComponents = 0;
    for (const component of components.values()) {
      if (component?.asset?.isErased) {
        erasedComponents++;
      }
    }

    return erasedComponents;
  };

  const countOcclusionComponents = () => {
    let occlusionComponents = 0;
    for (const component of components.values()) {
      if (component?.isFix) {
        occlusionComponents++;
      }
    }

    return occlusionComponents + countInfilledComponents();
  };

  return {
    componentCount: components.size,
    addComponent,
    removeComponent,
    transformComponent,
    scaleComponent,
    getFixState,
    hasId,
    getIds,
    getMaskedFragment2DTransform,
    getInfillFragment2DTransform,
    getScreenCorners,
    getDimensions,
    setDimensions,
    getScreenDimensions,
    get3DTransform,
    set3DTransform,
    getOrderedComponents,
    getBottomTopVertex,
    getDepixObject,
    setDepixObject,
    removeFixAndInfillComponents,
    save,
    load,
    hasShadow,
    hasRelighting,
    countInfilledComponents,
    countOcclusionComponents,
    countErasedComponents,
  };
}

export default useSceneComponents;
