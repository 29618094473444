import { ShareToButtonProps } from '@components/SharedImage/ShareToButtonProps';
import { IconButton, styled } from '@mui/material';
import DiscordIcon from '@components/Ui/Icons/DiscordIcon';
import React from 'react';

interface ShareToDiscordProps extends ShareToButtonProps {}

export const ShareToDiscord = ({ onClick }: ShareToDiscordProps) => {
  return (
    <DiscordIconButton onClick={onClick}>
      <DiscordIcon />
    </DiscordIconButton>
  );
};

const DiscordIconButton = styled(IconButton)`
  background: #5865f2;
`;
