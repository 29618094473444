export const GRAPHQL_ERROR_CODES = {
  UNKNOWN: 'UNKNOWN',
  RATE_LIMIT: 'RATE_LIMIT',
};

export const isGraphqlError = (error) => {
  return error?.graphQLErrors !== undefined;
};

export const decomposeGraphqlError = (error) => {
  if (!error?.graphQLErrors || error.graphQLErrors.length === 0) {
    return { errorCode: GRAPHQL_ERROR_CODES.UNKNOWN, exception: null, isGraphqlError: false };
  }
  const errorCode = error.graphQLErrors[0].extensions?.code;
  const exception = error.graphQLErrors[0].extensions?.exception;

  return { errorCode, exception, isGraphqlError: isGraphqlError(error) };
};
