/* eslint-disable max-len */

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const EditSegmentationIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <rect
      x="5.89062"
      y="2.92188"
      width="23"
      height="4"
      transform="rotate(45 5.89062 2.92188)"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M3.81395 11.4717C3.88053 11.3029 4.11947 11.3029 4.18605 11.4717L5.0995 13.7878C5.11983 13.8394 5.16063 13.8802 5.21218 13.9005L7.52826 14.8139C7.69709 14.8805 7.69709 15.1195 7.52826 15.1861L5.21218 16.0995C5.16063 16.1198 5.11983 16.1606 5.0995 16.2122L4.18605 18.5283C4.11947 18.6971 3.88053 18.6971 3.81395 18.5283L2.9005 16.2122C2.88017 16.1606 2.83937 16.1198 2.78782 16.0995L0.471743 15.1861C0.302914 15.1195 0.302914 14.8805 0.471743 14.8139L2.78782 13.9005C2.83937 13.8802 2.88017 13.8394 2.9005 13.7878L3.81395 11.4717Z"
      fill="currentColor"
    />
    <path
      d="M14.3139 0.471743C14.3805 0.302914 14.6195 0.302914 14.6861 0.471743L15.4581 2.42925C15.4784 2.48079 15.5192 2.52159 15.5708 2.54192L17.5283 3.31395C17.6971 3.38053 17.6971 3.61947 17.5283 3.68605L15.5708 4.45808C15.5192 4.47841 15.4784 4.51921 15.4581 4.57076L14.6861 6.52826C14.6195 6.69709 14.3805 6.69709 14.3139 6.52826L13.5419 4.57076C13.5216 4.51921 13.4808 4.47841 13.4292 4.45808L11.4717 3.68605C11.3029 3.61947 11.3029 3.38053 11.4717 3.31395L13.4292 2.54192C13.4808 2.52159 13.5216 2.48079 13.5419 2.42924L14.3139 0.471743Z"
      fill="currentColor"
    />
    <line x1="10.5303" y1="8.53033" x2="8.53033" y2="10.5303" stroke="#121213" strokeWidth="1.5" fill="none" />
  </SvgIcon>
);
