import { Box, styled } from '@mui/material';
import { SharedImage } from '@libs/SharedImage/SharedImage';
import { ShareActions } from '@components/SharedImage/ShareActions';
import { CallToActions } from '@components/SharedImage/CallToActions';
import { TrendingImages } from '@components/SharedImage/TrendingImages';

interface SharedImageAsideProps {
  sharedImage: SharedImage;
  trendingImages?: SharedImage[];
  className?: string;
}

export const SharedImageAside = ({ sharedImage, trendingImages = [], className }: SharedImageAsideProps) => {
  return (
    <Wrapper className={className}>
      <ShareActions sharedImage={sharedImage} />
      <CallToActions sharedImage={sharedImage} />
      {trendingImages && <TrendingImages images={trendingImages} />}
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing(8)};

  ${(props) => props.theme.breakpoints.down('md')} {
    gap: ${(props) => props.theme.spacing(6)};
  }

  ${(props) => props.theme.breakpoints.down('lg')} {
    gap: ${(props) => props.theme.spacing(4)};
  }
`;
