/* eslint-disable max-len */
import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const EraseObjectIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 19" fill="none">
      <path
        d="M18.2501 17.4813H10.2157L13.4782 14.2282L18.7844 8.92193C19.2029 8.49893 19.4377 7.92791 19.4377 7.33287C19.4377 6.73782 19.2029 6.1668 18.7844 5.7438L14.5376 1.49693C14.1158 1.07615 13.5443 0.839844 12.9485 0.839844C12.3527 0.839844 11.7812 1.07615 11.3594 1.49693L6.05318 6.80318L0.746928 12.1094C0.326149 12.5312 0.0898438 13.1027 0.0898438 13.6985C0.0898438 14.2943 0.326149 14.8658 0.746928 15.2876L4.22505 18.7657C4.2952 18.8355 4.37863 18.8906 4.47041 18.9276C4.56219 18.9646 4.66047 18.9829 4.75943 18.9813H18.2501C18.449 18.9813 18.6397 18.9023 18.7804 18.7616C18.921 18.621 19.0001 18.4302 19.0001 18.2313C19.0001 18.0324 18.921 17.8416 18.7804 17.701C18.6397 17.5603 18.449 17.4813 18.2501 17.4813ZM12.4188 2.5563C12.5606 2.41829 12.7506 2.34106 12.9485 2.34106C13.1464 2.34106 13.3364 2.41829 13.4782 2.5563L17.7157 6.80318C17.7863 6.87216 17.8423 6.95455 17.8806 7.04551C17.9189 7.13647 17.9387 7.23417 17.9387 7.33287C17.9387 7.43156 17.9189 7.52926 17.8806 7.62022C17.8423 7.71118 17.7863 7.79357 17.7157 7.86255L12.9438 12.6344L7.64693 7.32818L12.4188 2.5563ZM5.0688 17.4813L1.8063 14.2282C1.66829 14.0864 1.59106 13.8964 1.59106 13.6985C1.59106 13.5006 1.66829 13.3106 1.8063 13.1688L6.58755 8.39693L11.8844 13.6938L8.09693 17.4813H5.0688Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
