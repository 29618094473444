import MuiDialogTitle from '@mui/material/DialogTitle';
import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';

type DialogTitleSize = 'medium' | 'large';

interface DialogTitleProps {
  size?: DialogTitleSize;
  className?: string;
  children?: ReactNode;
}

export const DialogTitle = ({ size = 'large', className, children }: DialogTitleProps) => {
  return (
    <StyledDialogTitle className={className} size={size}>
      {children}
    </StyledDialogTitle>
  );
};

const StyledDialogTitle = styled(MuiDialogTitle)<{ size: DialogTitleSize }>`
  padding: 0;
  margin-bottom: ${(props) => props.theme.spacing(1)};

  &.MuiDialogTitle-root {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: ${(props) => (props.size == 'large' ? '2rem' : '1.75rem')};
    font-weight: 500;
    line-height: 1.3;
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    &.MuiDialogTitle-root {
      font-size: ${(props) => (props.size == 'large' ? '2rem' : '1.75rem')};
      line-height: 1.6;
    }
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    &.MuiDialogTitle-root {
      font-size: ${(props) => (props.size == 'large' ? '2.5rem' : '2rem')};
    }
  }
`;
