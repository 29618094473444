import React, { createContext, ReactNode, useEffect, useState } from 'react';

import { useSegmentation } from '@contexts/Segmentation/UseSegmentation';
import { useActiveObject, useScene } from './SceneContext';

export enum SelectMode {
  INFILLING = 'selectInfilling',
  GROUND = 'selectGround',
  OBJECT = 'selectObject',
  ERASE = 'eraseObject',
  NONE = 'none',
}

export interface ComposeSelectModeContextValue {
  selectMode: SelectMode;
  setSelectMode: (mode: SelectMode) => void;
  resetSelectMode: () => void;
}

export const ComposeSelectModeContext = createContext<ComposeSelectModeContextValue>(null);

interface ComposeSelectModeProviderProps {
  selectMode?: SelectMode;
  children?: ReactNode;
}

const ComposeSelectModeProvider = ({
  selectMode: initialSelectMode = SelectMode.NONE,
  children,
}: ComposeSelectModeProviderProps) => {
  const [selectMode, setSelectMode] = useState<SelectMode>(initialSelectMode);
  const segmentation = useSegmentation();
  const { activeObject } = useActiveObject();
  const scene = useScene();

  const setSelectModeWithOptions = (mode) => {
    if (Object.values(SelectMode).indexOf(mode) > -1) {
      setSelectMode(mode);
    }
  };

  const resetSelectMode = () => {
    setSelectMode(SelectMode.NONE);
    segmentation.reset();
    segmentation.setEnabled(false);
  };

  useEffect(() => {
    if (scene.backgroundImage) {
      resetSelectMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene.backgroundImage]);

  useEffect(() => {
    if (activeObject) {
      resetSelectMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject]);

  const value = {
    selectMode,
    setSelectMode: setSelectModeWithOptions,
    resetSelectMode,
  };

  return <ComposeSelectModeContext.Provider value={value}>{children}</ComposeSelectModeContext.Provider>;
};

export default ComposeSelectModeProvider;
