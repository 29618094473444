import { OnboardingDialog } from '@components/Onboarding/OnboardingDialog';
import { AudienceType } from '@libs/Profile/Audience';
import { GoalType } from '@libs/Profile/Goal';
import Routes from '@root/routes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUserProfile from '@hooks/UseUserProfile';
import _ from 'lodash';

interface OnboardingPageProps {
  className?: string;
}

const audiences = _.shuffle([
  AudienceType.E_COMMERCE,
  AudienceType.SOCIAL_MEDIA,
  AudienceType.PERSONAL,
  AudienceType.CREATIVE,
]);
const goals = _.shuffle([GoalType.REMOVE_BACKGROUND, GoalType.IMAGE_CLEANUP, GoalType.PHOTOREALISTIC]);

export const OnboardingPage = ({ className }: OnboardingPageProps) => {
  const navigate = useNavigate();
  const userProfile = useUserProfile();

  const [selectedAudience, setSelectedAudience] = useState<AudienceType>(userProfile.metadata?.audience);
  const [selectedGoals, setSelectedGoals] = useState<GoalType[]>(userProfile.metadata?.goals || []);

  const handleSelectAudience = (audience: AudienceType): Promise<any> => {
    setSelectedAudience(audience);
    return userProfile.setAudience(audience);
  };

  const handleSelectGoals = (goals: GoalType[]): Promise<any> => {
    setSelectedGoals(goals);
    return userProfile.setGoals(goals);
  };

  const handleCloseDialog = () => {
    userProfile.refresh().then(() => navigate(Routes.home(), { replace: true }));
  };

  useEffect(() => {
    if (userProfile?.metadata?.audience && !selectedAudience) {
      setSelectedAudience(userProfile?.metadata?.audience);
    }
  }, [userProfile.metadata?.audience]);

  useEffect(() => {
    if (userProfile?.metadata?.goals?.length > 0 && selectedGoals.length == 0) {
      setSelectedGoals(userProfile?.metadata?.goals);
    }
  }, [userProfile.metadata?.goals]);

  return (
    <OnboardingDialog
      open={true}
      audiences={audiences}
      selectedAudience={selectedAudience}
      onSelectAudience={handleSelectAudience}
      goals={goals}
      selectedGoals={selectedGoals}
      onSelectGoals={handleSelectGoals}
      onClose={handleCloseDialog}
      className={className}
    />
  );
};
