import React from 'react';
import { CropToolContext } from '@contexts/CropToolContext';

function useCropTool(): CropToolContext {
  const context = React.useContext(CropToolContext);

  if (!context) {
    throw new Error('useCropTool must be used within a CropToolContext');
  }
  return context;
}

export default useCropTool;
