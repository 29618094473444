export const isOpenCVLoaded = () => window.__isCVLoaded;

const loadOpenCvScipt = () => {
  return new Promise((resolve) => {
    const pathsConfig = {
      asm: '/opencv/asm/opencv_js.js',
      wasm: '/opencv/wasm/opencv_js.js',
      threads: '/opencv/threads/opencv_js.js',
      simd: '/opencv/simd/opencv_js.js',
      threadsSimd: '/opencv/threads_simd/opencv_js.js',
    };
    window.loadOpenCV(pathsConfig, resolve);
  });
};

export const loadOpenCv = async () => {
  if (window.loadOpenCV && !isOpenCVLoaded()) {
    await loadOpenCvScipt();

    window.cv = await window.cv();
    window.__isCVLoaded = true;
  }

  return window.cv;
};
