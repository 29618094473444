import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import MuiButton from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import Loading from '@components/Loading/Loading';

const OutlinedButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color, theme }) => ({
  padding: theme.spacing(1, 3),
  borderRadius: theme.borderRadius.medium,
}));

const FloatingButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color, theme }) => ({
  margin: 0,
  textTransform: 'none',
  color: theme.palette[color].contrastText,
  transition: '0.2s ease-in filter',
  '& .MuiButton-startIcon': {
    marginRight: 0,
    marginLeft: 0,
    padding: theme.spacing(1),
    borderRadius: '50%',
    backgroundColor: theme.palette[color].main,
    boxShadow: theme.elevation?.button,
  },
  '&:hover': {
    filter: 'brightness(90%)',
    backgroundColor: 'transparent',
  },
}));

const RegularButton = ({ onClick, color = 'secondary.contrastText', startIcon, children, sx, ...props }) => (
  <MuiButton
    component="div"
    sx={{
      color,
      fontWeight: 600,
      textTransform: 'none',
      height: '45px',
      borderRadius: '30px',
      p: 0,
      border: `2.5px solid`,
      boxShadow: (theme) => theme.elevation.button,
      ...sx,
    }}
    onClick={onClick}
    startIcon={startIcon}
    {...props}>
    <Box sx={{ pl: startIcon ? 1 : 0 }}>{children}</Box>
  </MuiButton>
);

RegularButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  startIcon: PropTypes.node,
  children: PropTypes.node,
  sx: PropTypes.object,
};

const CompactButton = ({ onClick, color = 'secondary.contrastText', startIcon, children, ...props }) => (
  <MuiButton
    sx={{ color, textTransform: 'none', height: '45px', width: '242px', borderRadius: '30px', p: 0 }}
    onClick={onClick}
    startIcon={startIcon}
    {...props}>
    {children}
  </MuiButton>
);

CompactButton.propTypes = {
  color: PropTypes.string,
  startIcon: PropTypes.node,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

const ContainedButton = ({ onClick, color = 'primary', startIcon, children, className, sx, ...props }) => (
  <MuiButton
    sx={{
      color: `${color}.contrastText`,
      backgroundColor: `${color}.main`,
      textTransform: 'uppercase',
      borderRadius: '30px',
      lineHeight: '1.1rem',
      p: (theme) => theme.spacing(1, 4),
      mb: (theme) => theme.spacing(1),
      ...sx,
    }}
    className={className}
    variant="contained"
    onClick={onClick}
    startIcon={startIcon}
    {...props}>
    {children}
  </MuiButton>
);

ContainedButton.propTypes = {
  color: PropTypes.string,
  startIcon: PropTypes.node,
  children: PropTypes.node,
  onClick: PropTypes.func,
  sx: PropTypes.object,
};

const IconButton = ({ onClick, color = 'primary', startIcon, children, ...props }) => (
  <FloatingButton component="div" onClick={onClick} color={color} startIcon={startIcon} disableRipple {...props}>
    <Typography
      component="div"
      variant="label"
      sx={(theme) => ({
        ml: 1,
        my: 0,
        py: 0.25,
        px: 1.5,
        borderRadius: `${theme.borderRadius?.small}px`,
        backgroundColor: `${color}.main`,
        boxShadow: theme.elevation?.button,
        color: `${color}.contrastText`,
      })}>
      {children}
    </Typography>
  </FloatingButton>
);

const LoadingButton = ({ color, sx: { color: sxColor, backgroundColor, ...sx } = {}, ...props }) => {
  const loadingColor = color ?? sxColor ?? backgroundColor;
  return <Loading global={false} color={loadingColor} {...props} />;
};

LoadingButton.propTypes = {
  color: PropTypes.string,
  sx: PropTypes.object,
};

const mapButton = (variant, loading) => {
  if (loading) return LoadingButton;

  switch (variant) {
    case 'compact':
      return CompactButton;
    case 'icon':
      return IconButton;
    case 'contained':
      return ContainedButton;
    case 'outlined':
      return OutlinedButton;
    case 'text':
      return MuiButton;
    default:
      return RegularButton;
  }
};

const Button = ({ variant, loading = false, ...props }) => {
  const InnerButton = mapButton(variant, loading);

  return <InnerButton variant={variant} loading={loading ? true : 'false'} {...props} />;
};

Button.propTypes = {
  variant: PropTypes.oneOf(['compact', 'icon', 'outlined', 'contained', 'text']),
  loading: PropTypes.bool,
};

export default Button;
