import { getRelativePosition } from './FabricUtils';
import Vector3D from '../../Libs/Geometry/Vector3D';
import Plane from '../../Libs/Geometry/Plane';
import Vector2D from '../../Libs/Geometry/Vector2D';

/**
 *
 * @param {*} objectWidth
 * @param {*} objectHeight
 * @param {*} angle
 * @return {*}
 */
const getBottom = (objectWidth, objectHeight, angle) => {
  let x = 0;
  let y = 0;

  if (angle > 315) {
    x = -objectWidth / 2;
    y = objectHeight;
  } else if (angle > 225) {
    x = -objectWidth;
    y = objectHeight / 2;
  } else if (angle > 135) {
    x = -objectWidth / 2;
  } else if (angle > 45) {
    y = objectHeight / 2;
  } else {
    x = -objectWidth / 2;
    y = objectHeight;
  }
  return [x, y];
};

/**
 * Computes the anchor point of the object
 * taking in consideration its transformation.
 * Anchor point is based on the center bottom part
 * of the bounding box of the image
 * @param {*} posX
 * @param {*} posY
 * @param {*} width
 * @param {*} height
 * @param {*} angle
 * @param {*} transform
 * @return {Point}
 */
const getAnchorPoint = (posX, posY, width, height, angle) => {
  const [x, y] = getBottom(width, height, angle);
  const anchorPoint = new fabric.Point(posX + x, posY + y);
  return anchorPoint;
};

/**
 *
 * @param {*} fabricObject
 * @return {*}
 */
const getAnchorPointY = (fabricObject) => {
  const size = fabricObject._getTransformedDimensions(0, 0);
  const [x, y] = fabricObject.getPosition();
  return getAnchorPoint(x, y, size.x, size.y, fabricObject.angle).y;
};

const get3DPoint = (x, y, camera, plane) => {
  // Normalize point
  const xN = x / camera.imgW;
  const yN = 1 - y / camera.imgH;
  // retrieve 3D top of object
  const [rayDirection, rayOrigine] = camera.getViewRay(xN, yN);
  return plane.cross(rayOrigine, rayDirection);
};

/**
 * Returns the 3D plane where the sticker object is projected
 * @param {*} z
 * @return {*}
 */
const getObjectPlane = (z) => {
  const perpenticularPlane = new Plane(new Vector3D(0, 0, z), new Vector3D(0, 0, -1));
  return perpenticularPlane;
};

const getObjectVertices = (fabricObject, camera, plane, background) => {
  // Get object canvas dimension
  const size = fabricObject._getTransformedDimensions(0, 0);
  const angle = fabricObject.angle;
  const [x, y] = fabricObject.getPosition();

  // Convert to background coord
  const backgroundObjectPose = getRelativePosition(new Vector2D(x, y), background);
  const backgroundObjectSize = new fabric.Point(size.x / background.scaleX, size.y / background.scaleY);

  const backgroundAnchorPoint = getAnchorPoint(
    backgroundObjectPose.x,
    backgroundObjectPose.y,
    backgroundObjectSize.x,
    backgroundObjectSize.y,
    angle
  );

  const bottomVertex = get3DPoint(backgroundAnchorPoint.x, backgroundAnchorPoint.y, camera, plane);
  const perpenticularPlane = getObjectPlane(bottomVertex.z);
  const topVertex = get3DPoint(
    backgroundAnchorPoint.x,
    backgroundAnchorPoint.y - backgroundObjectSize.y,
    camera,
    perpenticularPlane
  );

  return [bottomVertex, topVertex];
};

const getObjectCornerVertices = (fabricObject, camera, plane, background) => {
  // Get object canvas dimension
  const size = fabricObject._getTransformedDimensions(0, 0);
  const angle = fabricObject.angle;
  const [x, y] = fabricObject.getPosition();

  // Convert to background coord
  const backgroundObjectPose = getRelativePosition(new Vector2D(x, y), background);
  const backgroundObjectSize = new fabric.Point(size.x / background.scaleX, size.y / background.scaleY);

  // get anchor Z position
  const backgroundAnchorPoint = getAnchorPoint(
    backgroundObjectPose.x,
    backgroundObjectPose.y,
    backgroundObjectSize.x,
    backgroundObjectSize.y,
    angle
  );
  const bottomVertex = get3DPoint(backgroundAnchorPoint.x, backgroundAnchorPoint.y, camera, plane);
  const perpenticularPlane = getObjectPlane(bottomVertex.z);

  const topLeftVertex = get3DPoint(backgroundObjectPose.x, backgroundObjectPose.y, camera, perpenticularPlane);

  const topRightVertex = get3DPoint(
    backgroundObjectPose.x + backgroundObjectSize.x,
    backgroundObjectPose.y,
    camera,
    perpenticularPlane
  );

  const bottomLeftVertex = get3DPoint(
    backgroundObjectPose.x,
    backgroundObjectPose.y + backgroundObjectSize.y,
    camera,
    perpenticularPlane
  );

  const bottomRightVertex = get3DPoint(
    backgroundObjectPose.x + backgroundObjectSize.x,
    backgroundObjectPose.y + backgroundObjectSize.y,
    camera,
    perpenticularPlane
  );

  return [topLeftVertex, topRightVertex, bottomLeftVertex, bottomRightVertex];
};

export { get3DPoint, getAnchorPointY, getAnchorPoint, getBottom, getObjectVertices, getObjectCornerVertices };
