/* eslint-disable max-len */
import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DropletLessIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="5 0 24 24">
    <path
      d="M17.1803 23.0078C14.2619 23.0078 11.4536 21.8485 9.37346 19.7848C7.29328 17.7212 6.11155 14.9223 6.08817 12.0038L6.00003 0.999804L17.004 0.999803C19.9225 0.999802 22.7307 2.15915 24.8108 4.2228C26.891 6.28646 28.0728 9.08536 28.0962 12.0038C28.1196 14.9223 26.9826 17.7212 24.9355 19.7848C22.8884 21.8485 20.0988 23.0078 17.1803 23.0078Z"
      fill="#F83338"
    />
    <path
      d="M5.99603 0.49982L5.49603 0.49982L5.50003 0.999804L5.58817 12.0038C5.61261 15.0548 6.84803 17.9809 9.02274 20.1383C11.1974 22.2958 14.1333 23.5078 17.1843 23.5078C20.2354 23.5078 23.1518 22.2958 25.2919 20.1383C27.432 17.9809 28.6206 15.0549 28.5962 12.0038C28.5717 8.95276 27.3363 6.02668 25.1616 3.86927C22.9869 1.71185 20.0511 0.499818 17 0.499819L5.99603 0.49982Z"
      stroke="white"
      fill="none"
    />

    <path d="M13.1843 12L21.1843 12" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);
