import BoundingBox from '@libs/Geometry/BoundingBox';
import { toRadians } from '@libs/Math';
import Transformation3D from './Transformation3D';
import Vector2D from './Vector2D';
import Vector3D from './Vector3D';

/**
 * Defines a Camera object
 */
export default class Camera {
  public camPose: Vector3D;
  public camUp: Vector3D;
  public cX: number;
  public cY: number;
  public sensorSize?: number;
  public focalLength?: number;
  public imgH?: number;
  public imgW?: number;
  public fovH?: number;
  public fovV?: number;
  public camDirection?: Vector3D;

  constructor(cameraHeight = 1.5) {
    this.camPose = new Vector3D(0, cameraHeight, -1.6);
    this.camUp = new Vector3D(0, 1, 0);
    this.cX = 0;
    this.cY = 0;
    this.setElevation(0);
  }

  setResolution(width: number, height: number, sensorSize = 35, focalLength = 70) {
    this.sensorSize = sensorSize;
    this.focalLength = focalLength;
    this.setFov(sensorSize, focalLength, height, width);
    this.imgH = height;
    this.imgW = width;
  }

  getTransformation() {
    const transformation = new Transformation3D();
    transformation.setRotation(this.getElevation(), 0, 0);
    transformation.setTranslation(this.camPose);
    return transformation;
  }

  /**
   * Serialize into an object (json)
   * @return{*}
   */
  serialize() {
    return {
      sensorSize: this.sensorSize,
      focalLength: this.focalLength,
      imgH: this.imgH,
      imgW: this.imgW,
      camPose: this.camPose.serialize(),
      camUp: this.camUp.serialize(),
      camDirection: this.camDirection.serialize(),
    };
  }

  /**
   * Create an object from a json
   */
  static unserialize(state: any): Camera {
    const ret = new Camera();
    ret.setResolution(state.imgW, state.imgH, state.sensorSize, state.focalLength);
    ret.camPose = Vector3D.unserialize(state.camPose);
    ret.camUp = Vector3D.unserialize(state.camUp);
    ret.camDirection = Vector3D.unserialize(state.camDirection);
    return ret;
  }

  /**
   * Compute the field of view from sensor information
   */
  setFov(sensorSize: number, focalLength: number, imgH: number, imgW: number) {
    this.fovH = 2 * Math.atan(sensorSize / focalLength / 2) * (180 / Math.PI);
    this.fovV = 2 * Math.atan(((sensorSize / focalLength / 2) * imgH) / imgW) * (180 / Math.PI);
  }

  normalizeImageCoord(coord: Vector2D): Vector2D {
    return new Vector2D(coord.x / this.imgW, coord.y / this.imgH);
  }

  unnormalizeImageCoord(coord: Vector2D): Vector2D {
    return new Vector2D(coord.x * this.imgW, coord.y * this.imgH);
  }

  /**
   * computes a camera direction from an elevation angle
   */
  setElevation(angle: number) {
    const oa = Math.tan(toRadians(angle + 90));
    this.camDirection = new Vector3D(0, -1 / oa, 1).getNormalized();
  }

  /**
   * Returns the elevation from the camera direction
   */
  getElevation(): number {
    return Math.atan2(this.camDirection.z, -this.camDirection.y) - Math.PI / 2;
  }

  /**
   * Returns the camera aspect ratio
   */
  getAspectRatio(): number {
    return this.imgW / this.imgH;
  }

  getBoundingBoxAspectRatio(boundingBox: BoundingBox): number {
    return (this.imgW * boundingBox.width) / (this.imgH * boundingBox.height);
  }

  getViewRay(x: number, y: number): [Vector3D, Vector3D] {
    const nX = x + this.cX - 0.5;
    const nY = y + this.cY - 0.5;
    const hn2 = 2.0 * Math.tan(this.fovH * (Math.PI / 180.0 / 2.0));
    const vn2 = 2.0 * Math.tan(this.fovV * (Math.PI / 180.0 / 2.0));

    const hvec = this.camDirection.cross(this.camUp).getNormalized().mulScalar(hn2);
    const vvec = hvec.cross(this.camDirection).getNormalized().mulScalar(vn2);

    const rayDirection = new Vector3D(
      this.camDirection.x + nX * hvec.x + nY * vvec.x,
      this.camDirection.y + nX * hvec.y + nY * vvec.y,
      this.camDirection.z + nX * hvec.z + nY * vvec.z
    ).getNormalized();
    const rayOrigine = this.camPose;
    return [rayDirection, rayOrigine];
  }

  projectPoint(point: Vector3D): Vector2D {
    let disp = point.sub(this.camPose);
    let d = disp.dot(this.camDirection);
    d = 1 / d;
    disp = disp.mulScalar(d);

    let hn2 = 2.0 * Math.tan(this.fovH * (Math.PI / 180.0 / 2.0));
    let vn2 = 2.0 * Math.tan(this.fovV * (Math.PI / 180.0 / 2.0));
    const hvec = this.camDirection.cross(this.camUp).getNormalized().mulScalar(hn2);
    const vvec = hvec.cross(this.camDirection).getNormalized().mulScalar(vn2);
    hn2 = hvec.dot(hvec);
    vn2 = vvec.dot(vvec);
    let x = disp.dot(hvec) / hn2 + 0.5 - this.cX;
    let y = disp.dot(vvec) / vn2 + 0.5 - this.cY;
    x = x * this.imgW;
    // invert Y
    y = -y + 1;
    y = y * this.imgH;
    return new Vector2D(x, y);
  }
}
