import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

const PanelContent = ({ children, sx = {}, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        px: 2,
        overflow: 'auto',
        width: '100%',
        height: '100%',
        ...sx,
      }}
      {...props}
      flexGrow={1}>
      {children}
    </Box>
  );
};

PanelContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PanelContent;
