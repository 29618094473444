import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { ReactNode } from 'react';

interface DialogHeaderProps {
  className?: string;
  children?: ReactNode;
}

export const DialogHeader = ({ className, children }: DialogHeaderProps) => {
  return <DialogHeaderWrapper className={className}>{children}</DialogHeaderWrapper>;
};

const DialogHeaderWrapper = styled(Box)`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing(2)};
`;
