import { useEffect, useState } from 'react';
import { HistoryStack } from '@libs/HistoryStack';

interface UseUndoRedoStack<HistoryType> {
  push: (state: HistoryType, onUndo?: () => void, onRedo?: () => void) => void;
  undo: () => HistoryType;
  redo: () => HistoryType;
  clear: () => void;
  currentState: HistoryType;
  canUndo: boolean;
  canRedo: boolean;
}

const useUndoRedoStack = <HistoryType = any>(): UseUndoRedoStack<HistoryType> => {
  const [historyStack, setHistoryStack] = useState(null as HistoryStack<HistoryType>);
  useEffect(() => {
    setHistoryStack(new HistoryStack());
  }, []);

  const push = (state: HistoryType, onUndo: () => void = () => {}, onRedo: () => void = () => {}) => {
    historyStack.push(state, onUndo, onRedo);
  };

  const undo = (): HistoryType => {
    return historyStack.undo();
  };

  const redo = (): HistoryType => {
    return historyStack.redo();
  };

  const clear = () => {
    historyStack.clear();
  };

  return {
    push,
    undo,
    redo,
    clear,
    currentState: historyStack?.currentState,
    canUndo: historyStack?.canUndo,
    canRedo: historyStack?.canRedo,
  };
};

export default useUndoRedoStack;
