/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useMemo, useState } from 'react';
import { DEFAULT_NS } from '@libs/i18n-config';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Switch from '@components/Viewport/Switch';
import useFabric from '@hooks/Fabric';
import { useScene } from '@contexts/SceneContext';
import { useTranslation } from 'react-i18next';
import Button from '@components/Ui/Button';
import { DownloadEverything } from '@components/Download/DownloadEverything';

export const PasteCanvasDebugTools = () => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'canvas.compose.debug' });
  const [showMasks, setShowMasks] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [showSceneUpdateState, setShowSceneUpdateState] = useState(true);
  const [downloadEverythingOpen, setDownloadEverythingOpen] = useState(false);

  const { displayMask, displayGrid } = useFabric();
  const scene = useScene();

  const onElevationChange = (event, newValue) => {
    scene.rotateCamera(newValue);
    displayGrid(showGrid, scene.camera, scene.groundPlane);
  };

  useEffect(() => {
    displayGrid(showGrid, scene.camera, scene.groundPlane);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showGrid, scene.camera]);

  useEffect(() => {
    scene.setShowSceneUpdateState(showSceneUpdateState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSceneUpdateState]);

  useEffect(() => {
    displayMask(showMasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMasks]);

  const makeSwitch = (label, callback, state) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography>{label}</Typography>
        <Switch label={label} checked={state} onChange={(e) => callback(e.target.checked)} />
      </Box>
    );
  };

  const showSceneUpdateSwitch = useMemo(() => {
    return makeSwitch(t('showSceneUpdate'), setShowSceneUpdateState, showSceneUpdateState);
  }, [showSceneUpdateState, t]);

  const showMaskSwitch = useMemo(() => {
    return makeSwitch(t('showMask'), setShowMasks, showMasks);
  }, [showMasks, t]);

  const showGridSwitch = useMemo(() => {
    return makeSwitch(t('drawGrid'), setShowGrid, showGrid);
  }, [showGrid, t]);

  return (
    <>
      {showSceneUpdateSwitch}
      {showMaskSwitch}
      {showGridSwitch}

      <Slider
        value={scene.camera.getElevation()}
        onChange={onElevationChange}
        aria-labelledby="continuous-slider"
        valueLabelDisplay="on"
        step={0.5}
        min={-30}
        max={10}>
        Elevation
      </Slider>
      <Button variant="compact" onClick={() => setDownloadEverythingOpen(true)}>
        Download everything
      </Button>
      <DownloadEverything open={downloadEverythingOpen} onClose={() => setDownloadEverythingOpen(false)} />
    </>
  );
};
