import { CropWindow } from '@contexts/CropToolContext';
import { useActiveObject, useScene } from '@contexts/SceneContext';

import useFabric from '@hooks/Fabric';
import useCropTool from '@hooks/UseCropTool';
import MuiBox from '@mui/material/Box';
import BoundingBox from '@libs/Geometry/BoundingBox';
import Vector2D from '@libs/Geometry/Vector2D';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CropOverlay, { CropBoundingBox } from './CropOverlay';
import { BackgroundType } from '@libs/BackgroundObject';

export enum EditMode {
  EDIT_WIDTH,
  EDIT_HEIGHT,
  NONE,
}

const INITIAL_CROP_WINDOW: CropWindow = {
  width: 1,
  height: 1,
  x: 0,
  y: 0,
  cursor: 'default',
};

const CanvasCropTool = () => {
  const fabricHook = useFabric();
  const [boundingBox, setBoundingBox] = useState({} as CropBoundingBox);
  const { activeObject } = useActiveObject();
  const scene = useScene();
  const { enabled, setEnabled, cropWindow, setCropWindow, applied, setApplied } = useCropTool();
  const { t } = useTranslation();
  const [backgroundResolution, setBackgroundResolution] = useState<{ width: number; height: number }>(null);
  const [editMode, setEditMode] = useState<EditMode>(EditMode.NONE);
  const [enterKeyPressed, setEnterKeyPressed] = useState(false);
  const [escapeKeyPressed, setEscapeKeyPressed] = useState(false);

  const backgroundBoundingBox: CropBoundingBox = useMemo((): CropBoundingBox => {
    if (!fabricHook.background) return;
    const backgroundOrigin = { x: 0, y: 0 };
    const backgroundEnd = { x: fabricHook.background.width, y: fabricHook.background.height };

    const htmlOrigin = fabricHook.canvasToHtml(fabricHook.backgroundToViewport(backgroundOrigin));
    const htmlEnd = fabricHook.canvasToHtml(fabricHook.backgroundToViewport(backgroundEnd));

    const width = htmlEnd.x - htmlOrigin.x;
    const height = htmlEnd.y - htmlOrigin.y;
    const normalizedHtmlOrigin = fabricHook.normalizeHtmlCoord(htmlOrigin);
    return {
      width: width,
      height: height,
      x: `${normalizedHtmlOrigin.x * 100}%`,
      y: `${normalizedHtmlOrigin.y * 100}%`,
    };
  }, [fabricHook.background?.width, fabricHook.background?.height, fabricHook.canvasToHtml]);

  const handleClickOutside = () => {
    if (editMode == EditMode.NONE) {
      setApplied(true);
    }
  };

  useEffect(() => {
    if (activeObject) {
      setEnabled(false);
    }
  }, [activeObject, setEnabled]);

  // When a new background is detected, change the default crop window size
  useEffect(() => {
    if (!backgroundBoundingBox) return;
    setCropWindow({
      ...INITIAL_CROP_WINDOW,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fabricHook?.background?.name]);

  useEffect(() => {
    if (!backgroundBoundingBox) return;
    setBoundingBox(backgroundBoundingBox);
  }, [backgroundBoundingBox]);

  // Apply crop
  useEffect(() => {
    if (applied) {
      const center = new Vector2D(cropWindow.x + cropWindow.width / 2, cropWindow.y + cropWindow.height / 2);
      scene.crop(new BoundingBox(center, cropWindow.width, cropWindow.height));
      setEnabled(false);
      setApplied(false);
    }
  }, [applied, setApplied, cropWindow]);

  useEffect(() => {
    if (enabled) {
      scene.removeCrop();
    }
  }, [enabled]);

  useEffect(() => {
    if (!enabled) return;

    const handleCropToolKeydown = (e) => {
      if (editMode == EditMode.NONE) {
        if (e.key === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          setEnabled(false);
        } else if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
          setApplied(true);
        }
      }
    };

    window.addEventListener('keydown', handleCropToolKeydown);

    return () => window.removeEventListener('keydown', handleCropToolKeydown);
  }, [enabled]);

  useEffect(() => {
    if (fabricHook?.background?.type == BackgroundType.DEPIX) {
      setBackgroundResolution(fabricHook.background.depixObject.resolution);
    } else if (fabricHook?.background?.type == BackgroundType.PLAIN) {
      const background = fabricHook.background;
      setBackgroundResolution({ width: background.width, height: background.height });
    } else {
      setBackgroundResolution(null);
    }
  }, [fabricHook?.background]);

  return (
    <MuiBox
      role="presentation"
      onDoubleClick={() => setApplied(true)}
      aria-label={t('tools.cropTool.ariaLabel.canvasCropTool')}>
      {enabled && (
        <CropOverlay
          boundingBox={boundingBox}
          cropWindow={cropWindow}
          onChange={setCropWindow}
          editMode={editMode}
          onChangeEditMode={setEditMode}
          onClickOutside={handleClickOutside}
          resolution={backgroundResolution}
        />
      )}
    </MuiBox>
  );
};

export default CanvasCropTool;
