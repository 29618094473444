import { useCallback } from 'react';
import PropTypes from 'prop-types';
import useUploadDepixObject from './UseUploadDepixObject';

import useUserProfile from './UseUserProfile';

export const useUploadImage = (onImageReady, isPreview, autoSegment) => {
  const userProfile = useUserProfile();

  const onImageReadyWrapped = useCallback(
    (depixObject) => {
      userProfile.refresh();
      onImageReady(depixObject);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onImageReady, userProfile]
  );

  const { requestFromURL, requestFromImage, requestFromStockImage, cloneFromObjectId, loadingFlag } =
    useUploadDepixObject(onImageReadyWrapped);

  const uploadImageFromFile = useCallback(
    async (file) => {
      const resize = true;
      requestFromImage(file, isPreview, resize, autoSegment);
    },
    [requestFromImage, isPreview]
  );

  const uploadImageFromUrl = useCallback(
    async (url) => {
      requestFromURL(url, isPreview, autoSegment);
    },
    [requestFromURL, isPreview]
  );

  const uploadImageFromStock = useCallback(
    (apiName, url, width) => {
      requestFromStockImage(url, apiName, width, isPreview, autoSegment);
    },
    [requestFromStockImage, isPreview]
  );

  return {
    uploadImageFromFile,
    uploadImageFromUrl,
    uploadImageFromStock,
    cloneFromObjectId,
    isLoading: loadingFlag,
  };
};

useUploadImage.propTypes = {
  onImageReady: PropTypes.func.isRequired,
};

export default useUploadImage;
