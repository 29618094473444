import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SearchImageIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <circle cx="11" cy="11" r="7" stroke="currentColor" strokeWidth="1.5" fill="none" />
    <path d="M16 17L19.5 20.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </SvgIcon>
);

export default SearchImageIcon;
