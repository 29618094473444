/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { RelightState } from '@hooks/UseRelight';
import { useActiveObject, useSceneRelight } from '../Context/SceneContext';
import LoadingBackdrop from '@components/WebApp/LoadingBackdrop';

import RelightPanel from '@components/Object/RelightPanel';
import { useAlert } from '@hooks/Alert';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const RelightPanelContainer = ({ isSmallDevice, onClose }) => {
  const { t } = useTranslation();
  const {
    activeObject,
    setSceneCommitted,
    setLightProperties,
    setBlur,
    lightIntensity,
    relightEnabled,
    ambient,
    ambientColor,
    lowResidualAlpha,
    highResidualAlpha,
    blur,
  } = useActiveObject();
  const { renderRelight, relightState, error } = useSceneRelight();

  const alert = useAlert();

  const handleRelightChange = (ambient, lightIntensity, ambientColor, residualAlpha, commit = false) => {
    setLightProperties(ambientColor, residualAlpha, ambient, lightIntensity, commit);
  };

  const handleRelightChangeCommitted = () => {
    const isPreview = false;
    setSceneCommitted();
    renderRelight(activeObject, isPreview);
  };

  const handleFocusChange = (e, newVal) => {
    setBlur(newVal);
  };

  useEffect(() => {
    if (error) {
      onClose();
      if (error.networkError) {
        alert.networkError();
      } else {
        alert.error(t('general.genericError'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const viewAmbient = React.useMemo(() => {
    return {
      r: ambientColor[0] * 255,
      g: ambientColor[1] * 255,
      b: ambientColor[2] * 255,
    };
  }, [ambientColor]);

  if (!activeObject) return null;

  return (
    <LoadingBackdrop isLoading={relightState === RelightState.COMPUTING}>
      {relightState !== RelightState.DISABLE && (
        <RelightPanel
          isSmallDevice={isSmallDevice}
          disabled={!relightEnabled}
          ambient={ambient}
          ambientColor={viewAmbient}
          lightIntensity={lightIntensity}
          lowResidualAlpha={lowResidualAlpha}
          highResidualAlpha={highResidualAlpha}
          onChange={handleRelightChange}
          onChangeCommitted={handleRelightChangeCommitted}
          objectFocus={blur}
          onObjectFocus={handleFocusChange}
        />
      )}
    </LoadingBackdrop>
  );
};

RelightPanelContainer.propTypes = {
  isSmallDevice: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default RelightPanelContainer;
