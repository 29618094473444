import { BackgroundObject, BackgroundType } from './BackgroundObject';
import { v4 as uuid } from 'uuid';
import { generateUniformBackground, loadImage, rgbaToColorString } from './ImgUtils';

export const DEFAULT_PLAIN_BACKGROUND_COLOR: Color = {
  r: 255,
  g: 255,
  b: 255,
  a: 1,
};

export const DEFAULT_PLAIN_BACKGROUND_HIGH_RESOLUTION = 2048;
export const DEFAULT_PLAIN_BACKGROUND_LOW_RESOLUTION = 512;

export type Color = {
  r: number;
  g: number;
  b: number;
  a: number;
};

export class PlainBackgroundObject implements BackgroundObject {
  public updated: boolean;
  private width: number;
  private height: number;

  image: HTMLImageElement;
  objectID: string;
  type: BackgroundType;
  color: Color;

  constructor(color: Color, width: number, height: number, objectID: string | undefined) {
    this.image = null;
    this.objectID = objectID || uuid();
    this.type = BackgroundType.PLAIN;
    this.color = color;
    this.width = width;
    this.height = height;
  }

  /**
   * creates a PlainBackgroundObject with specified color and size
   */
  static async create(
    color = DEFAULT_PLAIN_BACKGROUND_COLOR,
    width = DEFAULT_PLAIN_BACKGROUND_HIGH_RESOLUTION,
    height = DEFAULT_PLAIN_BACKGROUND_HIGH_RESOLUTION,
    objectID?: string | undefined
  ): Promise<PlainBackgroundObject> {
    const plainBackgroundObject = new PlainBackgroundObject(color, width, height, objectID);
    const colorString = rgbaToColorString(color);
    return new Promise<PlainBackgroundObject>((resolve, reject) => {
      loadImage(generateUniformBackground(colorString, width, height))
        .then((image) => {
          plainBackgroundObject.image = image;
          resolve(plainBackgroundObject);
        })
        .catch(reject);
    });
  }

  changeColor(color = DEFAULT_PLAIN_BACKGROUND_COLOR) {
    this.color = color;
    const colorString = rgbaToColorString(color);
    return loadImage(generateUniformBackground(colorString, this.width, this.height)).then((image) => {
      this.image = image;
      this.updated = true;
    });
  }

  /**
   * get RGBA image
   */
  getImage(): Promise<HTMLImageElement> {
    return new Promise((resolve) => resolve(this.image));
  }

  isTransparent(): boolean {
    return this.color.a < 1;
  }

  getColorHexCode(): string {
    const r = Number(this.color.r).toString(16);
    const g = Number(this.color.g).toString(16);
    const b = Number(this.color.b).toString(16);
    return `#${r}${g}${b}`;
  }

  getColorOpacity(): number {
    return this.color.a;
  }

  /**
   * Serilaize into json
   * @return {*}
   */
  serialize() {
    return { objectID: this.objectID, color: this.color, width: this.width, height: this.height };
  }

  /**
   * Create object from json
   * @param {*} state
   * @return {Promise}
   */
  static unserialize(state) {
    return new Promise((resolve) => {
      PlainBackgroundObject.create(state.color, state.width, state.height, state.objectID).then((object) => {
        resolve(object);
      });
    });
  }
}
