import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ShadowIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5 18H13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
      <path d="M20.5 15H13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
      <path d="M21 12H13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
      <path d="M20.5 9H13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
      <path d="M18.5 6H13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
      <path d="M12 21L12 3" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
      <rect
        x="2.75"
        y="2.75"
        width="18.5"
        height="18.5"
        rx="9.25"
        stroke="currentColor"
        strokeWidth="1.5"
        fill="none"
      />
    </SvgIcon>
  );
};

export default ShadowIcon;
