import { SvgIconProps } from '@mui/material/SvgIcon';
import { SvgIcon } from '@mui/material';

const DiscordIcon = (props: SvgIconProps) => (
  <SvgIcon width={28} height={21} viewBox="0 0 28 21" {...props}>
    <path
      d="M23.4808 2.14097C26.5449 6.59062 28.0581 11.6097 27.4924 17.3878C27.49 17.4123 27.4771 17.4347 27.4568 17.4495C25.1365 19.1323 22.8884 20.1536 20.6718 20.8309C20.6545 20.8361 20.6361 20.8358 20.619 20.8301C20.6019 20.8244 20.5871 20.8135 20.5765 20.7991C20.0644 20.0953 19.5991 19.3534 19.1915 18.5743C19.1681 18.5284 19.1895 18.4731 19.2376 18.455C19.9766 18.18 20.6793 17.8504 21.3552 17.4602C21.4084 17.4294 21.4118 17.354 21.3626 17.3178C21.2192 17.2126 21.0771 17.1021 20.9411 16.9916C20.9156 16.9711 20.8814 16.9671 20.8526 16.9808C16.4646 18.9822 11.6577 18.9822 7.21785 16.9808C7.18902 16.9681 7.15477 16.9725 7.13001 16.9926C6.99436 17.1031 6.85193 17.2126 6.70983 17.3178C6.66066 17.354 6.66473 17.4294 6.71831 17.4602C7.3942 17.843 8.09687 18.18 8.83482 18.4564C8.88264 18.4744 8.90536 18.5284 8.88162 18.5743C8.4828 19.3544 8.01752 20.0963 7.49593 20.8001C7.47321 20.8286 7.43591 20.8416 7.40064 20.8309C5.1946 20.1536 2.94651 19.1323 0.62618 17.4495C0.60685 17.4347 0.592946 17.4113 0.590911 17.3868C0.118164 12.3888 1.08163 7.32821 4.59875 2.13996C4.60723 2.12623 4.62011 2.11551 4.63503 2.10915C6.36561 1.32466 8.21964 0.74752 10.1574 0.417916C10.1927 0.412557 10.228 0.428635 10.2463 0.459451C10.4857 0.878155 10.7594 1.4151 10.9445 1.8539C12.9871 1.54574 15.0616 1.54574 17.1469 1.8539C17.3321 1.42448 17.5962 0.878155 17.8346 0.459451C17.8431 0.444164 17.8563 0.431916 17.8723 0.424457C17.8882 0.416998 17.9061 0.414708 17.9235 0.417916C19.8623 0.748525 21.7163 1.32567 23.4455 2.10915C23.4608 2.11551 23.4734 2.12623 23.4808 2.14097ZM11.983 11.6439C12.0043 10.1664 10.9137 8.94374 9.54462 8.94374C8.18674 8.94374 7.10661 10.1556 7.10661 11.6439C7.10661 13.1318 8.20811 14.3437 9.54462 14.3437C10.9028 14.3437 11.983 13.1318 11.983 11.6439ZM20.9977 11.6439C21.0191 10.1664 19.9284 8.94374 18.5597 8.94374C17.2015 8.94374 16.1214 10.1556 16.1214 11.6439C16.1214 13.1318 17.2229 14.3437 18.5597 14.3437C19.9284 14.3437 20.9977 13.1318 20.9977 11.6439Z"
      fill="white"
    />
  </SvgIcon>
);

export default DiscordIcon;
