import { useAuthV2 } from '@contexts/AuthV2';
import { useCallback } from 'react';

export enum Feature {
  TEST_FEATURES = 'testfeatures',
  DEV_TOOLS = 'devtools',
  GUEST_ACCESS = 'guestAccess',
  ESTIMATE_BACKGROUND = 'estimateBackground',
  INFILL_TOOL = 'infillTool',
  HIGH_RESOLUTION = 'highResolution',
  SHARE_WITH_DEPIX = 'shareWithDepix',
  SHARE_TO_PUBLIC = 'shareToPublic',
}

export const USE_FEATURE_PREFIX = 'use';

export const useFeatureFlag = () => {
  const { hasPermissions } = useAuthV2();

  const canUseFeature = useCallback(
    (feature: Feature): boolean => {
      if (feature === Feature.GUEST_ACCESS) {
        return false;
      }

      return hasPermissions && hasPermissions([`${USE_FEATURE_PREFIX}:${feature}`]);
    },
    [hasPermissions]
  );

  return {
    canUseFeature,
  };
};
