import { useEffect, useState } from 'react';
import { useScene } from '@contexts/SceneContext';
import { useAlert } from '@hooks/Alert';
import useQueryObjects from '@hooks/useQueryObjects';
import useFabric from '@hooks/Fabric';
import { Feature, useFeatureFlag } from '@hooks/FeatureFlag';
import { PlainBackgroundObject } from '@libs/PlainBackgroundObject';
import { useMutation } from '@apollo/client';
import { SHARE_IMAGE } from '@libs/DepixApi';
import Routes from '@root/routes';

interface ShareImage {
  canShare: boolean;
  shareImage: (title: string) => void;
  isLoading: boolean;
}

export const useShareImage = (): ShareImage => {
  const alert = useAlert();
  const fabric = useFabric();
  const scene = useScene();

  const { canUseFeature } = useFeatureFlag();
  const canShareFlag = canUseFeature(Feature.SHARE_TO_PUBLIC);

  const [image, setImage] = useState(null as Blob);
  const [imageFormat, setImageFormat] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(null as string);
  const [canShare, setCanShare] = useState(false);

  const getObjectsRequestCallback = (objects) => {
    for (const obj of objects) {
      const depixObj = fabric.depixObjectFromID(obj.objectID);
      if (depixObj) {
        depixObj.setupFullResolution(obj);
      }
    }
    const fullResolution = true;
    const imageFormat = scene.isBackgroundTransparent() ? 'png' : 'jpeg';
    setImageFormat(imageFormat);
    fabric.exportCanvas(fullResolution, imageFormat).then((image) => setImage(image.file));
  };

  const { queryObjects } = useQueryObjects(getObjectsRequestCallback);

  const onCompleted = () => {
    setImage(null);
    setTitle(null);
    setIsLoading(false);
  };

  const onShareLinkReturned = (url: string) => {
    window.open(url, '_blank').focus();
  };

  const shareImage = (title: string) => {
    setIsLoading(true);
    setTitle(title);
    const isPreviewResolution = false;
    const ids = scene
      .getDepixObjects()
      .filter((obj) => !(obj instanceof PlainBackgroundObject))
      .map((obj) => obj.objectID);
    queryObjects(ids, isPreviewResolution, {
      withId: true,
      withImage: true,
      withMask: true,
    });
  };

  useEffect(() => {
    if (image) {
      const backgroundId = scene.backgroundImage.objectID;
      const components = scene.getComponentDepixObjects();
      const componentObjectIds = components.map((component) => component.objectID);
      const extension = imageFormat;

      shareImageCall({ variables: { image, backgroundId, componentIds: componentObjectIds, title, extension } }).catch(
        () => alert.invalidImage()
      );
    }
  }, [image]);

  useEffect(() => {
    setCanShare(scene?.containsComponent() && canShareFlag);
  }, [scene?.containsComponent]);

  const [shareImageCall] = useMutation(SHARE_IMAGE, {
    onCompleted(data) {
      onCompleted();
      if (data?.shareImage) {
        onShareLinkReturned(Routes.imageSharing.image(data.shareImage.ref));
      }
    },
    onError(error) {},
  });

  return { shareImage, canShare, isLoading };
};
