import { AuthV2Api, useAuthV2Api } from '@hooks/AuthV2Api';

import { User, UserRole } from '@libs/User';
import jwtDecode from 'jwt-decode';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface AuthV2Context extends Omit<AuthV2Api, 'token'> {
  user: User;
  isAuthenticated: boolean;
  isGuest: boolean;
  isDepix: boolean;
  isChampion: boolean;
  requiresVerification: boolean;
  getAccessTokenSilently: () => Promise<string | undefined>;
  hasPermissions: (permission: string | string[]) => boolean;
}

const AuthV2Context = createContext<AuthV2Context>(null);

export const AuthV2Provider = ({ children }) => {
  const api = useAuthV2Api();
  const [user, setUser] = useState<User | null>(null);
  const [requiresVerification, setRequiresVerification] = useState(false);
  const [isDepix, setIsDepix] = useState(false);
  const [isChampion, setIsChampion] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(user != null);
  const [tokenObject, setTokenObject] = useState<{ token: string } | undefined>(
    api.token ? JSON.parse(api.token as string) : undefined
  );

  const hasPermissions = useCallback(
    (features: string | string[]): boolean => {
      return user && user.hasPermissions(features);
    },
    [user]
  );

  const getAccessTokenSilently = useCallback(async () => {
    return tokenObject?.token;
  }, [tokenObject]);

  useEffect(() => {
    if (api.token) {
      setTokenObject(JSON.parse(api.token as string));
    } else {
      setTokenObject(undefined);
    }
  }, [api.token]);

  useEffect(() => {
    if (tokenObject) {
      const decodedToken = jwtDecode(tokenObject.token);
      setUser(User.fromToken(decodedToken));
      setIsAuthenticated(true);
      setRequiresVerification(false);
    } else {
      setUser(null);
      setIsAuthenticated(false);
    }
  }, [tokenObject]);

  useEffect(() => {
    if (api.refreshError?.code == 'MUST_VALIDATE_EMAIL' || api.loginError?.code == 'MUST_VALIDATE_EMAIL') {
      setRequiresVerification(true);
    } else {
      setRequiresVerification(false);
    }
  }, [api.refreshError, api.loginError]);

  useEffect(() => {
    if (user) {
      setIsDepix(user.hasRole(UserRole.DEPIX));
      setIsChampion(user.hasRole(UserRole.CHAMPION));
    } else {
      setIsDepix(false);
      setIsChampion(false);
    }
  }, [user]);

  const contextValue = {
    ...api,
    isLoading: api?.isLoading,
    user,
    isAuthenticated: isAuthenticated,
    isGuest: false,
    isDepix,
    isChampion: isChampion,
    requiresVerification,
    getAccessTokenSilently,
    hasPermissions,
  };

  return <AuthV2Context.Provider value={contextValue}>{children}</AuthV2Context.Provider>;
};

export const useAuthV2 = () => {
  const auth = useContext(AuthV2Context);

  return { ...auth };
};
