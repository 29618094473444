import download from 'downloadjs';
import mime from 'mime/lite';
import { useMemo, useState } from 'react';
import JSZip from 'jszip';

export interface UseMultipleDownloads {
  supportsNativeFs: boolean;
  downloadZip: (name: string, images: Blob[]) => Promise<any>;
  downloadToFs: (name: string, images: Blob[]) => Promise<any>;
}

export const useMultipleDownloads = (): UseMultipleDownloads => {
  const [directory, setDirectory] = useState<FileSystemDirectoryHandle | null>(null);

  const supportsNativeFs = useMemo(() => {
    return 'showDirectoryPicker' in window;
  }, []);

  const downloadZip = async (name: string, images: Blob[]) => {
    const zip = new JSZip();
    images.map((image, index) => {
      const extension = mime.getExtension(image.type);
      zip.file(`${name}_${index}.${extension}`, image, { binary: true });
    });
    const zipFile = await zip.generateAsync({ type: 'blob', compression: 'DEFLATE', compressionOptions: { level: 6 } });
    return download(zipFile, `${name}.zip`, 'application/zip');
  };

  const downloadToFs = async (name: string, images: Blob[]) => {
    let currentDirectory;
    if (directory == null || (await directory.queryPermission()) != 'granted') {
      const newDirectory = await showDirectoryPicker({ id: 'downloader', mode: 'readwrite', startIn: 'pictures' });
      setDirectory(newDirectory);
      currentDirectory = newDirectory;
    } else {
      currentDirectory = directory;
    }

    return Promise.all(
      images.map(async (image: Blob, index: number) => {
        const extension = mime.getExtension(image.type);
        const fileHandle = await currentDirectory.getFileHandle(`${name}_${index}.${extension}`, { create: true });
        const writer = await fileHandle.createWritable({ keepExistingData: false });
        await writer.write(image);
        return writer.close();
      })
    );
  };

  return {
    supportsNativeFs,
    downloadZip,
    downloadToFs,
  };
};
