import React, { useEffect } from 'react';

import ReactAppzi from 'react-appzi';

import Header from '@components/Header/Header';
import Viewport from '@components/Viewport/Viewport';
import useInstrumentation from '@hooks/UseInstrumentation';
import { AlertProvider } from '@contexts/AlertContext';
import { OutOfImagesDialog } from '@components/Subscribe/OutOfImagesDialog';
import { MeasureType } from '@libs/Instrumentation/MeasureType';
import useExitPrompt from '@hooks/ExitPrompt';
import { ShutdownNoticeDialog } from '@components/ShutdownNotice/ShutdownNoticeDialog';

ReactAppzi.initialize('3rCFw');

const Home = () => {
  const instrumentation = useInstrumentation();

  const { setShowExitPrompt } = useExitPrompt();
  useEffect(() => {
    const skipExitPrompt = process.env.REACT_APP_SKIP_EXIT_PROMPT && process.env.NODE_ENV === 'development';
    setShowExitPrompt(!skipExitPrompt);
    return () => {
      setShowExitPrompt(false);
    };
  }, []);

  useEffect(() => {
    // instrument project creation
    instrumentation.instrument(MeasureType.PROJECT_CREATED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AlertProvider>
      <OutOfImagesDialog />
      <Header />

      <Viewport />
      <ShutdownNoticeDialog />
    </AlertProvider>
  );
};
export default Home;
