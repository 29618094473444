import { CutCanvasExtractorHacks } from '@components/Viewport/CutCanvasExtractorHacks';
import CutCanvasGrabberHacksProvider from '@contexts/CutCanvasGrabberHacksContext';
import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import ComposePanel from './ComposePanel';
import ExtractPanel from './ExtractPanel';
import { COMPOSE_PANEL_NAME } from '@libs/ComposePanelName';
import { EXTRACT_PANEL_NAME } from '@libs/ExtractPanelName';

import { FabricContextProvider } from '@contexts/FabricContext';
import { SegmentationProvider } from '@contexts/Segmentation/SegmentationContext';
import { SceneContextProvider } from '@contexts/SceneContext';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Divider from '@components/Ui/Divider';
import useUserProfile from '@hooks/UseUserProfile';

export const VIEWPORT_CLASS_NAME = 'viewport';

const TAB_HEIGHT = 38;

interface ViewportProps {
  panel?: string;
}

const Viewport = ({ panel }: ViewportProps) => {
  const { t } = useTranslation();
  const [currentPanel, setCurrentPanel] = useState(panel || EXTRACT_PANEL_NAME);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { isFirstTimeUse } = useUserProfile();

  const panelWidth = isSmallDevice ? '100%' : '50%';
  const panelForceVisible = !isSmallDevice;
  const leftPanelVisible = panelForceVisible;
  const rightPanelVisible = panelForceVisible && !isFirstTimeUse;
  const leftPanelWidth = isFirstTimeUse && !isSmallDevice ? '100%' : panelWidth;
  const rightPanelWidth = isFirstTimeUse && !isSmallDevice ? '0%' : panelWidth;

  const changePanel = (_, panel) => {
    setCurrentPanel(panel);
  };

  const activateComposePanel = () => {
    setCurrentPanel(COMPOSE_PANEL_NAME);
  };

  const renderPanelTab = (value, name) => (
    <PanelTab className={name.toLowerCase() + '_panel_tab'} value={value} label={name} disableRipple />
  );

  const [extractedCutCanvasProperties, setExtractedCutCanvasProperties] = useState();
  const onExtractedCutCanvasProperties = (data) => {
    setExtractedCutCanvasProperties(data);
  };

  return (
    <Grid
      container
      flexDirection="column"
      sx={{
        backgroundColor: theme.palette.secondary.light,
        height: '100%',
      }}
      role="presentation"
      className={VIEWPORT_CLASS_NAME}
      aria-label="viewport">
      <Box
        sx={(theme) => ({
          position: 'absolute',
          top: 13,
          left: 0,
          right: 0,
          zIndex: theme.zIndex.mobileStepper,
          pointerEvents: 'none',
          display: 'flex',
          justifyContent: 'center',
        })}>
        <PanelTabs
          value={currentPanel}
          onChange={changePanel}
          variant="fullWidth"
          aria-label={t('panel.ariaLabel.panelTab')}>
          {renderPanelTab(EXTRACT_PANEL_NAME, t('canvas.cut.name'))}
          <StyledDivider color={theme.palette.secondary.light} />
          {renderPanelTab(COMPOSE_PANEL_NAME, t('canvas.compose.name'))}
        </PanelTabs>
      </Box>

      <Grid item container flexGrow={1} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
        <CutCanvasGrabberHacksProvider cut={extractedCutCanvasProperties}>
          <SceneContextProvider>
            <FabricContextProvider name={EXTRACT_PANEL_NAME}>
              <SegmentationProvider enabled={true} preview={true}>
                <CutCanvasExtractorHacks onExtract={onExtractedCutCanvasProperties} />
                <StyledExtractPanel
                  value={currentPanel}
                  width={leftPanelWidth}
                  visible={leftPanelVisible}
                  includeTextInToolbar={isFirstTimeUse && !isSmallDevice}
                  onExtract={activateComposePanel}
                />
              </SegmentationProvider>
            </FabricContextProvider>

            <FabricContextProvider name={COMPOSE_PANEL_NAME}>
              <SegmentationProvider enabled={true} preview={true}>
                <StyledComposePanel value={currentPanel} width={rightPanelWidth} visible={rightPanelVisible} />
              </SegmentationProvider>
            </FabricContextProvider>
          </SceneContextProvider>
        </CutCanvasGrabberHacksProvider>
      </Grid>
    </Grid>
  );
};

const StyledDivider = styled(Divider)`
  height: ${TAB_HEIGHT}px;
  width: 0px;
`;

const PanelTabs = styled(Tabs)`
  height: ${TAB_HEIGHT}px;
  min-height: ${TAB_HEIGHT}px;
  transition: ease-out height 0.2s;
  background-color: ${(props) => props.theme.palette.secondary.main};
  border-radius: ${(props) => props.theme.borderRadius.medium}px;
  box-shadow: 1px 2px 4px rgba(17, 17, 17, 0.04);
  opacity: 0;

  & .MuiTabs-indicator {
    display: none;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    opacity: 1;
  }

  & .MuiTabs-flexContainer {
    align-items: center;
  }
`;

const PanelTab = styled(Tab)`
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  font-weight: 500;
  margin-left: 8px;
  margin-right: 8px;
  text-transform: none;

  &.MuiButtonBase-root {
    min-width: 0;
    min-height: ${TAB_HEIGHT}px;
    height: ${TAB_HEIGHT}px;
    color: ${(props) => props.theme.palette.secondary.contrastText};
    opacity: 1;

    ${(props) => props.theme.breakpoints.down('md')} {
      &:not(.Mui-selected) {
        opacity: 0.4;
        pointer-events: auto;
      }
    }
  }
`;

const StyledExtractPanel = styled(ExtractPanel)`
  transition: ease-out width 0.05s;
`;

const StyledComposePanel = styled(ComposePanel)`
  transition: ease-out width 0.05s;
`;

export default Viewport;
