import { Box, DialogContent, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';
import { generateShareLink, SharedImage } from '@libs/SharedImage/SharedImage';
import { Link } from '@components/Ui/Link';
import useInstrumentation from '@hooks/UseInstrumentation';
import { ShareLinkIncentive } from '@libs/Instrumentation/ShareLinkIncentive';
import React, { useState } from 'react';
import { Dialog } from '@components/Ui/Dialog/Dialog';
import { DialogTitle } from '@components/Ui/Dialog/DialogTitle';
import { DialogHeader } from '@components/Ui/Dialog/DialogHeader';
import { DialogActions } from '@components/Ui/Dialog/DialogActions';
import { ShareLink } from '@components/SharedImage/ShareLink';
import Surface from '@components/Ui/Surface';
import { ShareToFacebook } from '@components/SharedImage/ShareToFacebook';
import { ShareToInstagram } from '@components/SharedImage/ShareToInstagram';
import { ShareToDiscord } from '@components/SharedImage/ShareToDiscord';
import { ShareToReddit } from '@components/SharedImage/ShareToReddit';

interface ShareToSocialsProps {
  sharedImage: SharedImage;
  className?: string;
}

export const ShareToSocials = ({ sharedImage, className }: ShareToSocialsProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'imageSharing.shareActions' });
  const instrumentation = useInstrumentation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [showDialog, setShowDialog] = useState<boolean>(false);

  const handleClick = (incentive: ShareLinkIncentive) => {
    instrumentation.imageLinkShared(incentive);
  };

  const handleNotImplemented = (incentive: ShareLinkIncentive) => {
    handleClick(incentive);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const link = generateShareLink(sharedImage.ref);

  return (
    <>
      <Wrapper className={className}>
        <ShareToReddit
          onClick={() => handleClick(ShareLinkIncentive.REDDIT)}
          link={link}
          title={sharedImage.title}
          description={sharedImage.description}
        />
        <ShareToFacebook
          onClick={() => handleNotImplemented(ShareLinkIncentive.FACEBOOK)}
          link={link}
          title={sharedImage.title}
          description={sharedImage.description}
        />
        <ShareToInstagram
          onClick={() => handleNotImplemented(ShareLinkIncentive.INSTAGRAM)}
          link={link}
          title={sharedImage.title}
          description={sharedImage.description}
        />
        <ShareToDiscord
          onClick={() => handleNotImplemented(ShareLinkIncentive.DISCORD)}
          link={link}
          title={sharedImage.title}
          description={sharedImage.description}
        />
      </Wrapper>

      <Typography>
        <Trans
          i18nKey="otherChannels"
          t={t}
          components={{
            channelsLink: <Link onClick={() => handleNotImplemented(ShareLinkIncentive.OTHER_CHANNELS)} />,
          }}
        />
      </Typography>

      <Dialog open={showDialog} closeable onClose={handleCloseDialog} PaperComponent={StyledSurface}>
        <StyledDialogHeader>
          <StyledDialogTitle>{t('notImplemented.title')}</StyledDialogTitle>
        </StyledDialogHeader>
        <DialogContent>
          <Typography>
            <Trans t={t} i18nKey={'notImplemented.description'} />
          </Typography>
        </DialogContent>
        <StyledDialogActions>
          <ShareLink link={link} compact={isSmall} />
        </StyledDialogActions>
      </Dialog>
    </>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${(props) => props.theme.spacing(2)};
`;

const StyledSurface = styled(Surface)`
  padding: ${(props) => props.theme.spacing(4, 6)};
  text-align: center;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(2, 2)};
  }
`;

const StyledDialogHeader = styled(DialogHeader)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-bottom: 0;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-bottom: 0;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
`;
