import React, { createContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';

import Vector2D from '@libs/Geometry/Vector2D';
import Vector3D from '@libs/Geometry/Vector3D';
import BoundingBox from '@libs/Geometry/BoundingBox';
import { reorderObjects } from '@libs/Geometry/Layers';
import { BackgroundType } from '@libs/BackgroundObject';
import { DEFAULT_PLAIN_BACKGROUND_HIGH_RESOLUTION } from '@libs/PlainBackgroundObject';
import { getGlobalPosition, getRelativePosition, setImageToCenter } from '@components/FabricJS/FabricUtils';
import { get3DPoint } from '@components/FabricJS/FragmentGeometry';
import '@components/FabricJS/DepixImage';
import '@components/FabricJS/BackgroundImage';
import '@components/FabricJS/FabricUtils';
import useInstrumentation, { CroppedTag } from '@hooks/UseInstrumentation';

import useCanvasEventController from '@hooks/CanvasEventController';
import { useAlert } from '@hooks/Alert';
import { dataURItoBlob } from '@libs/ImgUtils';
import { useTranslation } from 'react-i18next';

import { useDetectDevice } from '@hooks/DetectDevice';

import { INFILL_IMAGE_TYPE } from '@components/FabricJS/InfillImage';
import { OBJECT_3D_IMAGE_TYPE } from '@components/FabricJS/Object3DImage';

// eslint-disable-next-line max-len
fabric.textureSize = 2048; // if you set a value higher than this, you are fired :) (https://gitlab.com/depix/depix-api/-/issues/571)

const MASK_ID = 'mask';

const FabricContext = createContext();

export const FabricContextProvider = (props) => {
  const { children } = props;
  const canvasHook = useCanvasEventController();
  const [background, setBackground] = useState(null);
  const [viewport, setViewport] = useState(new BoundingBox());
  const [ground, setGround] = useState(null);
  const [updated, setUpdated] = useState(canvasHook.updated);
  const theme = useTheme();
  const alert = useAlert();
  const { t } = useTranslation();
  const instrumentation = useInstrumentation();
  const { isMobile } = useDetectDevice();

  const removeObjectsByName = useCallback(
    (name) => {
      const tempObjs = canvasHook.getObjectsByName(name);
      for (const tempObj of tempObjs) {
        canvasHook.remove(tempObj);
        tempObj?.dispose();
      }
    },
    [canvasHook]
  );

  const clearSelection = useCallback(() => {
    const activeObjects = canvasHook.canvas.getActiveObjects();
    activeObjects.forEach(function (object) {
      canvasHook.remove(object);
    });
  }, [canvasHook]);

  const setupBackground = useCallback(
    async (backgroundObject, id) => {
      if (ground) {
        canvasHook.remove(ground);
        setGround(null);
      }

      if (backgroundObject) {
        return backgroundObject
          .getImage()
          .then((image) => {
            const fabricImage = new fabric.BackgroundImage(
              image,
              id,
              backgroundObject.type,
              backgroundObject.depixObject,
              {
                selectable: false,
                hoverCursor: 'default',
              }
            );
            setImageToCenter(canvasHook.canvas, fabricImage);
            canvasHook.remove(background);
            canvasHook.add(fabricImage);
            fabricImage.sendToBack();
            fabricImage.alphaFilter = new fabric.Image.filters.SetAlpha({ image: null, invert: false, alpha: 1 });
            fabricImage.filters.push(fabricImage.alphaFilter);
            fabricImage.applyFilters();
            setBackground(fabricImage);
          })
          .catch(() => {
            alert.invalidImage();
          });
      }
    },
    [ground, canvasHook, background]
  );

  const setCursor = (object, cursor) => {
    object.updateOptions({ hoverCursor: cursor });
  };

  const setupGround = useCallback(
    (groundDepixObject, id) => {
      if (groundDepixObject) {
        const padding = 1;
        const cropFragment = false;
        const depixImage = new fabric.DepixImage(groundDepixObject, id, padding, cropFragment);
        depixImage
          .loadImage()
          .then(() => {
            // Setup the fragment to the right position w.r.t the background
            depixImage.scaleX = background.scaleX;
            depixImage.scaleY = background.scaleY;
            depixImage.left = background.left;
            depixImage.top = background.top;
            depixImage.absolutePositioned = true;

            setGround(depixImage);
          })
          .catch((e) => {
            console.log(e);
            alert.invalidImage();
          });
      } else {
        setGround(null);
      }
    },
    [ground, background, canvasHook]
  );

  const setupInfillObject = (depixObject, id, onLoad) => {
    const padding = 1;

    const options = {
      selectable: true,
      hoverCursor: 'pointer',
    };

    fabric.InfillImage.create(depixObject, id, padding, options)
      .then((infillImage) => {
        canvasHook.add(infillImage);

        onLoad(infillImage);
      })
      .catch(() => {
        alert.invalidImage();
      });
  };

  const setup3DObject = useCallback(
    (depixObject, id, onLoad, onShadowChange, onShadowCommit, isFix = false) => {
      const padding = 2;
      const options = {
        selectable: true,
        hoverCursor: isFix ? 'pointer' : 'move',
      };

      fabric.Object3DImage.create(depixObject, id, onShadowChange, onShadowCommit, canvasHook.canvas, padding, options)
        .then((object) => {
          const pointer = new fabric.ObjectPointer({
            name: id,
            color: theme.palette.primary.main,
            top: 0,
            left: 0,
          });
          object.uiElements.push(pointer);

          if (isFix) {
            object.lockMovement();

            object.setControlsVisibility('float', false);
          }

          if (isFix || isMobile) {
            object.setControlsVisibility({
              delete: true,
              light: false,
              tr: false,
              br: false,
              bl: false,
              tl: false,
              ml: false,
              mt: false,
              mr: false,
              mb: false,
              mtr: false,
            });
          }

          canvasHook.add(object);
          const shadow = object.getShadow();
          shadow.clipPath = ground;
          canvasHook.add(shadow);
          canvasHook.add(pointer);

          onLoad(object);
          canvasHook.canvas.setActiveObject(object);
        })
        .catch((e) => {
          console.error(e);
          alert.invalidImage();
        });
    },
    [canvasHook, theme.palette.primary.main]
  );

  useEffect(() => {
    getAllShadows().map((x) => {
      x.clipPath = ground;
    });
  }, [ground]);

  const drawMaskOverlay = useCallback(
    (mask, alpha = 0.4) => {
      if (!canvasHook.canvas) return;
      fabric.Image.fromURL(
        mask,
        (img, isError) => {
          if (isError) {
            console.error(`Cannot load mask image from URL : ${mask}`);
            alert.error(t('error.images.cannotDrawSegmentation'), t('error.generic.title'));
            return;
          }

          // Keep a copy of original mask object
          const maskCopy = fabric.util.object.clone(img);

          if (background) {
            background.alphaFilter.alpha = alpha;
            background.alphaFilter.image = maskCopy;
            background.applyFilters();
          }
          const previousMasks = canvasHook.getObjectsByName(MASK_ID);
          for (const previousMask of previousMasks) {
            canvasHook.remove(previousMask);
          }
          canvasHook.render();
        },
        { crossOrigin: 'anonymous' }
      );
    },
    [canvasHook, background]
  );

  const clearMaskOverlay = useCallback(() => {
    removeObjectsByName(MASK_ID);
    if (background) {
      background.alphaFilter.alpha = 1;
      background.applyFilters();
      canvasHook.render();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [background, canvasHook]);

  const viewportToBackground = useCallback(
    (coordinate, normalized = true, clip = true) => {
      const backgroundCoord = getRelativePosition(coordinate, background, clip);
      const x = normalized ? backgroundCoord.x / background.width : backgroundCoord.x;
      const y = normalized ? backgroundCoord.y / background.height : backgroundCoord.y;
      return new Vector2D(x, y);
    },
    [background]
  );

  const isOutsideBackground = useCallback(
    (viewportCoord) => {
      const normalized = true;
      const clip = false;
      const backgroundCoord = viewportToBackground(viewportCoord, normalized, clip);
      return backgroundCoord.x < 0 || backgroundCoord.x > 1 || backgroundCoord.y < 0 || backgroundCoord.y > 1;
    },
    [viewportToBackground]
  );

  const viewportToCanvas = useCallback(
    (coordinate) => {
      return fabric.util.transformPoint(coordinate, canvasHook.viewportTransform);
    },
    [canvasHook.viewportTransform]
  );

  const getBackgroundResolution = useCallback(
    (isPreview) => {
      if (!background) return null;
      if (isPreview) {
        return { width: background.width, height: background.height };
      } else {
        if (background?.depixObject?.resolution) {
          return background.depixObject.resolution;
        } else {
          return {
            width: DEFAULT_PLAIN_BACKGROUND_HIGH_RESOLUTION,
            height: DEFAULT_PLAIN_BACKGROUND_HIGH_RESOLUTION,
          };
        }
      }
    },
    [background]
  );

  const normalizeBackgroundCoord = useCallback(
    (coordinate) => {
      const x = coordinate.x / background.width;
      const y = coordinate.y / background.height;
      return new Vector2D(x, y);
    },
    [background]
  );

  const unnormalizeBackgroundCoord = useCallback(
    (coordinate) => {
      const x = coordinate.x * background.width;
      const y = coordinate.y * background.height;
      return new Vector2D(x, y);
    },
    [background]
  );

  const normalizeCanvasCoord = useCallback(
    (coordinate) => {
      const x = coordinate.x / canvasHook.canvas.width;
      const y = coordinate.y / canvasHook.canvas.height;
      return new Vector2D(x, y);
    },
    [canvasHook.canvas]
  );

  const backgroundToViewport = useCallback(
    (coordinate) => {
      return getGlobalPosition(coordinate, background);
    },
    [background]
  );

  const viewportToReferenceAbsolute = useCallback(
    (viewportCoord, offset = null, referenceClassName = 'client') => {
      const canvas = canvasHook?.ref?.current;
      if (!canvas || !viewportCoord) return {};

      const containerRect = canvas?.getBoundingClientRect();
      const canvasCoord = viewportToCanvas(viewportCoord);

      const normalizedCoord = normalizeCanvasCoord(canvasCoord);
      let calculatedTop = normalizedCoord.y * containerRect.height + containerRect.y;
      let calculatedLeft = normalizedCoord.x * containerRect.width + containerRect.x;

      if (offset) {
        calculatedTop = calculatedTop + offset.y;
        calculatedLeft = calculatedLeft + offset.x;
      }

      let x = calculatedLeft > 0 ? calculatedLeft : 0;
      let y = calculatedTop > 0 ? calculatedTop : 0;

      if (referenceClassName !== 'client') {
        const refElement = document.getElementById(referenceClassName);
        const refRect = refElement.getBoundingClientRect();
        x -= refRect.x;
        y -= refRect.y;
      }
      return { x, y };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canvasHook?.ref?.current, viewportToCanvas, normalizeCanvasCoord]
  );

  const getCanvasURL = useCallback(
    (format, quality = 0.8) => {
      const initialView = canvasHook.canvas.viewportTransform;
      // Transform the background to take full viewport (watch out for object ratio offset)

      // use the viewport dimensions/position
      const backgroundScaledSize = new Vector2D(background.getScaledWidth(), background.getScaledHeight());
      const viewportTransform = canvasHook.computeBoundingBoxScreenTransform(backgroundScaledSize, viewport);

      canvasHook.canvas.setViewportTransform(viewportTransform);
      const boundingBoxSize = viewport.getSize(backgroundScaledSize);

      let widthRatio = 1;
      let heightRatio = 1;
      // handle background ratio crop
      if (boundingBoxSize.x < boundingBoxSize.y) {
        widthRatio = boundingBoxSize.x / boundingBoxSize.y;
      } else {
        heightRatio = boundingBoxSize.y / boundingBoxSize.x;
      }

      const finalWidth = canvasHook.canvas.width * widthRatio;
      const finalHeight = canvasHook.canvas.height * heightRatio;
      const viewportFullResolutionWidth = Math.round(viewport.width * background.width);
      const dataUrl = canvasHook.canvas.toDataURL({
        multiplier: viewportFullResolutionWidth / finalWidth,
        format: format,
        quality,
        width: finalWidth,
        height: finalHeight,
      });
      canvasHook.canvas.setViewportTransform(initialView);
      return dataUrl;
    },
    [canvasHook.canvas, background, viewport]
  );

  const displayMask = useCallback(
    (enable) => {
      // loop through all objects, convert them to masks
      const objects = canvasHook.getObjects();
      for (const obj of objects) {
        if (obj.filters) {
          const brightness = obj === background ? -1 : 1;
          if (enable) {
            const filterWhite = new fabric.Image.filters.Brightness({
              brightness,
            });
            obj.filters.push(filterWhite);
          } else {
            obj.filters.pop();
          }
          obj.applyFilters();
        }
      }
    },
    [canvasHook, background]
  );

  const displayGrid = useCallback(
    (enable, camera, plane) => {
      const tag = 'ground_grid';
      const gridOption = {
        stroke: 'rgba(0,255,0,.8)',
        strokeWidth: 1,
        selectable: false,
        strokeDashArray: [3, 3],
        evented: false,
      };
      removeObjectsByName(tag);
      if (enable) {
        let [x, y] = [background.width, background.height];
        const poseBottomRight = get3DPoint(x, y, camera, plane);

        [x, y] = [0, background.height];
        const poseBottomLeft = get3DPoint(x, y, camera, plane);

        [x, y] = [0, 0];
        const poseTopLeft = get3DPoint(x, y, camera, plane);

        const transfo3Dto2d = (pose) => {
          const projected = camera.projectPoint(pose);
          projected.x = Math.round(projected.x);
          projected.y = Math.round(projected.y);
          return getGlobalPosition(projected, background);
        };

        // Draw horizontal line
        const gridLayer = [];
        const step = 10;
        const gridStep = (poseBottomRight.x - poseBottomLeft.x) / step;
        for (let i = -step * 2; i <= step * 3; i++) {
          const bottomPose = new Vector3D(poseBottomLeft.x, poseBottomLeft.y, poseBottomLeft.z);
          const topPose = new Vector3D(poseBottomLeft.x, poseTopLeft.y, poseTopLeft.z);
          bottomPose.x += i * gridStep;
          topPose.x += i * gridStep;

          const bottom = transfo3Dto2d(bottomPose);
          const top = transfo3Dto2d(topPose);

          gridLayer.push(new fabric.Line([bottom.x, bottom.y, top.x, top.y], gridOption));
        }

        // Draw vertical line
        const verticalLenght = poseBottomLeft.z - poseTopLeft.z;
        for (let i = 0; i < Math.abs(verticalLenght / gridStep); i++) {
          const leftPose = new Vector3D(poseBottomLeft.x, poseBottomLeft.y, poseBottomLeft.z);
          const rightPose = new Vector3D(poseBottomRight.x, poseBottomRight.y, poseBottomRight.z);
          leftPose.z -= i * gridStep;
          rightPose.z -= i * gridStep;

          const left = transfo3Dto2d(leftPose);
          const right = transfo3Dto2d(rightPose);

          gridLayer.push(new fabric.Line([left.x, left.y, right.x, right.y], gridOption));
        }
        const gridGroup = new fabric.Group(gridLayer, { selectable: false, evented: false });
        gridGroup.name = tag;
        canvasHook.add(gridGroup);
      }
    },
    [background, canvasHook, removeObjectsByName]
  );

  const updateViewport = (boundingBox) => {
    setViewport(boundingBox);
  };

  const orderObjects = useCallback(
    (objectList) => {
      if (!canvasHook.canvas) return;
      const objects = canvasHook.canvas.getOrderedItemsByName(objectList);
      reorderObjects(background, objects);
    },
    [background, canvasHook.canvas]
  );

  const getAll3DObjects = () => {
    return canvasHook.getObjects().filter((x) => x.type === OBJECT_3D_IMAGE_TYPE);
  };

  const getAllShadows = () => {
    return getAll3DObjects().map((x) => x.getShadow());
  };

  const getAllInfillObjects = () => {
    return canvasHook.getObjects().filter((x) => x.type === INFILL_IMAGE_TYPE);
  };

  const getInfillOr3dObjectsFromID = (id) => {
    return [...getAll3DObjects(), ...getAllInfillObjects()].filter((x) => x.name === id);
  };

  const getAllObjectsID = () => {
    return [...getAll3DObjects(), ...getAllInfillObjects()].map((object) => object.name);
  };

  const depixObjectFromID = (depixID) => {
    if (background && depixID === background.name) return background.depixObject;

    if (ground && depixID === ground.depixObject.objectID) return ground.depixObject;

    const objects = getAll3DObjects();
    const filteredObjects = objects.filter((x) => x.depixObject.objectID === depixID);

    if (filteredObjects.length !== 0) {
      return filteredObjects[0].depixObject;
    }

    return null;
  };

  const enableUIElements = (enable) => {
    const objects = getAll3DObjects();
    for (const object of objects) {
      object.setUIElementVisibility(enable);
    }
  };

  const hideUiElements = () => {
    enableUIElements(false);
  };

  const setResolution = (fullResolution = true) => {
    // for each object, change their assets
    const promiseList = [];
    if (background && background.type !== BackgroundType.PLAIN) {
      promiseList.push(
        background.loadImage({
          invertMask: false,
          ignoreMask: !background.depixObject?.mask,
          keepSize: true,
          fullResolution: fullResolution,
        })
      );
    }
    if (ground) {
      promiseList.push(
        ground.loadImage({
          invertMask: false,
          keepSize: true,
          fullResolution: fullResolution,
        })
      );
    }
    for (const object of getAll3DObjects()) {
      if (!object.isRelighted()) {
        promiseList.push(
          object.loadImage({
            invertMask: false,
            keepSize: true,
            fullResolution: fullResolution,
          })
        );
      }
    }
    return Promise.all(promiseList).then(() => {
      canvasHook.render();
      setUpdated({});
    });
  };

  const exportCanvas = (fullResolution = false, format = 'jpeg', quality = 0.8) => {
    return new Promise((resolve) => {
      setResolution(fullResolution)
        .then(() => {
          hideUiElements();
          if (!viewport.isFullSize()) {
            instrumentation.imageCropped(CroppedTag.DOWNLOAD);
          }
          resolve(exportRawCanvas(format, quality));
          setResolution(false);
        })
        .catch((e) => {
          console.log(e);
          alert.invalidImage();
        });
    });
  };

  const exportRawCanvas = (format = 'jpeg', quality = 0.8) => {
    const canvasUrl = getCanvasURL(format, quality);
    const file = dataURItoBlob(canvasUrl);
    const url = URL.createObjectURL(file);

    return {
      file,
      url,
    };
  };

  const render = (scene) => {
    orderObjects(scene.getOrderedComponents());
    canvasHook.render();
  };

  const isEmpty = () => {
    const result = canvasHook.getObjects().every((obj) => obj.type === BackgroundType.PLAIN);
    return result;
  };

  const canvasToHtml = (coord) => {
    return canvasHook.canvasToHtml(coord, background);
  };

  useEffect(() => {
    setUpdated({});
  }, [canvasHook?.updated]);

  useEffect(() => {
    if (!viewport.isFullSize()) {
      const width = unnormalizeBackgroundCoord({ x: viewport.width }).x * background.scaleX;
      const height = unnormalizeBackgroundCoord({ y: viewport.height }).y * background.scaleY;
      const center = unnormalizeBackgroundCoord(viewport.center);

      canvasHook.canvas.clipPath = new fabric.Rect({
        left: center.x * background.scaleX - width / 2,
        top: center.y * background.scaleY - height / 2,
        width: width,
        height: height,
      });
    } else {
      if (canvasHook?.canvas) {
        canvasHook.canvas.clipPath = undefined;
      }
    }
  }, [viewport, background]);

  return (
    <FabricContext.Provider
      value={{
        name: props.name,
        setupBackground,
        setupGround,
        setupInfillObject,
        setup3DObject,
        setCursor,
        background,
        viewport,
        ground,
        clearSelection,
        drawMaskOverlay,
        clearMaskOverlay,
        isOutsideBackground,
        viewportToBackground,
        viewportToCanvas,
        getBackgroundResolution,
        backgroundToViewport,
        normalizeCanvasCoord,
        unnormalizeBackgroundCoord,
        viewportToReferenceAbsolute,
        getCanvasURL,
        displayMask,
        displayGrid,
        updateViewport,
        orderObjects,
        removeObjectsByName,
        discardActiveObject: canvasHook.discardActiveObject,
        init: canvasHook.init,
        getObjects: canvasHook.getObjects,
        clear: canvasHook.clear,
        resize: canvasHook.resize,
        registerListener: canvasHook.registerListener,
        unregisterListener: canvasHook.unregisterListener,
        setCanvasEventState: canvasHook.setState,
        canvasToHtml,
        normalizeHtmlCoord: canvasHook.normalizeHtmlCoord,
        render,
        updated,
        exportCanvas,
        exportRawCanvas,
        enableUIElements,
        getAll3DObjects,
        getAllInfillObjects,
        getInfillOr3dObjectsFromID,
        getAllObjectsID,
        depixObjectFromID,
        isEmpty,
      }}>
      {children}
    </FabricContext.Provider>
  );
};

FabricContextProvider.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FabricContext;
