import React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import Logo from './Logo';

import AccountAndSettingContainer from '@components/Account/AccountAndSettingContainer';
import HelpButton from '@components/Help/HelpButton';
import { useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuthV2 } from '@contexts/AuthV2';
import Button from '@components/Ui/Button';
import { useTranslation } from 'react-i18next';
import Routing from '@root/routes';
import { useNavigate } from 'react-router-dom';
import useInstrumentation from '@hooks/UseInstrumentation';
import { OpenAppIncentive } from '@libs/Instrumentation/OpenAppIncentive';

interface HeaderProps {
  className?: string;
}

const Header = ({ className }: HeaderProps) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { isLoading, isAuthenticated } = useAuthV2();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const instrumentation = useInstrumentation();

  const handleOpenApp = () => {
    instrumentation.openAppClicked(OpenAppIncentive.TRY_APP_BUTTON);
    navigate(Routing.auth());
  };

  return (
    <AppBar
      color="transparent"
      position="fixed"
      sx={{ boxShadow: 'none', pointerEvents: 'none' }}
      className={className}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          color: 'primary.contrastText',
          justifyContent: 'space-between',
          px: '0 !important',
          py: 1,
          minHeight: 'auto !important',
          overflow: 'visible',
        }}>
        <Box
          sx={{
            pointerEvents: 'auto',
          }}>
          <StyledLink href="/">
            <Logo small={isSmallDevice} />
          </StyledLink>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'center' }}>
          {!isLoading && isAuthenticated && (
            <>
              <HelpButton />
              <AccountAndSettingContainer />
            </>
          )}

          {!isLoading && !isAuthenticated && (
            <TryButton onClick={handleOpenApp} variant="outlined">
              {t('general.tryDepix')}
            </TryButton>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const StyledLink = styled('a')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TryButton = styled(Button)`
  margin-right: ${(props) => props.theme.spacing(2)};
  text-transform: none;
  pointer-events: auto;
`;

export default Header;
