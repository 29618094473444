import { useMutation } from '@apollo/client';
import useResendVerificationEmail from '@hooks/UseResendVerificationEmail';
import { resetPassword as auth0ResetPassword } from '@libs/Auth0Utils';
import useInstrumentation from '@hooks/UseInstrumentation';
import * as captcha from '@libs/captcha/captcha';
import { LOGIN, REFRESH_LOGIN, SIGN_UP } from '@libs/DepixApi';
import { SuccessfulLoginResponse, SuccessfulUserCreationResponse } from '@api/graphql';
import jwtDecode from 'jwt-decode';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorageState } from './useLocalStorageState';

const STORAGE_KEY = 'auth_token';

export interface AuthV2Api {
  login: (usernameOrEmail: string, password: string) => Promise<any>;
  signup: (email: string, password: string, agreeTerms: boolean, consentNewsletter: boolean) => Promise<any>;
  refreshUser: () => Promise<any>;
  logout: () => void;
  resetPassword: (usernameOrEmail: string) => Promise<void>;
  resendVerificationEmail: (usernameOrEmail: string) => Promise<void>;
  token: string;
  signUpError: AuthError | null;
  loginError: AuthError | null;
  resetPasswordError: string | null;
  verificationEmailError: Error | null;
  refreshError: AuthError | null;
  updateToken: (newToken: string) => void;
  isSendingVerificationEmail: boolean;
  isLoading: boolean;
}

export interface AuthError {
  message: string;
  code: string;
}

export const useAuthV2Api = (): AuthV2Api => {
  const { t } = useTranslation();
  const instrumentation = useInstrumentation();
  const {
    resend,
    isLoading: isSendingVerificationEmail,
    isError: verificationEmailError,
  } = useResendVerificationEmail(() => {});
  const [token, setToken, unsetToken] = useLocalStorageState(STORAGE_KEY, undefined);
  const [signUpError, setSignUpError] = useState<AuthError | null>(null);
  const [loginError, setLoginError] = useState<AuthError | null>(null);
  const [refreshError, setRefreshError] = useState<AuthError | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState<string | null>(null);

  const [signUpMutation, { loading: signupLoading }] = useMutation(SIGN_UP, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      instrumentation.signUpSuccessful((data.createUser as SuccessfulUserCreationResponse).auth0UserId);
      setSignUpError(null);
    },
    onError: (error) => {
      const exception = error.graphQLErrors[0].extensions;
      instrumentation.signUpError((exception.originalCode as string) || 'UNKNOWN');
      setSignUpError({
        code: (exception.originalCode as string) || '',
        message: t(`error.api.authCodes.${exception.originalCode || 'UNKNOWN'}`),
      });
    },
  });

  const signup = async (email: string, password: string, agreeTerms: boolean, consentNewsletter: boolean) => {
    setSignUpError(null);

    const captchaToken = await captcha.secureCall('signup');

    const response = await signUpMutation({
      context: { headers: { 'x-recaptcha-token': captchaToken } },
      variables: { email, password, agreeTerms, consentNewsletter },
    });

    if (!response.errors) {
      await login(email, password);
    }

    return response;
  };

  const [loginMutation, { loading: loginLoading }] = useMutation(LOGIN, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const newToken = (data.login as SuccessfulLoginResponse).accessToken;
      const parsed = jwtDecode(newToken) as any;
      const userAuth0Id = parsed.sub as string;
      instrumentation.signInSuccessful(userAuth0Id);

      setToken(JSON.stringify({ token: newToken }));
      setLoginError(null);
      setIsLoading(false);
    },
    onError: (error) => {
      const exception = error.graphQLErrors[0].extensions;
      const code = (exception.originalCode as string) || (exception.code as string);
      instrumentation.signInError(code || 'UNKNOWN');
      setIsLoading(false);

      setLoginError({
        code: code || '',
        message: t(`error.api.authCodes.${code || 'UNKNOWN'}`),
      });
    },
  });

  const login = async (usernameOrEmail: string, password: string) => {
    setLoginError(null);
    setIsLoading(true);

    const captchaToken = await captcha.secureCall('login');
    return loginMutation({
      context: { headers: { 'x-recaptcha-token': captchaToken } },
      variables: { usernameOrEmail, password },
    });
  };

  const [refreshMutation] = useMutation(REFRESH_LOGIN, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const newToken = (data.refresh as SuccessfulLoginResponse).accessToken;
      setToken(JSON.stringify({ token: newToken }));
      setIsLoading(false);
    },
    onError: (error) => {
      console.error(error);
      setIsLoading(false);
      const exception = error.graphQLErrors[0].extensions;
      const code = (exception.originalCode as string) || (exception.code as string);

      setRefreshError({
        code: code || '',
        message: t(`error.api.authCodes.${code || 'UNKNOWN'}`),
      });
    },
  });
  const refreshUser = useCallback(async () => {
    if (isLoading || !token) return;

    setIsLoading(true);
    return refreshMutation();
  }, [token, isLoading]);

  const updateToken = useCallback(
    (newToken: string) => {
      if (token !== newToken) {
        setToken(JSON.stringify({ token: newToken }));
      }
    },
    [token]
  );

  const logout = () => {
    instrumentation.logout();
    unsetToken();
  };

  const resetPassword = async (usernameOrEmail: string): Promise<void> => {
    try {
      setResetPasswordLoading(true);
      await auth0ResetPassword(usernameOrEmail);

      instrumentation.forgotPasswordSuccessful();
      setResetPasswordLoading(false);
    } catch (e) {
      const message: string = e?.message || e;
      console.error('Reset password failed: ' + message);

      instrumentation.forgotPasswordError(message);
      setResetPasswordLoading(false);
      setResetPasswordError(message);
    }
  };

  const resendVerificationEmail = async (usernameOrEmail: string): Promise<void> => {
    return resend(usernameOrEmail);
  };

  useEffect(() => {
    setIsLoading(signupLoading || loginLoading || resetPasswordLoading);
  }, [signupLoading, loginLoading, resetPasswordLoading]);

  return {
    signup,
    login,
    refreshUser,
    logout,
    token: token as string,
    signUpError,
    loginError,
    refreshError,
    updateToken,
    isLoading,
    resetPassword,
    resetPasswordError,
    resendVerificationEmail,
    isSendingVerificationEmail,
    verificationEmailError,
  };
};
