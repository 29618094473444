import { UserPilotEvent, useUserPilot } from '@contexts/UserPilotContext';
import { BackgroundObject } from '@libs/BackgroundObject';
import { COMPOSE_PANEL_NAME } from '@libs/ComposePanelName';
import { EXTRACT_PANEL_NAME } from '@libs/ExtractPanelName';
import { MeasureType } from '@libs/Instrumentation/MeasureType';
import { SubscriptionIncentive } from '@libs/Instrumentation/SubscriptionIncentive';
import { PlainBackgroundObject } from '@libs/PlainBackgroundObject';
import { Subscription } from '@libs/Subscription';
import { useState } from 'react';

import ga4 from 'react-ga4';

import { Feature, useFeatureFlag } from './FeatureFlag';
import { ShareLinkIncentive } from '@libs/Instrumentation/ShareLinkIncentive';
import { OpenAppIncentive } from '@libs/Instrumentation/OpenAppIncentive';

export enum CroppedTag {
  DOWNLOAD = 'Download',
  APPLY = 'Apply',
}

type Instrumentation = {
  instrument: (measureType: MeasureType, payload?: object) => void;
  segmentationClickAdded: (segmentationType: string, segmentedImageId: string) => void;
  segmentationUndo: (segmentedImageId: string) => void;
  segmentationRedo: (segmentedImageId: string) => void;
  segmentationCleared: (segmentedImageId: string) => void;
  segmentationApplied: (segmentedImageId: string) => void;
  segmentationEditSelected: () => void;
  imageUploaded: (panel: string, source: string, imageId?: string) => void;
  imageCropped: (imageCroppedTag: CroppedTag) => void;
  imageDownloaded: (properties: ImageDownloadProperties) => void;
  downloadDialogSkipped: (panel: string) => void;
  instrumentVideo: (vidyardEmbed: object) => void;
  subscriptionCancelClicked: (subscription: Subscription) => void;
  subscriptionUpdateClicked: (subscription: Subscription) => void;
  subscriptionReceiptClicked: (subscription: Subscription) => void;
  helpMenuOpened: () => void;
  getSupportButtonClicked: () => void;
  backgroundChanged: (background: BackgroundObject) => void;
  accountCreationStarted: () => void;
  accountCreationCancelled: () => void;
  subscriptionOfferShown: (incentive: string) => void;
  subscriptionPlanSelected: () => void;
  subscriptionPlanQuitted: () => void;
  outOfImagesQuitted: () => void;
  forgotPasswordStarted: () => void;
  signInSuccessful: (userAuth0Id: string) => void;
  signInError: (code: string) => void;
  signUpSuccessful: (userAuth0Id: string) => void;
  signUpError: (code: string) => void;
  forgotPasswordSuccessful: () => void;
  forgotPasswordError: (message: string) => void;
  resendVerificationEmailSuccessful: () => void;
  resendVerificationEmailError: () => void;
  logout: () => void;
  backgroundRemoved: (imageId: string) => void;
  openAppClicked: (incentive: OpenAppIncentive) => void;
  imageLinkShared: (incentive: ShareLinkIncentive) => void;
  subscribeClicked: (incentive: SubscriptionIncentive) => void;
};

interface ImageDownloadProperties {
  panel: string;
  highResolution: boolean;
  backgroundType: ImageDownloadBackgroundType;
  backgroundColor?: string;
  backgroundColorOpacity?: number;
  imageId?: string;
  relighting?: boolean;
  shadows?: boolean;
  infilled?: boolean;
  erased?: boolean;
  groundObject?: boolean;
  componentsCount?: number;
  infillComponentsCount?: number;
  occlusionComponentsCount?: number;
  erasedComponentsCount?: number;
  isCropped?: boolean;
}

export type ImageDownloadBackgroundType = 'plain' | 'image' | 'transparent' | 'mask';

export default function useInstrumentation(): Instrumentation {
  const featureFlag = useFeatureFlag();
  const [isVideoInstrumented, setIsVideoInstrumented] = useState(false);
  const { trackEvent } = useUserPilot();

  const instrument = (measureType: MeasureType, payload = {}) => {
    const data = { timestamp: Date.now(), measure: measureType, ...payload };

    ga4.event(measureType, data);

    if (featureFlag.canUseFeature(Feature.DEV_TOOLS)) {
      console.debug(`[${measureType}]:`, payload);
    }
  };

  const segmentationClickAdded = (segmentationType, segmentedImageId) => {
    const payload = {
      segmentation_action: 'click_added',
      segmentation_type: segmentationType,
      segmented_image: segmentedImageId,
    };
    instrument(MeasureType.IMAGE_SEGMENTED, payload);
  };

  const segmentationUndo = (segmentedImageId) => {
    const payload = {
      segmentation_action: 'segmentation_undo',
      segmented_image: segmentedImageId,
    };
    instrument(MeasureType.IMAGE_SEGMENTED, payload);
  };

  const segmentationRedo = (segmentedImageId) => {
    const payload = {
      segmentation_action: 'segmentation_redo',
      segmented_image: segmentedImageId,
    };
    instrument(MeasureType.IMAGE_SEGMENTED, payload);
  };

  const segmentationCleared = (segmentedImageId) => {
    const payload = {
      segmentation_action: 'click_cleared',
      segmented_image: segmentedImageId,
    };
    instrument(MeasureType.IMAGE_SEGMENTED, payload);
  };

  const segmentationApplied = (segmentedImageId) => {
    const payload = {
      segmentation_action: 'segmentation_applied',
      segmented_image: segmentedImageId,
    };
    instrument(MeasureType.IMAGE_SEGMENTED, payload);
  };

  const segmentationEditSelected = () => {
    trackEvent(UserPilotEvent.SEGMENTATION_EDIT_SELECTED);
    instrument(MeasureType.SEGMENTATION_EDIT_SELECTED);
  };

  const imageUploaded = (panel, source, imageId) => {
    const payload = {
      to_panel: panel,
      upload_source: source,
      upload_image: imageId,
    };
    if (panel === EXTRACT_PANEL_NAME) {
      trackEvent(UserPilotEvent.CUT_UPLOADED);
    }
    if (panel === COMPOSE_PANEL_NAME) {
      trackEvent(UserPilotEvent.COMPOSE_UPLOADED);
    }
    instrument(MeasureType.IMAGE_UPLOADED, payload);
  };

  const imageCropped = (imageCroppedTag) => {
    const payload = {
      tag: imageCroppedTag,
    };
    instrument(MeasureType.IMAGE_CROPPED, payload);
  };

  const imageDownloaded = (properties: ImageDownloadProperties) => {
    const payload = {
      from_panel: properties.panel,
      image: properties.imageId,
      high_resolution: properties.highResolution,
      download_background_type: properties.backgroundType,
      download_background_color: properties.backgroundColor,
      download_background_color_opacity: properties.backgroundColorOpacity,
      download_objects_count: properties.componentsCount,
      download_occlusion_objects_count: properties.occlusionComponentsCount,
      download_infill_objects_count: properties.infillComponentsCount,
      download_erased_objects_count: properties.erasedComponentsCount,
      download_has_relighting: properties.relighting,
      download_has_shadow: properties.shadows,
      download_has_infilling: properties.infilled,
      download_has_erased: properties.erased,
      download_has_ground_object: properties.groundObject,
      download_is_cropped: properties.isCropped,
    };

    instrument(MeasureType.IMAGE_DOWNLOADED, payload);
    trackEvent(UserPilotEvent.IMAGE_DOWNLOADED, payload);

    if (properties.backgroundType == 'mask') {
      trackEvent(UserPilotEvent.MASK_DOWNLOADED);
    } else if (properties.backgroundType == 'plain') {
      trackEvent(UserPilotEvent.PLAIN_BACKGROUND_DOWNLOADED);
    } else if (properties.backgroundType == 'transparent') {
      trackEvent(UserPilotEvent.TRANSPARENT_BACKGROUND_DOWNLOADED);
    } else if (properties.backgroundType == 'image') {
      trackEvent(UserPilotEvent.IMAGE_BACKGROUND_DOWNLOADED);
    }
  };

  const downloadDialogSkipped = (panel: string) => {
    instrument(MeasureType.DOWNLOAD_DIALOG_SKIPPED, { panel });
  };

  const instrumentVideo = (vidyardEmbed) => {
    if (!isVideoInstrumented) {
      setIsVideoInstrumented(true);
      vidyardEmbed.api.addReadyListener(function () {
        vidyardEmbed.api.progressEvents(
          function (result) {
            if (result.event == 1) {
              dataLayer.push({
                event: 'vidyard',
                eventCategory: 'video',
                eventAction: 'Play',
                eventLabel: result.player.metadata.chapters_attributes[result.chapter].video_attributes.name,
              });
            } else {
              dataLayer.push({
                event: 'vidyard',
                eventCategory: 'video',
                eventAction: 'Watched ' + result.event + '%',
                eventLabel: result.player.metadata.chapters_attributes[result.chapter].video_attributes.name,
              });
            }
          },
          [1, 10, 25, 50, 75, 95]
        );
      });
    }
  };

  const subscriptionCancelClicked = (subscription: Subscription) => {
    instrument(MeasureType.SUBSCRIPTION_CANCEL_CLICKED, subscription);
  };

  const subscriptionUpdateClicked = (subscription: Subscription) => {
    instrument(MeasureType.SUBSCRIPTION_UPDATE_CLICKED, subscription);
  };

  const subscriptionReceiptClicked = (subscription: Subscription) => {
    instrument(MeasureType.SUBSCRIPTION_RECEIPT_CLICKED, subscription);
  };

  const helpMenuOpened = () => {
    instrument(MeasureType.HELP_MENU_OPENED, {});
  };

  const getSupportButtonClicked = () => {
    instrument(MeasureType.GET_SUPPORT_BUTTON_CLICKED, {});
  };

  const backgroundChanged = (background: BackgroundObject) => {
    if (background instanceof PlainBackgroundObject) {
      const type = background.color.a === 0 ? 'transparent' : 'plain color';
      instrument(MeasureType.BACKGROUND_CHANGED, { background_type: type });
    } else {
      instrument(MeasureType.BACKGROUND_CHANGED, { background_type: 'image' });
    }
  };

  const subscriptionOfferShown = (incentive: SubscriptionIncentive) => {
    instrument(MeasureType.SUBSCRIPTION_OFFER_SHOWN, { incentive });
    trackEvent(UserPilotEvent.SUBSCRIPTION_OFFER_SHOWN, { incentive });
  };

  const subscriptionPlanSelected = () => {
    instrument(MeasureType.SUBSCRIPTION_PLAN_SELECTED);
  };

  const subscriptionPlanQuitted = () => {
    instrument(MeasureType.SUBSCRIPTION_PLAN_QUITTED);
  };

  const outOfImagesQuitted = () => {
    instrument(MeasureType.OUT_OF_IMAGES_QUITTED);
  };

  const accountCreationStarted = () => {
    instrument(MeasureType.ACCOUNT_CREATION_STARTED);
  };

  const signUpSuccessful = (userAuth0Id: string) => {
    dataLayer.push({
      event: 'sign_up',
      Form_Type: 'Creation de compte',
      user_id: userAuth0Id,
    });
    instrument(MeasureType.SIGN_UP_SUCCESSFUL);
  };

  const accountCreationCancelled = () => {
    instrument(MeasureType.ACCOUNT_CREATION_CANCELLED);
  };

  const forgotPasswordStarted = () => {
    instrument(MeasureType.FORGOT_PASSWORD_STARTED);
  };

  const signInSuccessful = (userAuth0Id: string) => {
    dataLayer.push({
      event: 'sign_in',
      Form_Type: 'Sign in',
      user_id: userAuth0Id,
    });
    instrument(MeasureType.SIGN_IN_SUCCESSFUL);
  };

  const signInError = (code: string) => {
    instrument(MeasureType.SIGN_IN_ERROR, { code });
  };

  const signUpError = (code: string) => {
    instrument(MeasureType.SIGN_UP_ERROR, { code });
  };

  const forgotPasswordSuccessful = () => {
    instrument(MeasureType.FORGOT_PASSWORD_SUCCESSFUL);
  };

  const forgotPasswordError = (message: string) => {
    instrument(MeasureType.FORGOT_PASSWORD_ERROR, { message });
  };

  const resendVerificationEmailSuccessful = () => {
    instrument(MeasureType.RESEND_VERIFICATION_EMAIL_SUCCESSFUL);
  };

  const resendVerificationEmailError = () => {
    instrument(MeasureType.RESEND_VERIFICATION_EMAIL_ERROR);
  };

  const logout = () => {
    instrument(MeasureType.LOGOUT);
  };

  const backgroundRemoved = (imageId: string) => {
    instrument(MeasureType.BACKGROUND_REMOVED, { original_image: imageId });
  };

  const openAppClicked = (incentive: OpenAppIncentive) => {
    instrument(MeasureType.OPEN_APP_CLICKED, { open_app_incentive: incentive });
    trackEvent(UserPilotEvent.OPEN_APP_CLICKED, { open_app_incentive: incentive });
    // TODO: Instrument in our backend
  };

  const imageLinkShared = (incentive: ShareLinkIncentive) => {
    instrument(MeasureType.IMAGE_LINK_SHARED, { image_link_share_incentive: incentive });
    trackEvent(UserPilotEvent.IMAGE_LINK_SHARED, { image_link_share_incentive: incentive });
    // TODO: Instrument in our backend
  };

  const subscribeClicked = (incentive: SubscriptionIncentive) => {
    instrument(MeasureType.SUBSCRIBE_CLICKED, { subscribe_click_incentive: incentive });
    trackEvent(UserPilotEvent.SUBSCRIBE_CLICKED, { subscribe_click_incentive: incentive });
  };

  return {
    instrument,
    segmentationClickAdded,
    segmentationUndo,
    segmentationRedo,
    segmentationCleared,
    segmentationApplied,
    segmentationEditSelected,
    imageUploaded,
    imageCropped,
    imageDownloaded,
    downloadDialogSkipped,
    instrumentVideo,
    subscriptionCancelClicked,
    subscriptionUpdateClicked,
    subscriptionReceiptClicked,
    helpMenuOpened,
    getSupportButtonClicked,
    backgroundChanged,
    accountCreationStarted,
    signUpSuccessful,
    accountCreationCancelled,
    subscriptionOfferShown,
    subscriptionPlanSelected,
    subscriptionPlanQuitted,
    outOfImagesQuitted,
    backgroundRemoved,
    forgotPasswordStarted,
    signInSuccessful,
    signInError,
    signUpError,
    forgotPasswordSuccessful,
    forgotPasswordError,
    resendVerificationEmailSuccessful,
    resendVerificationEmailError,
    logout,
    openAppClicked,
    imageLinkShared,
    subscribeClicked,
  };
}
