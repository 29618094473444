export const DEFAULT_NS = 'webpage';

export const I18N_CONFIG = {
  fallbackLng: 'en-US',
  ns: DEFAULT_NS,
  defaultNS: DEFAULT_NS,
  backend: {
    loadPath: process.env.REACT_APP_I18N_PATH,
    crossDomain: true,
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['strong', 'br', 'em'],
  },
};
