import React from 'react';

export enum Side {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
}

type CropSideIconProps = {
  side?: Side;
  color?: string;
};

const CropSideIcon = ({ side = Side.LEFT, color }: CropSideIconProps) => {
  const rotation = side === Side.TOP ? 90 : side === Side.BOTTOM ? -90 : side === Side.RIGHT ? 180 : 0;
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8" transform={`rotate(${rotation}, 6, 6)`}>
        <rect width="4" height="12" fill={color ?? 'currentColor'} />
      </g>
    </svg>
  );
};

export default CropSideIcon;
