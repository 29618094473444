import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';
import { generateShareLink, SharedImage } from '@libs/SharedImage/SharedImage';
import { ShareLink } from '@components/SharedImage/ShareLink';
import { ShareToSocials } from '@components/SharedImage/ShareToSocials';
import React from 'react';

interface ImageSharingProps {
  sharedImage: SharedImage;
  className?: string;
}

export const ShareActions = ({ sharedImage, className }: ImageSharingProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'imageSharing.shareActions' });

  return (
    <Wrapper className={className}>
      <StyledTypography variant="h4" fontWeight={500}>
        {t('title')}
      </StyledTypography>

      <ShareOptions>
        <ShareLink link={generateShareLink(sharedImage.ref)} />
        <ShareToSocials sharedImage={sharedImage} />
      </ShareOptions>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ShareOptions = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing(3)};
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  align-self: flex-start;
  margin-bottom: ${(props) => props.theme.spacing(4)};

  ${(props) => props.theme.breakpoints.down('md')} {
    align-self: center;
  }
`;
