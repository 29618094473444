interface Record<RecordType> {
  state: RecordType;
  onUndo: () => void;
  onRedo: () => void;
}

export class HistoryStack<HistoryType> {
  private undoStack: Record<HistoryType>[];
  private redoStack: Record<HistoryType>[];
  private currentRecord: Record<HistoryType> | null;
  private _canUndo: boolean;
  private _canRedo: boolean;
  constructor() {
    this.undoStack = [];
    this.redoStack = [];
    this.currentRecord = null;
    this._canUndo = false;
    this._canRedo = false;
  }

  get canRedo(): boolean {
    return this._canRedo;
  }

  get canUndo(): boolean {
    return this._canUndo;
  }

  get currentState(): HistoryType | null {
    return this.currentRecord?.state ?? null;
  }

  push = (state: HistoryType, onUndo: () => void = () => {}, onRedo: () => void = () => {}) => {
    if (this.currentRecord) {
      this.undoStack.push(this.currentRecord);
    }
    this.redoStack = [];
    this.currentRecord = { state, onUndo, onRedo };
    this.updateFlags();
  };

  undo = () => {
    if (this.undoStack.length === 0) return null;
    const record = this.undoStack.pop();

    this.redoStack.push(this.currentRecord);

    if (this.currentRecord.onUndo) {
      this.currentRecord.onUndo();
    }

    if (record) {
      this.currentRecord = record;
    }

    this.updateFlags();
    return record.state;
  };

  redo = () => {
    if (this.redoStack.length === 0) return null;
    const record = this.redoStack.pop();

    this.undoStack.push(this.currentRecord);
    if (record) {
      if (record.onRedo) {
        record.onRedo();
      }
      this.currentRecord = record;
    }

    this.updateFlags();
    return record.state;
  };

  clear = () => {
    this.redoStack = [];
    this.undoStack = [];
    this.currentRecord = null;
    this.updateFlags();
  };

  private updateFlags = () => {
    this._canUndo = this.undoStack.length > 0;
    this._canRedo = this.redoStack.length > 0;
  };
}
