import { useLazyQuery } from '@apollo/client';
import { SEND_FEEDBACK } from '../Libs/DepixApi';
import { useGraphqlErrorHandler } from '@hooks/useGraphqlErrorHandler';

/**
 *  @param {*} props
 *  @return {*}
 */
function useFeedbackQuery() {
  const { displayError } = useGraphqlErrorHandler();
  const [feedbackQuery, { loading: isLoading, data: isSuccess }] = useLazyQuery(SEND_FEEDBACK, {
    fetchPolicy: 'no-cache',
    onError(error) {
      displayError(error);
    },
  });
  const feedbackRequest = (image, comment) => {
    if (comment) {
      feedbackQuery({ variables: { image: image, comment: comment } });
    } else {
      feedbackQuery({ variables: { image: image } });
    }
  };

  return { feedbackRequest, isLoading, isSuccess };
}

export default useFeedbackQuery;
