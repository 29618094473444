import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import useResendVerificationEmail from '@hooks/UseResendVerificationEmail';
import { Box } from '@mui/material';

import VerificationPage from './VerificationPage';
import AddressInputPage from './AddressInputPage';
import ResendFailedPage from './ResendFailedPage';
import LoadingBackdrop from '../WebApp/LoadingBackdrop';

const INITIAL_RESEND_STATE = null;

const EmailVerificationBox = ({ onRetry, sx = {} }) => {
  const { t } = useTranslation();

  const [openEmailField, setOpenEmailField] = useState(false);
  const [email, setEmail] = useState(null);
  const [resendSuccess, setResendSuccess] = useState(INITIAL_RESEND_STATE);

  const resetResendState = () => setResendSuccess(INITIAL_RESEND_STATE);
  const handleResendComplete = (data) => {
    setResendSuccess(data.success);
    setOpenEmailField(false);
  };
  const { resend, isLoading } = useResendVerificationEmail(handleResendComplete);

  const handleOpenEmailField = () => setOpenEmailField(true);
  const handleCloseEmailField = () => setOpenEmailField(false);

  const onChange = (value) => {
    setEmail(value);
  };

  const onSubmit = () => {
    if (!email) return;
    resetResendState();
    resend(email);
  };

  const content = () => {
    if (resendSuccess === false) {
      return <ResendFailedPage onBack={resetResendState} />;
    } else if (openEmailField) {
      return <AddressInputPage onClose={handleCloseEmailField} onChange={onChange} onSubmit={onSubmit} />;
    }
    return <VerificationPage onOpenEmailField={handleOpenEmailField} onRetry={onRetry} />;
  };

  return (
    <Box sx={{ m: -2, ...sx }} role="option" aria-label={t('error.verifyAccount.label')}>
      <LoadingBackdrop isLoading={!!isLoading}>{content()}</LoadingBackdrop>
    </Box>
  );
};

EmailVerificationBox.propTypes = {
  onRetry: PropTypes.func.isRequired,
  sx: PropTypes.object,
};

export default EmailVerificationBox;
