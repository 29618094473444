import { ReactNode } from 'react';

import DefaultToolBar from '@components/Viewport/ToolBar';
import Divider from '@components/Ui/Divider';
import { styled } from '@mui/material/styles';
import { SegmentationModeSelector } from '@components/Viewport/SegmentActions/SegmentationModeSelector';
import { SegmentationFlowController } from '@components/Viewport/SegmentActions/SegmentationFlowController';

interface SegmentActionsProps {
  name?: string;
  label?: string;
  onReset: () => void;
  includeText?: boolean;
  children?: ReactNode;
}

const SegmentActions = ({ name = 'segment', label, onReset, includeText = false, children }: SegmentActionsProps) => {
  return (
    <DefaultToolBar name={name} label={label} includeText={includeText}>
      <SegmentationModeSelector includeText={includeText} />

      <StyledDivider />

      <SegmentationFlowController includeText={includeText} onReset={onReset} />

      {children && (
        <>
          <StyledDivider />
          {children}
        </>
      )}
    </DefaultToolBar>
  );
};

const StyledDivider = styled(Divider)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: 0;
  }
`;

export default SegmentActions;
