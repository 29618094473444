import { TypographyProps } from '@mui/material/Typography/Typography';
import React, { useEffect, useState } from 'react';
import { parseColor } from '@react-stately/color';
import { useTranslation } from 'react-i18next';
import reactCSS from 'reactcss';
import ColorSlider from './ColorSlider';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@mui/material';
import { TitleTypography } from '@components/Object/PanelText';
import Box from '@mui/material/Box';

type ColorPickerColor = {
  r: number;
  g: number;
  b: number;
};

type Props = {
  isRowView: boolean;
  color: ColorPickerColor;
  onChange: (event: any, color: ColorPickerColor) => void;
  onChangeCommitted: (event: any, color: ColorPickerColor) => void;
  titleTypographyProps: TypographyProps;
};

function LightColorPicker(props: Props) {
  const { t } = useTranslation();
  const { color, onChange, onChangeCommitted, isRowView, titleTypographyProps } = props;
  const [ariaColor, setAriaColor] = useState(parseColor('hsb(0, 1%, 100%)') as any);
  const [pickerEnabled, setPickerEnabled] = useState(false);

  const handleClick = () => {
    setPickerEnabled(!pickerEnabled);
  };

  const handleClose = (e) => {
    setPickerEnabled(false);
  };

  const onColorChange = (color) => {
    const event = {};
    setAriaColor(color);
    const rgb = color.toFormat('rgb');
    onChange(event, { r: rgb.red, g: rgb.green, b: rgb.blue });
  };

  const onColorChangeCommit = (color) => {
    const event = {};
    setAriaColor(color);
    const rgb = color.toFormat('rgb');
    onChangeCommitted(event, { r: rgb.red, g: rgb.green, b: rgb.blue });
  };

  useEffect(() => {
    const rgbColor = ariaColor.toFormat('rgb');
    rgbColor.blue = color.b;
    rgbColor.green = color.g;
    rgbColor.red = color.r;
    setAriaColor(rgbColor.toFormat('hsb'));
  }, [color]);

  const styles = reactCSS({
    default: {
      color: {
        width: '14px',
        height: '14px',
        borderRadius: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
        // eslint-disable-next-line max-len
        background: `rgba(${ariaColor.toFormat('rgb').red}, ${ariaColor.toFormat('rgb').green}, ${
          ariaColor.toFormat('rgb').blue
        }, ${255})`,
      },
      container: {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        zIndex: '2',
        left: '50%',
        transform: 'translate(-50%, 0)',
        backgroundColor: 'white',
        padding: '15px',
      },
      swatch: {
        padding: '1px',
        background: '#fff',
        borderRadius: '10px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        margin: '4px',
        cursor: 'pointer',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        {!isRowView && (
          <TitleTypography {...titleTypographyProps}>{t('tools.enhance.panels.light.color')}</TitleTypography>
        )}
        <div style={styles.swatch}>
          <div style={styles.color} />
        </div>
      </Box>
      {pickerEnabled ? (
        <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown" touchEvent="onTouchStart">
          <div style={styles.container as React.CSSProperties}>
            <div style={styles.cover as React.CSSProperties} onClick={handleClose} />
            <ColorSlider channel="hue" value={ariaColor} onChange={onColorChange} onChangeEnd={onColorChangeCommit} />
            <ColorSlider
              channel="saturation"
              value={ariaColor}
              onChange={onColorChange}
              onChangeEnd={onColorChangeCommit}
            />
          </div>
        </ClickAwayListener>
      ) : null}
    </>
  );
}

LightColorPicker.propTypes = {
  color: PropTypes.object,
  onChange: PropTypes.func,
  onChangeCommitted: PropTypes.func,
};

export default LightColorPicker;
