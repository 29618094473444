import { intervalToDuration, formatDuration, format, isToday, parseISO } from 'date-fns';
import { enUS, es, fr } from 'date-fns/locale';

// Used to differentiate between "on {date}" and "at {time}"
export enum PREPOSITION_TYPE {
  date = 'date',
  time = 'time',
}

const convertToDateFnsLocale = (localeString: string): Locale => {
  if (localeString.toLowerCase().startsWith('en')) {
    return enUS;
  } else if (localeString.toLowerCase().startsWith('es')) {
    return es;
  } else if (localeString.toLowerCase().startsWith('fr')) {
    return fr;
  }

  return enUS;
};

export const secondsToHumanReadable = (seconds: number, localeString = 'en-US') => {
  if (isNaN(seconds)) return '';
  const helperDuration = intervalToDuration({ start: 0, end: seconds * 1000 });
  const locale = convertToDateFnsLocale(localeString);
  return formatDuration(helperDuration, { locale });
};

export type HumanReadableDateTime = {
  formatedTime: string;
  preposition: string;
  prepositionType: PREPOSITION_TYPE;
};
export const utcIsoToHumanReadable = (ISOtime: string): HumanReadableDateTime => {
  const date = parseISO(ISOtime);
  let preposition = 'on';
  let timeFormat = 'MMM. do. p';
  let prepositionType = PREPOSITION_TYPE.date;

  if (isToday(date)) {
    preposition = 'at';
    timeFormat = 'p';
    prepositionType = PREPOSITION_TYPE.time;
  }

  const formatedTime = format(date, timeFormat);

  return { formatedTime, preposition, prepositionType };
};

export const utcIsoToDate = (isoTime: Date | string | null | undefined): HumanReadableDateTime => {
  if (!isoTime) {
    return { formatedTime: null, preposition: null, prepositionType: null };
  }

  const date = isoTime instanceof Date ? isoTime : parseISO(isoTime);
  const preposition = 'on';
  const prepositionType = PREPOSITION_TYPE.date;
  let timeFormat = 'MMM. do';

  if (date.getFullYear() != new Date().getFullYear()) {
    timeFormat = 'MMM. do yyyy';
  }

  const formatedTime = format(date, timeFormat);

  return { formatedTime, preposition, prepositionType };
};
