import { createTheme, responsiveFontSizes, TypographyStyle } from '@mui/material';
import { cyan, grey } from '@mui/material/colors';

const palette = {
  primary: {
    main: '#0032C8',
    light: '#B8C5F0',
    lighter: 'rgba(184, 197, 240, 0.15)',
    dark: '#0013cc',
    grey: '#ddd',
  },
  secondary: {
    main: '#ffffff',
    contrastText: '#121213',
    lighter: grey[50],
    light: grey[100],
    lightGrey: '#f3f3f3',
    grey: '#e7e7e7',
    medium: '#A0A0A1',
    dark: grey[600],
    darker: grey[900],
  },
  ternary: {
    main: '#edf7e6',
    dark: '#dce6d5',
  },
  action: {
    disabled: '#b9b9b9',
  },
  removed: { main: '#F83338' },
  accept: { main: '#4EC289', dark: '#3DB178' },
  success: { main: '#3DB178' },
  info: { main: '#E6F4F7' },
  warning: { main: '#F7F1E6' },
  error: { main: '#c72d4a' },
  greyInfo: {
    main: grey[500],
  },
  segmentation: {
    foreground: '#5DE2A1',
    background: '#F83338',
  },
  divider: 'rgba(0, 0, 0, 0.1)',
};

interface BorderRadiusOptions {
  large: number;
  medium: number;
  default: number;
  small: number;
  xsmall: number;
}

const customVariables = {
  borderRadius: {
    large: 32,
    medium: 24,
    default: 16,
    small: 8,
    xsmall: 3,
  },
};

export const BorderRadius = {
  XSMALL: 'xsmall',
  SMALL: 'small',
  DEFAULT: 'default',
  MEDIUM: 'medium',
  LARGE: 'large',
};

interface Elevation {
  tag: string;
  canvas: string;
  button: string;
  modal: string;
  tooltip: string;
  small: string;
}

let theme = createTheme({
  palette,
  elevation: {
    tag: '0 1px 2px rgba(0, 0, 0, 0.20)',
    canvas: '0px 2px 8px rgba(0, 0, 0, 0.14)',
    button: '2px 4px 12px rgba(0, 0, 0, 0.12)',
    modal: '0px 16px 32px rgba(0, 0, 0, 0.16)',
    tooltip: '0px 16px 32px rgba(0, 0, 0, 0.4)',
    small: '2px 8px 16px rgba(17, 17, 17, 0.2)',
  },
  typography: {
    fontFamily: 'Poppins',
    dropZoneText: {
      color: grey[400],
      fontWeight: 'bold',
      '@media (min-width:400px)': {
        fontSize: 13,
      },
      '@media (min-width:500px)': {
        fontSize: 18,
      },
      '@media (min-width:600px)': {
        fontSize: 23,
      },
      '@media (min-width:900px)': {
        fontSize: 18,
      },
      '@media (min-width:1301px)': {
        fontSize: 23,
      },
    },
    h1: {
      fontFamily: 'Cormorant, sans-serif',
      fontWeight: 500,
      letterSpacing: -3,
    },
    subtitle1: {
      color: palette.secondary.dark,
      fontSize: 14,
      fontWeight: 400,
    },
    caption: {
      fontSize: '0.75em',
    },
  },
  components: {
    MuiDialog: {
      defaultProps: { BackdropProps: { style: { backgroundColor: 'rgba(0, 0, 0, 0.135)' } } },
    },
    MuiDivider: {
      variants: [
        {
          props: { light: true },
          style: {
            '&:after, &:before': {
              borderTop: 'thin solid rgba(255, 255, 255, 0.2)',
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'compact' },
          style: {
            flexDirection: 'column !important' as any,
            padding: '6px 8px',
            margin: 8,
            '& .MuiButton-startIcon': {
              margin: 0,
            },
            '& .MuiButton-startIcon > :nth-of-type(1)': {
              fontSize: 24,
            },
          },
        },
        {
          props: { variant: 'outlined-thick' },
          style: {
            borderRadius: 15,
            border: '2px solid',
            fontWeight: '500',
            fontSize: '16px',
            '& .MuiButton-startIcon': {
              margin: 0,
            },
            '& .MuiButton-startIcon > :nth-of-type(1)': {
              fontSize: '24px !important',
            },
          },
        },
      ],
    },
  },
  zIndex: {
    appBar: 101,
    mobileStepper: 100,
    composeToolbar: 103,
    loadingSpinner: 999999,
  },
  ...customVariables,
});

declare module '@mui/material/styles' {
  interface Theme {
    borderRadius: BorderRadiusOptions;
    elevation: Elevation;
  }
  interface ThemeOptions {
    borderRadius?: BorderRadiusOptions;
    elevation?: Elevation;
  }
  interface TypographyVariants {
    dropZoneText: TypographyStyle;
  }
  interface TypographyVariantsOptions {
    dropZoneText?: TypographyStyle;
  }
  interface PaletteColor {
    main: string;
    lighter: string;
    lightGrey: string;
    grey: string;
    medium: string;
    dark: string;
    darker: string;
  }
  interface PaletteColorOptions {
    main: string;
    lighter?: string;
    lightGrey?: string;
    grey?: string;
    medium?: string;
    dark?: string;
    darker?: string;
  }
  interface SegmentationColor {
    foreground: string;
    background: string;
  }
  interface SegmentationColorOptions {
    foreground?: string;
    background?: string;
  }
  interface Palette {
    accept: PaletteColor;
    ternary: PaletteColor;
    segmentation: SegmentationColor;
  }
  interface PaletteOptions {
    accept?: PaletteColorOptions;
    ternary?: PaletteColorOptions;
    segmentation?: SegmentationColorOptions;
  }
}

declare module '@mui/material/styles/zIndex' {
  interface ZIndex {
    composeToolbar: number;
    loadingSpinner: number;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    dropZoneText: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    compact: true;
    'outlined-thick': true;
  }
}

theme = responsiveFontSizes(theme);

export default theme;
