import React, { ReactNode } from 'react';

import FloatingActionButton from '@components/Ui/FloatingActionButton';
import useUserProfile from '@hooks/UseUserProfile';

interface ActionButtonProps {
  label: string;
  showLabel: boolean;
  tooltip: string;
  expand: boolean;
  onClick: () => void;
  onClose: () => void;
  children?: ReactNode;
}

export const ActionButton = ({ label, showLabel, tooltip, expand, onClick, onClose, children }: ActionButtonProps) => {
  const { limitReached, openOutOfImageDialog } = useUserProfile();

  const handleOnFabClicked = () => {
    if (!expand && limitReached) {
      openOutOfImageDialog();
    } else {
      onClick();
    }
  };

  return (
    <FloatingActionButton
      label={label}
      showLabel={showLabel}
      tooltip={tooltip}
      onClick={handleOnFabClicked}
      onClose={onClose}
      expand={expand}>
      {children}
    </FloatingActionButton>
  );
};
