import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Draggable from 'react-draggable';

import Box from '@mui/material/Box';

import RelightIcon from '@components/Ui/Icons/RelightIcon';
import RelightPanel from '@containers/RelightPanelContainer';
import ShadowIcon from '@components/Ui/Icons/ShadowIcon';
import ShadowPanel from '@containers/ShadowPanelContainer';
import DeleteObjectIcon from '@components/Ui/Icons/DeleteObjectIcon';
import ToolButton from '@components/Viewport/ToolButton';
import ToolPanel from '@components/Object/ToolPanel';
import Divider from '@components/Ui/Divider';
import ToolBar from '@components/Viewport/ToolBar';
import { useTheme, useMediaQuery } from '@mui/material';
import Placeable, { Placement } from '@components/Placeable/Placeable';

import useInstrumentation from '@hooks/UseInstrumentation';
import { useActiveObject, useScene } from '@contexts/SceneContext';
import { MeasureType } from '@libs/Instrumentation/MeasureType';

import { OBJECT_3D_IMAGE_TYPE } from '../FabricJS/Object3DImage';
import { styled } from '@mui/material/styles';

const DRAGGABLE_PANEL_WIDTH = 246;

export const ObjectToolType = { RELIGHT: 'Relight', SHADOW: 'Shadow' };

const ObjectTools = ({
  position,
  currentTool,
  onChange,
  onPanelMoved = ({ x, y }) => {},
  panelStartingPos = { x: 0, y: 0 },
  children,
}) => {
  const { t } = useTranslation(null, { keyPrefix: 'tools' });
  const { setRelightEnabled, setShadowEnabled, relightEnabled, shadowEnabled, activeObject, deleteObject } =
    useActiveObject();
  const instrumentation = useInstrumentation();
  const objectToolPanelRef = useRef(null);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const [toolPanelPos, setToolPanelPos] = useState(isSmallDevice ? { x: 0, y: 0 } : null);
  const [use3DImageTool, setUse3DImageTool] = useState(true);

  const closePanel = () => {
    onChange(false);
  };

  const onClickRelight = (panel) => {
    onToolClicked(panel);
    if (!relightEnabled) {
      toggleRelight(true);
    }
  };

  const onClickShadow = (panel) => {
    onToolClicked(panel);
    if (!shadowEnabled) {
      toggleShadow(true);
    }
  };

  const handleDeleteObject = () => {
    deleteObject();
  };

  const onToolClicked = (panel) => {
    if (currentTool === panel) {
      closePanel();
    } else {
      onChange(panel);
    }
  };

  const toggleTool = (tool, checked) => {
    if (checked) {
      onChange(tool);
    } else if (currentTool === tool) {
      closePanel();
    }
  };

  const onSwitchRelight = (event) => {
    toggleRelight(event.target.checked);
  };

  const toggleRelight = (enabled) => {
    setRelightEnabled(enabled);
    toggleTool(ObjectToolType.RELIGHT, enabled);
    instrumentation.instrument(MeasureType.RELIGHT_TOGGLED, { enabled });
  };

  const onSwitchShadow = (event) => {
    toggleShadow(event.target.checked);
  };

  const toggleShadow = (enabled) => {
    setShadowEnabled(enabled);
    toggleTool(ObjectToolType.SHADOW, enabled);
    instrumentation.instrument(MeasureType.SHADOW_TOGGLED, { enabled });
  };

  const onRelightPanelClose = () => {
    closePanel();
    toggleRelight(false);
  };

  const toolPanelSx = isSmallDevice
    ? {
        position: 'fixed',
        bottom: 0,
        left: 0,
        height: '45%',
        width: '100%',
        maxHeight: 250,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px -16px 32px rgba(0, 0, 0, 0.08)',
        borderRadius: '24px 24px 0px 0px',
      }
    : {
        position: 'fixed',
        top: 'calc(50% - 299px)',
        left: `calc(50% - ${DRAGGABLE_PANEL_WIDTH}px + 20px)`,
        width: `${DRAGGABLE_PANEL_WIDTH}px`,
        maxHeight: 550,
        color: 'transparent',
        cursor: 'move',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
        backgroundColor: 'white',
        borderRadius: 4,
      };

  const contrastText = theme.palette.secondary.contrastText;
  const toolPanelProps = { vertical: true, iconColor: contrastText };

  useEffect(() => {
    if (isSmallDevice) {
      setToolPanelPos({ x: 0, y: 0 });
    } else {
      setToolPanelPos(null);
    }
  }, [isSmallDevice]);

  useEffect(() => {
    if (activeObject && isPanelContentValid(currentTool, activeObject)) {
      return;
    }

    closePanel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject]);

  useEffect(() => {
    setUse3DImageTool(activeObject?.type === OBJECT_3D_IMAGE_TYPE);
  }, [activeObject]);

  const isPanelContentValid = (currentTool, activeObject) => {
    return (
      (currentTool === ObjectToolType.SHADOW && activeObject.isCastingShadow()) ||
      (currentTool === ObjectToolType.RELIGHT && activeObject.isRelighted())
    );
  };

  const onStop = (_, draggableData) => {
    onPanelMoved({ x: draggableData.x, y: draggableData.y });
  };

  return (
    <Box
      role="toolbar"
      aria-label={t('enhance.aria.label')}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        bottom: 0,
        alignSelf: 'flex-end',
        width: '100%',
      }}>
      {currentTool && (
        <Draggable
          disabled={isSmallDevice}
          position={toolPanelPos}
          onStop={onStop}
          nodeRef={objectToolPanelRef}
          defaultPosition={panelStartingPos}
          bounds="body">
          <Box ref={objectToolPanelRef} sx={{ ...toolPanelSx, zIndex: theme.zIndex.modal }}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                transition: 'ease-out max-height 0.2s',
                backgroundColor: 'transparent',
              }}>
              {currentTool === ObjectToolType.RELIGHT && (
                <ToolPanel name={ObjectToolType.RELIGHT} onClose={closePanel} {...toolPanelProps}>
                  <RelightPanel isSmallDevice={isSmallDevice} onClose={onRelightPanelClose} />
                </ToolPanel>
              )}
              {currentTool === ObjectToolType.SHADOW && (
                <ToolPanel name={ObjectToolType.SHADOW} onClose={closePanel} {...toolPanelProps}>
                  <ShadowPanel isSmallDevice={isSmallDevice} />
                </ToolPanel>
              )}
            </Box>
          </Box>
        </Draggable>
      )}
      <StyledPlaceable position={position} placement={Placement.TOP}>
        <ToolBar name="Object" label={t('enhance.ariaLabel.root')} value={currentTool}>
          {use3DImageTool && (
            <>
              <ToolButton
                name={ObjectToolType.RELIGHT}
                toolTip={t('enhance.tooltips.lighting')}
                checked={relightEnabled}
                onClick={onClickRelight}
                onSwitch={onSwitchRelight}>
                <RelightIcon />
              </ToolButton>

              <Divider />

              <ToolButton
                name={ObjectToolType.SHADOW}
                toolTip={t('enhance.tooltips.shadow')}
                checked={shadowEnabled}
                onClick={onClickShadow}
                onSwitch={onSwitchShadow}>
                <ShadowIcon />
              </ToolButton>

              <Divider />
            </>
          )}

          <ToolButton
            name={t('objectTools.delete.ariaLabel')}
            toolTip={t('objectTools.delete.tooltip')}
            onClick={handleDeleteObject}>
            <DeleteObjectIcon fontSize="small" />
          </ToolButton>
        </ToolBar>
      </StyledPlaceable>
    </Box>
  );
};

ObjectTools.propTypes = {
  currentTool: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default ObjectTools;

const StyledPlaceable = styled(Placeable)`
  z-index: ${(props) => props.theme.zIndex.composeToolbar};
`;
