import { ReactNode, useEffect } from 'react';
import Panel from '@components/Viewport/Panel';
import { SegmentationMode } from '@contexts/Segmentation/SegmentationMode';
import { KEEP_CURSOR, REMOVE_CURSOR } from '@components/Ui/Cursor/Cursor';
import useFabric from '@hooks/Fabric';
import { useSegmentation } from '@contexts/Segmentation/UseSegmentation';

interface EditorPanelProps {
  name: string;
  value: string | boolean;
  visible?: boolean;
  fullHeight?: boolean;
  width?: string;
  children: ReactNode;
  className?: string;
}

export const EditorPanel = ({
  name,
  value,
  width = '100%',
  visible = true,
  fullHeight = false,
  children,
  className,
}: EditorPanelProps) => {
  const fabric = useFabric();
  const segmentation = useSegmentation();

  useEffect(() => {
    if (fabric?.background) {
      if (segmentation.segmentationMode == SegmentationMode.FOREGROUND) {
        fabric.setCursor(fabric.background, KEEP_CURSOR);
      } else if (segmentation.segmentationMode == SegmentationMode.BACKGROUND) {
        fabric.setCursor(fabric.background, REMOVE_CURSOR);
      } else {
        fabric.setCursor(fabric.background, 'default');
      }
    }
  }, [segmentation?.segmentationMode]);

  return (
    <Panel name={name} value={value} width={width} visible={visible} fullHeight={fullHeight} className={className}>
      {children}
    </Panel>
  );
};
