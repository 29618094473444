import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import ContentTemplate from './ContentTemplate';

const ResendFailedPage = ({ onBack }) => {
  const { t } = useTranslation();

  const buttons = [{ name: t('error.verifyAccount.buttons.back'), onClick: onBack }];

  return (
    <ContentTemplate
      title={t('error.verifyAccount.titles.resendFailedPage')}
      description={t('error.verifyAccount.descriptions.resendFailedPage')}
      buttons={buttons}
    />
  );
};

ResendFailedPage.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default ResendFailedPage;
