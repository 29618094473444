/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RelightIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2V4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2 12H4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 12H22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.5 5.5L18 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 5.5L6 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.96782 15.6575L8.52298 16.2613L8.96782 15.6575ZM15.0322 15.6575L14.5873 15.0537L15.0322 15.6575ZM17.75 11.8226C17.75 8.71962 15.1499 6.25 12 6.25V7.75C14.373 7.75 16.25 9.59867 16.25 11.8226H17.75ZM12 6.25C8.85013 6.25 6.25 8.71962 6.25 11.8226H7.75C7.75 9.59867 9.62702 7.75 12 7.75V6.25ZM15.477 16.2613C16.8513 15.2489 17.75 13.6413 17.75 11.8226H16.25C16.25 13.1315 15.6053 14.3038 14.5873 15.0537L15.477 16.2613ZM6.25 11.8226C6.25 13.6413 7.14868 15.2489 8.52298 16.2613L9.41266 15.0537C8.39471 14.3038 7.75 13.1315 7.75 11.8226H6.25ZM9.25 17.4848V21H10.75V17.4848H9.25ZM11 22.75H13V21.25H11V22.75ZM14.75 21V17.4848H13.25V21H14.75ZM13 22.75C13.9665 22.75 14.75 21.9665 14.75 21H13.25C13.25 21.1381 13.1381 21.25 13 21.25V22.75ZM9.25 21C9.25 21.9665 10.0335 22.75 11 22.75V21.25C10.8619 21.25 10.75 21.1381 10.75 21H9.25ZM14.75 17.4848C14.75 17.2824 14.7971 17.0828 14.8876 16.9018L13.546 16.231C13.3513 16.6203 13.25 17.0495 13.25 17.4848H14.75ZM10.75 17.4848C10.75 17.0495 10.6487 16.6203 10.454 16.231L9.11238 16.9018C9.20288 17.0828 9.25 17.2824 9.25 17.4848H10.75ZM8.52298 16.2613C8.80222 16.467 9.00035 16.6777 9.11238 16.9018L10.454 16.231C10.197 15.717 9.79778 15.3374 9.41266 15.0537L8.52298 16.2613ZM14.5873 15.0537C14.2022 15.3374 13.803 15.717 13.546 16.231L14.8876 16.9018C14.9997 16.6777 15.1978 16.467 15.477 16.2613L14.5873 15.0537Z" />
    </SvgIcon>
  );
};

export default RelightIcon;
