import React, { useContext } from 'react';

import UploadImageIcon from '@components/Ui/Icons/UploadImageIcon';
import UploadImageContext from '@contexts/UploadImageContext';
import useInstrumentation from '@hooks/UseInstrumentation';
import { useDetectDevice } from '@hooks/DetectDevice';
import Button from '@components/Ui/Button';
import { useTranslation } from 'react-i18next';

interface UploadImageButtonProps {
  id: string;
  variant?: string;
  color?: string;
  target: string;
  onClick?: () => void;
}

export default function UploadImageButton({
  id,
  variant,
  color = 'secondary.contrastText',
  target,
  onClick,
}: UploadImageButtonProps) {
  const { t } = useTranslation();
  const { uploadImageFromFile } = useContext(UploadImageContext);
  const instrumentation = useInstrumentation();
  const isDesktop = useDetectDevice();

  const handleFileUpload = (event) => {
    uploadImageFromFile(event.target.files[0]);
    instrumentation.imageUploaded(target, 'local');
  };

  const handleButtonClick = (event) => {
    event.target.value = null;
    if (onClick) {
      onClick();
    }
  };

  const deviceMessage = isDesktop ? t('gallery.upload_desktop') : t('gallery.upload_device');

  return (
    <>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id={id}
        type="file"
        onChange={handleFileUpload}
        onClick={handleButtonClick}
        aria-label={t('uploadImage.ariaLabel')}
      />
      <label htmlFor={id}>
        <Button variant={variant} component="span" color={color} startIcon={<UploadImageIcon />}>
          {deviceMessage}
        </Button>
      </label>
    </>
  );
}
