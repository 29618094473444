import React, { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import MoreIcon from '@components/Ui/Icons/MoreIcon';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useMediaQuery, useTheme } from '@mui/material';
import { FooterButtonContainer } from '@components/Ui/FooterButton';
import Typography from '@mui/material/Typography';

export const BackgroundColor = 'primary';

interface FloatingActionButtonProps {
  label: string;
  tooltip: string;
  expand: boolean;
  showLabel?: boolean;
  onClick: () => void;
  onClose: () => void;
  children?: ReactNode;
}

const FloatingActionButton = ({
  label,
  tooltip,
  expand,
  showLabel = false,
  onClick,
  onClose,
  children,
}: FloatingActionButtonProps) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const tooltipTitle = expand ? '' : tooltip;

  return (
    <>
      <ClickAwayListener onClickAway={onClose}>
        <Container>
          <Tooltip title={tooltipTitle} placement="top-start" enterDelay={500} arrow>
            <FooterButtonContainer isSmall={isSmallDevice} isLeft={true}>
              <StyledFab
                aria-label={tooltip}
                variant={showLabel ? 'extended' : 'circular'}
                color={BackgroundColor}
                onClick={onClick}
                size={isSmallDevice ? 'medium' : 'large'}>
                <MainIcon expanded={expand} />
                {showLabel && <Label>{label}</Label>}
              </StyledFab>
            </FooterButtonContainer>
          </Tooltip>

          <FloatingActions aria-hidden={!expand} expanded={expand} sx={{ zIndex: 'appBar' }}>
            {mapActions(children)}
          </FloatingActions>
        </Container>
      </ClickAwayListener>
    </>
  );
};

export default FloatingActionButton;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  zIndex: theme.zIndex.appBar,
}));

const FloatingActions = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<{ expanded: boolean }>(({ expanded, theme }) => ({
  height: 'auto',
  maxHeight: expanded ? 1000 : 0,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  transition: '0.3s ease max-height',
  margin: theme.spacing(1, 0.25),
}));

const MainIcon = styled(MoreIcon, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})(({ expanded }) => ({
  transition: '0.3s ease transform',
  transform: expanded ? 'rotate(-315deg)' : 'rotate(0deg)',
}));

const mapActions = (children) => {
  if (!Array.isArray(children)) {
    return <Box flexGrow={0}>{children}</Box>;
  }

  return React.Children.map(children, (child, index) => {
    return (
      <Box key={`floating-action-${index}`} flexGrow={0}>
        {child}
      </Box>
    );
  });
};

const StyledFab = styled(Fab)`
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
`;

const Label = styled(Typography)`
  margin-left: ${(props) => props.theme.spacing(1)};
  text-transform: none;
`;
