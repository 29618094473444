import React from 'react';

import Box from '@mui/material/Box';
import CloseIcon from '@components/Ui/Icons/CloseIcon';
import { IconButton } from '@mui/material';

const ToolPanel = ({ name, onClose, hidden = false, vertical = false, iconColor = 'white', children }) => (
  <Box hidden={hidden} position="relative" role="tabpanel" aria-label={name} sx={{ height: '100%' }}>
    <Box sx={{ overflowX: 'auto', height: '100%' }}>{children}</Box>
    <IconButton onClick={onClose} sx={{ position: 'absolute', top: vertical ? 10 : 0, right: vertical ? 5 : 0 }}>
      <CloseIcon
        fontSize={vertical ? 'small' : 'medium'}
        sx={{
          color: iconColor,
        }}
      />
    </IconButton>
  </Box>
);

export default ToolPanel;
