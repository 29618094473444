import React, { ReactNode, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import Surface from '@components/Ui/Surface';
import Button from '@components/Ui/Button';

import { useQueryParams } from '@hooks/UseQueryParams';

import Routes, { ErrorCode } from '@root/routes';
import EmailVerificationBox from '../EmailVerification/EmailVerificationBox';
import { useAuthV2 } from '@contexts/AuthV2';

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: 'center',
  fontSize: theme.typography.h3.fontSize,
  margin: theme.spacing(2, 2, 0),
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h2.fontSize,
    margin: theme.spacing(4, 4, 0),
  },
}));

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing(0, 2),
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0, 4),
  },
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  justifyContent: 'center',
  margin: theme.spacing(0, 2, 2),
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0, 4, 4),
  },
}));

interface ErrorDialogProps {
  title: ReactNode;
  description: ReactNode;
  action: ReactNode;
  loading: boolean;
  onAction: () => void;
}

const ErrorDialog = ({ title, description, action, loading, onAction, ...props }: ErrorDialogProps) => {
  return (
    <Dialog PaperComponent={Surface} open={true} {...props}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onAction} loading={loading}>
          {action}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface VerificationDialog {
  onRetry: () => void;
}

const VerificationDialog = ({ onRetry }: VerificationDialog) => (
  <Dialog PaperComponent={Surface} open={true}>
    <EmailVerificationBox onRetry={onRetry} sx={{ m: 5 }} />
  </Dialog>
);

const ErrorPage = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const params = useQueryParams();
  const { t } = useTranslation();

  const auth = useAuthV2();

  const backgroundLocation = location.pathname == Routes.errors.index() ? Routes.home() : location;

  const handleReload = () => {
    setLoading(true);

    auth
      .refreshUser()
      .then(() => navigate(backgroundLocation, { replace: true }))
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const handleReauthenticate = () => {
    auth.logout();
    navigate(Routes.auth());
  };

  useEffect(() => {
    if (params.has(ErrorCode.UNAUTHENTICATED)) {
      auth.logout();
    }
  }, [params, auth.logout]);

  const renderError = () => {
    if (params.has(ErrorCode.UNAUTHENTICATED)) {
      return (
        <ErrorDialog
          aria-label={t('error.unauthenticated.title')}
          title={<Trans i18nKey="error.unauthenticated.title" t={t} />}
          description={<Trans i18nKey="error.unauthenticated.description" t={t} />}
          action={<Trans i18nKey="error.unauthenticated.action" t={t} />}
          loading={loading}
          onAction={handleReauthenticate}
        />
      );
    }

    if (params.has(ErrorCode.UNVERIFIED)) {
      return <VerificationDialog onRetry={handleReload} />;
    }

    return (
      <ErrorDialog
        aria-label={t('error.unknown.title')}
        title={<Trans i18nKey="error.unknown.title" t={t} />}
        description={<Trans i18nKey="error.unknown.description" t={t} />}
        action={<Trans i18nKey="error.unknown.action" t={t} />}
        loading={loading}
        onAction={handleReload}
      />
    );
  };

  return renderError();
};

export default ErrorPage;
