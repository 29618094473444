import { Profile } from '@libs/Profile/Profile';
import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { GET_USER_PROFILE } from '@libs/DepixApi';
import { useGraphqlErrorHandler } from '@hooks/useGraphqlErrorHandler';
import { useAuthV2 } from '@contexts/AuthV2';
import { useAlert } from '@hooks/Alert';
import { useTranslation } from 'react-i18next';
import { mapProfile } from '@libs/Profile/ProfileMapper';
import { useLocation, useNavigate } from 'react-router-dom';
import Routes from '@root/routes';
import { AudienceType } from '@libs/Profile/Audience';
import { GoalType } from '@libs/Profile/Goal';
import { UserMetadata } from '@libs/Profile/UserMetadata';
import { useProfileApi } from '@hooks/Profile/ProfileApi';
import { SubscriptionIncentive } from '@libs/Instrumentation/SubscriptionIncentive';
import useInstrumentation from '@hooks/UseInstrumentation';

interface UserProfileContext {
  loading: boolean;
  profile: Profile;
  metadata?: UserMetadata;
  requiresOnboarding: () => boolean;
  hasAudience: () => boolean;
  refresh: () => Promise<void>;
  isFirstTimeUse: boolean;
  setVisited: () => void;
  limitReached: boolean;
  isShowingOutOfImageDialog: boolean;
  openOutOfImageDialog: () => void;
  closeOutOfImageDialog: () => void;
  setAudience: (audience: AudienceType) => Promise<void>;
  setGoals: (goals: GoalType[]) => Promise<void>;
  isLoading: boolean;
  error: boolean;
  hasProfile: boolean;
}

const UserProfileContext = createContext<UserProfileContext>(null);

export const VISITED_KEY = 'app.visited';
const MAX_RETRIES = 0;
const RETRY_INTERVAL_MS = 500;

export const UserProfileProvider = ({ children }: { children?: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { info } = useAlert();
  const instrumentation = useInstrumentation();

  const { metadata, loading: loadingMetadata, loadMetadata, refreshMetadata, setGoals, setAudience } = useProfileApi();
  const { isAuthenticated, user } = useAuthV2();
  const { displayError } = useGraphqlErrorHandler();

  const [profile, setProfile] = useState<Profile>(Profile.empty());
  const [isFirstTimeUse, setIsFirstTimeUse] = useState(!localStorage.getItem(VISITED_KEY));
  const [profileFetchRetries, setProfileFetchRetries] = useState(0);
  const [incentiveDialogShowed, setIncentiveDialogShowed] = useState(false);
  const [isShowingOutOfImageDialog, setIsShowingOutOfImageDialog] = useState(false);
  const [error, setError] = useState(false);

  const openOutOfImageDialog = () => setIsShowingOutOfImageDialog(true);
  const closeOutOfImageDialog = () => setIsShowingOutOfImageDialog(false);

  const handleGoToSubscribe = useCallback(
    (incentive: SubscriptionIncentive) => {
      instrumentation.subscribeClicked(incentive);
      navigate(Routes.subscribe(), { state: { backgroundLocation: location } });
    },
    [location]
  );

  const onCompleted = useCallback(
    (data) => {
      if (data?.userProfile) {
        const userProfile = mapProfile(data.userProfile);
        setProfile(userProfile);

        return userProfile;
      }

      setProfileFetchRetries(0);
      return null;
    },
    [incentiveDialogShowed, handleGoToSubscribe]
  );

  const [getUserProfile, { data, refetch, loading }] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: 'no-cache',
    onError(error) {
      setProfileFetchRetries(profileFetchRetries + 1);
      if (profileFetchRetries < MAX_RETRIES) {
        setTimeout(() => getUserProfile(), RETRY_INTERVAL_MS);
      } else {
        setProfileFetchRetries(0);
        displayError(error);
        setError(!!error);
      }
    },
  });

  useEffect(() => {
    if (!profile || incentiveDialogShowed || location?.pathname == Routes.auth()) {
      return;
    }

    if (profile.uploadLimit.uploadLeft >= 0 && profile.uploadLimit.uploadLeft <= 5) {
      const action = {
        label: t('profile.incentive.label'),
        onClick: () => handleGoToSubscribe(SubscriptionIncentive.OUT_OF_IMAGE_ALERT),
      };
      info(
        t('profile.incentive.message', { count: profile.uploadLimit.uploadLeft }),
        t('profile.incentive.title', { count: profile.uploadLimit.uploadLeft }),
        6,
        action
      );

      setIncentiveDialogShowed(() => true);
    }
  }, [profile, incentiveDialogShowed, location, handleGoToSubscribe]);

  useEffect(() => {
    if (!loading) {
      onCompleted(data);
    }
  }, [loading, data, onCompleted]);

  useEffect(() => {
    if (isAuthenticated) {
      getUserProfile();
      loadMetadata();
    }
  }, [isAuthenticated, getUserProfile]);

  useEffect(() => {
    if (user) {
      refresh();
    }
  }, [user]);

  const refresh = () => {
    if (!refetch) {
      return;
    }

    return refreshMetadata().finally(() => refetch().then(({ data }) => onCompleted(data)));
  };

  const setVisited = () => {
    setIsFirstTimeUse(false);
    localStorage.setItem(VISITED_KEY, 'true');
  };

  const requiresOnboarding = (): boolean => {
    return metadata && !metadata.audience;
  };

  const hasAudience = (): boolean => {
    return !!metadata?.audience;
  };

  const value = {
    loading: loading || loadingMetadata,
    profile,
    metadata,
    requiresOnboarding,
    hasAudience,
    setAudience,
    setGoals,
    refresh,
    isFirstTimeUse,
    setVisited,
    limitReached: profile.uploadLimit.uploadLeft <= 0,
    isShowingOutOfImageDialog,
    openOutOfImageDialog,
    closeOutOfImageDialog,
    isLoading: loading,
    error,
    hasProfile: profile.uploadLimit.uploadLeft != Profile.empty().uploadLimit.uploadLeft,
  };

  return <UserProfileContext.Provider value={value}>{children}</UserProfileContext.Provider>;
};

export default UserProfileContext;
