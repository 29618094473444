import { FabricLoadableImage } from '@components/FabricJS/LoadableImage';
import { ScreenCorners } from '@libs/Geometry/ScreenCorners';
import { ShadowProjection } from '@libs/Geometry/ShadowProjection';
import Transformation2D from '@libs/Geometry/Transformation2D';
import { TwoDimensionArray } from '@libs/Geometry/TwoDimensionArray';
import { useCallback, useEffect, useState } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';

import { DECOMPOSE_SCENES } from '@libs/DepixApi';
import { angular2world, world2angular, toRadians, toDegrees } from '@libs/Math';
import Vector3D from '@libs/Geometry/Vector3D';
import Vector2D from '@libs/Geometry/Vector2D';
import Camera from '@libs/Geometry/Camera';
import Plane from '@libs/Geometry/Plane';
import Transformation3D from '@libs/Geometry/Transformation3D';
import { screenCoordPlacer, zPlacer } from '@libs/Geometry/ScenePlacer';
import {
  Color,
  DEFAULT_PLAIN_BACKGROUND_HIGH_RESOLUTION,
  DEFAULT_PLAIN_BACKGROUND_LOW_RESOLUTION,
  PlainBackgroundObject,
} from '@libs/PlainBackgroundObject';
import { DepixBackgroundObject } from '@libs/DepixBackgroundObject';
import { computeMinimalObjectSize, getBoundingBoxSize } from '@libs/ImgUtils';
import { MeasureType } from '@libs/Instrumentation/MeasureType';
import BoundingBox from '@libs/Geometry/BoundingBox';
import { BackgroundObject, BackgroundType } from '@libs/BackgroundObject';

import { get3DPoint } from '@components/FabricJS/FragmentGeometry';
import useSceneComponents, { ComponentId } from '@hooks/useSceneComponents';
import { useFeatureFlag, Feature } from './FeatureFlag';
import useUndoRedoStack from '@hooks/undoRedoStack';
import { useGraphqlErrorHandler } from '@hooks/useGraphqlErrorHandler';
import { useAlert } from '@hooks/Alert';
import useInstrumentation from './UseInstrumentation';
import { DepixObject, ShadowState } from '@libs/DepixObject';

const HIGHLIGHT_FILTER_INDEX = 99;
const HIGHLIGHT_FILTER_COLOR = '#fff';
const HIGHLIGHT_FILTER_ALPHA = 0.2;

export type SceneChangedFlag = {} | null;

export interface Depix3DScene {
  camera: Camera;
  rotateCamera: (elevation: number) => void;
  groundPlane: Plane | null;
  viewport: BoundingBox;
  setSceneBackground: (background: BackgroundObject) => void;
  backgroundImage: BackgroundObject | null;
  isPlainBackground: boolean;
  isBackgroundTransparent: () => boolean;
  setPlainBackground: (color?: Color) => Promise<void>;
  updated: SceneChangedFlag;
  setSceneCommitted: (undo?: () => void, redo?: () => void) => void;
  objectsUpdated: ComponentId[];
  addSceneComponent: (
    depixObject: DepixObject,
    bottomCoord: TwoDimensionArray,
    screenSize: TwoDimensionArray,
    isFix?: boolean
  ) => ComponentId;
  calculateNewObjectInViewport: (object: DepixObject) => LocationInViewport;
  removeSceneComponent: (id: ComponentId) => void;
  removeInfillSceneComponent: (id: ComponentId) => void;
  containsComponent: () => boolean;
  componentCount: number;
  getComponentScreenTransform: (id: ComponentId) => Transformation2D;
  getComponentScreenCorners: (id: ComponentId) => ScreenCorners;
  getComponentScreenCenter: (id: ComponentId) => Vector2D;
  getOrderedComponents: () => ComponentId[];
  getIDs: () => ComponentIdsByType;
  getIDsList: () => ComponentId[];
  getDepixObject: (id: ComponentId) => DepixObject | BackgroundObject;
  getDepixObjects: () => (DepixObject | BackgroundObject)[];
  getComponentDepixObjects: () => DepixObject[];
  getComponentFixState: (id: ComponentId) => boolean | null;
  setComponentAsset: (id: ComponentId, depixObject: DepixObject) => void;
  setComponentPosition: (id: ComponentId, translation: Vector3D, minimumSize?: number) => void;
  getComponentPosition: (id: ComponentId) => Vector3D | null;
  getComponentDimensions: (id: ComponentId) => Vector3D;
  copyComponentAttributes: (targetComponentId: ComponentId, sourceComponentId: ComponentId) => void;
  setComponentAngle: (id: ComponentId, angleDegree: number) => void;
  setComponentScale: (id: ComponentId, scaleX: number, scaleY: number) => void;
  setComponentShadowProjection: (objectID: ComponentId, backgroundCoord: Vector2D) => void;
  getComponentShadowProjection: (objectID: ComponentId) => ShadowProjection;
  setComponentShadow: (objectID: ComponentId, enable: boolean) => void;
  getComponentShadow: (objectID: ComponentId) => boolean;
  setComponentShadowState: (objectID: ComponentId, state: ShadowState) => void;
  getComponentShadowState: (objectID: ComponentId) => ShadowState;
  setComponentShadowProperties: (objectID: ComponentId, diffusion, opacity) => void;
  setComponentLightProperties: (
    objectID: ComponentId,
    ambientColor: Color,
    residualAlpha: { low: number; high: number },
    ambient: Color,
    lightIntensity: number
  ) => void;
  setComponentRelight: (objectID: ComponentId, enable: boolean) => void;
  getComponentRelight: (objectID: ComponentId) => boolean;
  setComponentEnhancementProperties: (objectID: ComponentId, blur: number) => void;
  imageToWorld: (vector2D: Vector2D, zPlane?: number) => Vector3D;
  worldToImage: (vector3D: Vector3D) => Vector2D;
  setSceneGround: (ground: DepixObject) => void;
  ground: DepixObject;
  isObjectInScene: (id: ComponentId) => boolean;
  isLoading: boolean;
  requestError: ApolloError;
  setShowSceneUpdateState: (state: boolean) => void;
  save: () => any;
  load: (state: any) => Promise<void>;
  undo: () => Promise<void>;
  canUndo: boolean;
  redo: () => Promise<void>;
  canRedo: boolean;
  highlightObject: (object: FabricLoadableImage) => void;
  dehighlightObject: (object: FabricLoadableImage) => void;
  crop: (boundingBox: BoundingBox) => void;
  removeCrop: () => void;
  getStats: () => SceneStats;
}

export interface LocationInViewport {
  bottomCoord: TwoDimensionArray;
  objectSize: TwoDimensionArray;
}

export interface ComponentIdsByType {
  sceneComponents: ComponentId[];
  infillSceneComponents: ComponentId[];
  background?: ComponentId;
  ground?: ComponentId;
}

export interface SceneCommittedHandler {
  onUndo: () => void;
  onRedo: () => void;
}

export interface SceneStats {
  backgroundTransparent: boolean;
  isBackgroundImage: boolean;
  isBackgroundPlain: boolean;
  backgroundColor?: string;
  backgroundColorOpacity?: number;
  componentsCount: number;
  occlusionComponentsCount: number;
  infillComponentsCount: number;
  erasedComponentsCount: number;
  hasRelighting: boolean;
  hasShadow: boolean;
  hasGround: boolean;
  isCropped: boolean;
}

/**
 * Handle 3D scene given a Depix Object.
 * When the object is received, image is sent for estimations
 * Light is populated
 * Ground is populated
 * @return {*}
 */
function use3DScene(): Depix3DScene {
  // Debug tools
  const featureFlag = useFeatureFlag();
  const isDevMode = featureFlag.canUseFeature(Feature.DEV_TOOLS);
  const [showSceneUpdateState, setShowSceneUpdateState] = useState<boolean>(isDevMode);
  const [backgroundImage, setBackgroundImage] = useState<BackgroundObject | null>(null);
  const [updated, setUpdated] = useState<SceneChangedFlag>(null);
  const [objectsUpdated, setObjectsUpdated] = useState<ComponentId[]>([]);
  const [sceneCommitted, setSceneCommitted] = useState<SceneCommittedHandler | {} | null>(null);

  const [camera, setCamera] = useState<Camera>(new Camera());
  const [viewport, setViewport] = useState<BoundingBox>(new BoundingBox());
  const [groundPlane, setGroundPlane] = useState<Plane | null>(null);

  const sceneComponents = useSceneComponents();
  const infillSceneComponents = useSceneComponents();

  const [ground, setGround] = useState<DepixObject | null>(null);
  const { displayError } = useGraphqlErrorHandler();
  const alert = useAlert();

  const instrumentation = useInstrumentation();

  const [decomposeScene, decomposeSceneQuery] = useLazyQuery(DECOMPOSE_SCENES, {
    onCompleted({ objects: imageDecomposition }) {
      console.log(imageDecomposition);
      rotateCamera(-toDegrees(imageDecomposition[0].cameraElevation));
    },
    onError(error) {
      displayError(error);
    },
  });
  const { loading: isLoading, error: requestError } = decomposeSceneQuery;

  const undoRedoStack = useUndoRedoStack();

  useEffect(() => {
    if (!showSceneUpdateState) return;

    const ids = getIDs();

    let message = '=========Scene Updated=========\n';
    message += 'Background: ' + ids.background + '\n';
    message += 'Total of ' + ids.sceneComponents.length + ' Objects\n';
    for (let i = 0; i < ids.sceneComponents.length; ++i) {
      message += '\t Object ' + i + ' : ' + ids.sceneComponents[i] + '\n';
    }
    message += 'Ground Object: ' + ground?.objectID;

    console.debug(message);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [updated]);

  const handleSetBackgroundImage = useCallback(
    (newBackground) => {
      if (backgroundImage && backgroundImage.objectID !== newBackground?.objectID) {
        instrumentation.backgroundChanged(newBackground);
      }
      setBackgroundImage(newBackground);
    },
    [backgroundImage, instrumentation]
  );

  const save = useCallback(() => {
    const state: any = {};
    if (backgroundImage) {
      state[backgroundImage.type] = backgroundImage.serialize();
    }
    if (ground) {
      state.ground = ground.serialize();
    }
    if (camera) {
      state.camera = camera.serialize();
    }
    if (groundPlane) {
      state.groundPlane = groundPlane.serialize();
    }
    state.components = sceneComponents.save();
    state.infillComponents = infillSceneComponents.save();

    return state;
  }, [backgroundImage, ground, camera, groundPlane, sceneComponents, infillSceneComponents]);

  const load = (state: any) => {
    const promises = [];
    if (state[BackgroundType.PLAIN]) {
      promises.push(
        PlainBackgroundObject.unserialize(state[BackgroundType.PLAIN]).then((object) => {
          handleSetBackgroundImage(object);
        })
      );
    } else if (state[BackgroundType.DEPIX]) {
      promises.push(
        DepixBackgroundObject.unserialize(state[BackgroundType.DEPIX]).then((object) => {
          handleSetBackgroundImage(object);
        })
      );
    } else {
      handleSetBackgroundImage(null);
    }
    if (state.ground) {
      promises.push(
        DepixObject.unserialize(state.ground).then((object) => {
          setGround(object);
        })
      );
    } else {
      setGround(null);
    }

    promises.push(sceneComponents.load(state.components));
    promises.push(infillSceneComponents.load(state.infillComponents));

    const camera = state.camera ? Camera.unserialize(state.camera) : null;
    setCamera(camera);

    const groundPlane = state.groundPlane ? Plane.unserialize(state.groundPlane) : null;
    setGroundPlane(groundPlane);

    return new Promise<void>((resolve) => {
      Promise.all(promises).then(() => {
        setUpdated({});

        const updatedComponents = [
          ...state.components.map((x) => x.name),
          ...state.infillComponents.map((x) => x.name),
        ];

        setObjectsUpdated(updatedComponents);
        resolve();
      });
    });
  };

  useEffect(() => {
    if (sceneCommitted) {
      const handler = sceneCommitted as SceneCommittedHandler;
      const state = save();
      undoRedoStack.push(state, handler.onUndo, handler.onRedo);
    }
    setSceneCommitted(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneCommitted]);

  const undo = async () => {
    const state = undoRedoStack.undo();
    if (state) {
      await load(state);
    }
  };

  const redo = async () => {
    const state = undoRedoStack.redo();
    if (state) {
      await load(state);
    }
  };

  const setPlainBackground = (color?: Color): Promise<void> => {
    let width = DEFAULT_PLAIN_BACKGROUND_LOW_RESOLUTION;
    let height = DEFAULT_PLAIN_BACKGROUND_LOW_RESOLUTION;
    if (featureFlag.canUseFeature(Feature.HIGH_RESOLUTION)) {
      width = DEFAULT_PLAIN_BACKGROUND_HIGH_RESOLUTION;
      height = DEFAULT_PLAIN_BACKGROUND_HIGH_RESOLUTION;
    }
    return PlainBackgroundObject.create(color, width, height)
      .then((backgroundObject) => setSceneBackground(backgroundObject))
      .catch(() => alert.invalidImage());
  };

  const rotateCamera = (elevation: number) => {
    const rotationOffset = camera.getElevation() - toRadians(elevation);
    const transformationOffset = new Transformation3D();
    transformationOffset.setRotation(rotationOffset, 0, 0);

    camera.setElevation(elevation);

    // Rotate the objects so they are "independent" from the ground plane.
    // In other words : the world rotates, the objects stay fix
    const componentIDs = sceneComponents.getIds();
    for (const componentID of componentIDs) {
      const componentTransform = sceneComponents.get3DTransform(componentID);
      const translation = componentTransform.t;
      const cameraTransform = camera.getTransformation();
      let newTranslation = cameraTransform.inverse().dot([[translation.x, translation.y, translation.z, 1]]);
      newTranslation = transformationOffset.dot(newTranslation);
      newTranslation = cameraTransform.dot(newTranslation);
      componentTransform.sett(newTranslation[0]);
      sceneComponents.set3DTransform(componentID, componentTransform);
    }
    setObjectsUpdated(componentIDs);
  };

  const getMinimumZ = useCallback(() => {
    const [bottomRayDirection, bottomRayOrigin] = camera.getViewRay(0.5, 0);
    const closestPoint = groundPlane.cross(bottomRayOrigin, bottomRayDirection);
    return closestPoint.z;
  }, [camera, groundPlane]);

  const setSceneBackground = useCallback(
    (background: BackgroundObject) => {
      sceneComponents.removeFixAndInfillComponents();
      infillSceneComponents.removeFixAndInfillComponents();
      setGround(null);

      handleSetBackgroundImage(background);

      if (featureFlag.canUseFeature(Feature.ESTIMATE_BACKGROUND) && background instanceof DepixBackgroundObject) {
        const decomposeSceneRequest = {
          ids: [background.depixObject.objectID],
        };
        decomposeScene({ variables: decomposeSceneRequest });
      }

      // Define Ground plane
      const groundPlane = new Plane(new Vector3D(0, 0, 0), new Vector3D(0, 1, 0));
      setGroundPlane(groundPlane);

      // Define Camera
      camera.setResolution(background.image.width, background.image.height);
      rotateCamera(-10);
      setViewport(new BoundingBox());

      setUpdated({});
      setSceneCommitted({});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sceneComponents, handleSetBackgroundImage]
  );

  const setSceneGround = (ground: DepixObject) => {
    setGround(ground);
    setUpdated({});
    setSceneCommitted({});
  };

  const calculateNewObjectInViewport = useCallback(
    (object: DepixObject): LocationInViewport => {
      const objectBoundingBox = object.boundingBox;
      const [xStart, , xEnd, yEnd] = objectBoundingBox;
      const translatedXStart = xStart * viewport.width + viewport.getStartX();
      const translatedXEnd = xEnd * viewport.width + viewport.getStartX();
      const translatedYEnd = yEnd * viewport.height + viewport.getStartY();
      const centerX = translatedXStart + (translatedXEnd - translatedXStart) / 2;
      const bottomCoord: TwoDimensionArray = [centerX, translatedYEnd];

      const objectOriginalSize = getBoundingBoxSize(objectBoundingBox);
      const [objectWidth, objectHeight] = computeMinimalObjectSize(
        objectOriginalSize,
        camera.getAspectRatio(),
        object.getAspectRatio()
      );

      const viewportScaleDown = Math.min(viewport.width, viewport.height);
      const objectSize: TwoDimensionArray = [objectWidth * viewportScaleDown, objectHeight * viewportScaleDown];

      return { bottomCoord, objectSize };
    },
    [camera, viewport]
  );

  const addSceneComponent = useCallback(
    (depixObject: DepixObject, bottomCoord: TwoDimensionArray, screenSize: TwoDimensionArray, isFix = false) => {
      let transform = null;
      let dimensions = null;
      if (isFix || depixObject.isInfilled) {
        [transform, dimensions] = screenCoordPlacer(
          bottomCoord[0],
          bottomCoord[1],
          screenSize[0],
          screenSize[1],
          camera,
          groundPlane
        );
      } else {
        [transform, dimensions] = zPlacer(
          bottomCoord[0],
          bottomCoord[1],
          screenSize[0],
          screenSize[1],
          camera,
          getMinimumZ()
        );
      }

      let componentId;
      let infillComponentId;

      if (!depixObject.isErased) {
        componentId = sceneComponents.addComponent(depixObject, transform, dimensions, isFix);
      }

      if (depixObject.isInfilled) {
        const [objectBottomCoord, objectMaxSizeScreenRatio] = depixObject.getInfilledBoundingBoxPosition();
        [transform, dimensions] = screenCoordPlacer(
          objectBottomCoord[0],
          objectBottomCoord[1],
          objectMaxSizeScreenRatio[0],
          objectMaxSizeScreenRatio[1],
          camera,
          groundPlane
        );
        infillComponentId = infillSceneComponents.addComponent(
          depixObject,
          transform,
          dimensions,
          isFix,
          depixObject.isInfilled
        );
      }

      setUpdated({});
      setSceneCommitted({});

      if (componentId) {
        return componentId;
      } else {
        return infillComponentId;
      }
    },
    [sceneComponents, infillSceneComponents, camera, groundPlane, getMinimumZ]
  );

  const removeSceneComponent = useCallback(
    (id: ComponentId) => {
      sceneComponents.removeComponent(id);
      setUpdated({});
      setSceneCommitted({});
    },
    [sceneComponents]
  );

  const removeInfillSceneComponent = useCallback(
    (id: ComponentId) => {
      infillSceneComponents.removeComponent(id);
      setUpdated({});
      setSceneCommitted({});
    },
    [infillSceneComponents]
  );

  const setComponentPosition = useCallback(
    (id: ComponentId, translation: Vector3D, minimumSize: number = 0) => {
      const objectPose = sceneComponents.get3DTransform(id);
      if (objectPose) {
        const originalPose = objectPose.clone();
        objectPose.setTranslation(new Vector3D(translation.x, translation.y, Math.max(translation.z, 0)));
        sceneComponents.set3DTransform(id, objectPose);

        const currentSize = sceneComponents.getScreenDimensions(id, camera);
        const currentMinimumSize = Math.min(currentSize[0], currentSize[1]);
        if (currentMinimumSize <= minimumSize || translation.z < 0) {
          sceneComponents.set3DTransform(id, originalPose);
        }
      }
    },
    [sceneComponents, camera]
  );

  const copyComponentAttributes = useCallback(
    (targetComponentId: ComponentId, sourceComponentId: ComponentId) => {
      const dimensions = sceneComponents.getDimensions(sourceComponentId);
      const transform = sceneComponents.get3DTransform(sourceComponentId);
      sceneComponents.setDimensions(targetComponentId, dimensions);
      sceneComponents.set3DTransform(targetComponentId, transform);

      const sourceDepixObject = sceneComponents.getDepixObject(sourceComponentId);
      const targetDepixObject = sceneComponents.getDepixObject(targetComponentId);
      targetDepixObject.setupProperties(sourceDepixObject.prop);
    },
    [sceneComponents]
  );

  const setComponentAsset = useCallback(
    (id: ComponentId, depixObject: DepixObject) => {
      const previousDepixObject = sceneComponents.getDepixObject(id);
      if (previousDepixObject) {
        // update object aspect ratio by changing the width
        const dimensions = sceneComponents.getDimensions(id);
        const newObjectRatio = depixObject.getAspectRatio();
        const newDimensions = new Vector3D(dimensions.y * newObjectRatio, dimensions.y, dimensions.z);
        newDimensions.x = dimensions.x < 0 ? -newDimensions.x : newDimensions.x;
        sceneComponents.setDimensions(id, newDimensions);
        // update the object properties
        depixObject.setupProperties(previousDepixObject.prop);
        sceneComponents.setDepixObject(id, depixObject);
        setObjectsUpdated([id]);
        setSceneCommitted({});
      }
    },
    [sceneComponents]
  );

  const setComponentAngle = useCallback(
    (id: ComponentId, angleDegree: number) => {
      const objectPose = sceneComponents.get3DTransform(id);
      if (objectPose) {
        objectPose.setRotation(0, 0, toRadians(angleDegree));
        sceneComponents.set3DTransform(id, objectPose);
      }
    },
    [sceneComponents]
  );

  const setComponentScale = useCallback(
    (id: ComponentId, scaleX: number, scaleY: number) => {
      const transform2D = sceneComponents.getMaskedFragment2DTransform(id, camera);
      if (transform2D) {
        const newScaleX = scaleX / transform2D.getScale().x;
        const newScaleY = scaleY / transform2D.getScale().y;
        sceneComponents.scaleComponent(id, new Vector3D(newScaleX, newScaleY, 1));
      }
    },
    [sceneComponents, camera]
  );

  const getComponentPosition = useCallback(
    (id: ComponentId) => {
      const objectPose = sceneComponents.get3DTransform(id);
      return objectPose ? objectPose.t : null;
    },
    [sceneComponents]
  );

  const getComponentDimensions = useCallback(
    (id: ComponentId) => {
      if (infillSceneComponents.hasId(id)) {
        return infillSceneComponents.getDimensions(id);
      } else if (sceneComponents.hasId(id)) {
        return sceneComponents.getDimensions(id);
      }
    },
    [infillSceneComponents, sceneComponents]
  );

  const getComponentScreenTransform = useCallback(
    (id: ComponentId) => {
      if (infillSceneComponents.hasId(id)) {
        return infillSceneComponents.getInfillFragment2DTransform(id, camera);
      } else if (sceneComponents.hasId(id)) {
        return sceneComponents.getMaskedFragment2DTransform(id, camera);
      }
    },
    [camera, infillSceneComponents, sceneComponents]
  );

  const getComponentScreenCorners = useCallback(
    (id: ComponentId) => {
      if (infillSceneComponents.hasId(id)) {
        return infillSceneComponents.getScreenCorners(id, camera);
      } else if (sceneComponents.hasId(id)) {
        return sceneComponents.getScreenCorners(id, camera);
      }
    },
    [sceneComponents, infillSceneComponents, camera]
  );

  const getComponentScreenCenter = useCallback(
    (id: ComponentId) => {
      const transformation = getComponentScreenTransform(id);
      const center = transformation.dot([[0, 0, 1]])[0];
      return new Vector2D(center[0], center[1]);
    },
    [getComponentScreenTransform]
  );

  // TODO: this is really painful, it can return either an object OR the background. Do we really need this?
  // If you change this, track all cases of "as DepixObject" and remove the cast.
  const getDepixObject = useCallback(
    (id: ComponentId): DepixObject | BackgroundObject => {
      if (backgroundImage?.objectID === id) {
        return backgroundImage;
      } else if (ground?.objectID === id) {
        return ground;
      } else if (infillSceneComponents.hasId(id)) {
        return infillSceneComponents.getDepixObject(id);
      } else if (sceneComponents.hasId(id)) {
        return sceneComponents.getDepixObject(id);
      }
    },
    [sceneComponents, infillSceneComponents, ground, backgroundImage]
  );

  const getComponentFixState = useCallback(
    (id: ComponentId) => {
      return sceneComponents.getFixState(id);
    },
    [sceneComponents]
  );

  const getOrderedComponents = useCallback(() => {
    return [...sceneComponents.getOrderedComponents(), ...infillSceneComponents.getOrderedComponents()];
  }, [sceneComponents, infillSceneComponents]);

  const getIDs = useCallback((): ComponentIdsByType => {
    return {
      sceneComponents: sceneComponents.getIds(),
      infillSceneComponents: infillSceneComponents.getIds(),
      background: backgroundImage?.objectID,
      ground: ground?.objectID,
    };
  }, [sceneComponents, infillSceneComponents, backgroundImage, ground]);

  const getComponentIdList = useCallback((): ComponentId[] => {
    return [...sceneComponents.getIds(), ...infillSceneComponents.getIds()];
  }, [sceneComponents, infillSceneComponents]);

  const getIDsList = useCallback((): ComponentId[] => {
    const ids: ComponentId[] = [...sceneComponents.getIds(), ...infillSceneComponents.getIds()];
    if (backgroundImage) {
      ids.push(backgroundImage.objectID);
    }
    if (ground) {
      ids.push(ground.objectID);
    }
    return ids;
  }, [sceneComponents, infillSceneComponents, backgroundImage, ground]);

  const containsComponent = useCallback(() => {
    return getIDsList().length > 1;
  }, [getIDsList]);

  const getDepixObjects = useCallback(() => {
    return getIDsList().map(getDepixObject);
  }, [getIDsList, getDepixObject]);

  const getComponentDepixObjects = useCallback(() => {
    return getComponentIdList().map(getDepixObject) as DepixObject[];
  }, [getComponentIdList, getDepixObject]);

  const isObjectInScene = useCallback(
    (id: ComponentId) => {
      const ids = getIDsList();
      return ids.includes(id);
    },
    [getIDsList]
  );

  const imageToWorld = useCallback(
    (vector2D: Vector2D, zPlane?: number): Vector3D => {
      // project on a plane that is parallel to the camera
      if (zPlane) {
        const plane = new Plane(new Vector3D(0, 0, zPlane), new Vector3D(0, 0, 1));
        return get3DPoint(vector2D.x, vector2D.y, camera, plane);
      } else {
        return get3DPoint(vector2D.x, vector2D.y, camera, groundPlane);
      }
    },
    [camera, groundPlane]
  );

  const worldToImage = useCallback(
    (vector3D: Vector3D): Vector2D => {
      return camera.projectPoint(vector3D);
    },
    [camera]
  );

  /**
   * Will set the light parameter based on projection of the top of the object on the ground
   * @param {string} objectID
   * @param {Vector2D} backgroundCoord coord in background coordinate system
   */
  const setComponentShadowProjection = (objectID: ComponentId, backgroundCoord: Vector2D) => {
    const [azimuth, elevation] = getDirectionFromGroundPosition(objectID, backgroundCoord.x, backgroundCoord.y);
    const depixObject = getDepixObject(objectID) as DepixObject;
    const magnitude = depixObject.lightIntensity;
    depixObject.setLight(azimuth, elevation, magnitude);
    setObjectsUpdated([objectID]);
  };

  /**
   * Will get the shadow top position on screen based on the light parameters
   * @param {string} objectID
   * @return {[Vector2D, Vector2D, Vector2D]} center, left corner and right corner of the shadow in background coord
   */
  const getComponentShadowProjection = (objectID: ComponentId): ShadowProjection => {
    const depixObject = getDepixObject(objectID) as DepixObject;
    const centerVertex = getGroundPositionFromDirection(objectID, depixObject.lightAzimuth, depixObject.lightElevation);
    const objectWidth = sceneComponents.getDimensions(objectID).x;
    const [centerCoord, leftCoord, rightCoord] = projectShadowToGround(objectWidth, centerVertex);
    return [centerCoord, leftCoord, rightCoord];
  };

  const setComponentShadow = (objectID: ComponentId, enable: boolean) => {
    const depixObject = getDepixObject(objectID) as DepixObject;
    depixObject.prop.shadowVisible = enable;
    setObjectsUpdated([objectID]);
    setSceneCommitted({});
  };

  const getComponentShadow = (objectID: ComponentId): boolean => {
    const depixObject = getDepixObject(objectID) as DepixObject;
    return depixObject.prop.shadowVisible;
  };

  const setComponentShadowState = (objectID: ComponentId, state: ShadowState) => {
    const depixObject = getDepixObject(objectID) as DepixObject;
    depixObject.prop.shadowState = state;
    setObjectsUpdated([objectID]);
    setSceneCommitted({
      onUndo: () => instrumentation.instrument(MeasureType.SHADOW_TOGGLED, { enabled: !state }),
      onRedo: () => instrumentation.instrument(MeasureType.SHADOW_TOGGLED, { enabled: state }),
    });
  };

  const getComponentShadowState = (objectID: ComponentId): ShadowState => {
    const depixObject = getDepixObject(objectID) as DepixObject;
    return depixObject.prop.shadowState;
  };

  const setComponentShadowProperties = (objectID: ComponentId, diffusion, opacity) => {
    const depixObject = getDepixObject(objectID) as DepixObject;
    depixObject.prop.shadowDiffusion = diffusion;
    depixObject.prop.shadowOpacity = opacity;
    setObjectsUpdated([objectID]);
  };

  const setComponentRelight = (objectID: ComponentId, enable: boolean) => {
    const depixObject = getDepixObject(objectID) as DepixObject;
    depixObject.prop.relight = enable;
    setObjectsUpdated([objectID]);

    setSceneCommitted({
      onUndo: () => instrumentation.instrument(MeasureType.RELIGHT_TOGGLED, { enabled: !enable }),
      onRedo: () => instrumentation.instrument(MeasureType.RELIGHT_TOGGLED, { enabled: enable }),
    });
  };

  const getComponentRelight = (objectID: ComponentId): boolean => {
    const depixObject = getDepixObject(objectID) as DepixObject;
    return depixObject.prop.relight;
  };

  const setComponentLightProperties = (
    objectID: ComponentId,
    ambientColor: Color,
    residualAlpha: { low: number; high: number },
    ambient: Color,
    lightIntensity: number
  ) => {
    const depixObject = getDepixObject(objectID) as DepixObject;

    depixObject.prop.ambientColor = [ambientColor.r / 255, ambientColor.g / 255, ambientColor.b / 255];
    depixObject.prop.lowResidualAlpha = residualAlpha.low;
    depixObject.prop.highResidualAlpha = residualAlpha.high;
    depixObject.setAmbient(ambient);
    const azimuth = depixObject.lightAzimuth;
    const elevation = depixObject.lightElevation;
    depixObject.setLight(azimuth, elevation, lightIntensity);

    setObjectsUpdated([objectID]);
  };

  const setComponentEnhancementProperties = (objectID: ComponentId, blur: number) => {
    const depixObject = getDepixObject(objectID) as DepixObject;
    depixObject.prop.blur = blur;
    setObjectsUpdated([objectID]);
  };

  /**
   * Given the ground position, compute the direction vector
   * from that position to the top of the object
   * @param {*} object
   * @param {number} targetx
   * @param {number} targety
   * @return {*}
   */
  const getDirectionFromGroundPosition = useCallback(
    (objectID: ComponentId, targetx: number, targety: number) => {
      // Offset the ground plane Y ot the object bottom
      const [bottomVertex, topVertex] = sceneComponents.getBottomTopVertex(objectID);
      const objectPlane = new Plane(
        new Vector3D(groundPlane.origin.x, bottomVertex.y, groundPlane.origin.z),
        groundPlane.normal
      );

      // Convert point to 3D point on ground
      const targetVertex = get3DPoint(targetx, targety, camera, objectPlane);
      // Get object bottom ground position

      // Compute direction vector from the two point
      const direction = targetVertex.sub(topVertex).getNormalized();
      // Rotate the coordinate system (azimuth and elevation w.r.t object)
      const T = new Transformation3D();
      T.setRotation(0, 0, (-3 * Math.PI) / 2);
      const T2 = new Transformation3D();
      T2.setRotation(0, Math.PI / 2, 0);
      const T3 = T.combine(T2);
      const rotatedDir = T3.dot([[direction.x, direction.y, direction.z, 1]])[0];

      // Convert to angular
      const [phi, theta] = world2angular(rotatedDir[0], rotatedDir[1], rotatedDir[2]);
      return [phi, theta];
    },
    [camera, groundPlane, sceneComponents]
  );

  const getGroundPositionFromDirection = useCallback(
    (objectID: ComponentId, phi: number, theta: number) => {
      const [x, y, z] = angular2world(phi, theta, 1);
      // Rotate the coordinate system (azimuth and elevation w.r.t object)
      const T = new Transformation3D();
      T.setRotation(0, 0, (-3 * Math.PI) / 2);
      const T2 = new Transformation3D();
      T2.setRotation(0, Math.PI / 2, 0);
      const T3 = T.combine(T2);
      const T3Inv = T3.inverse();
      const rotatedDirection = T3Inv.dot([[x, y, z, 1]])[0];
      const lightDirection = new Vector3D(rotatedDirection[0], rotatedDirection[1], rotatedDirection[2]);

      const [bottomVertex, topVertex] = sceneComponents.getBottomTopVertex(objectID);
      const objectPlane = new Plane(
        new Vector3D(groundPlane.origin.x, bottomVertex.y, groundPlane.origin.z),
        groundPlane.normal
      );
      return objectPlane.cross(topVertex, lightDirection);
    },
    [groundPlane, sceneComponents]
  );

  /**
   * Project the two upper corners to ground given a screen position
   * (relative to canvas)
   */
  const projectShadowToGround = useCallback(
    (objectWidth, targetVertex) => {
      const targetVertexLeft = new Vector3D(targetVertex.x - objectWidth / 2, targetVertex.y, targetVertex.z);
      const targetVertexRight = new Vector3D(targetVertex.x + objectWidth / 2, targetVertex.y, targetVertex.z);
      const center = camera.projectPoint(targetVertex);
      const topLeft = camera.projectPoint(targetVertexLeft);
      const topRight = camera.projectPoint(targetVertexRight);

      return [center, topLeft, topRight];
    },
    [camera]
  );

  const highlightObject = useCallback((object: FabricLoadableImage) => {
    if (object.filters[HIGHLIGHT_FILTER_INDEX] !== undefined) {
      return;
    }

    object.filters[HIGHLIGHT_FILTER_INDEX] = new fabric.Image.filters.BlendColor({
      color: HIGHLIGHT_FILTER_COLOR,
      mode: 'add',
      alpha: HIGHLIGHT_FILTER_ALPHA,
    });
    object.applyFilters();
    object.canvas.renderAll();
  }, []);

  const dehighlightObject = useCallback((object: FabricLoadableImage) => {
    if (object.filters[HIGHLIGHT_FILTER_INDEX] === undefined) {
      return;
    }

    object.filters[HIGHLIGHT_FILTER_INDEX] = undefined;
    object.applyFilters();
    object.canvas.renderAll();
  }, []);

  const crop = useCallback(
    (boundingBox: BoundingBox) => {
      setViewport(boundingBox);
    },
    [setViewport]
  );

  const removeCrop = useCallback(() => {
    setViewport(new BoundingBox());
  }, [setViewport]);

  const isBackgroundTransparent = () => {
    return backgroundImage.isTransparent();
  };

  const getStats = (): SceneStats => {
    const isPlain = backgroundImage.type === BackgroundType.PLAIN;
    return {
      backgroundTransparent: backgroundImage.isTransparent(),
      isBackgroundImage: !isPlain,
      isBackgroundPlain: isPlain,
      backgroundColor: backgroundImage instanceof PlainBackgroundObject ? backgroundImage.getColorHexCode() : undefined,
      backgroundColorOpacity:
        backgroundImage instanceof PlainBackgroundObject ? backgroundImage.getColorOpacity() : undefined,
      componentsCount: sceneComponents.componentCount,
      occlusionComponentsCount: sceneComponents.countOcclusionComponents(),
      erasedComponentsCount: infillSceneComponents.countErasedComponents(),
      infillComponentsCount: infillSceneComponents.componentCount,
      hasRelighting: sceneComponents.hasRelighting(),
      hasShadow: sceneComponents.hasShadow(),
      hasGround: ground !== null,
      isCropped: !viewport?.isFullSize(),
    };
  };

  return {
    camera,
    rotateCamera,
    groundPlane,
    viewport,
    setSceneBackground,
    backgroundImage,
    isPlainBackground: backgroundImage?.type === BackgroundType.PLAIN,
    isBackgroundTransparent,
    setPlainBackground,
    updated,
    setSceneCommitted: (onUndo, onRedo) => {
      setSceneCommitted({ onUndo, onRedo });
    },
    objectsUpdated,
    addSceneComponent,
    calculateNewObjectInViewport,
    removeSceneComponent,
    removeInfillSceneComponent,
    containsComponent,
    componentCount: sceneComponents.componentCount,
    getComponentScreenTransform,
    getComponentScreenCorners,
    getComponentScreenCenter,
    getOrderedComponents,
    getIDs,
    getIDsList,
    getDepixObject,
    getDepixObjects,
    getComponentDepixObjects,
    getComponentFixState,
    setComponentAsset,
    setComponentPosition,
    getComponentPosition,
    getComponentDimensions,
    copyComponentAttributes,
    setComponentAngle,
    setComponentScale,
    setComponentShadowProjection,
    getComponentShadowProjection,
    setComponentShadow,
    getComponentShadow,
    setComponentShadowState,
    getComponentShadowState,
    setComponentShadowProperties,
    setComponentLightProperties,
    setComponentRelight,
    getComponentRelight,
    setComponentEnhancementProperties,
    imageToWorld,
    worldToImage,
    setSceneGround,
    ground,
    isObjectInScene,
    isLoading,
    requestError,
    setShowSceneUpdateState,
    save,
    load,
    undo,
    canUndo: undoRedoStack.canUndo,
    redo,
    canRedo: undoRedoStack.canRedo,
    highlightObject,
    dehighlightObject,
    crop,
    removeCrop,
    getStats,
  };
}

use3DScene.propTypes = {};

export default use3DScene;
