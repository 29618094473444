import { Box, styled } from '@mui/material';
import { SubscriptionIncentive } from '@libs/Instrumentation/SubscriptionIncentive';
import React from 'react';
import { SubscribeButton } from '@components/Account/SubscribeButton';
import { Trans, useTranslation } from 'react-i18next';
import { SubscriptionTerm } from '@components/Account/SubscriptionTerm';
import { Link } from '@components/Ui/Link';
import Routing from '@root/routes';
import useInstrumentation from '@hooks/UseInstrumentation';
import { useLocation, useNavigate } from 'react-router-dom';
import Routes from '@root/routes';

interface SubscriptionBannerProps {
  incentive: SubscriptionIncentive;
  className?: string;
}

export const SubscriptionBanner = ({ incentive, className }: SubscriptionBannerProps) => {
  const { t } = useTranslation();
  const instrumentation = useInstrumentation();
  const location = useLocation();
  const navigate = useNavigate();

  const handleMoreClick = () => {
    instrumentation.subscribeClicked(SubscriptionIncentive.PROFILE_SUBSCRIPTION_TERMS_MORE);
    navigate(Routing.subscribe(), { state: { backgroundLocation: location } });
  };

  const terms = [
    { id: 'unlimited_images', label: t('profile.subscription.terms.unlimitedImages') },
    { id: 'easy_light_shadow', label: t('profile.subscription.terms.easyLightAndShadow') },
    {
      id: 'more',
      label: (
        <Trans
          i18nKey="profile.subscription.terms.more"
          components={{ moreLink: <Link onClick={handleMoreClick} /> }}
        />
      ),
    },
  ];

  return (
    <Wrapper className={className}>
      <StyledSubscribeButton incentive={incentive}>{t('profile.subscribeIncentive.full')}</StyledSubscribeButton>

      <Box>
        <SubscriptionTerms>
          {terms.map((term) => (
            <SubscriptionTerm key={term.id} label={term.label} />
          ))}
        </SubscriptionTerms>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing(2)};

  padding: ${(props) => props.theme.spacing(4, 2)};
  background-color: ${(props) => props.theme.palette.primary.lighter};
`;

const StyledSubscribeButton = styled(SubscribeButton)`
  padding: ${(props) => props.theme.spacing(1.5, 4)};
`;

const SubscriptionTerms = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(1)};
`;
