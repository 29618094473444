/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  mutation CreateGuestUser {\n    createGuestUser {\n      token\n    }\n  }\n": types.CreateGuestUserDocument,
    "\n  mutation NewObject ($image: Upload!, $isPreview: Boolean = true, $autoSegment: Boolean = false) {\n    newObject(image: $image) {\n      id\n      originalMask(isPreview: $isPreview)\n      image(isPreview: $isPreview)\n      mask(isPreview: $isPreview, autoSegment: $autoSegment) {\n        url\n        id\n      }\n      resolution {\n        width\n        height\n      }\n    }\n  }\n": types.NewObjectDocument,
    "\n  query SendFeedback ($image: Upload!, $comment: String) {\n    sendFeedback(image: $image, comment: $comment)\n  }\n": types.SendFeedbackDocument,
    "\n  mutation CloneObject ($id: ID!, $isPreview: Boolean = true, $withInfillFragment: Boolean = false, $maskId: String) {\n    cloneObject(id: $id, maskId: $maskId) {\n      id\n      image(isPreview: $isPreview)\n      mask(isPreview: $isPreview) {\n        url\n        id\n      }\n      normals(isPreview: $isPreview)\n      residualShading(isPreview: $isPreview)\n      albedo(isPreview: $isPreview)\n      lighting\n      infillFragment(isPreview: $isPreview) @include(if: $withInfillFragment)\n    }\n  }\n": types.CloneObjectDocument,
    "\n  query GetObjects (\n    $ids: [ID!]!\n    $isPreview: Boolean = true\n    $withId: Boolean = false\n    $withImage: Boolean = false\n    $withMask: Boolean = false\n    $withOriginalMask: Boolean = false\n    $withNormals: Boolean = false\n    $withResidualShading: Boolean = false\n    $withAlbedo: Boolean = false\n    $withLighting: Boolean = false\n    $maskId: String\n  ) {\n    objects(ids: $ids) {\n      id @include(if: $withId)\n      image(isPreview: $isPreview) @include(if: $withImage)\n      mask(isPreview: $isPreview, maskId: $maskId) @include(if: $withMask) {\n        url\n        id\n      }\n      originalMask(isPreview: $isPreview) @include(if: $withOriginalMask)\n      normals(isPreview: $isPreview) @include(if: $withNormals)\n      residualShading(isPreview: $isPreview) @include(if: $withResidualShading)\n      albedo(isPreview: $isPreview) @include(if: $withAlbedo)\n      lighting @include(if: $withLighting)\n    }\n  }\n": types.GetObjectsDocument,
    "\n  mutation SegmentObject ($objectID: String!, $userInput: [SegmentationUserInput!]!, $maskID: String, $isPreview: Boolean = true) {\n    applySegmentation(objectID: $objectID, userInput: $userInput, maskID: $maskID) {\n      id\n      mask(isPreview: $isPreview) {\n        url\n        id\n      }\n    }\n  }\n": types.SegmentObjectDocument,
    "\n  mutation DecomposeObject ($objectID: String!, $isPreview: Boolean = true) {\n    imageDecomposition(objectID: $objectID) {\n      id\n      image(isPreview: $isPreview)\n      mask(isPreview: $isPreview) {\n        url\n        id\n      }\n      normals(isPreview: $isPreview)\n      residualShading(isPreview: $isPreview)\n      albedo(isPreview: $isPreview)\n      lighting\n    }\n  }\n": types.DecomposeObjectDocument,
    "\nquery DecomposeScenes ($ids: [ID!]!) {\n  objects(ids: $ids) {\n    cameraElevation\n    sceneLighting {\n      ambientRGB\n      lightSources {\n        radius\n        positionXYZ\n        intensity\n      }\n    }\n  }\n}\n": types.DecomposeScenesDocument,
    "\n  query QueryStockImages ($keyword: String, $page: Int) {\n    queryStockImages(keyword: $keyword, page: $page) {\n      totalPages\n      imageList {\n        id\n        apiName\n        previewUrl\n        width\n        height\n        downloadUrl\n        description\n        copyright {\n          authorName\n          authorLink\n          sourceName\n          sourceLink\n        }\n      }\n    }\n  }\n": types.QueryStockImagesDocument,
    "\n  mutation LoadStockImage (\n    $apiName: ApiName!\n    $url: String!\n    $maxSize: Int\n    $isPreview: Boolean = true\n    $autoSegment: Boolean = false\n  ) {\n    loadStockImage(apiName: $apiName, downloadUrl: $url, maxSize: $maxSize) {\n      id\n      image(isPreview: $isPreview)\n      mask(isPreview: $isPreview, autoSegment: $autoSegment) {\n        url\n        id\n      }\n      resolution {\n        width\n        height\n      }\n    }\n  }\n": types.LoadStockImageDocument,
    "\n  query ResendVerificationEmail ($email: String!) {\n    resendVerificationEmail(email: $email) {\n      alreadyVerified\n    }\n  }\n": types.ResendVerificationEmailDocument,
    "\n  fragment SubscriptionFields on SubscriptionResponse {\n    __typename\n    ... on ResolverError {\n      message\n    }\n    ... on Subscription {\n      enablesAccess\n      updateUrl\n      planId\n      nextBillDate\n      receiptUrl\n      cancelUrl\n      status\n      pausedAt\n      pausedFrom\n      cancellationEffectiveDate\n      checkoutId\n      isInGracePeriod\n    }\n  }\n": types.SubscriptionFieldsFragmentDoc,
    "\n  query GetUserProfile {\n    userProfile {\n      uploadUsage\n      uploadQuota\n      newUploadAt\n      subscriptions {\n        ...SubscriptionFields\n      }\n    }\n  }\n": types.GetUserProfileDocument,
    "\n  query ListUserAvailablePlans ($currency: String = null) {\n    userProfile {\n      availablePlans(currency: $currency) {\n        id\n        name\n        price\n        billingType\n        currency\n        link\n      }\n    }\n  }\n": types.ListUserAvailablePlansDocument,
    "\n  query GetSubscriptions {\n    userProfile {\n      subscriptions {\n        ...SubscriptionFields\n      }\n    }\n  }\n": types.GetSubscriptionsDocument,
    "\n  mutation createUser($email: String!, $password: String!, $agreeTerms: Boolean!, $consentNewsletter: Boolean!) {\n    createUser(email: $email, password: $password, agreeTerms: $agreeTerms, consentNewsletter: $consentNewsletter) {\n      ... on SuccessfulUserCreationResponse {\n        id\n        username\n        email\n        auth0UserId\n      }\n    }\n  }\n": types.CreateUserDocument,
    "\n  mutation login($usernameOrEmail: String!, $password: String!) {\n    login(usernameOrEmail: $usernameOrEmail, password: $password) {\n      ... on SuccessfulLoginResponse {\n        accessToken\n        expiresIn\n      }\n    }\n  }\n": types.LoginDocument,
    "\n  mutation refresh {\n    refresh {\n      ... on SuccessfulLoginResponse {\n        accessToken\n        expiresIn\n      }\n    }\n  }\n": types.RefreshDocument,
    "\n  fragment UserMetadataFields on UserMetadataResponse {\n    __typename\n    ... on ResolverError {\n      message\n    }\n    ... on UserMetadata {\n      audience\n      goals\n    }\n  }\n": types.UserMetadataFieldsFragmentDoc,
    "\n  query GetUserMetadata {\n    userProfile {\n      metadata {\n        ...UserMetadataFields\n      }\n    }\n  }\n": types.GetUserMetadataDocument,
    "\n  mutation updateUserAudience($audience: AudienceType!) {\n    updateUserAudience(audience: $audience)\n  }\n": types.UpdateUserAudienceDocument,
    "\n  mutation updateUserGoals($goals: [UserGoals!]!) {\n    updateUserGoals(goals: $goals)\n  }\n": types.UpdateUserGoalsDocument,
    "\n  mutation ShareImage ($image: Upload!, $title: String!, $description: String, $backgroundId: String!, $componentIds: [String!], $extension: String!) {\n    shareImage(image: $image, title: $title, description: $description, backgroundId: $backgroundId, componentIds: $componentIds, extension: $extension) {\n      ref\n      imageUrl\n    }\n  }\n": types.ShareImageDocument,
    "\n  query GetSharedImage ($id: String!, $referrer: String = null) {\n    sharedImage(id: $id, referrer: $referrer) {\n      ref\n      imageUrl\n      title\n      description\n      viewCount\n    }\n  }\n": types.GetSharedImageDocument,
    "\n  query GetTrendingImages ($limit: Int!, $exclude: String) {\n    trendingImages(limit: $limit, exclude: $exclude) {\n      ref\n      imageUrl\n      title\n      description\n      viewCount\n    }\n  }\n": types.GetTrendingImagesDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateGuestUser {\n    createGuestUser {\n      token\n    }\n  }\n"): (typeof documents)["\n  mutation CreateGuestUser {\n    createGuestUser {\n      token\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation NewObject ($image: Upload!, $isPreview: Boolean = true, $autoSegment: Boolean = false) {\n    newObject(image: $image) {\n      id\n      originalMask(isPreview: $isPreview)\n      image(isPreview: $isPreview)\n      mask(isPreview: $isPreview, autoSegment: $autoSegment) {\n        url\n        id\n      }\n      resolution {\n        width\n        height\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation NewObject ($image: Upload!, $isPreview: Boolean = true, $autoSegment: Boolean = false) {\n    newObject(image: $image) {\n      id\n      originalMask(isPreview: $isPreview)\n      image(isPreview: $isPreview)\n      mask(isPreview: $isPreview, autoSegment: $autoSegment) {\n        url\n        id\n      }\n      resolution {\n        width\n        height\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SendFeedback ($image: Upload!, $comment: String) {\n    sendFeedback(image: $image, comment: $comment)\n  }\n"): (typeof documents)["\n  query SendFeedback ($image: Upload!, $comment: String) {\n    sendFeedback(image: $image, comment: $comment)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CloneObject ($id: ID!, $isPreview: Boolean = true, $withInfillFragment: Boolean = false, $maskId: String) {\n    cloneObject(id: $id, maskId: $maskId) {\n      id\n      image(isPreview: $isPreview)\n      mask(isPreview: $isPreview) {\n        url\n        id\n      }\n      normals(isPreview: $isPreview)\n      residualShading(isPreview: $isPreview)\n      albedo(isPreview: $isPreview)\n      lighting\n      infillFragment(isPreview: $isPreview) @include(if: $withInfillFragment)\n    }\n  }\n"): (typeof documents)["\n  mutation CloneObject ($id: ID!, $isPreview: Boolean = true, $withInfillFragment: Boolean = false, $maskId: String) {\n    cloneObject(id: $id, maskId: $maskId) {\n      id\n      image(isPreview: $isPreview)\n      mask(isPreview: $isPreview) {\n        url\n        id\n      }\n      normals(isPreview: $isPreview)\n      residualShading(isPreview: $isPreview)\n      albedo(isPreview: $isPreview)\n      lighting\n      infillFragment(isPreview: $isPreview) @include(if: $withInfillFragment)\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetObjects (\n    $ids: [ID!]!\n    $isPreview: Boolean = true\n    $withId: Boolean = false\n    $withImage: Boolean = false\n    $withMask: Boolean = false\n    $withOriginalMask: Boolean = false\n    $withNormals: Boolean = false\n    $withResidualShading: Boolean = false\n    $withAlbedo: Boolean = false\n    $withLighting: Boolean = false\n    $maskId: String\n  ) {\n    objects(ids: $ids) {\n      id @include(if: $withId)\n      image(isPreview: $isPreview) @include(if: $withImage)\n      mask(isPreview: $isPreview, maskId: $maskId) @include(if: $withMask) {\n        url\n        id\n      }\n      originalMask(isPreview: $isPreview) @include(if: $withOriginalMask)\n      normals(isPreview: $isPreview) @include(if: $withNormals)\n      residualShading(isPreview: $isPreview) @include(if: $withResidualShading)\n      albedo(isPreview: $isPreview) @include(if: $withAlbedo)\n      lighting @include(if: $withLighting)\n    }\n  }\n"): (typeof documents)["\n  query GetObjects (\n    $ids: [ID!]!\n    $isPreview: Boolean = true\n    $withId: Boolean = false\n    $withImage: Boolean = false\n    $withMask: Boolean = false\n    $withOriginalMask: Boolean = false\n    $withNormals: Boolean = false\n    $withResidualShading: Boolean = false\n    $withAlbedo: Boolean = false\n    $withLighting: Boolean = false\n    $maskId: String\n  ) {\n    objects(ids: $ids) {\n      id @include(if: $withId)\n      image(isPreview: $isPreview) @include(if: $withImage)\n      mask(isPreview: $isPreview, maskId: $maskId) @include(if: $withMask) {\n        url\n        id\n      }\n      originalMask(isPreview: $isPreview) @include(if: $withOriginalMask)\n      normals(isPreview: $isPreview) @include(if: $withNormals)\n      residualShading(isPreview: $isPreview) @include(if: $withResidualShading)\n      albedo(isPreview: $isPreview) @include(if: $withAlbedo)\n      lighting @include(if: $withLighting)\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SegmentObject ($objectID: String!, $userInput: [SegmentationUserInput!]!, $maskID: String, $isPreview: Boolean = true) {\n    applySegmentation(objectID: $objectID, userInput: $userInput, maskID: $maskID) {\n      id\n      mask(isPreview: $isPreview) {\n        url\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SegmentObject ($objectID: String!, $userInput: [SegmentationUserInput!]!, $maskID: String, $isPreview: Boolean = true) {\n    applySegmentation(objectID: $objectID, userInput: $userInput, maskID: $maskID) {\n      id\n      mask(isPreview: $isPreview) {\n        url\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DecomposeObject ($objectID: String!, $isPreview: Boolean = true) {\n    imageDecomposition(objectID: $objectID) {\n      id\n      image(isPreview: $isPreview)\n      mask(isPreview: $isPreview) {\n        url\n        id\n      }\n      normals(isPreview: $isPreview)\n      residualShading(isPreview: $isPreview)\n      albedo(isPreview: $isPreview)\n      lighting\n    }\n  }\n"): (typeof documents)["\n  mutation DecomposeObject ($objectID: String!, $isPreview: Boolean = true) {\n    imageDecomposition(objectID: $objectID) {\n      id\n      image(isPreview: $isPreview)\n      mask(isPreview: $isPreview) {\n        url\n        id\n      }\n      normals(isPreview: $isPreview)\n      residualShading(isPreview: $isPreview)\n      albedo(isPreview: $isPreview)\n      lighting\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery DecomposeScenes ($ids: [ID!]!) {\n  objects(ids: $ids) {\n    cameraElevation\n    sceneLighting {\n      ambientRGB\n      lightSources {\n        radius\n        positionXYZ\n        intensity\n      }\n    }\n  }\n}\n"): (typeof documents)["\nquery DecomposeScenes ($ids: [ID!]!) {\n  objects(ids: $ids) {\n    cameraElevation\n    sceneLighting {\n      ambientRGB\n      lightSources {\n        radius\n        positionXYZ\n        intensity\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query QueryStockImages ($keyword: String, $page: Int) {\n    queryStockImages(keyword: $keyword, page: $page) {\n      totalPages\n      imageList {\n        id\n        apiName\n        previewUrl\n        width\n        height\n        downloadUrl\n        description\n        copyright {\n          authorName\n          authorLink\n          sourceName\n          sourceLink\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query QueryStockImages ($keyword: String, $page: Int) {\n    queryStockImages(keyword: $keyword, page: $page) {\n      totalPages\n      imageList {\n        id\n        apiName\n        previewUrl\n        width\n        height\n        downloadUrl\n        description\n        copyright {\n          authorName\n          authorLink\n          sourceName\n          sourceLink\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation LoadStockImage (\n    $apiName: ApiName!\n    $url: String!\n    $maxSize: Int\n    $isPreview: Boolean = true\n    $autoSegment: Boolean = false\n  ) {\n    loadStockImage(apiName: $apiName, downloadUrl: $url, maxSize: $maxSize) {\n      id\n      image(isPreview: $isPreview)\n      mask(isPreview: $isPreview, autoSegment: $autoSegment) {\n        url\n        id\n      }\n      resolution {\n        width\n        height\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation LoadStockImage (\n    $apiName: ApiName!\n    $url: String!\n    $maxSize: Int\n    $isPreview: Boolean = true\n    $autoSegment: Boolean = false\n  ) {\n    loadStockImage(apiName: $apiName, downloadUrl: $url, maxSize: $maxSize) {\n      id\n      image(isPreview: $isPreview)\n      mask(isPreview: $isPreview, autoSegment: $autoSegment) {\n        url\n        id\n      }\n      resolution {\n        width\n        height\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ResendVerificationEmail ($email: String!) {\n    resendVerificationEmail(email: $email) {\n      alreadyVerified\n    }\n  }\n"): (typeof documents)["\n  query ResendVerificationEmail ($email: String!) {\n    resendVerificationEmail(email: $email) {\n      alreadyVerified\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SubscriptionFields on SubscriptionResponse {\n    __typename\n    ... on ResolverError {\n      message\n    }\n    ... on Subscription {\n      enablesAccess\n      updateUrl\n      planId\n      nextBillDate\n      receiptUrl\n      cancelUrl\n      status\n      pausedAt\n      pausedFrom\n      cancellationEffectiveDate\n      checkoutId\n      isInGracePeriod\n    }\n  }\n"): (typeof documents)["\n  fragment SubscriptionFields on SubscriptionResponse {\n    __typename\n    ... on ResolverError {\n      message\n    }\n    ... on Subscription {\n      enablesAccess\n      updateUrl\n      planId\n      nextBillDate\n      receiptUrl\n      cancelUrl\n      status\n      pausedAt\n      pausedFrom\n      cancellationEffectiveDate\n      checkoutId\n      isInGracePeriod\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUserProfile {\n    userProfile {\n      uploadUsage\n      uploadQuota\n      newUploadAt\n      subscriptions {\n        ...SubscriptionFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUserProfile {\n    userProfile {\n      uploadUsage\n      uploadQuota\n      newUploadAt\n      subscriptions {\n        ...SubscriptionFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ListUserAvailablePlans ($currency: String = null) {\n    userProfile {\n      availablePlans(currency: $currency) {\n        id\n        name\n        price\n        billingType\n        currency\n        link\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListUserAvailablePlans ($currency: String = null) {\n    userProfile {\n      availablePlans(currency: $currency) {\n        id\n        name\n        price\n        billingType\n        currency\n        link\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSubscriptions {\n    userProfile {\n      subscriptions {\n        ...SubscriptionFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSubscriptions {\n    userProfile {\n      subscriptions {\n        ...SubscriptionFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createUser($email: String!, $password: String!, $agreeTerms: Boolean!, $consentNewsletter: Boolean!) {\n    createUser(email: $email, password: $password, agreeTerms: $agreeTerms, consentNewsletter: $consentNewsletter) {\n      ... on SuccessfulUserCreationResponse {\n        id\n        username\n        email\n        auth0UserId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createUser($email: String!, $password: String!, $agreeTerms: Boolean!, $consentNewsletter: Boolean!) {\n    createUser(email: $email, password: $password, agreeTerms: $agreeTerms, consentNewsletter: $consentNewsletter) {\n      ... on SuccessfulUserCreationResponse {\n        id\n        username\n        email\n        auth0UserId\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation login($usernameOrEmail: String!, $password: String!) {\n    login(usernameOrEmail: $usernameOrEmail, password: $password) {\n      ... on SuccessfulLoginResponse {\n        accessToken\n        expiresIn\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation login($usernameOrEmail: String!, $password: String!) {\n    login(usernameOrEmail: $usernameOrEmail, password: $password) {\n      ... on SuccessfulLoginResponse {\n        accessToken\n        expiresIn\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation refresh {\n    refresh {\n      ... on SuccessfulLoginResponse {\n        accessToken\n        expiresIn\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation refresh {\n    refresh {\n      ... on SuccessfulLoginResponse {\n        accessToken\n        expiresIn\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UserMetadataFields on UserMetadataResponse {\n    __typename\n    ... on ResolverError {\n      message\n    }\n    ... on UserMetadata {\n      audience\n      goals\n    }\n  }\n"): (typeof documents)["\n  fragment UserMetadataFields on UserMetadataResponse {\n    __typename\n    ... on ResolverError {\n      message\n    }\n    ... on UserMetadata {\n      audience\n      goals\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUserMetadata {\n    userProfile {\n      metadata {\n        ...UserMetadataFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUserMetadata {\n    userProfile {\n      metadata {\n        ...UserMetadataFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateUserAudience($audience: AudienceType!) {\n    updateUserAudience(audience: $audience)\n  }\n"): (typeof documents)["\n  mutation updateUserAudience($audience: AudienceType!) {\n    updateUserAudience(audience: $audience)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateUserGoals($goals: [UserGoals!]!) {\n    updateUserGoals(goals: $goals)\n  }\n"): (typeof documents)["\n  mutation updateUserGoals($goals: [UserGoals!]!) {\n    updateUserGoals(goals: $goals)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ShareImage ($image: Upload!, $title: String!, $description: String, $backgroundId: String!, $componentIds: [String!], $extension: String!) {\n    shareImage(image: $image, title: $title, description: $description, backgroundId: $backgroundId, componentIds: $componentIds, extension: $extension) {\n      ref\n      imageUrl\n    }\n  }\n"): (typeof documents)["\n  mutation ShareImage ($image: Upload!, $title: String!, $description: String, $backgroundId: String!, $componentIds: [String!], $extension: String!) {\n    shareImage(image: $image, title: $title, description: $description, backgroundId: $backgroundId, componentIds: $componentIds, extension: $extension) {\n      ref\n      imageUrl\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSharedImage ($id: String!, $referrer: String = null) {\n    sharedImage(id: $id, referrer: $referrer) {\n      ref\n      imageUrl\n      title\n      description\n      viewCount\n    }\n  }\n"): (typeof documents)["\n  query GetSharedImage ($id: String!, $referrer: String = null) {\n    sharedImage(id: $id, referrer: $referrer) {\n      ref\n      imageUrl\n      title\n      description\n      viewCount\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTrendingImages ($limit: Int!, $exclude: String) {\n    trendingImages(limit: $limit, exclude: $exclude) {\n      ref\n      imageUrl\n      title\n      description\n      viewCount\n    }\n  }\n"): (typeof documents)["\n  query GetTrendingImages ($limit: Int!, $exclude: String) {\n    trendingImages(limit: $limit, exclude: $exclude) {\n      ref\n      imageUrl\n      title\n      description\n      viewCount\n    }\n  }\n"];

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
**/
export function gql(source: string): unknown;

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;