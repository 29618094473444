import { useContext } from 'react';
import FabricContext from '../Context/FabricContext';

const useFabric = () => {
  const fabric = useContext(FabricContext);

  if (fabric === undefined) {
    throw new Error('useFabric must be used within a FabricContextProvider');
  }

  return fabric;
};

export default useFabric;
