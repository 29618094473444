import * as React from 'react';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

import SuccessIcon from '@components/Ui/Icons/SuccessIcon';
import WarningIcon from '@components/Ui/Icons/WarningIcon';
import ErrorIcon from '@components/Ui/Icons/ErrorIcon';
import InfoIcon from '../Components/Ui/Icons/InfoIcon';
import { Box, Button, Typography } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material';
import { Ref } from 'react';
import { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@components/Ui/Icons/CloseIcon';
import { AlertColor } from '@mui/material/Alert/Alert';
import { SvgIconProps } from '@mui/material/SvgIcon';

export type AlertType = 'success' | 'info' | 'warning' | 'error';

export interface AlertAction {
  label: string;
  onClick: () => void;
}

interface Toast {
  open: boolean;
  severity: AlertType;
  title: string;
  message: string;
  duration: number | null;
  action?: AlertAction;
}

export const DEFAULT_AUTO_HIDE_DURATION = 3;

const AlertContext = React.createContext(null);

export function AlertProvider({ children }) {
  const [alert, setAlert] = React.useState<Toast>({
    open: false,
    severity: 'info',
    title: '',
    message: '',
    duration: DEFAULT_AUTO_HIDE_DURATION * 1000,
  });
  const { open } = alert;
  const handleClose = (event = null, reason = null) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({
      ...alert,
      open: false,
    });
  };

  const issueAlert = (
    severity: AlertType,
    title: string,
    message: string,
    duration: number | null = null,
    action: AlertAction | undefined
  ) => {
    const smartDuration = duration ?? (action ? null : DEFAULT_AUTO_HIDE_DURATION);

    setAlert({
      ...alert,
      open: true,
      severity,
      message,
      duration: smartDuration ? smartDuration * 1000 : null,
      title,
      action,
    });
  };

  return (
    <AlertContext.Provider value={{ issueAlert }}>
      <div>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <StyledSnackbar
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={alert.duration}
            key="bottom-right"
            color="secondary.contrastText"
            onClose={handleClose}>
            <Alert
              title={alert.title}
              message={alert.message}
              action={alert.action}
              severity={alert.severity}
              onClose={handleClose}
            />
          </StyledSnackbar>
        </Stack>
      </div>
      {children}
    </AlertContext.Provider>
  );
}
export default AlertContext;

interface AlertProps extends BoxProps {
  title?: string;
  message: string;
  action?: AlertAction;
  onClose: () => void;
  severity: AlertColor;
}

const Alert = React.forwardRef(function Alert(
  { title, message, action, onClose, severity, ...props }: AlertProps,
  ref: Ref<HTMLDivElement>
) {
  const handleClick = () => {
    if (action) {
      action.onClick();
      onClose();
    }
  };

  const Icon = (props: SvgIconProps) =>
    severity == 'success' ? (
      <SuccessIcon {...props} />
    ) : severity == 'info' ? (
      <InfoIcon {...props} />
    ) : severity == 'warning' ? (
      <WarningIcon {...props} />
    ) : (
      <ErrorIcon {...props} />
    );

  return (
    <StyledBox ref={ref} {...props}>
      <StyledIconButton onClick={onClose}>
        <CloseIcon />
      </StyledIconButton>
      <TitleWrapper>
        <Icon fontSize="small" />
        {title && (
          <Typography display="inline" fontWeight="bold" align="left">
            {title}
          </Typography>
        )}
      </TitleWrapper>

      <StyledMessage variant="h6" align="left">
        {message}
      </StyledMessage>
      {action && (
        <StyledButton variant="contained" onClick={handleClick}>
          {action.label}
        </StyledButton>
      )}
    </StyledBox>
  );
});

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.secondary.main};
  border-radius: ${(props) => props.theme.borderRadius.default}px;
  box-shadow: ${(props) => props.theme.elevation.small};
  padding: ${(props) => props.theme.spacing(2, 3)};
  width: 260px;

  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 200px;
    padding: ${(props) => props.theme.spacing(1)};
  }
`;

const TitleWrapper = styled(Box)`
  max-width: calc(100% - 15px);
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.secondary.medium};
  gap: ${(props) => props.theme.spacing(2)};
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 5px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    top: 0;
    right: 0;
  }
`;

const StyledSnackbar = styled(Snackbar)`
  max-width: 420px;
`;

const StyledMessage = styled(Typography)`
  margin: ${(props) => props.theme.spacing(2, 1)};
  line-height: 1;
`;

const StyledButton = styled(Button)`
  border-radius: ${(props) => props.theme.borderRadius.large}px;
  text-transform: none;
`;
