import MuiDialogTitle from '@mui/material/DialogTitle';
import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';

type SubtitleSize = 'small' | 'medium';

interface DialogSubtitleProps {
  size?: SubtitleSize;
  className?: string;
  children?: ReactNode;
}

export const DialogSubtitle = ({ size = 'medium', className, children }: DialogSubtitleProps) => {
  return (
    <StyledDialogTitle size={size} className={className}>
      {children}
    </StyledDialogTitle>
  );
};

const notForwardedProps: PropertyKey[] = ['size'];

const StyledDialogTitle = styled(MuiDialogTitle, {
  shouldForwardProp: (propName: PropertyKey) => !notForwardedProps.includes(propName),
})<{ size: SubtitleSize }>`
  padding: 0;

  &.MuiDialogTitle-root {
    color: ${(props) => props.theme.palette.secondary.dark};
    font-size: ${(props) => (props.size == 'small' ? '1rem' : '1.125rem')};
    font-weight: ${(props) => (props.size == 'small' ? 400 : 500)};
    line-height: ${(props) => (props.size == 'small' ? 1.1 : 1.3)};
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    &.MuiDialogTitle-root {
      font-size: ${(props) => (props.size == 'small' ? '1.125rem' : '1.25rem')};
      line-height: ${(props) => (props.size == 'small' ? 1.3 : 1.6)};
    }
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    &.MuiDialogTitle-root {
      font-size: ${(props) => (props.size == 'small' ? '1.25' : '1.5rem')};
    }
  }
`;
