/* eslint-disable max-len */
import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const InfoIcon = ({ style = {}, ...props }) => (
  <SvgIcon style={{ ...style }} {...props} viewBox="0 0 52 52">
    <path d="M26.5375 21.5937C26.4679 21.2095 26.257 20.8653 25.9463 20.6288C25.6357 20.3923 25.2477 20.2807 24.8588 20.316C24.47 20.3513 24.1084 20.5309 23.8453 20.8194C23.5823 21.108 23.4368 21.4845 23.4375 21.875V35.9437L23.4625 36.225C23.5321 36.6092 23.743 36.9535 24.0537 37.1899C24.3643 37.4264 24.7523 37.538 25.1412 37.5027C25.53 37.4675 25.8916 37.2879 26.1547 36.9993C26.4177 36.7108 26.5632 36.3342 26.5625 35.9437V21.875L26.5375 21.5937ZM27.4969 14.8438C27.4969 14.2221 27.2499 13.626 26.8104 13.1865C26.3709 12.7469 25.7747 12.5 25.1531 12.5C24.5315 12.5 23.9354 12.7469 23.4958 13.1865C23.0563 13.626 22.8094 14.2221 22.8094 14.8438C22.8094 15.4654 23.0563 16.0615 23.4958 16.501C23.9354 16.9406 24.5315 17.1875 25.1531 17.1875C25.7747 17.1875 26.3709 16.9406 26.8104 16.501C27.2499 16.0615 27.4969 15.4654 27.4969 14.8438ZM50 25C50 18.3696 47.3661 12.0107 42.6777 7.32233C37.9893 2.63392 31.6304 0 25 0C18.3696 0 12.0107 2.63392 7.32233 7.32233C2.63392 12.0107 0 18.3696 0 25C0 31.6304 2.63392 37.9893 7.32233 42.6777C12.0107 47.3661 18.3696 50 25 50C31.6304 50 37.9893 47.3661 42.6777 42.6777C47.3661 37.9893 50 31.6304 50 25ZM3.125 25C3.125 22.1273 3.69081 19.2828 4.79014 16.6288C5.88946 13.9748 7.50076 11.5633 9.53204 9.53204C11.5633 7.50076 13.9748 5.88946 16.6288 4.79013C19.2828 3.69081 22.1273 3.125 25 3.125C27.8727 3.125 30.7172 3.69081 33.3712 4.79013C36.0252 5.88946 38.4367 7.50076 40.468 9.53204C42.4992 11.5633 44.1105 13.9748 45.2099 16.6288C46.3092 19.2828 46.875 22.1273 46.875 25C46.875 30.8016 44.5703 36.3656 40.468 40.468C36.3656 44.5703 30.8016 46.875 25 46.875C19.1984 46.875 13.6344 44.5703 9.53204 40.468C5.42968 36.3656 3.125 30.8016 3.125 25Z" />
  </SvgIcon>
);

export default InfoIcon;
