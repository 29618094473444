import { DropletMoreIcon } from '@components/Ui/Icons/DropletMoreIcon';
import { DropletLessIcon } from '@components/Ui/Icons/DropletLessIcon';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';
import { styled } from '@mui/material/styles';
import ToolButton, { TooltipPlacement } from '@components/Viewport/ToolButton';
import { SegmentationMode } from '@contexts/Segmentation/SegmentationMode';
import { useSegmentation } from '@contexts/Segmentation/UseSegmentation';

export type SegmentationModeAction = SegmentationMode;

const DEFAULT_STARTING_ACTION = SegmentationMode.FOREGROUND;

interface SegmentationModeSelectorProps {
  includeText?: boolean;
  inlineButton?: boolean;
  selectText?: string;
  selectTooltip?: string;
  unselectText?: string;
  unselectTooltip?: string;
  tooltipPlacement?: TooltipPlacement;
  className?: string;
}

export const SegmentationModeSelector = ({
  includeText = false,
  inlineButton = false,
  selectText,
  selectTooltip,
  unselectText,
  unselectTooltip,
  tooltipPlacement = TooltipPlacement.TOP,
  className,
}: SegmentationModeSelectorProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'tools.cut' });
  const { changeMode, segmentationMode } = useSegmentation();

  const selectButtonText = includeText ? selectText || t('buttons.select') : undefined;
  const selectButtonTooltip = selectTooltip || t('tooltips.select');
  const unselectButtonText = includeText ? unselectText || t('buttons.unselect') : undefined;
  const unselectButtonTooltip = unselectTooltip || t('tooltips.unselect');

  const selectAction = (action: SegmentationModeAction) => {
    if (segmentationMode !== action) {
      changeMode(action);
    }
  };

  useEffect(() => {
    if (!segmentationMode) {
      changeMode(DEFAULT_STARTING_ACTION);
    }
  }, [segmentationMode]);

  return (
    <>
      <DropletToolButton
        disableBorder={false}
        name={SegmentationMode.FOREGROUND}
        buttonText={selectButtonText}
        toolTip={selectButtonTooltip}
        onClick={selectAction}
        inline={inlineButton}
        tooltipPlacement={tooltipPlacement}
        className={className}
        activated={segmentationMode == SegmentationMode.FOREGROUND}>
        <DropletMoreIcon />
      </DropletToolButton>
      <DropletToolButton
        disableBorder={false}
        name={SegmentationMode.BACKGROUND}
        buttonText={unselectButtonText}
        toolTip={unselectButtonTooltip}
        onClick={selectAction}
        inline={inlineButton}
        tooltipPlacement={tooltipPlacement}
        className={className}
        activated={segmentationMode == SegmentationMode.BACKGROUND}>
        <DropletLessIcon />
      </DropletToolButton>
    </>
  );
};

const DropletToolButton = styled(ToolButton)`
  border-top-left-radius: 0;

  & div {
    border-top-left-radius: 0;
  }
`;
