import { argMax } from '../Math';

export const matrixCross = (a, b) => a.map((x) => matrixT(b).map((y) => vectorDot(x, y)));
export const vectorDot = (a, b) => a.map((x, i) => a[i] * b[i]).reduce((m, n) => m + n);
export const matrixT = (a) => a[0].map((x, i) => a.map((y) => y[i]));
export const createMatrix = (a, b, fill = 0) => Array.from(Array(a), () => new Array(b).fill(fill));
export const matrixMultScalar = (a, b) => a.map((row, i) => a[i].map((column, j) => a[i][j] * b));
export const matrixMult = (a, b) => a.map((row, i) => a[i].map((column, j) => a[i][j] * b[i][j]));
export const matrixAdd = (a, b) => a.map((row, i) => a[i].map((column, j) => a[i][j] + b[i][j]));
export const matrixSub = (a, b) => a.map((row, i) => a[i].map((column, j) => a[i][j] - b[i][j]));

export const matrixDot = (a, b) => {
  const aNumRows = a.length;
  const aNumCols = a[0].length;
  const bNumCols = b[0].length;
  const m = new Array(aNumRows); // initialize array of rows
  for (let r = 0; r < aNumRows; ++r) {
    m[r] = new Array(bNumCols); // initialize the current row
    for (let c = 0; c < bNumCols; ++c) {
      m[r][c] = 0; // initialize the current cell
      for (let i = 0; i < aNumCols; ++i) {
        m[r][c] += a[r][i] * b[i][c];
      }
    }
  }
  return m;
};

export const rodrigues = (x, y, z) => {
  const matrix = [
    [1, 0, 0],
    [0, 1, 0],
    [0, 0, 1],
  ];
  const omegaSkew = [
    [0, -z, y],
    [z, 0, -x],
    [-y, x, 0],
  ];
  const omegaSkewSqr = matrixCross(omegaSkew, omegaSkew);
  const thetaSqr = x * x + y * y + z * z;
  const theta = Math.sqrt(thetaSqr);
  const sinTheta = Math.sin(theta);
  if (theta === 0) {
    return matrix;
  }

  const oneMinusCosTheta = 1 - Math.cos(theta);
  const oneMinusCosDivThetaSqr = oneMinusCosTheta / thetaSqr;
  let A = createMatrix(3, 3, 1);
  let B = createMatrix(3, 3, 0);

  if (thetaSqr > 1e-12 && theta !== 0) {
    const sinThetaDivTheta = sinTheta / theta;
    A = createMatrix(3, 3, sinThetaDivTheta);
    B = createMatrix(3, 3, oneMinusCosDivThetaSqr);
  }
  const AOmega = matrixMult(A, omegaSkew);
  const BOmegaSqr = matrixMult(B, omegaSkewSqr);
  return matrixAdd(matrixAdd(matrix, AOmega), BOmegaSqr);
};

/**
 * Based on http://math.stackexchange.com
 * /questions/83874/efficient-and-accurate-numerical-implementation-of-the-inverse-rodrigues-rotatio
 * @param {*} matrix
 * @return {*}
 */
export const rodriguesInverse = (matrix) => {
  let x = 0;
  let y = 0;
  let z = 0;
  const r = matrixSub(matrix, matrixT(matrix));
  const diag = [matrix[0][0], matrix[1][1], matrix[2][2]];
  const trace = diag[0] + diag[1] + diag[2];
  if (trace >= 3 - 1e-12) {
    const w = matrixMultScalar(r, 0.5 - (trace - 3) / 12);
    x = w[2][1];
    y = w[0][2];
    z = w[1][0];
  } else if (trace > -1 + 1e-12) {
    const theta = Math.acos((trace - 1) / 2);
    const w = matrixMultScalar(r, theta / (2 * Math.sin(theta)));
    x = w[2][1];
    y = w[0][2];
    z = w[1][0];
  } else {
    const a = argMax(diag);
    const b = (a + 1) % 3;
    const c = (a + 2) % 3;
    const s = Math.sqrt(diag[a] - diag[b] - diag[c] + 1);
    const v = [[0, 0, 0]];
    v[0][a] = s / 2;
    v[0][b] = (1 / (2 * s)) * (matrix[b][a] + matrix[a][b]);
    v[0][c] = (1 / (2 * s)) * (matrix[c][a] + matrix[a][c]);
    const f = matrixMultScalar(v, 1 / Math.sqrt(v[0][0] + v[0][1] + v[0][2]))[0];
    x = f[0] * Math.PI;
    y = f[1] * Math.PI;
    z = f[2] * Math.PI;
  }
  return [x, y, z];
};
