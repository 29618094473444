import React from 'react';
import styled from '@emotion/styled';
import Slider from '@mui/material/Slider';

const StyledSlider = styled(Slider)(({ theme, variant }) =>
  variant === 'depixBlue'
    ? {
        '& .MuiSlider-thumb': {
          backgroundColor: 'white',
          boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.25)',
        },
        '& .MuiSlider-track': {
          backgroundColor: theme.palette.primary.main,
        },
        '& .MuiSlider-rail': {
          backgroundColor: theme.palette.secondary.medium,
        },
      }
    : { color: '#00E0FF' }
);

/**
 *
 * @param {*} props
 * @return {*}
 */
export default function PanelSlider(props) {
  const { value, onChange, onChangeCommitted, ariaLabelledby, marks, step, min, max, disabled, ...rest } = props;

  return (
    <StyledSlider
      value={value}
      onChange={onChange}
      onChangeCommitted={onChangeCommitted}
      aria-labelledby={ariaLabelledby}
      marks={marks}
      step={step}
      min={min}
      max={max}
      disabled={disabled}
      onMouseDown={(e) => e.stopPropagation()}
      onTouchStart={(e) => e.stopPropagation()}
      {...rest}
    />
  );
}
