/* eslint-disable max-len */
import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const UploadImageIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M9 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9 7C7.89543 7 7 7.89543 7 9C7 10.1046 7.89543 11 9 11Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M21 10V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16V8C3 5.23858 5.23858 3 8 3H13.8"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      fill="none"
    />
    <path d="M20 1L20 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17 4L23 4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3.44602 18.4944C3.1668 18.8004 3.18847 19.2748 3.49442 19.554C3.80037 19.8332 4.27475 19.8115 4.55398 19.5056L3.44602 18.4944ZM13.5828 17.5L13.0288 18.0056C13.0342 18.0114 13.0396 18.0172 13.0451 18.0229L13.5828 17.5ZM19.4356 18.4939C19.7083 18.8056 20.1822 18.8372 20.4939 18.5644C20.8056 18.2917 20.8372 17.8178 20.5644 17.5061L19.4356 18.4939ZM15.9622 21.0229C16.251 21.3198 16.7258 21.3265 17.0228 21.0377C17.3197 20.7489 17.3264 20.2741 17.0376 19.9771L15.9622 21.0229ZM10.9531 14.6186L10.3991 15.1242L10.9531 14.6186ZM4.55398 19.5056L8.55257 15.1242L7.44462 14.1131L3.44602 18.4944L4.55398 19.5056ZM10.3991 15.1242L13.0288 18.0056L14.1368 16.9944L11.5071 14.1131L10.3991 15.1242ZM14.1589 17.9802L15.5768 16.2791L14.4245 15.3187L13.0067 17.0198L14.1589 17.9802ZM13.0451 18.0229L15.9622 21.0229L17.0376 19.9771L14.1205 16.9772L13.0451 18.0229ZM17.4777 16.2563L19.4356 18.4939L20.5644 17.5061L18.6065 15.2685L17.4777 16.2563ZM15.5768 16.2791C16.0687 15.6889 16.9717 15.678 17.4777 16.2563L18.6065 15.2685C17.4934 13.9964 15.5068 14.0202 14.4245 15.3187L15.5768 16.2791ZM8.55257 15.1242C9.04827 14.5811 9.90344 14.5811 10.3991 15.1242L11.5071 14.1131C10.4165 12.9181 8.53516 12.9181 7.44462 14.1131L8.55257 15.1242Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default UploadImageIcon;
