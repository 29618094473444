import { useCallback, useEffect, useReducer, useState } from 'react';
import useCanvas from '@components/FabricJS/Canvas';

export const EventName = {
  MOUSE_DOWN: 'mouseDown',
  MOUSE_UP: 'mouseUp',
  OBJECT_MOVING: 'objectMoving',
  OBJECT_ADDED: 'objectAdded',
  OBJECT_CHANGED: 'objectChanged',
  SELECTION_CHANGED: 'selectionChanged',
  ON_MOUSE_OVER: 'mouseOver',
  ON_MOUSE_OUT: 'mouseOut',
};

const INITIAL_DEFAULT_HANDLER = {};

const DefaultHandlerActionTypes = {
  SET: 'SET',
  REMOVE: 'REMOVE',
};

export const State = {
  DEFAULT: 'default',
  SPECIAL: 'special', // can be a better name?
};

const reducer = (state, { payload: { eventName, handlerKey, handler, eventState }, ...action }) => {
  if (!eventName || !handlerKey) return state;
  switch (action.type) {
    case DefaultHandlerActionTypes.SET: {
      let handlerStack = state[eventName] ?? [];
      if (!handler) return state;
      handlerStack = handlerStack.filter((handlerObj) => handlerObj.key !== handlerKey);
      handlerStack.push({ key: handlerKey, handler, eventState });
      return { ...state, [eventName]: handlerStack };
    }
    case DefaultHandlerActionTypes.REMOVE: {
      let handlerStack = state[eventName];
      if (!handlerStack) return state;
      handlerStack = handlerStack.filter((handlerObj) => handlerObj.key !== handlerKey);
      return { ...state, [eventName]: handlerStack };
    }
    default:
      return state;
  }
};

const useCanvasEventController = () => {
  const { onMouseUp, onObjectAdded, onObjectMove, onObjectChange, onSelectionChange, onHover, ...canvas } = useCanvas();
  const [handlers, dispatch] = useReducer(reducer, INITIAL_DEFAULT_HANDLER);
  const [state, setState] = useState(State.DEFAULT);

  const registerListener = useCallback(
    (event, handler, handlerName, state = State.DEFAULT) => {
      const verifiedState = verifyState(state);
      const handlerKey = handlerName ?? handler.name;
      dispatch({
        type: DefaultHandlerActionTypes.SET,
        payload: { eventName: event, handlerKey, handler, eventState: verifiedState },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onMouseUp]
  );

  const unregisterListener = (event, handlerName) => {
    dispatch({ type: DefaultHandlerActionTypes.REMOVE, payload: { eventName: event, handlerKey: handlerName } });
  };

  const setVerifiedState = (state) => {
    setState(verifyState(state));
  };

  // Don't need this when move to TS
  const verifyState = (state) => {
    if (Object.values(State).indexOf(state) < 0) {
      return State.DEFAULT;
    }
    return state;
  };

  const handlerController = useCallback(
    (eventName) => (rawEvent, processedEvent) => {
      const handlerStack = handlers[eventName];
      if (!handlerStack || handlerStack.length === 0) return;

      let filteredStack = handlerStack.filter((handlerObj) => handlerObj.eventState === state);
      if (filteredStack.length === 0) {
        filteredStack = handlerStack.filter((handlerObj) => handlerObj.eventState === State.DEFAULT);
      }
      if (filteredStack.length > 0) {
        filteredStack.forEach((handlerObj) => handlerObj.handler(rawEvent, processedEvent));
      }
    },
    [handlers, state]
  );

  useEffect(() => {
    onMouseUp(handlerController(EventName.MOUSE_UP));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onMouseUp, handlers[EventName.MOUSE_UP], state]);

  useEffect(() => {
    onObjectAdded(handlerController(EventName.OBJECT_ADDED));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onObjectAdded, handlers[EventName.OBJECT_ADDED], state]);

  useEffect(() => {
    onObjectMove(handlerController(EventName.OBJECT_MOVING));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onObjectMove, handlers[EventName.OBJECT_MOVING], state]);

  useEffect(() => {
    onHover(handlerController(EventName.ON_MOUSE_OVER), handlerController(EventName.ON_MOUSE_OUT));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onObjectMove, handlers[EventName.ON_MOUSE_OVER], handlers[EventName.ON_MOUSE_OUT], state]);

  useEffect(() => {
    onObjectChange(handlerController(EventName.OBJECT_CHANGED));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onObjectChange, handlers[EventName.OBJECT_CHANGED], state]);

  useEffect(() => {
    onSelectionChange(handlerController(EventName.SELECTION_CHANGED));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSelectionChange, handlers[EventName.SELECTION_CHANGED], state]);

  return {
    ...canvas,
    setState: setVerifiedState,
    registerListener,
    unregisterListener,
  };
};

export default useCanvasEventController;
