import CanvasCropTool from '@components/Crop/CanvasCropTool';
import UploadImageContext from '@contexts/UploadImageContext';
import { EventName } from '@hooks/CanvasEventController';
import useFabric from '@hooks/Fabric';

import { Box, Grid, Typography } from '@mui/material';
import React, { ReactNode, useContext, useEffect, useState } from 'react';

import FabricCanvas from '../FabricJS/FabricCanvas';
import DropZone from '../WebApp/DropZone';
import LoadingBackdrop from '../WebApp/LoadingBackdrop';
import useUserProfile from '@hooks/UseUserProfile';

type DescriptionTypographyProps = { children: ReactNode };
const DescriptionTypography = ({ children }: DescriptionTypographyProps) => (
  <Typography display="inline" color="secondary.dark" fontWeight={700}>
    {children}
  </Typography>
);

type UploadCanvasProps = {
  id: string;
  loadingMessage?: string;
  className?: string;
  description?: string;
  showInstruction: boolean;
  enableCrop?: boolean;
  disableClickUpload?: boolean;
  onBackgroundChange?: (src: string, width: number, height: number, offsetLeft: string, offsetTop: string) => void;
  onRemoveBackground?: () => void;
  children: ReactNode;
  [fabricOption: string]: any;
};

const UploadCanvas = ({
  id,
  loadingMessage,
  description,
  showInstruction,
  enableCrop = false,
  disableClickUpload = false,
  children,
  onBackgroundChange,
  onRemoveBackground,
  className,
  ...canvasOptions
}: UploadCanvasProps) => {
  const { registerListener, unregisterListener, isEmpty, background, normalizeCanvasCoord } = useFabric();
  const { uploadImageFromFile, uploadImageFromStock, isLoading } = useContext(UploadImageContext);
  const [canvasHasElement, setCanvasHasElement] = useState(false);
  const { limitReached, openOutOfImageDialog } = useUserProfile();

  useEffect(() => {
    const listenerName = 'check canvas has element';
    registerListener(
      EventName.OBJECT_ADDED,
      () => {
        setCanvasHasElement(!isEmpty());
      },
      listenerName
    );
    return () => {
      unregisterListener(EventName.OBJECT_ADDED, listenerName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerListener]);

  const handleImageUpload = (image) => {
    if (limitReached) {
      openOutOfImageDialog();
      return;
    }

    if (image.source && image.downloadUrl) {
      uploadImageFromStock(image.source, image.downloadUrl, image.width);
    } else {
      uploadImageFromFile(image);
    }
  };

  const centerSx = (flexDirection) => ({
    display: 'flex',
    flexDirection,
    justifyContent: 'center',
    alignItems: 'center',
  });

  useEffect(() => {
    if (onRemoveBackground && !background) {
      onRemoveBackground();
    }

    const hasCoords = background?.lineCoords?.tl; // avoids flickering on load
    if (onBackgroundChange && background?.canvas?.getElement() && (hasCoords?.x || hasCoords?.y)) {
      const backgroundTopLeft = background.lineCoords.tl;
      const normalizedTopLeft = normalizeCanvasCoord(backgroundTopLeft);

      const absoluteWidth = background.lineCoords.br.x - background.lineCoords.tl.x;
      const absoluteHeight = background.lineCoords.br.y - background.lineCoords.tl.y;
      const canvasEl = background.canvas.getElement();
      const width = absoluteWidth * (canvasEl.clientWidth / background.canvas.width);
      const height = absoluteHeight * (canvasEl.clientHeight / background.canvas.height);

      onBackgroundChange(
        background.getSrc(),
        width,
        height,
        `${normalizedTopLeft.y * 100}%`,
        `${normalizedTopLeft.x * 100}%`
      );
    }
  }, [background, background?.lineCoords]);

  const defaultDropZoneContent = () => {
    return (
      <>
        {showInstruction && (
          <Box sx={centerSx('column')}>
            {description && (
              <Grid container rowSpacing={2} sx={{ width: '250px' }}>
                <Grid item xs={12} sx={centerSx('row')}>
                  <DescriptionTypography>{description}</DescriptionTypography>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </>
    );
  };

  return (
    <Box sx={{ height: '100%', width: '100%' }} className={className}>
      <LoadingBackdrop isLoading={isLoading} loadingMessage={loadingMessage}>
        <DropZone
          onDrop={handleImageUpload}
          defaultContent={defaultDropZoneContent()}
          showBorder={false}
          isShowDefault={!canvasHasElement}
          isClickDisabled={disableClickUpload || canvasHasElement}
          target={id}>
          <FabricCanvas id={id} options={canvasOptions} />
          {enableCrop && <CanvasCropTool />}
          {children}
        </DropZone>
      </LoadingBackdrop>
    </Box>
  );
};

export default UploadCanvas;
