import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { COMPOSE_PANEL_NAME } from '@libs/ComposePanelName';
import FloatingActionButton from '@components/Ui/FloatingActionButton';
import StockImagesButton from '@components/StockImage/StockImagesButton';
import UploadImageButton from '@components/UploadImage/UploadImageButton';
import useUserProfile from '@hooks/UseUserProfile';
import { ActionButton } from '@components/Viewport/ActionButton';
import { useTranslation } from 'react-i18next';

interface PasteActionButtonProps {
  showLabel: boolean;
  defaultExpanded?: boolean;
}

const PasteActionButton = ({ showLabel, defaultExpanded = false }: PasteActionButtonProps) => {
  const { t } = useTranslation();

  const [expand, setExpand] = useState(defaultExpanded);

  const handleClose = () => setExpand(false);

  const handleClick = () => setExpand(!expand);

  return (
    <ActionButton
      label={t('canvas.compose.actionButton.label')}
      showLabel={showLabel}
      expand={expand}
      tooltip={t('canvas.compose.actionButton.tooltip')}
      onClick={handleClick}
      onClose={handleClose}>
      <StockImagesButton variant="icon" color="secondary" target={COMPOSE_PANEL_NAME} onClick={handleClose} />
      <UploadImageButton
        variant="icon"
        color="secondary"
        target={COMPOSE_PANEL_NAME}
        id="paste-action-upload-image"
        onClick={handleClose}
      />
    </ActionButton>
  );
};

export default PasteActionButton;
