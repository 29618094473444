/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RedoIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.834 12.6894L21.1786 8.34469L16.834 4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M16.834 18.4822H8.86869C7.52436 18.4822 6.23509 17.9482 5.2845 16.9976C4.33391 16.047 3.79988 14.7577 3.79988 13.4134V13.4134C3.79988 12.0691 4.33391 10.7798 5.2845 9.82922C6.23509 8.87864 7.52436 8.3446 8.86869 8.3446H21.1786"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};

export default RedoIcon;
