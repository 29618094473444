export enum MeasureType {
  PROJECT_CREATED = 'project_created',
  IMAGE_UPLOADED = 'image_uploaded',
  IMAGE_DOWNLOADED = 'image_downloaded',
  SEGMENTATION_EDIT_SELECTED = 'segmentation_edit_selected',
  DOWNLOAD_DIALOG_SKIPPED = 'download_dialog_skipped',
  IMAGE_SEGMENTED = 'image_segmented',
  SEGMENTATION_MODE_CHANGED = 'segmentation_mode_changed',
  OBJECT_PASTED = 'object_pasted',
  OBJECT_SWAPED = 'object_swaped',
  OBJECT_ERASED = 'object_erased',
  GROUND_OBJECT_ADDED = 'ground_object_added',
  OCCLUSION_OBJECT_ADDED = 'occlusion_object_added',
  RELIGHT_TOGGLED = 'relight_toggled',
  SHADOW_TOGGLED = 'shadow_toggled',
  SHADOW_GROUND_PROJECTION_TOGGLED = 'shadow_ground_projection_toggled',
  SUBSCRIPTION_CANCEL_CLICKED = 'subscription_cancel_clicked',
  SUBSCRIPTION_UPDATE_CLICKED = 'subscription_update_clicked',
  SUBSCRIPTION_RECEIPT_CLICKED = 'subscription_receipt_clicked',
  GET_SUPPORT_BUTTON_CLICKED = 'get_support_button_clicked',
  HELP_MENU_OPENED = 'help_menu_opened',
  BACKGROUND_CHANGED = 'background_changed',
  SUBSCRIPTION_OFFER_SHOWN = 'subscription_offer_shown',
  SUBSCRIPTION_PLAN_SELECTED = 'subscription_plan_selected',
  SUBSCRIPTION_PLAN_QUITTED = 'subscription_plan_quitted',
  OUT_OF_IMAGES_QUITTED = 'out_of_images_quitted',
  BACKGROUND_REMOVED = 'background_removed',
  ACCOUNT_CREATION_STARTED = 'account_creation_started',
  ACCOUNT_CREATION_CANCELLED = 'account_creation_cancelled',
  SIGN_UP_SUCCESSFUL = 'SIGN_UP_SUCCESSFUL',
  FORGOT_PASSWORD_STARTED = 'FORGOT_PASSWORD_STARTED',
  SIGN_IN_SUCCESSFUL = 'SIGN_IN_SUCCESSFUL',
  SIGN_IN_ERROR = 'SIGN_IN_ERROR',
  SIGN_UP_ERROR = 'SIGN_UP_ERROR',
  FORGOT_PASSWORD_SUCCESSFUL = 'FORGOT_PASSWORD_SUCCESSFUL',
  FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR',
  RESEND_VERIFICATION_EMAIL_SUCCESSFUL = 'RESEND_VERIFICATION_EMAIL_SUCCESSFUL',
  RESEND_VERIFICATION_EMAIL_ERROR = 'RESEND_VERIFICATION_EMAIL_ERROR',
  LOGOUT = 'LOGOUT',
  IMAGE_CROPPED = 'IMAGE_CROPPED',
  OPEN_APP_CLICKED = 'OPEN_APP_CLICKED',
  IMAGE_LINK_SHARED = 'IMAGE_LINK_SHARED',
  SUBSCRIBE_CLICKED = 'SUBSCRIBE_CLICKED',
}
