import React, { ReactNode, useEffect, useState } from 'react';
import { Backdrop, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import Loading from '@components/Loading/Loading';

const BackdropBox = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  zIndex: 0,
}));

const LoadingBackdropBox = styled(Backdrop)(() => ({
  position: 'absolute',
}));

interface LoadingBackdropProps {
  isLoading: boolean;
  loadingMessage?: string;
  children?: ReactNode;
  className?: string;
}

const LoadingBackdrop = ({ isLoading, loadingMessage, children, className }: LoadingBackdropProps) => {
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (isLoading) {
      setFilter('blur(4px)');
    } else {
      setFilter('');
    }
  }, [isLoading]);

  return (
    <BackdropBox className={className}>
      <Box sx={{ filter: filter, width: '100%', height: '100%' }}>{children}</Box>
      <LoadingBackdropBox open={isLoading} invisible={true} componentsProps={{ root: { 'aria-hidden': !isLoading } }}>
        {isLoading && (
          <LoadingWrapper>
            <Loading
              global={false}
              opacity={0.5}
              loadingMessageComponent={<Typography fontWeight={600}>{loadingMessage}</Typography>}
            />
          </LoadingWrapper>
        )}
      </LoadingBackdropBox>
    </BackdropBox>
  );
};

export default LoadingBackdrop;

const LoadingWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
