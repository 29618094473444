import React from 'react';
import ToolButton from '@components/Viewport/ToolButton';
import ClearIcon from '@components/Ui/Icons/ClearIcon';
import UndoIcon from '@components/Ui/Icons/UndoIcon';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';
import { useSegmentation } from '@contexts/Segmentation/UseSegmentation';
import RedoIcon from '@components/Ui/Icons/RedoIcon';

export enum SegmentationFlowAction {
  CLEAR = 'Clear',
  UNDO = 'Undo',
  REDO = 'Redo',
}

interface SegmentationFlowControllerProps {
  includeText: boolean;
  onReset: () => void;
}

export const SegmentationFlowController = ({ includeText, onReset }: SegmentationFlowControllerProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'tools.cut' });
  const { undo, redo, clear, loading, canUndo, canRedo } = useSegmentation();
  const disableUndo = !canUndo;
  const disableRedo = !canRedo || loading;

  const handleClear = () => {
    clear();
    onReset();
  };

  return (
    <>
      <ToolButton
        name={SegmentationFlowAction.CLEAR}
        buttonText={includeText ? t('buttons.clear') : undefined}
        toolTip={t('tooltips.clear')}
        onClick={handleClear}>
        <ClearIcon />
      </ToolButton>
      <ToolButton
        name={SegmentationFlowAction.UNDO}
        buttonText={includeText ? t('buttons.undo') : undefined}
        toolTip={t('tooltips.undo')}
        onClick={undo}
        disabled={disableUndo}>
        <UndoIcon />
      </ToolButton>
      <ToolButton
        name={SegmentationFlowAction.REDO}
        buttonText={includeText ? t('buttons.redo') : undefined}
        toolTip={t('tooltips.redo')}
        onClick={redo}
        disabled={disableRedo}>
        <RedoIcon />
      </ToolButton>
    </>
  );
};
