import { useCallback, useEffect, useState } from 'react';

import useFabric from '@hooks/Fabric';
import { EventName } from '@hooks/CanvasEventController';
import { CONTROL_OFFSET, ICON_SIZE, OBJECT_3D_IMAGE_TYPE } from '@components/FabricJS/Object3DImage';
import { useActiveObject, useScene } from '@contexts/SceneContext';
import Vector2D from '@libs/Geometry/Vector2D';

const useObjectToolbarPosition = () => {
  const { registerListener, unregisterListener, viewportToReferenceAbsolute, backgroundToViewport } = useFabric();
  const { activeObject } = useActiveObject();
  const scene = useScene();

  const [position, setPosition] = useState(null);
  const [isChangingObject, setIsChangingObject] = useState(false);

  const handleHideObjectToolbar = () => {
    setPosition(null);
  };

  useEffect(() => {
    if (scene?.getDepixObject(activeObject?.name) && !isChangingObject) {
      // Get object position
      const corners = scene.getComponentScreenCorners(activeObject.name);
      const center = scene.getComponentScreenCenter(activeObject.name);

      // get highest corner
      const yList = Object.values(corners).map((corner) => corner.y);
      const minY = Math.min(...yList);

      // convert to canvas coordinate
      const topY = backgroundToViewport(new Vector2D(0, minY)).y;
      const centerX = backgroundToViewport(center).x;

      const topMid = { x: centerX, y: topY - CONTROL_OFFSET - ICON_SIZE - 5 };
      const topMidClients = viewportToReferenceAbsolute(topMid);

      setPosition(topMidClients);
    } else {
      handleHideObjectToolbar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject?.name, isChangingObject, scene.updated]);

  const handleObjectOnMove = useCallback(
    (rawEvent) => {
      const isObject = rawEvent?.target?.type === OBJECT_3D_IMAGE_TYPE;

      if (!isObject || isChangingObject) return;
      setIsChangingObject(true);
      handleHideObjectToolbar();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [registerListener, isChangingObject]
  );

  const handleObjectOnChange = useCallback(
    (e) => {
      if (isChangingObject) return;

      const fabricObject = e?.transform?.target;
      if (fabricObject?.type === 'object3DImage') {
        setIsChangingObject(true);
        handleHideObjectToolbar();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [registerListener, isChangingObject]
  );

  useEffect(() => {
    const onDragName = 'object toolbar on object drag';
    registerListener(EventName.OBJECT_MOVING, handleObjectOnMove, onDragName);
    const onRotateName = 'object toolbar on object Rotate';
    registerListener(EventName.OBJECT_CHANGED, handleObjectOnChange, onRotateName);
    return () => {
      unregisterListener(EventName.OBJECT_MOVING, onDragName);
      unregisterListener(EventName.OBJECT_CHANGED, onRotateName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleObjectOnMove, handleObjectOnChange]);

  const handleObjectOnDragEnded = useCallback(
    (rawEvent) => {
      const isObject = rawEvent?.target?.type === OBJECT_3D_IMAGE_TYPE;
      if (!isObject) return;
      setIsChangingObject(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [registerListener]
  );

  useEffect(() => {
    const listenerName = 'object toolbar on object drag ended';
    registerListener(EventName.MOUSE_UP, handleObjectOnDragEnded, listenerName);
    return () => {
      unregisterListener(EventName.MOUSE_UP, listenerName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleObjectOnDragEnded]);

  return {
    position,
  };
};

export default useObjectToolbarPosition;
