import { useCallback, useState } from 'react';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { convertAudienceToGrapqhl, convertGoalsToGraphql, mapMetadata, UserMetadata } from '@libs/Profile/UserMetadata';
import { AudienceType } from '@libs/Profile/Audience';
import { GoalType } from '@libs/Profile/Goal';
import { GET_USER_METADATA, UPDATE_USER_AUDIENCE, UPDATE_USER_GOALS } from '@libs/DepixApi';

interface ProfileApi {
  metadata?: UserMetadata;
  loadMetadata: () => Promise<any>;
  refreshMetadata: () => Promise<any>;
  setAudience: (audience: AudienceType) => Promise<void>;
  setGoals: (goals: GoalType[]) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export const useProfileApi = (): ProfileApi => {
  const [metadata, setMetadata] = useState<UserMetadata>();

  const [getUserMetadata, { loading, error, refetch: refreshMetadata }] = useLazyQuery(GET_USER_METADATA, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setMetadata(mapMetadata(data));
    },
  });

  const [updateUserAudience] = useMutation(UPDATE_USER_AUDIENCE, {
    fetchPolicy: 'no-cache',
  });

  const [updateUserGoals] = useMutation(UPDATE_USER_GOALS, {
    fetchPolicy: 'no-cache',
  });

  const loadMetadata = useCallback(() => {
    return getUserMetadata();
  }, [getUserMetadata]);

  const setAudience = useCallback(
    async (audience: AudienceType) => {
      await updateUserAudience({
        variables: { audience: convertAudienceToGrapqhl(audience) },
      });
    },
    [updateUserAudience]
  );

  const setGoals = useCallback(
    async (goals: GoalType[]) => {
      await updateUserGoals({
        variables: { goals: goals.map(convertGoalsToGraphql) },
      });
    },
    [updateUserGoals]
  );

  return {
    metadata,
    loadMetadata,
    refreshMetadata,
    setAudience,
    setGoals,
    loading,
    error,
  };
};
