import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getVersion } from '@libs/version';

export const AppVersion = () => {
  const { t } = useTranslation();
  const version = getVersion();

  return (
    <Typography sx={{ fontStyle: 'italic', fontSize: '0.8em', color: (theme) => theme.palette.secondary.dark }}>
      {t('general.appVersion')}: {version}
    </Typography>
  );
};
