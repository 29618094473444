import { useLazyQuery } from '@apollo/client';
import { RESEND_VERIFICATION_EMAIL } from '../Libs/DepixApi';
import { useGraphqlErrorHandler } from './useGraphqlErrorHandler';
import useInstrumentation from '@hooks/UseInstrumentation';

/**
 * Detect main object in image and return coordinates
 * @param {func} onCompletedCallback
 * @return {*}
 */
function useResendVerificationEmail(onCompletedCallback) {
  const { displayError } = useGraphqlErrorHandler();
  const instrumentation = useInstrumentation();

  const [resendVerificationEmail, { loading: isLoading, error: isError }] = useLazyQuery(RESEND_VERIFICATION_EMAIL, {
    onCompleted({ resendVerificationEmail }) {
      onCompletedCallback(resendVerificationEmail);
      instrumentation.resendVerificationEmailSuccessful();
    },
    onError(error) {
      instrumentation.resendVerificationEmailError();
      displayError(error);
    },

    fetchPolicy: 'no-cache',
  });

  const resend = async (email) => {
    const variables = { email };

    return resendVerificationEmail({
      variables: variables,
    });
  };

  return { resend, isLoading, isError };
}

export default useResendVerificationEmail;
