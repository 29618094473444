import Camera from '@libs/Geometry/Camera';
import Plane from './Plane';
import Transformation3D from './Transformation3D';
import Vector3D from './Vector3D';

/**
 * Let the user provide relative screen position to return
 * a 3D pose and dimension by placing the bottom of the object on the ground plane
 * @param bottomX Relative to camera width (0 - 1)
 * @param bottomY Relative to camera height (0 - 1)
 * @param sizeRatioW Relative to camera width (0 - 1)
 * @param sizeRatioH Relative to camera height (0 - 1)
 * @param camera
 * @param groundPlane
 */
export const screenCoordPlacer = (
  bottomX: number,
  bottomY: number,
  sizeRatioW: number,
  sizeRatioH: number,
  camera: Camera,
  groundPlane: Plane
): [Transformation3D, Vector3D] => {
  // get the Z position from the bottom of the image
  const bottomPosition = intersect(bottomX, bottomY, camera, groundPlane);
  // Compute the 3D image dimensions
  // Object plane is parallel to the camera plane
  const objectPlane = new Plane(new Vector3D(0, 0, bottomPosition.z), camera.camDirection);
  const leftPosition = intersect(bottomX - sizeRatioW / 2, bottomY, camera, objectPlane);
  const rightPosition = intersect(bottomX + sizeRatioW / 2, bottomY, camera, objectPlane);
  const topPosition = intersect(bottomX, bottomY - sizeRatioH, camera, objectPlane);

  const dimensions = new Vector3D(
    leftPosition.sub(rightPosition).getMagnitude(),
    bottomPosition.sub(topPosition).getMagnitude(),
    0
  );
  // get the XYZ position of the bounding box center
  const center = intersect(bottomX, bottomY - sizeRatioH / 2, camera, objectPlane);
  const transform = new Transformation3D();
  transform.setTranslation(center);
  return [transform, dimensions];
};

/**
 * Let the user provide relative screen position to return
 * a 3D pose and dimension the object at a given Z
 * @param {number} bottomX Relative to camera width (0 - 1)
 * @param {number} bottomY Relative to camera height (0 - 1)
 * @param {number} sizeRatioW Relative to camera width (0 - 1)
 * @param {number} sizeRatioH Relative to camera height (0 - 1)
 * @param {Camera} camera
 * @param {number} zDistance
 * @return {[Transformation3D, Vector3D]}
 */
export const zPlacer = (
  bottomX: number,
  bottomY: number,
  sizeRatioW: number,
  sizeRatioH: number,
  camera: Camera,
  zDistance: number
): [Transformation3D, Vector3D] => {
  // Compute the 3D image dimensions
  // Object plane is parallel to the camera plane
  const objectPlane = new Plane(new Vector3D(0, 0, zDistance), new Vector3D(0, 0, 1));
  const leftPosition = intersect(bottomX - sizeRatioW / 2, bottomY, camera, objectPlane);
  const rightPosition = intersect(bottomX + sizeRatioW / 2, bottomY, camera, objectPlane);
  const topPosition = intersect(bottomX, bottomY - sizeRatioH, camera, objectPlane);
  const bottomPosition = intersect(bottomX, bottomY, camera, objectPlane);

  const dimensions = new Vector3D(
    leftPosition.sub(rightPosition).getMagnitude(),
    bottomPosition.sub(topPosition).getMagnitude(),
    0
  );
  // get the XYZ position of the bounding box center
  const center = intersect(bottomX, bottomY - sizeRatioH / 2, camera, objectPlane);
  const transform = new Transformation3D();
  transform.setTranslation(center);
  return [transform, dimensions];
};

const intersect = (coordX: number, coordY: number, camera: Camera, plane: Plane) => {
  const invCoordY = 1 - coordY;
  const [rayDirection, rayOrigine] = camera.getViewRay(coordX, invCoordY);
  return plane.cross(rayOrigine, rayDirection);
};
