import { SvgIconProps } from '@mui/material/SvgIcon';
import { SvgIcon } from '@mui/material';

const FacebookIcon = (props: SvgIconProps) => (
  <SvgIcon width={15} height={27} viewBox="0 0 15 27" {...props}>
    <path
      d="M13.7396 15.1603L14.4662 10.5434H9.99005V7.54244C9.99005 6.28001 10.615 5.04644 12.6133 5.04644H14.6769V1.11488C13.4751 0.923241 12.2608 0.819563 11.0437 0.804688C7.35958 0.804687 4.95437 3.01934 4.95437 7.02304V10.5434H0.870605V15.1603H4.95437V26.3274H9.99005V15.1603H13.7396Z"
      fill="white"
    />
  </SvgIcon>
);

export default FacebookIcon;
