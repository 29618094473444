import { Box, MobileStepper, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DEFAULT_NS } from '@libs/i18n-config';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { EditSegmentationIcon } from '@components/Ui/Icons/EditSegmentationIcon';
import { EraseObjectIcon } from '@components/Ui/Icons/EraseObjectIcon';
import { LightAndShadowIcon } from '@components/Ui/Icons/LightAndShadowIcon';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay, virtualize } from 'react-swipeable-views-utils';
import { mod } from 'react-swipeable-views-core';
import { Feature } from '@components/FeatureCarousel/Feature';
import { FeatureThumbnail } from '@components/FeatureCarousel/FeatureThumbnail';
import { FeatureDemo } from '@components/FeatureCarousel/FeatureDemo';

interface FeatureCarouselProps {
  className?: string;
}

const FEATURES: Feature[] = [
  {
    id: 'remove_background',
    media: '/features/remove-background.gif',
    icon: <EditSegmentationIcon />,
  },
  { id: 'erase_elements', media: '/features/erase-elements.gif', icon: <EraseObjectIcon /> },
  { id: 'relight_shadow', media: '/features/relight-shadow.gif', icon: <LightAndShadowIcon /> },
];

const VirtualSwipeableViews = autoPlay(virtualize(SwipeableViews));

export const FeatureCarousel = ({ className }: FeatureCarouselProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'feature_carousel' });

  const [currentFeature, setCurrentFeature] = useState<number>(Math.floor(FEATURES.length / 2));
  const previousFeature =
    mod(currentFeature, FEATURES.length) == 0 ? FEATURES.length - 1 : mod(currentFeature, FEATURES.length) - 1;
  const nextFeature =
    mod(currentFeature, FEATURES.length) == FEATURES.length - 1 ? 0 : mod(currentFeature, FEATURES.length) + 1;

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const handlePreviousFeature = () => {
    setCurrentFeature(previousFeature);
  };

  const handleNextFeature = () => {
    setCurrentFeature(nextFeature);
  };

  const swipeViewStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  };

  return (
    <Wrapper className={className}>
      <Box>
        <Title variant="h1">{t('title')}</Title>
        <Subtitle variant="h4">{t('subtitle')}</Subtitle>
      </Box>

      <Carousel>
        {!isSmall && <StyledFeatureThumbnail feature={FEATURES[previousFeature]} onClick={handlePreviousFeature} />}

        <VirtualSwipeableViews
          index={currentFeature}
          onChangeIndex={setCurrentFeature}
          animateTransitions={isSmall}
          resistance={false}
          enableMouseEvents={true}
          interval={5500}
          disabled={!isSmall}
          style={{ maxWidth: '500px' }}
          slideStyle={swipeViewStyle}
          slideRenderer={({ index, key }) => <FeatureDemo key={key} feature={FEATURES[mod(index, FEATURES.length)]} />}
        />

        {!isSmall && <StyledFeatureThumbnail feature={FEATURES[nextFeature]} onClick={handleNextFeature} />}
      </Carousel>
      <Box>
        <StyledStepper
          position="static"
          variant="dots"
          steps={FEATURES.length}
          activeStep={mod(currentFeature, FEATURES.length)}
          backButton={null}
          nextButton={null}
        />
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(4)};

  ${(props) => props.theme.breakpoints.down('sm')} {
    gap: ${(props) => props.theme.spacing(2)};
  }
`;

const Title = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: 500;

  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
    line-height: 5rem;
  }

  ${(props) => props.theme.breakpoints.up('xs')} and (max-height: 700px) {
    letter-spacing: -0.1rem;
    line-height: 3.25rem;
    margin-bottom: ${(props) => props.theme.spacing(1)};
  }

  ${(props) => props.theme.breakpoints.up('xs')} and (max-height: 600px) {
    font-size: 2.75rem;
    letter-spacing: -0.1rem;
    line-height: 2.75rem;
  }
`;

const Subtitle = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.light};

  ${(props) => props.theme.breakpoints.up('xs')} and (max-height: 700px) {
    font-size: 1.125rem;
  }

  ${(props) => props.theme.breakpoints.up('xs')} and (max-height: 600px) {
    font-size: 1rem;
  }
`;

const Carousel = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.spacing(4)};

  width: 100%;
`;

const StyledFeatureThumbnail = styled(FeatureThumbnail)`
  cursor: pointer;
`;

const StyledStepper = styled(MobileStepper)`
  justify-content: center;
  background-color: transparent;

  & .MuiMobileStepper-dot {
    width: 12px;
    height: 12px;
    margin: ${(props) => props.theme.spacing(0, 1.5)};
  }
`;
