import React from 'react';

import reportWebVitals from './reportWebVitals';

import './index.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getVersion } from '@libs/version';
import { Userpilot } from 'userpilot';
import legacyApp from '@root/legacy';
import { I18N_CONFIG } from '@libs/i18n-config';
import ga4 from 'react-ga4';
import { mapCampaignFromSearchParams, storeCampaignParams } from '@hooks/Campaign';

if (process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    sampleRate: 1,
    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE || 1,
    release: getVersion(),
  });
} else {
  console.warn('Sentry is disabled');
}

i18n.use(HttpApi).use(LanguageDetector).use(initReactI18next).init(I18N_CONFIG);

Userpilot.initialize(process.env.REACT_APP_USER_PILOT_API_KEY);

// Hotjar currently disabled, payment plan was disabled
// if (process.env.REACT_APP_HOTJAR_ID) {
//   const hotjarVersion = 6;
//
//   hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, hotjarVersion);
// }

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
const GTM_MEASUREMENT_ID = process.env.REACT_APP_GTM_MEASUREMENT_ID;

ga4.initialize([{ trackingId: GTM_MEASUREMENT_ID }, { trackingId: GA_MEASUREMENT_ID }]);

const searchParams = new URLSearchParams(document.location.search);
if (searchParams.has('utm_source') || searchParams.has('utm_campaign')) {
  storeCampaignParams(searchParams);
}

// If we ever have actual routes, use sentryRoutes:
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/

const container = document.getElementById('root');
legacyApp.renderApp(container);

reportWebVitals(console.log);
