import Vector2D from './Vector2D';

/**
 * Defines a BoundingBox object that handles a relative width/height and center
 */
export default class BoundingBox {
  constructor(public center: Vector2D = new Vector2D(0.5, 0.5), public width: number = 1, public height: number = 1) {}

  getSize(reference): Vector2D {
    return new Vector2D(this.width * reference.x, this.height * reference.y);
  }

  isFullSize(): boolean {
    return this.width >= 1 && this.height >= 1;
  }

  getAspectRatio(): number {
    return this.width / this.height;
  }

  getStartX(): number {
    return this.center.x - this.width / 2;
  }

  getStartY(): number {
    return this.center.y - this.height / 2;
  }

  /**
   * Serialize into an object (json)
   * @return{*}
   */
  serialize(): any {
    const state = {
      width: this.width,
      height: this.height,
      center: this.center.serialize(),
    };
    return state;
  }

  /**
   * Create an object from a json
   * @param {*} state
   * @return {BoundingBox}
   */
  static unserialize(state: any): BoundingBox {
    return new BoundingBox(Vector2D.unserialize(state.center), state.width, state.height);
  }
}
