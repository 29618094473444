import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GRAPHQL_POSSIBLE_TYPES } from '@libs/graphql/possibleTypes';

if (process.env.NODE_ENV === 'development') {
  new ApolloLink((operation, forward) => {
    console.log(`GraphQL Request: ${operation.operationName}`);
    operation.setContext(({ headers = {} }) => ({
      start: new Date(),
      headers: {
        ...headers,
        'Depix-Frontend-Variant': 'new',
      },
    }));
    return forward(operation).map((response) => {
      const responseTime = new Date() - operation.getContext().start;
      console.log(`GraphQL Response took: ${responseTime}`);
      return response;
    });
  });
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message},
            Location: ${JSON.stringify(locations)},
              Path: ${path}`
      )
    );
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => !!error,
  },
});

// eslint-disable-next-line new-cap
const httpLink = new createUploadLink({
  uri: process.env.REACT_APP_DEPIX_DOMAIN,
});

export const defaultLinks = [errorLink, retryLink, httpLink];

const client = new ApolloClient({
  cache: new InMemoryCache({ possibleTypes: GRAPHQL_POSSIBLE_TYPES }),
  link: from(defaultLinks),
  connectToDevTools: true,
});

export const configureApolloClient = (uri = process.env.REACT_APP_DEPIX_DOMAIN) => {
  const links = [errorLink, retryLink, new createUploadLink({ uri })];

  const client = new ApolloClient({
    uri,
    cache: new InMemoryCache({ possibleTypes: GRAPHQL_POSSIBLE_TYPES }),
    link: from(defaultLinks),
    connectToDevTools: true,
  });

  return { client, links };
};

export default client;
