/* eslint-disable require-jsdoc */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import useScript, { Status } from '@hooks/UseScript';
import useInstrumentation from '@hooks/UseInstrumentation';

const VIDYARD_EMBED_JS_URL = 'https://play.vidyard.com/embed/v4.js';

export const Videos = {
  SEGMENTATION: 'SEGMENTATION',
  COMPOSITION: 'COMPOSITION',
  ADVANCED_FEATURES: 'ADVANCED_FEATURES',
};

const Video_UUIDS = {
  [Videos.SEGMENTATION]: 'w87rDVeSvC6jcPGSYsSkRa',
  [Videos.COMPOSITION]: 'FBn47nEjyHzJcfxXSmcHP7',
  [Videos.ADVANCED_FEATURES]: '9qXpieCQ8mebCeUhhnMwvK',
};

function VideoPlayer(props) {
  const { maxWidth, maxHeight, type, videoName, aspect, onLoadCallback } = props;
  const { instrumentVideo } = useInstrumentation();
  const containerRef = React.useRef();
  const scriptState = useScript(VIDYARD_EMBED_JS_URL);
  const isReady = scriptState === Status.READY;

  useEffect(() => {
    if (isReady) {
      window.VidyardV4.api
        .renderPlayer({
          aspect,
          container: containerRef.current,
          height: maxHeight,
          type,
          uuid: Video_UUIDS[videoName],
          width: maxWidth,
        })
        .then((player) => {
          if (onLoadCallback) {
            onLoadCallback(player, window.VidyardV4.api);
          }
          instrumentVideo(window.vidyardEmbed);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  return <Box role="presentation" aria-label="videoPlayer" ref={containerRef} sx={{ width: '100%' }} />;
}

VideoPlayer.propTypes = {
  scriptsLoadedSuccessfully: PropTypes.bool,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  type: PropTypes.string,
  videoName: PropTypes.oneOf(Object.values(Videos)),
  aspect: PropTypes.string,
  onLoadCallback: PropTypes.func,
};

// eslint-disable-next-line new-cap
export default VideoPlayer;
