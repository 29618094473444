/* eslint-disable react-hooks/exhaustive-deps */
import { DepixObject } from '@libs/DepixObject';
import React, { useCallback, useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import CanvasContainer from './CanvasContainer';
import SegmentationClicks from '@components/Segmentation/SegmentationClicks';

import { useActiveObject, useScene, useSceneRelight } from '@contexts/SceneContext';

import { getRelativePosition, decomposeTransform, toCanvasCoord } from '../FabricJS/FabricUtils';
import Vector3D from '@libs/Geometry/Vector3D';
import Vector2D from '@libs/Geometry/Vector2D';
import '../FabricJS/ObjectPointer';
import '../FabricJS/Object3DImage';
import '../FabricJS/DepixImage';

import { INFILL_IMAGE_TYPE } from '@components/FabricJS/InfillImage';
import { OBJECT_3D_IMAGE_TYPE } from '@components/FabricJS/Object3DImage';

import UploadCanvas from './UploadCanvas';
import useUploadDepixObject from '@hooks/UseUploadDepixObject';
import useFabric from '@hooks/Fabric';
import useUserProfile from '@hooks/UseUserProfile';
import { EventName } from '@hooks/CanvasEventController';
import { useMediaQuery, useTheme } from '@mui/material';
import { COMPOSE_PANEL_NAME } from '@libs/ComposePanelName';
import useCropTool from '@hooks/UseCropTool';

const USE_PREVIEW = true;
const MINIMUM_PIXEL_SIZE = 50;

interface ClipboardContent {
  depixObject?: DepixObject;
  name?: string;
}

function PasteCanvas() {
  const { activeObject, setActiveObject } = useActiveObject();
  const scene = useScene();
  const { renderRelight, initialiseRelight } = useSceneRelight();
  const [clipBoard, setClipBoard] = useState<ClipboardContent>({});
  const [objectChangedFromUI, setObjectChangedFromUI] = useState(false);
  const { isFirstTimeUse } = useUserProfile();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const showInstruction = !isFirstTimeUse || isSmallDevice;
  const cropTool = useCropTool();

  const fabricHook = useFabric();

  const renderShadow = (object) => {
    if (object.isCastingShadow() && object.isCastingShadowOnTheGround()) {
      let [center, topLeft, topRight] = scene.getComponentShadowProjection(object.name);
      // Move to Canvas coord system
      center = fabricHook.backgroundToViewport(center);
      topLeft = fabricHook.backgroundToViewport(topLeft);
      topRight = fabricHook.backgroundToViewport(topRight);
      // Update shadow
      const shadow = object.getShadow();
      shadow.setGroundCoord(center, topRight, topLeft);
    }
  };

  /**
   * Change light setting based on the warping position
   * @param {*} object
   * @param {number} x Global canvas coordinate
   * @param {number} y Global canvas coordinate
   */
  const onGroundChange = useCallback(
    (object, x, y) => {
      const object3D = object.parent;
      const normalized = false;
      const backgroundCoord = fabricHook.viewportToBackground(new Vector2D(x, y), normalized);
      scene.setComponentShadowProjection(object3D.name, backgroundCoord);
    },
    [scene, fabricHook?.viewportToBackground]
  );

  const onGroundReleaseChange = (e) => {
    const object3D = e.target.parent;
    const isPreview = false;
    renderRelight(object3D, isPreview);
  };

  const onSelectionChange = useCallback(
    (e) => {
      if (e.selected?.length > 0) {
        const selectedObject = e.selected[0];

        if (selectedObject.type === INFILL_IMAGE_TYPE) {
          setActiveObject(selectedObject);
          scene.dehighlightObject(selectedObject);
        }

        if (selectedObject.type === OBJECT_3D_IMAGE_TYPE) {
          selectedObject.getShadow().onGroundChange = onGroundChange;
          setActiveObject(selectedObject);
          scene.dehighlightObject(selectedObject);
        }

        if (selectedObject.parent) {
          selectedObject.onGroundChange = onGroundChange;
          setActiveObject(selectedObject.parent);
        }
      } else if (e.deselected) {
        setActiveObject(null);
      }
    },
    [onGroundChange]
  );

  const deleteObject = (object) => {
    if (object) {
      if (object.type === OBJECT_3D_IMAGE_TYPE) scene.removeSceneComponent(object.name);
      if (object.type === INFILL_IMAGE_TYPE) scene.removeInfillSceneComponent(object.name);
    }
  };

  const updateObjectPosition = (fabricObject) => {
    const transform = scene.getComponentScreenTransform(fabricObject.name);
    let decomposedTransform = decomposeTransform(transform);
    decomposedTransform = toCanvasCoord(decomposedTransform, fabricHook.background);
    const dimensions = scene.getComponentDimensions(fabricObject.name);
    decomposedTransform.flipX = dimensions.x < 0;
    decomposedTransform.flipY = dimensions.y < 0;
    fabricObject.setDecomposition(decomposedTransform);
  };

  const updateCanvasObject = (fabricObject) => {
    const asset = scene.getDepixObject(fabricObject.name);
    fabricObject.refresh(asset).then(() => {
      updateObjectPosition(fabricObject);
      fabricObject.updateUIElements(scene, fabricHook);

      renderShadow(fabricObject);
      renderRelight(fabricObject);
      setObjectChangedFromUI(true);
    });
  };

  const onObjectLoaded = (fabricObject) => {
    if (!fabricObject.depixObject.isInfillObject) {
      initialiseRelight(fabricObject);
    }
    updateCanvasObject(fabricObject);
  };

  // Setup a default white background
  useEffect(() => {
    if (!scene.backgroundImage) {
      scene.setPlainBackground();
    }
  }, [scene.backgroundImage]);

  // Update scene components
  useEffect(() => {
    const ids = scene.getIDs();
    // Update background if changed
    const fabricBackgroundId = fabricHook.background?.name;
    const isNewBackground = fabricBackgroundId !== ids.background;
    const isBackgroundUpdated = scene?.backgroundImage?.updated;
    if (isNewBackground || isBackgroundUpdated) {
      fabricHook.setupBackground(scene.backgroundImage, ids.background);
      scene.backgroundImage.updated = false;
    }

    const fabricObjectsID = fabricHook.getAllObjectsID();

    // Check if scene Components ID match fabric component scene ID, create 3d object if not here
    for (let i = 0; i < ids.sceneComponents.length; ++i) {
      if (!fabricObjectsID.includes(ids.sceneComponents[i])) {
        const depixObject = scene.getDepixObject(ids.sceneComponents[i]);
        const isFix = scene.getComponentFixState(ids.sceneComponents[i]);
        fabricHook.setup3DObject(
          depixObject,
          ids.sceneComponents[i],
          onObjectLoaded,
          onGroundChange,
          onGroundReleaseChange,
          isFix
        );
      }
    }

    // Check if scene Components ID match fabric component scene ID, create infill object if not here
    for (let i = 0; i < ids.infillSceneComponents.length; ++i) {
      if (!fabricObjectsID.includes(ids.infillSceneComponents[i])) {
        const depixObject = scene.getDepixObject(ids.infillSceneComponents[i]);
        fabricHook.setupInfillObject(depixObject, ids.infillSceneComponents[i], updateObjectPosition);
      }
    }

    // Update ground if different
    const FabricGroundID = fabricHook.ground?.name;
    if (FabricGroundID !== ids.ground) {
      fabricHook.setupGround(scene.ground, ids.ground);
    }

    // Delete 3d objects that are not in the scene.
    for (const object3D of fabricHook.getAll3DObjects()) {
      const currentID = object3D.name;
      if (!ids.sceneComponents.includes(currentID)) {
        fabricHook.removeObjectsByName(currentID);
      }
    }

    // Delete infill objects that are not in the scene
    for (const object3D of fabricHook.getAllInfillObjects()) {
      const currentID = object3D.name;
      if (!ids.infillSceneComponents.includes(currentID)) {
        fabricHook.removeObjectsByName(currentID);
      }
    }
  }, [scene.updated]);

  useEffect(() => {
    for (const updatedObjectID of scene.objectsUpdated) {
      const objects = fabricHook.getInfillOr3dObjectsFromID(updatedObjectID);

      objects.forEach((x) => {
        if (x.type === OBJECT_3D_IMAGE_TYPE) {
          updateCanvasObject(x);
        } else if (x.type === INFILL_IMAGE_TYPE) {
          updateObjectPosition(x);
        }
      });
    }
  }, [scene.objectsUpdated]);

  useEffect(() => {
    fabricHook.render(scene);
    setObjectChangedFromUI(false);
  }, [
    scene.objectsUpdated,
    scene.updated,
    fabricHook.background,
    fabricHook.ground,
    fabricHook.infillImages,
    objectChangedFromUI,
  ]);

  useEffect(() => {
    fabricHook.enableUIElements(activeObject && activeObject.type === 'object3DImage');
    // Todo this should be activated when an object is added to "fabric" Task #254
  }, [activeObject]);

  const updateObjectsScreenPose = () => {
    const objects = fabricHook.getAll3DObjects();
    for (const object of objects) {
      updateCanvasObject(object);
    }
  };

  useEffect(() => {
    updateObjectsScreenPose();
  }, [fabricHook.background]);

  const get3DDisplacement = (initialClick, currentClick, objectPose, lockZ) => {
    // Retrieve the drag displacement in image coordinate
    const initialClickBackground = getRelativePosition(initialClick, fabricHook.background);
    const currentClickBackground = getRelativePosition(currentClick, fabricHook.background);
    let clickDelta = currentClickBackground.sub(initialClickBackground);

    // Get the ground position of the object
    const groundPose = new Vector3D(objectPose.x, 0, objectPose.z);
    const groundScreenCoord = scene.worldToImage(groundPose);
    let useZPlane = null;
    if (lockZ) {
      useZPlane = objectPose.z;
    } else {
      // Move object upward only as you drag back
      clickDelta = new Vector2D(0, clickDelta.y);
    }
    const currentPose = scene.imageToWorld(groundScreenCoord.add(clickDelta), useZPlane);
    return currentPose.sub(groundPose);
  };

  const onObjectMove = (e) => {
    const currentSelection = e.transform.target;
    if (
      currentSelection != null &&
      currentSelection.type === 'object3DImage' &&
      currentSelection.type !== 'activeSelection'
    ) {
      if (!e.transform.initialPosition) {
        e.transform.initialPosition = scene.getComponentPosition(currentSelection.name);
      }
      const lastPoint = new fabric.Point(e.transform.lastX, e.transform.lastY);
      const lockZ = !currentSelection.isFloating;
      const displacement = get3DDisplacement(lastPoint, e.pointer, e.transform.initialPosition, lockZ);
      scene.setComponentPosition(
        currentSelection.name,
        e.transform.initialPosition.add(displacement),
        lockZ ? 0 : MINIMUM_PIXEL_SIZE
      );
      updateCanvasObject(currentSelection);
      setObjectChangedFromUI(true);
    }
    const selectionParent = currentSelection.parent;
    if (selectionParent) {
      currentSelection.setDisplacement(selectionParent);
    }
  };

  const clampScaleToMinimumSize = (fabricObject, scaleX, scaleY) => {
    const width = Math.abs(scaleX * fabricObject.width);
    const height = Math.abs(scaleY * fabricObject.height);
    scaleX = Math.max(width, MINIMUM_PIXEL_SIZE) / fabricObject.width;
    scaleY = Math.max(height, MINIMUM_PIXEL_SIZE) / fabricObject.height;

    return [scaleX, scaleY];
  };

  const onObjectModified = (e) => {
    if (e.e.type === 'mouseup') {
      scene.setSceneCommitted();
    }
    // Update image scale value based on user drag
    const fabricObject = e.transform.target;
    // put all objets to floating if there is no background
    if (fabricObject.type === 'object3DImage') {
      if (e.transform.action === 'rotate') {
        scene.setComponentAngle(fabricObject.name, fabricObject.angle);
      } else if (e.transform.action === 'scale' || e.transform.action === 'scaleX' || e.transform.action === 'scaleY') {
        let scaleX = fabricObject.scaleX / fabricHook.background.scaleX;
        let scaleY = fabricObject.scaleY / fabricHook.background.scaleY;

        [scaleX, scaleY] = clampScaleToMinimumSize(fabricObject, scaleX, scaleY);

        // Apply flipping
        scaleX = fabricObject.flipX ? -scaleX : scaleX;
        scaleY = fabricObject.flipY ? -scaleY : scaleY;

        scene.setComponentScale(fabricObject.name, scaleX, scaleY);
      }
      updateCanvasObject(fabricObject);
    }
  };

  useEffect(() => {
    const listenerName = 'on object move';
    fabricHook.registerListener(EventName.OBJECT_MOVING, onObjectMove, listenerName);
    return () => {
      fabricHook.unregisterListener(EventName.OBJECT_MOVING, listenerName);
    };
  }, [scene.updated, fabricHook.background, fabricHook.orderObjects, fabricHook.registerListener]);

  const onHighlightObject = (event) => {
    if (
      (!(event.target instanceof fabric.Object3DImage) && !(event.target instanceof fabric.InfillImage)) ||
      event.target === activeObject
    ) {
      return;
    }

    scene.highlightObject(event.target);
  };

  const onDehighlightObject = (event) => {
    if (!(event.target instanceof fabric.Object3DImage) && !(event.target instanceof fabric.InfillImage)) {
      return;
    }

    scene.dehighlightObject(event.target);
  };

  useEffect(() => {
    const listenerName = 'highlightOnHover';
    fabricHook.registerListener(EventName.ON_MOUSE_OVER, onHighlightObject, listenerName);
    fabricHook.registerListener(EventName.ON_MOUSE_OUT, onDehighlightObject, listenerName);
    return () => {
      fabricHook.unregisterListener(EventName.ON_MOUSE_OVER, listenerName);
      fabricHook.unregisterListener(EventName.ON_MOUSE_OUT, listenerName);
    };
  }, [scene.updated, fabricHook.background, fabricHook.orderObjects, fabricHook.registerListener, activeObject]);

  useEffect(() => {
    const listenerName = 'on object modified';
    fabricHook.registerListener(EventName.OBJECT_CHANGED, onObjectModified, listenerName);
    return () => {
      fabricHook.unregisterListener(EventName.OBJECT_CHANGED, listenerName);
    };
  }, [fabricHook.registerListener, activeObject, scene.updated, fabricHook.background]);

  useEffect(() => {
    const listenerName = 'on selection changed';
    fabricHook.registerListener(EventName.SELECTION_CHANGED, onSelectionChange, listenerName);
    return () => {
      fabricHook.unregisterListener(EventName.SELECTION_CHANGED, listenerName);
    };
  }, [onSelectionChange, fabricHook.registerListener]);

  const copyObject = (object) => {
    const [objectBottomCoord, objectMaxSizeScreenRatio] = object.getBoundingBoxPosition();
    const newComponentID = scene.addSceneComponent(object, objectBottomCoord, objectMaxSizeScreenRatio);
    scene.copyComponentAttributes(newComponentID, clipBoard.name);
    const position = scene.getComponentPosition(newComponentID);
    position.y -= 0.1;
    position.x -= 0.1;
    scene.setComponentPosition(newComponentID, position);
  };

  const { cloneFromObjectId: cloneObject } = useUploadDepixObject(copyObject);

  const handleKeyDown = (event) => {
    const charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
      setClipBoard(activeObject);
    } else if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
      if (clipBoard) {
        const maskId = undefined;
        cloneObject(clipBoard.depixObject.objectID, maskId, USE_PREVIEW);
      }
    } else if (charCode === 's') {
      const sceneState = scene.save();
      console.debug('Save scene:', sceneState);
      localStorage.setItem('SCENE', JSON.stringify(sceneState));
    } else if (charCode === 'a') {
      const sceneState = JSON.parse(localStorage.getItem('SCENE'));
      scene.load(sceneState);
    } else if (
      event.key === 'Delete' ||
      event.key === 'Backspace' ||
      ((event.ctrlKey || event.metaKey) && charCode === 'x')
    ) {
      if (activeObject) {
        deleteObject(activeObject);
      }
    }
  };

  useEffect(() => {
    fabricHook.updateViewport(scene.viewport);
  }, [scene.viewport, fabricHook.updateViewport]);

  return (
    <CanvasContainer>
      <Grid item flexGrow={1} sx={{ display: 'flex', background: `url('/checkboardSquare.png') repeat top left` }}>
        <div onKeyDown={handleKeyDown} tabIndex={0} style={{ outline: 'none', width: '100%', height: '100%' }}>
          <UploadCanvas
            id={COMPOSE_PANEL_NAME}
            stopContextMenu={true}
            preserveObjectStacking="true"
            fireRightClick="true"
            enableCrop={true}
            disableClickUpload={cropTool.enabled}
            showInstruction={showInstruction}>
            <SegmentationClicks />
          </UploadCanvas>
        </div>
      </Grid>
    </CanvasContainer>
  );
}

export default PasteCanvas;
