import { Box, styled, TextField, Typography, useTheme } from '@mui/material';
import React from 'react';
import { StandardTextFieldProps } from '@mui/material/TextField/TextField';
import { TypographyProps } from '@mui/material/Typography/Typography';

interface EditableTextProps extends StandardTextFieldProps {
  label?: string;
  editing: boolean;
  value: string | number;
}

export const EditableText = ({ id, editing, value, onChange, label, onKeyDown, InputProps }: EditableTextProps) => {
  return (
    <Wrapper>
      {!editing && <Value>{value}</Value>}
      {editing && (
        <StyledInput
          id={id}
          onChange={onChange}
          type="number"
          InputProps={InputProps}
          value={value}
          onKeyDown={onKeyDown}
          variant="standard"
          autoFocus
          aria-label={label}
        />
      )}
    </Wrapper>
  );
};

const StyledTypography = styled(Typography)`
  padding-top: 1px;
`;

const Value = ({ children, ...props }: TypographyProps) => {
  const theme = useTheme();

  return (
    <StyledTypography {...props} color={theme.palette.secondary.dark} display="inline" fontWeight={500}>
      {children}
    </StyledTypography>
  );
};

const Wrapper = styled(Box)`
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledInput = styled(TextField)`
  & input {
    text-align: center;
    padding: 0;
    color: ${(props) => props.theme.palette.secondary.dark};
    font-weight: 500;

    -moz-appearance: textfield;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  & .MuiInput-root {
    &:before {
      border-bottom: none !important;
    }
    &:after {
      border-bottom: none !important;
    }
  }
`;
