import { Box, styled, Typography } from '@mui/material';
import { Feature } from '@components/FeatureCarousel/Feature';
import Surface from '@components/Ui/Surface';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';

interface FeatureThumbnailProps {
  feature: Feature;
  onClick: () => void;
  className?: string;
}

export const FeatureThumbnail = ({ feature, onClick, className }: FeatureThumbnailProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'feature_carousel' });

  return (
    <Wrapper className={className} onClick={onClick}>
      <Background>
        {feature.icon}
        <Typography>{t(`features.${feature.id}.title`)}</Typography>
      </Background>
    </Wrapper>
  );
};

const Wrapper = styled(Surface)`
  position: relative;
  flex-shrink: 0;
  width: 130px;
  height: 150px;
  padding: ${(props) => props.theme.spacing(1)};
  box-shadow: none;
  color: ${(props) => props.theme.palette.primary.main};
`;

const Background = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing(1)};
  width: 100%;
  height: 100%;

  padding: ${(props) => props.theme.spacing(3)};
  background-color: ${(props) => props.theme.palette.secondary.light};
  border-radius: ${(props) => props.theme.borderRadius.small}px;
`;
