/* eslint-disable max-len */
import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const DeleteIcon = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      d="M1 4H2.5M13 4H11.5M11.5 4C11.5 7.94708 11.5 9.87869 11.5 13.4986C11.5 14.0509 11.0523 14.5 10.5 14.5H3.5C2.94772 14.5 2.5 14.0523 2.5 13.5V4M11.5 4H9.5M2.5 4H4.5M4.5 4V2C4.5 1.44772 4.94772 1 5.5 1H8.5C9.05228 1 9.5 1.44772 9.5 2V4M4.5 4H9.5M5.5 7V11.5M8.5 7V11.5"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default DeleteIcon;
