import React, { useCallback } from 'react';
import SwapIcon from '@components/Ui/Icons/SwapIcon';
import ToolButton from '@components/Viewport/ToolButton';
import useUploadDepixObject from '@hooks/UseUploadDepixObject';
import { useActiveObject } from '@contexts/SceneContext';
import useInstrumentation from '@hooks/UseInstrumentation';
import { useAlert } from '@hooks/Alert';
import { useSegmentation } from '@contexts/Segmentation/UseSegmentation';
import { useTranslation } from 'react-i18next';
import { MeasureType } from '@libs/Instrumentation/MeasureType';

interface SwapButtonProps {
  onClick: () => void;
}

const SwapButton = ({ onClick = () => {} }: SwapButtonProps) => {
  const { t } = useTranslation();
  const instrumentation = useInstrumentation();
  const { isActiveObject, setAsset } = useActiveObject();
  const alert = useAlert();
  const segmentation = useSegmentation();

  const { cloneFromObjectId: swapObject } = useUploadDepixObject(setAsset);

  const handleSwap = useCallback(() => {
    swapObject(segmentation.segmentationObjectID, segmentation.maskId, segmentation.preview).then(() => {
      alert.success(t('tools.cut.alerts.swapSuccess'));
      instrumentation.backgroundRemoved(segmentation.segmentationObjectID);
      instrumentation.instrument(MeasureType.OBJECT_SWAPED);
      onClick();
    });
  }, [swapObject, segmentation.segmentationObjectID, segmentation.preview, alert, instrumentation, onClick]);

  const isDisabled = segmentation.actionsDisabled || !isActiveObject;

  return (
    <ToolButton name="Replace" toolTip={t('tools.cut.tooltips.swap')} onClick={handleSwap} disabled={isDisabled}>
      <SwapIcon />
    </ToolButton>
  );
};

export default SwapButton;
