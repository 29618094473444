import { CutCanvasGrabberHacksContext } from '@contexts/CutCanvasGrabberHacksContext';
import React from 'react';

function useCutCanvasGrabberHacks(): CutCanvasGrabberHacksContext {
  const context = React.useContext(CutCanvasGrabberHacksContext);

  if (!context) {
    throw new Error('useCutCanvasGrabberHacks must be used within a CutCanvasGrabberHacksContext');
  }
  return context;
}

export default useCutCanvasGrabberHacks;
