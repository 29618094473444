import React, { useEffect, useState } from 'react';
import { RadioGroup, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@components/Ui/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import useInstrumentation from '@hooks/UseInstrumentation';
import { SubscriptionIncentive } from '@libs/Instrumentation/SubscriptionIncentive';
import { DialogActions } from '@components/Ui/Dialog/DialogActions';
import Surface from '@components/Ui/Surface';
import { useLocation, useNavigate } from 'react-router-dom';
import Routes from '@root/routes';
import { Dialog } from '@components/Ui/Dialog/Dialog';
import { DialogHeader } from '@components/Ui/Dialog/DialogHeader';
import { DialogTitle } from '@components/Ui/Dialog/DialogTitle';
import { DownloadResolution, DownloadResolutionType } from '@libs/Download/DownloadResolution';
import { Link } from '@components/Ui/Link';
import { ResolutionSelect } from '@components/Download/ResolutionSelect';

interface Resolution {
  width: number;
  height: number;
}

interface DownloadImageDialogProps {
  open: boolean;
  onDownloadLowResolution: () => void;
  onClose: () => void;
  onSkip: () => void;
  lowResolution: Resolution;
  highResolution: Resolution;
}

export const DownloadImageDialog = ({
  open,
  onDownloadLowResolution,
  onClose,
  onSkip,
  lowResolution,
  highResolution,
}: DownloadImageDialogProps) => {
  const { t } = useTranslation();
  const instrumentation = useInstrumentation();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedResolution, setSelectedResolution] = useState<DownloadResolutionType>(DownloadResolutionType.LOW);

  const goToSubscribe = () => {
    onClose();
    navigate(Routes.subscribe(), { state: { backgroundLocation: location } });
  };

  const handleResolutionChange = (value) => {
    setSelectedResolution(value);
  };

  const handleButtonClick = () => {
    if (selectedResolution == DownloadResolutionType.HIGH) {
      goToSubscribe();
    } else {
      onDownloadLowResolution();
    }
  };

  useEffect(() => {
    if (open) {
      instrumentation.subscriptionOfferShown(SubscriptionIncentive.HIGH_RESOLUTION);
    }
  }, [open]);

  const resolutions: DownloadResolution[] = [];

  if (lowResolution) {
    resolutions.push({ type: DownloadResolutionType.LOW, name: t('download.form.options.sd'), size: lowResolution });
  }

  if (highResolution) {
    resolutions.push({
      type: DownloadResolutionType.HIGH,
      name: t('download.form.options.hd'),
      size: highResolution,
      premium: true,
    });
  }

  return (
    <StyledDialog
      open={open}
      PaperComponent={Surface}
      aria-label={t('download.form.title')}
      maxWidth="sm"
      closeable
      onClose={onSkip}>
      <DialogHeader>
        <StyledDialogTitle size="medium">{t('download.form.title')}</StyledDialogTitle>
      </DialogHeader>

      <DialogContent>
        <StyledResolutionSelect
          resolutions={resolutions}
          selectedResolution={selectedResolution}
          onChange={handleResolutionChange}
        />
      </DialogContent>

      <StyledDialogActions>
        <StyledButton variant="contained" onClick={handleButtonClick}>
          {t('download.form.submit')}
        </StyledButton>

        <Typography variant="caption" color="greyInfo.main" textAlign="center">
          <Trans i18nKey="download.form.social" components={{ socialLink: <Link target="_blank" /> }} />
        </Typography>
      </StyledDialogActions>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)``;

const StyledDialogTitle = styled(DialogTitle)`
  padding: ${(props) => props.theme.spacing(3, 3, 0)};
  text-align: center;

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props.theme.spacing(6, 6, 0)};
  }
`;

const DialogContent = styled(Box)`
  padding: ${(props) => props.theme.spacing(0, 3)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props.theme.spacing(0, 6)};
  }
`;

const StyledDialogActions = styled(DialogActions)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${(props) => props.theme.spacing(3)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props.theme.spacing(3, 6, 6)};
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  border-radius: ${(props) => props.theme.borderRadius.large}px;
  padding: ${(props) => props.theme.spacing(1, 4)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;

const StyledResolutionSelect = styled(ResolutionSelect)`
  width: 100%;
`;
