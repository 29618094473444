import { alpha, styled } from '@mui/material/styles';
import { Box, FormControlLabel, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface OptionProps {
  value: string;
  name: string;
  description?: string;
  aside?: ReactNode;
  selected?: boolean;
  control: ReactElement;
  className?: string;
}

export const SelectOption = ({ value, name, description, aside, selected, control, className }: OptionProps) => {
  const label = (
    <Label>
      <LabelContent>
        <Typography variant="body1" fontWeight={500} fontSize="inherit">
          {name}
        </Typography>
        {description && (
          <Typography variant="caption" color="greyInfo.main">
            {description}
          </Typography>
        )}
      </LabelContent>

      {aside && <LabelAside>{aside}</LabelAside>}
    </Label>
  );

  return (
    <Wrapper
      value={value}
      name={name}
      className={className}
      label={label}
      control={control}
      checked={selected}
      withDescription={!!description}
    />
  );
};

const Wrapper = styled(FormControlLabel, {
  shouldForwardProp: (propName: PropertyKey) => !['withDescription'].includes(propName as string),
})<{
  withDescription: boolean;
}>`
  display: flex;
  align-items: ${(props) => (props.withDescription ? 'flex-start' : 'center')};
  gap: ${(props) => props.theme.spacing(1)};

  margin: ${(props) => props.theme.spacing(1, 0)};
  padding: ${(props) => props.theme.spacing(0.5, 1.5)};

  border: 2px solid
    ${(props) => (props.checked ? props.theme.palette.primary.main : alpha(props.theme.palette.primary.main, 0.2))};
  border-radius: ${(props) => props.theme.borderRadius.medium}px;

  box-shadow: ${(props) => (props.checked ? props.theme.elevation.tag : 0)};

  & > .MuiFormControlLabel-label {
    width: 100%;
  }

  ${(props) => props.theme.breakpoints.up(400)} {
    padding: ${(props) => props.theme.spacing(1.25, 2)};
  }
`;

const Label = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) => props.theme.breakpoints.up(400)} {
    flex-direction: row;
  }
`;

const LabelContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(0.5)};
  flex-grow: 1;

  font-size: 0.9rem;

  ${(props) => props.theme.breakpoints.up(400)} {
    font-size: 1rem;
  }
`;

const LabelAside = styled(Box)``;
