import { alpha, styled } from '@mui/material/styles';
import { Step, StepButton, Stepper } from '@mui/material';
import { OnboardingStep } from '@libs/Onboarding/OnboardingStep';

interface OnboardingStepperProps {
  currentStep: OnboardingStep;
  steps: { completed: boolean; value: OnboardingStep }[];
  onChange: (value: OnboardingStep) => void;
}

export const OnboardingStepper = ({ currentStep, steps, onChange }: OnboardingStepperProps) => {
  return (
    <StyledStepper activeStep={currentStep == OnboardingStep.SELECT_AUDIENCE ? 0 : 1} orientation="vertical" nonLinear>
      {steps.map((step) => (
        <StyledStep key={step.value} completed={step.completed}>
          <StyledStepLabel onClick={() => onChange(step.value)} />
        </StyledStep>
      ))}
    </StyledStepper>
  );
};

export const StyledStepper = styled(Stepper)`
  align-self: center;
  align-items: center;

  .MuiStepConnector-root {
    margin: 0;
  }

  .MuiStepConnector-line {
    border-color: ${(props) => alpha(props.theme.palette.primary.main, 0.2)};
    min-height: 48px;
  }
`;

const StyledStep = styled(Step)`
  .MuiStepIcon-root {
    width: 35px;
    height: 35px;

    color: ${(props) => props.theme.palette.secondary.main};
    border: 1px solid ${(props) => alpha(props.theme.palette.primary.main, 0.2)};
    border-radius: 20px;

    .MuiStepIcon-text {
      color: ${(props) => alpha(props.theme.palette.primary.main, 0.2)};
      fill: currentColor;
      font-size: 0.7rem;
    }

    &.Mui-active {
      border: 1px solid ${(props) => props.theme.palette.primary.main};
      color: ${(props) => props.theme.palette.secondary.main};

      .MuiStepIcon-text {
        color: ${(props) => props.theme.palette.primary.main};
      }
    }
  }

  &.Mui-completed {
    .MuiStepIcon-root {
      color: ${(props) => props.theme.palette.primary.main};
      border: none;
    }
  }
`;

const StyledStepLabel = styled(StepButton)`
  border-radius: 40px;

  .MuiStepLabel-root {
    padding: ${(props) => props.theme.spacing(1)};
  }

  .MuiStepLabel-iconContainer {
    padding: 0;
  }
`;
