import _ from 'lodash';
import {
  cropAndPad,
  getBoundingBoxCenter,
  getBoundingBoxSize,
  getMinimumBoundingbox,
  loadImage,
  loadImages,
  dataURItoBlob,
  setAlpha,
} from '@libs/ImgUtils';
import { angular2world } from '@libs/Math';
import '@components/FabricJS/InfillImage';
import '../Components/FabricJS/Object3DImage';
import '../Components/FabricJS/TransformedImage';
import { TwoDimensionArray } from '@libs/Geometry/TwoDimensionArray';

type AmbientColor = [number, number, number];
type Lighting = [number, number, number, number];

export interface DepixObjectProperties {
  blur: number;
  shadowVisible: boolean;
  shadowState: ShadowState;
  shadowDiffusion: number;
  shadowOpacity: number;
  relight: boolean;
  lowResidualAlpha: number;
  highResidualAlpha: number;
  ambientColor: AmbientColor;
  lighting: Lighting;
}

export type ObjectId = string;

export class DepixObject {
  private apiObject;
  image: any;
  imageFullResolution: any;
  originalMask: any;
  maskId: any;
  maskFullResolution: any;
  mask: any;
  boundingBox: any;
  infillBoundingBox: any;
  normals: any;
  infillFragment: any;
  albedo: any;
  prop: DepixObjectProperties;
  isInfilled: boolean;
  residualShading: any;
  isErased?: boolean;

  constructor() {
    this.image = null;
    this.imageFullResolution = null;
    this.originalMask = null;
    this.maskId = null;
    this.mask = null;
    this.maskFullResolution = null;
    this.infillFragment = null;
    this.isInfilled = false;
    this.boundingBox = null;
    this.infillBoundingBox = null;
    this.normals = null;
    this.albedo = null;
    this.residualShading = null;
    this.isErased = false;
    this.prop = _.cloneDeep(DEFAULT_PROPERTIES);
  }

  serialize() {
    return { prop: _.cloneDeep(this.prop), apiObject: _.cloneDeep(this.apiObject) };
  }

  static async unserialize(state): Promise<DepixObject> {
    return new Promise((resolve) => {
      DepixObject.create(state.apiObject).then((object) => {
        object.setupProperties(state.prop);
        resolve(object);
      });
    });
  }

  static async create(apiObject): Promise<DepixObject> {
    const depixObject = new DepixObject();
    return new Promise((resolve, reject) => {
      depixObject
        .onLoad(apiObject)
        .then(() => {
          resolve(depixObject);
        })
        .catch(reject);
    });
  }

  onLoad(apiObject) {
    this.apiObject = apiObject;

    return this._loadFiles();
  }

  update(apiObject) {
    const keys = Object.keys(apiObject);

    keys.forEach((key) => {
      this.apiObject[key] = apiObject[key];
    });

    return this._loadFiles();
  }

  _loadFiles() {
    const labels = [];
    const urls = [];
    if (this.apiObject.image) {
      labels.push('image');
      urls.push(this.apiObject.image);
    }
    if (this.apiObject.originalMask) {
      labels.push('originalMask');
      urls.push(this.apiObject.originalMask);
    }
    if (this.apiObject.mask?.url) {
      this.maskId = this.apiObject.mask.id;
      labels.push('mask');
      urls.push(this.apiObject.mask?.url);
    }
    if (this.apiObject.infillFragment) {
      labels.push('infillFragment');
      urls.push(this.apiObject.infillFragment);
    }

    if (this.apiObject.normals) {
      labels.push('normals');
      urls.push(this.apiObject.normals);
    }

    if (this.apiObject.albedo) {
      labels.push('albedo');
      urls.push(this.apiObject.albedo);
    }

    if (this.apiObject.residualShading) {
      labels.push('residualShading');
      urls.push(this.apiObject.residualShading);
    }
    return new Promise<void>((resolve, reject) => {
      loadImages(labels, urls)
        .then((images) => {
          for (const label of labels) {
            if (label === 'mask') {
              this._setMask(images[label]);
            } else if (label === 'infillFragment' && images[label]) {
              this._setInfillFragment(images[label]);
            } else {
              this[label] = images[label];
            }
          }
          resolve();
        })
        .catch(reject);
    });
  }

  setupFullResolution(apiObject) {
    if (apiObject.image) {
      this.imageFullResolution = apiObject.image;
    }
    if (apiObject.mask) {
      this.maskFullResolution = apiObject.mask;
    }
  }

  setupProperties(props) {
    this.prop = _.cloneDeep(props);
  }

  async getAlphaImage(
    padding = 1,
    cropFragment = false,
    invertMask = false,
    maskOption: MaskOption = MaskOption.MASK,
    fullResolution = false
  ): Promise<HTMLImageElement> {
    const image = fullResolution && this.imageFullResolution !== null ? this.imageFullResolution : this.image;

    const useOriginalMask = maskOption === MaskOption.ORIGINAL_MASK && this.originalMask !== null;
    const useFullResolutionMask = maskOption === MaskOption.MASK && fullResolution && this.maskFullResolution !== null;
    const useMask = maskOption === MaskOption.MASK && this.mask !== null;
    let mask = null;
    if (useOriginalMask) {
      mask = this.originalMask;
    } else if (useFullResolutionMask) {
      mask = this.maskFullResolution;
    } else if (useMask) {
      mask = this.mask;
    }

    const blendImageSrc = setAlpha(image, mask, !!invertMask);
    const imagePromise = loadImage(blendImageSrc);
    if (!cropFragment) {
      return imagePromise;
    } else {
      return new Promise((resolve, reject) => {
        imagePromise
          .then((image) => {
            const [cropWidth, cropHeight] = getBoundingBoxSize(this.boundingBox);
            const [minx, miny] = this.boundingBox;
            const width = image.width;
            const height = image.height;
            const imageCropSrc = cropAndPad(
              image,
              minx * width,
              miny * height,
              cropWidth * width,
              cropHeight * height,
              padding
            );
            resolve(loadImage(imageCropSrc));
          })
          .catch(reject);
      });
    }
  }

  /**
   * return a promise with the blob URL of the image that is cropped and alpha from mask
   */
  generateImage(padding = 1, cropFragment = false, invertMask = false) {
    return this.getAlphaImage(padding, cropFragment, invertMask).then((image) => {
      const file = dataURItoBlob(image.src);
      const url = URL.createObjectURL(file);

      return {
        file,
        url,
      };
    });
  }

  getCroppedMask(padding = 1) {
    const [cropWidth, cropHeight] = getBoundingBoxSize(this.boundingBox);
    const [minx, miny] = this.boundingBox;
    const width = this.mask.width;
    const height = this.mask.height;
    return cropAndPad(this.mask, minx * width, miny * height, cropWidth * width, cropHeight * height, padding);
  }

  /**
   * Setup mask and compute bounding box out of mask
   */
  _setMask(maskImage) {
    this.boundingBox = getMinimumBoundingbox(maskImage);
    this.mask = maskImage;
  }

  /**
   * Setup infill fragment and compute bounding box out of mask
   */
  _setInfillFragment(infillFragmentImage) {
    const hasAlphaChanel = true;
    this.infillBoundingBox = getMinimumBoundingbox(infillFragmentImage, hasAlphaChanel);
    this.infillFragment = infillFragmentImage;
    this.isInfilled = true;
  }

  getAspectRatio() {
    const [bbWidth, bbHeight] = getBoundingBoxSize(this.boundingBox);
    const imgWidth = this.mask.width;
    const imgHeight = this.mask.height;
    return (bbWidth * imgWidth) / (bbHeight * imgHeight);
  }

  /**
   * Get a relative bounding box placement :
   * coord is the bottom of the bounding box (0 - 1)
   * max screen ratio is the screenratio of the largest side
   */
  getBoundingBoxPosition(): [TwoDimensionArray, TwoDimensionArray] {
    return this._computeBoundingBoxCoordAndSize(this.boundingBox);
  }

  getInfilledBoundingBoxPosition() {
    return this._computeBoundingBoxCoordAndSize(this.infillBoundingBox);
  }

  _computeBoundingBoxCoordAndSize(boundingBox): [TwoDimensionArray, TwoDimensionArray] {
    const boundingBoxCenter = getBoundingBoxCenter(boundingBox);
    const boundingBoxSize = getBoundingBoxSize(boundingBox);

    const objectBottomCoord: TwoDimensionArray = [boundingBoxCenter[0], boundingBox[3]];
    const objectScreenRatio: TwoDimensionArray = [boundingBoxSize[0], boundingBoxSize[1]];
    return [objectBottomCoord, objectScreenRatio];
  }

  updateImage(imageUrl, maskUrl = null) {
    return new Promise<void>((resolve) => {
      const labels = ['img'];
      const urls = [imageUrl];
      if (maskUrl) {
        labels.push('mask');
        urls.push(maskUrl);
      }
      loadImages(labels, urls).then((images) => {
        this.image = images['img'];
        if (images['mask']) {
          this._setMask(images['mask']);
        }
        resolve();
      });
    });
  }

  isDecomposed() {
    return !!this.normals || !!this.albedo;
  }

  get isInfillObject() {
    return !!this.infillFragment;
  }

  get objectID() {
    return this.apiObject.id;
  }

  get resolution() {
    return this.apiObject.resolution;
  }

  get lightingParams() {
    if (!this.prop.lighting) return undefined;
    const lightParamsR = [...this.prop.lighting];
    const lightParamsG = [...this.prop.lighting];
    const lightParamsB = [...this.prop.lighting];

    lightParamsR[0] *= this.prop.ambientColor[0];
    lightParamsG[0] *= this.prop.ambientColor[1];
    lightParamsB[0] *= this.prop.ambientColor[2];

    return [lightParamsR, lightParamsG, lightParamsB];
  }

  get ambient() {
    return this.prop.lighting[0];
  }

  static computeAmbiant(lightingParam) {
    return lightingParam[0];
  }

  get lightIntensity() {
    return DepixObject.computeLightIntensity(this.prop.lighting);
  }

  static computeLightIntensity(lightingParam) {
    const x = lightingParam[1];
    const y = lightingParam[2];
    const z = lightingParam[3];
    return Math.sqrt(x * x + y * y + z * z);
  }

  get lightAzimuth() {
    if (!this.prop.lighting) return undefined;
    const y = this.prop.lighting[2];
    const z = this.prop.lighting[3];
    return Math.atan2(-y, -z);
  }

  get lightElevation() {
    if (!this.prop.lighting) return undefined;
    const x = this.prop.lighting[1];
    const r = this.lightIntensity;
    return Math.acos(x / (r + 0.0000001));
  }

  setLight(azimuth, elevation, intensity) {
    const [x, y, z] = angular2world(azimuth, elevation, intensity);
    this.prop.lighting[1] = x;
    this.prop.lighting[2] = y;
    this.prop.lighting[3] = z;
  }

  setAmbient(ambient) {
    if (!this.prop.lighting) return;
    this.prop.lighting[0] = ambient;
  }

  hasSameImages(other: DepixObject) {
    return _.isEqual(other.serialize().apiObject, this.serialize().apiObject);
  }
}

// Front facing light
export const DEFAULT_LIGHTING = [0.8, 0.35, 0.55, -0.25];

export type ShadowState = 1 | 2;
export const SHADOW_STATE: { [p: string]: ShadowState } = { FLAT: 1, GROUND: 2 };

export const DEFAULT_PROPERTIES: DepixObjectProperties = {
  blur: 0,
  shadowVisible: false,
  shadowState: SHADOW_STATE.GROUND,
  shadowDiffusion: 0.3,
  shadowOpacity: 0.5,
  relight: false,
  lowResidualAlpha: 1,
  highResidualAlpha: 1,
  ambientColor: [1, 1, 1] as AmbientColor,
  lighting: _.cloneDeep(DEFAULT_LIGHTING) as Lighting,
};

export type MaskOption = 'none' | 'mask' | 'originalMask';

export const MaskOption: { [p: string]: MaskOption } = {
  NONE: 'none',
  MASK: 'mask',
  ORIGINAL_MASK: 'originalMask',
};
