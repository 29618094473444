/*

You can edit this manually to add all supertypes, or you can get it generated.

To generate it, start the stack, open the website and use this in the console :

fetch(`http://localhost/graphql`, {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify({
    variables: {},
    query: `
      {
        __schema {
          types {
            kind
            name
            possibleTypes {
              name
            }
          }
        }
      }
    `,
  }),
}).then(result => result.json())
  .then(result => {
    const possibleTypes = {};

    result.data.__schema.types.forEach(supertype => {
      if (supertype.possibleTypes) {
        possibleTypes[supertype.name] =
          supertype.possibleTypes.map(subtype => subtype.name);
      }
    });
    console.log("Here is what you need to copy: ")
    console.log(JSON.stringify(possibleTypes))
  });

 */

export const GRAPHQL_POSSIBLE_TYPES = { SubscriptionResponse: ['Subscription', 'ResolverError'] };
