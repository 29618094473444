import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { styled, useTheme } from '@mui/material/styles';

import CustomPicker from 'react-color/lib/Custom';
import EditableInput from 'react-color/lib/components/common/EditableInput';
import Saturation from 'react-color/lib/components/common/Saturation';
import Hue from 'react-color/lib/components/hue/Hue';
import Alpha from 'react-color/lib/components/common/Alpha';
import CirclePicker from 'react-color/lib/components/circle/Circle';
import * as color from 'react-color/lib/helpers/color';

import ColorToolIcon from '@components/Ui/Icons/ColorToolIcon';
import { rgbaToColorString } from '@libs/ImgUtils';
import Surface from '@components/Ui/Surface';
import * as materialColors from '@mui/material/colors';

const Pointer = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: `4px solid ${theme.palette.secondary.main}`,
  borderRadius: theme.borderRadius.small,
  height: theme.borderRadius.small * 2,
  width: theme.borderRadius.small * 2,
  transform: `translate(-50%, -50%)`,
}));

const InlinePointer = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: `4px solid ${theme.palette.secondary.main}`,
  borderRadius: theme.borderRadius.small,
  height: theme.borderRadius.small * 2,
  width: theme.borderRadius.small * 2,
  transform: `translate(-50%, -2px)`,
}));

const StyledCirclePicker = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  ['> .circle-picker span div span div']: {
    border: `1px solid ${theme.palette.secondary.grey}`,
  },
}));

const CIRCLE_COLOR_LIST = [
  'transparent',
  '#ffffff',
  materialColors.red['500'],
  materialColors.pink['500'],
  materialColors.purple['500'],
  materialColors.deepPurple['500'],
  materialColors.indigo['500'],
  materialColors.blue['500'],
  materialColors.lightBlue['500'],
  materialColors.teal['500'],
  materialColors.green['500'],
  materialColors.lightGreen['500'],
  materialColors.lime['500'],
  materialColors.yellow['500'],
  materialColors.amber['500'],
  materialColors.orange['500'],
  materialColors.deepOrange['500'],
  materialColors.brown['500'],
];

const ColorPicker = ({
  open,
  defaultColor = { rgb: { r: 255, g: 255, b: 255, a: 1 } },
  sx,
  onClickAway,
  activated,
  placement = 'top',
  ...props
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const currentColor = color.toState(props.color?.rgb || defaultColor);
  const [hex, setHex] = useState(currentColor.hex);
  delete props.onChangeComplete;

  let buttonRef = useRef(null);

  useEffect(() => {
    setAnchorEl(buttonRef?.current);
  }, [buttonRef]);

  const handleSaturationChange = (color) => {
    props.onChange(color);
  };

  const handleHueChange = (color) => {
    props.onChange(color);
  };

  const handleAlphaChange = (color) => {
    props.onChange(color);
  };

  const handleHexChange = () => {
    const hexValue = hex instanceof String ? hex : Object.values(hex)[0];
    if (color.isValidHex(hexValue)) {
      props.onChange({ hex: hexValue, source: 'hex' });
    }
  };

  const handleHexKeyDown = (e) => {
    if (e.key == 'Enter') {
      handleHexChange();
    }
  };

  const handleClickAway = () => {
    if (onClickAway) {
      onClickAway();
    }
  };

  return (
    <Box
      onMouseEnter={(e) => e.stopPropagation()}
      sx={{ position: 'relative', overflow: 'visible' }}
      role="colorPicker"
      aria-label={t('colorPicker.component')}>
      {open && (
        <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown" touchEvent="onTouchStart">
          <Popper placement={placement} disablePortal={false} open={!!anchorEl} anchorEl={anchorEl}>
            <Surface
              radius="small"
              sx={{ m: 2, backgroundColor: (theme) => theme.palette.secondary.main, p: 2 }}
              onClick={(e) => e.stopPropagation()}
              role="colorPicker"
              aria-label={t('colorPicker.panel')}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ position: 'relative', width: '100%', height: 100 }}>
                  <Saturation
                    {...props}
                    color={currentColor}
                    pointer={Pointer}
                    style={{ color: { border: `2px solid ${theme.palette.secondary.light}` } }}
                    radius={theme.borderRadius.small}
                    onChange={handleSaturationChange}
                  />
                </Box>

                <Hue
                  {...props}
                  color={currentColor}
                  pointer={InlinePointer}
                  styles={{
                    default: {
                      picker: {
                        margin: theme.spacing(2, 0, 0),
                        border: `2px solid ${theme.palette.secondary.light}`,
                        borderRadius: theme.borderRadius.small,
                      },
                      hue: { radius: theme.borderRadius.small },
                    },
                  }}
                  width="100%"
                  height={theme.borderRadius.small * 2}
                  onChange={handleHueChange}
                />

                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    height: theme.borderRadius.small * 2,
                    margin: theme.spacing(2, 0),
                  }}>
                  <Alpha
                    {...props}
                    rgb={currentColor.rgb}
                    hsl={currentColor.hsl}
                    pointer={InlinePointer}
                    style={{
                      alpha: {
                        border: `2px solid ${theme.palette.secondary.light}`,
                        borderRadius: theme.borderRadius.small,
                      },
                    }}
                    radius={theme.borderRadius.small}
                    width="100%"
                    height={theme.borderRadius.small * 2}
                    onChange={handleAlphaChange}
                  />
                </Box>

                <Box onBlur={handleHexChange} onKeyDown={handleHexKeyDown}>
                  <EditableInput
                    value={currentColor.hex}
                    label={t('general.color.hex')}
                    onChange={setHex}
                    style={{
                      wrap: { display: 'flex', flexDirection: 'column-reverse' },
                      label: { textTransform: 'uppercase', fontWeight: 500 },
                    }}
                  />
                </Box>

                <StyledCirclePicker>
                  <CirclePicker colors={CIRCLE_COLOR_LIST} onChangeComplete={props.onChange} />
                </StyledCirclePicker>
              </Box>
            </Surface>
          </Popper>
        </ClickAwayListener>
      )}

      <ColorToolIcon
        ref={buttonRef}
        sx={sx}
        color={props.color?.rgb ? rgbaToColorString(props.color.rgb) : rgbaToColorString(defaultColor.rgb)}
        activated={activated}
      />
    </Box>
  );
};

const ColorShape = PropTypes.shape({
  rgb: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
    a: PropTypes.number,
  }),
  hex: PropTypes.string,
});

ColorPicker.propTypes = {
  open: PropTypes.bool,
  sx: PropTypes.object,
  color: ColorShape,
  defaultColor: ColorShape,
  onChangeComplete: PropTypes.func,
  onClickAway: PropTypes.func,
  activated: PropTypes.bool,
  placement: PropTypes.oneOf(['top', 'left']),
};

export default CustomPicker(ColorPicker);
