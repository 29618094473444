/* eslint-disable max-len */
import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SuccessIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 52 52">
    <path d="M52 16.0952L36.7082 14.7581L30.7285 0L24.7489 14.7581L9.45709 16.0952L21.0382 26.6686L17.5875 42.3429L30.7285 34.0229L43.8696 42.3429L40.3952 26.6686L52 16.0952ZM35.7864 27.8076L36.7318 32.0419L33.1629 29.7886L30.7285 28.2533L28.2941 29.7886L24.7253 32.0419L25.647 27.8076L26.3088 24.8857L24.158 22.9295L20.9909 20.0571L25.1507 19.6857L27.9869 19.4381L29.0977 16.6895L30.7285 12.6781L32.3594 16.6895L33.4702 19.4381L36.3064 19.6857L40.4425 20.0571L37.2991 22.9295L35.1246 24.8857L35.7864 27.8076ZM0.381267 27.2381C-0.325418 26 -0.0228908 24.4895 1.06668 23.7467L7.51902 19.2895L11.2297 22.6571L3.66652 27.8819C3.26473 28.1543 2.81567 28.3029 2.3666 28.3029C1.61028 28.3029 0.853966 27.9067 0.381267 27.2381V27.2381ZM1.06668 47.4438L14.9168 37.9105L14.1841 41.501L13.3805 44.8933L3.66652 51.6038C3.26473 51.8762 2.81567 52 2.3666 52C1.61028 52 0.853966 51.6038 0.381267 50.8857C0.0420663 50.3324 -0.0761646 49.6616 0.052031 49.0179C0.180227 48.3741 0.544595 47.8089 1.06668 47.4438V47.4438ZM14.9404 26L17.1148 28.0057L16.4767 30.9524L3.66652 39.7429C3.26473 40.0152 2.81567 40.139 2.3666 40.139C1.61028 40.139 0.853966 39.7676 0.381267 39.0248C0.0414453 38.4759 -0.0772128 37.8083 0.0512142 37.1681C0.179641 36.5278 0.544714 35.9668 1.06668 35.6076L14.9404 26V26Z" />
  </SvgIcon>
);

export default SuccessIcon;
