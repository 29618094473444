import React, { useEffect, useState } from 'react';
import Loading from '@components/Loading/Loading';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { RetryDialog } from '@components/ProtectedRoute/RetryDialog';

const A_BIT_TOO_LONG_WAIT_TIME = 5000; // ms
const SOMETHING_WRONG_WAIT_TIME = 10000; // ms

enum FirstProfileLoadingState {
  LOADING_STARTED,
  A_BIT_TOO_LONG,
  SOMETHING_IS_WRONG,
}

interface PageLoadingScreenProps {
  loading?: boolean;
  error: boolean;
}

export const PageLoadingScreen = ({ loading = true, error }: PageLoadingScreenProps) => {
  const { t } = useTranslation();
  const [firstProfileLoadingState, setFirstProfileLoadingState] = useState(FirstProfileLoadingState.LOADING_STARTED);
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>(null);

  const loadingMessageComponent = <LoadingMessage> {t('profile.loading.longLoading')}</LoadingMessage>;
  const showLoading =
    firstProfileLoadingState == FirstProfileLoadingState.LOADING_STARTED ||
    firstProfileLoadingState == FirstProfileLoadingState.A_BIT_TOO_LONG;
  const showMessage = firstProfileLoadingState == FirstProfileLoadingState.A_BIT_TOO_LONG;
  const showDialog = firstProfileLoadingState == FirstProfileLoadingState.SOMETHING_IS_WRONG;

  const clearTimer = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  const startTimeout = () => {
    setTimeoutId(
      setTimeout(() => {
        setFirstProfileLoadingState(FirstProfileLoadingState.A_BIT_TOO_LONG);
        setTimeoutId(
          setTimeout(() => {
            setFirstProfileLoadingState(FirstProfileLoadingState.SOMETHING_IS_WRONG);
          }, SOMETHING_WRONG_WAIT_TIME)
        );
      }, A_BIT_TOO_LONG_WAIT_TIME)
    );
  };

  useEffect(() => {
    if (error) {
      setFirstProfileLoadingState(FirstProfileLoadingState.SOMETHING_IS_WRONG);
    }
  }, [error]);

  useEffect(() => {
    startTimeout();

    return clearTimer;
  }, []);

  return (
    <>
      {showLoading && (
        <Loading global blur={2} loadingMessageComponent={showMessage ? loadingMessageComponent : null} />
      )}
      <RetryDialog open={showDialog} />
    </>
  );
};

const LoadingMessage = styled(Typography)`
  font-weight: 600;

  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: 22px;
  }
`;
