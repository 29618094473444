import ToolBar from '@components/Viewport/ToolBar';
import BackgroundToolbar from '@components/Viewport/Paste/BackgroundToolbar';
import React from 'react';
import { styled } from '@mui/material/styles';

export const ComposeSideBar = () => {
  return (
    <StyledToolbar vertical>
      <BackgroundToolbar vertical />
    </StyledToolbar>
  );
};

const StyledToolbar = styled(ToolBar)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 26px;
`;
