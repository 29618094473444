import { ButtonProps, FormControlLabel, InputAdornment, InputAdornmentProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@components/Ui/Button';
import React, { useEffect, useState } from 'react';
import { BorderRadius } from '@root/theme';
import { ReCaptchaTerms } from '@pages/Auth/ReCaptchaTerms';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';
import { useAuthV2 } from '@contexts/AuthV2';
import useInstrumentation from '@hooks/UseInstrumentation';
import { Path, useLocation, useNavigate } from 'react-router-dom';
import Routes from '@root/routes';
import { SignInForm } from '@pages/Auth/SignInForm';
import { Link } from '@components/Ui/Link';
import { CreateAccountForm } from '@pages/Auth/CreateAccountForm';
import { ForgotPasswordForm } from '@pages/Auth/ForgotPasswordForm';
import { AFTER_LOGIN_REDIRECT_STATE } from '@pages/Auth/AuthDialogV2';
import { EmailOutlined, LockOutlined } from '@mui/icons-material';

export const AuthTitle = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: 500;

  ${(props) => props.theme.breakpoints.up('sm')} and (min-height: 760px) {
    margin-bottom: ${(props) => props.theme.spacing(6)};
  }

  ${(props) => props.theme.breakpoints.down('sm')} and (min-height: 600px) {
    margin-bottom: ${(props) => props.theme.spacing(6)};
  }
`;

export const AuthField = styled(TextField)`
  width: 100%;

  > .MuiOutlinedInput-root {
    border-radius: ${(props) => props.theme.borderRadius.large}px;

    > .MuiOutlinedInput-input {
      padding: ${(props) => props.theme.spacing(2, 3)};

      ${(props) => props.theme.breakpoints.down('sm')} {
        padding: ${(props) => props.theme.spacing(1.5, 3)};
      }
    }
  }

  & .MuiInputLabel-root {
    ${(props) => props.theme.breakpoints.down('sm')} {
      line-height: 1.1rem;
      font-size: 0.9rem;
    }
  }
`;

export const AuthSubmitButton = ({ children, ...props }: ButtonProps) => (
  <StyledButton variant="contained" {...props}>
    {children}
  </StyledButton>
);

const StyledButton = styled(Button)`
  width: 100%;
  padding: ${(props) => props.theme.spacing(2.5, 4)};
  margin: ${(props) => props.theme.spacing(2, 0)};
  text-transform: none;
  font-size: 1rem;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(1.75, 4)};
  }
`;

export const AuthFormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing(2)};
  height: 100%;

  ${(props) => props.theme.breakpoints.down('sm')} {
    gap: ${(props) => props.theme.spacing(1)};
  }

  ${(props) => props.theme.breakpoints.down('sm')} and (min-height: 600px) {
    gap: ${(props) => props.theme.spacing(2)};
  }
`;

export const AuthFormErrorMessage = styled(Typography)`
  color: ${(props) => props.theme.palette.error.main};
  max-width: fit-content;
  text-align: center;
`;

export const AuthFormSuccessMessage = styled(Typography)`
  color: ${(props) => props.theme.palette.success.main};
  max-width: fit-content;
  text-align: center;
`;

export const AuthFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  margin: 0;
  padding: ${(props) => props.theme.spacing(0, 1)};
  text-align: left;

  & span.MuiFormControlLabel-label {
    ${(props) => props.theme.breakpoints.down('sm')} {
      font-size: 0.85em;
    }
  }
`;

interface AuthFormProps {
  currentForm?: FormState;
  onChangeForm?: (form: FormState) => void;
  compact?: boolean;
}

export type FormState = 'login' | 'signup' | 'forgot';

export const AuthForm = ({ currentForm = 'signup', onChangeForm = (form) => {}, compact = false }: AuthFormProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'auth.v2.page' });
  const {
    signup,
    login,
    loginError,
    signUpError,
    isLoading,
    resetPassword,
    resetPasswordError,
    requiresVerification,
    resendVerificationEmail,
    isAuthenticated,
  } = useAuthV2();
  const instrumentation = useInstrumentation();

  const [prefillEmail, setPrefillEmail] = useState<string | null>(null);
  const [formState, setFormState] = useState<FormState>(currentForm);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      const state: any = location.state as any;
      const backgroundLocation = state?.backgroundLocation as Path | undefined;
      const afterLoginRedirect = state ? state[AFTER_LOGIN_REDIRECT_STATE] : undefined;
      navigate(afterLoginRedirect || backgroundLocation || Routes.home(), { replace: true });
    }
  }, [navigate, isAuthenticated]);

  const onSetCreatingAccount = (e: MouseEvent, isCreatingAccount: boolean) => {
    e.preventDefault();

    if (isCreatingAccount) {
      instrumentation.accountCreationStarted();
      setFormState('signup');
      onChangeForm('signup');
    } else {
      instrumentation.accountCreationCancelled();
      setFormState('login');
      onChangeForm('login');
    }
  };

  const onForgotPassword = (forgotPassword: boolean, usernameOrEmail: string) => {
    if (forgotPassword) {
      instrumentation.forgotPasswordStarted();
      setPrefillEmail(usernameOrEmail);
      setFormState('forgot');
      onChangeForm('forgot');
    } else {
      setPrefillEmail(usernameOrEmail);
      setFormState('login');
      onChangeForm('login');
    }
  };

  const onSignIn = async (usernameOrEmail: string, password: string) => {
    await login(usernameOrEmail, password);
  };

  const onSendResetEmail = async (usernameOrEmail: string): Promise<void> => {
    await resetPassword(usernameOrEmail);
  };

  const onAccountCreation = async (
    email: string,
    password: string,
    agreeTerms: boolean,
    consentNewsletter: boolean
  ) => {
    const response = await signup(email, password, agreeTerms, consentNewsletter);
    if (!response.errors) {
      setPrefillEmail(email);
      setFormState('login');
      onChangeForm('login');
    }
  };

  useEffect(() => {
    setFormState(currentForm);
  }, [currentForm]);

  return (
    <Wrapper>
      <AuthForms compact={compact}>
        {formState == 'login' && (
          <FormWrapper>
            <SignInForm
              onSignIn={onSignIn}
              onForgotPassword={onForgotPassword}
              error={loginError}
              isLoading={isLoading}
              prefillEmail={prefillEmail}
              requiresVerification={requiresVerification}
              onResendVerificationEmail={resendVerificationEmail}
            />

            <FormSwitchText>
              {t('no_account')} <Link onClick={(e) => onSetCreatingAccount(e, true)}>{t('create_account_link')}</Link>
            </FormSwitchText>
          </FormWrapper>
        )}

        {formState == 'signup' && (
          <FormWrapper>
            <CreateAccountForm onAccountCreation={onAccountCreation} error={signUpError} isLoading={isLoading} />

            <FormSwitchText>
              {t('already_account')} <Link onClick={(e) => onSetCreatingAccount(e, false)}>{t('sign_in_link')}</Link>
            </FormSwitchText>
          </FormWrapper>
        )}

        {formState == 'forgot' && (
          <FormWrapper>
            <ForgotPasswordForm
              onBackToLogin={onForgotPassword}
              onSendResetEmail={onSendResetEmail}
              prefillEmail={prefillEmail}
              error={resetPasswordError}
              isLoading={isLoading}
            />
          </FormWrapper>
        )}

        <StyledReCaptchaTerms />
      </AuthForms>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  border-radius: ${(props) => props.theme.borderRadius[BorderRadius.DEFAULT]}px;
  overflow: auto;
  max-width: 500px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    max-width: 350px;
  }
`;

interface AuthFormsProps {
  compact: boolean;
}

const notForwardedProps: PropertyKey[] = ['compact'];
const AuthForms = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => !notForwardedProps.includes(propName),
})<AuthFormsProps>`
  width: 100%;
  padding: ${(props) => props.theme.spacing(4, 8)};
  overflow-y: auto;

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: ${(props) => props.theme.spacing(4)};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(2)};
  }
`;

const FormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.spacing(1)};
  animation: slide-in 0.3s forwards;
  transform: translateX(100%);

  @keyframes slide-in {
    100% {
      transform: translateX(0%);
    }
  }
`;

const StyledReCaptchaTerms = styled(ReCaptchaTerms)`
  margin-top: ${(props) => props.theme.spacing(2)};
`;

const FormSwitchText = styled(Typography)`
  text-align: center;

  ${(props) => props.theme.breakpoints.up('sm')} and (min-height: 760px) {
    margin: ${(props) => props.theme.spacing(6, 0)};
  }

  ${(props) => props.theme.breakpoints.down('sm')} and (min-height: 670px) {
    margin: ${(props) => props.theme.spacing(4, 0)};
  }

  ${(props) => props.theme.breakpoints.down('sm')} and (min-height: 760px) {
    margin: ${(props) => props.theme.spacing(6, 0)};
  }
`;

interface PasswordAdornmentProps extends InputAdornmentProps {}

export const PasswordAdornment = (props: PasswordAdornmentProps) => {
  return (
    <StyledInputAdornment {...props}>
      <LockOutlined />
    </StyledInputAdornment>
  );
};

interface EmailAdornmentProps extends InputAdornmentProps {}

export const EmailAdornment = (props: EmailAdornmentProps) => {
  return (
    <StyledInputAdornment {...props}>
      <EmailOutlined />
    </StyledInputAdornment>
  );
};

const StyledInputAdornment = styled(InputAdornment)`
  color: ${(props) => props.theme.palette.secondary.medium};
`;
