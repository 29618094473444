import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import { BorderRadius } from '@root/theme';

const ImageMasonry = ({ cols = 2, gap = 8, images, onSelectImage, onDragImage, sx }) => {
  return (
    <Box sx={{ px: 2, ...sx }}>
      <ImageList variant="masonry" cols={cols} gap={gap} sx={{ margin: 0 }}>
        {images &&
          images.map((image) => {
            return (
              <ImageListItem
                key={image.id}
                sx={{
                  borderRadius: (theme) => theme.borderRadius[BorderRadius.XSMALL],
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}>
                <img
                  src={image.url}
                  alt={image.description}
                  loading="lazy"
                  style={{ width: '248px', maxWidth: '100%' }}
                  onClick={() => onSelectImage(image.id)}
                  draggable="true"
                  onDragStart={(e) => onDragImage(e, image.id)}
                />
                <ImageListItemBar title={image.title ?? ''} subtitle={image.subtitle ?? ''} />
              </ImageListItem>
            );
          })}
      </ImageList>
    </Box>
  );
};

ImageMasonry.propTypes = {
  cols: PropTypes.number,
  gap: PropTypes.number,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
      description: PropTypes.string,
      subtitle: PropTypes.element,
    })
  ),
  onSelectImage: PropTypes.func.isRequired,
  onDragImage: PropTypes.func.isRequired,
  sx: PropTypes.object,
};

export default ImageMasonry;
