import Vector3D from '../../Libs/Geometry/Vector3D';
import { getGlobalPosition } from './FabricUtils';

fabric.ObjectPointer = fabric.util.createClass(fabric.Group, {
  type: 'objectPointer',

  initialize: function (options) {
    options = options || {};
    this.pointerRadius = 5;
    this.groundPoint = new fabric.Circle({
      radius: this.pointerRadius,
      fill: options.color,
      top: options.top,
      left: options.left,
    });

    this.groundLine = new fabric.Line([0, 0, 1, 1], {
      fill: 'black',
      stroke: 'black',
      strokeWidth: 1,
      strokeDashArray: [5, 5],
    });

    this.callSuper('initialize', [this.groundPoint, this.groundLine], options);

    this.color = options.color;
    this.name = options.name;

    this.hasBorders = false;
    this.hasControls = false;
    this.selectable = false;
    this.perPixelTargetFind = true;
    this.visible = false;
  },

  update: function (id, scene, fabricCtx) {
    const pose = scene.getComponentPosition(id);
    const groundPose = new Vector3D(pose.x, 0, pose.z);
    // set Ground Position Point
    const globalObjectCoord = getGlobalPosition(scene.worldToImage(pose), fabricCtx.background);
    const globalGroundCoord = getGlobalPosition(scene.worldToImage(groundPose), fabricCtx.background);
    this._refreshSizeAndPose(globalObjectCoord, globalGroundCoord);
  },

  _refreshSizeAndPose: function (objectCoord, groundCoord) {
    this._objects = [];
    this._restoreObjectsState();

    this.groundLine.set({
      x1: groundCoord.x,
      y1: groundCoord.y,
      x2: objectCoord.x,
      y2: objectCoord.y,
    });
    this.addWithUpdate(this.groundLine);
    this.groundPoint.top = groundCoord.y - this.pointerRadius;
    this.groundPoint.left = groundCoord.x - this.pointerRadius;
    this.addWithUpdate(this.groundPoint);
  },
});
