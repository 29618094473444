import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ToolButton from '@components/Viewport/ToolButton';
import DownloadIcon from '@components/Ui/Icons/DownloadIcon';
import { DownloadImageDialog } from '@root/Components/Download/DownloadImageDialog';

import useInstrumentation from '@hooks/UseInstrumentation';
import useQueryObjects from '@hooks/useQueryObjects';
import useFabric from '@hooks/Fabric';
import { useAlert } from '@hooks/Alert';
import { Feature, useFeatureFlag } from '@hooks/FeatureFlag';

import { useSegmentation } from '@contexts/Segmentation/UseSegmentation';

import { downloadImage } from '@libs/DownloadImage';
import { EXTRACT_PANEL_NAME } from '@libs/ExtractPanelName';
import { resizeFile, sizeFromMaximum } from '@libs/ImgUtils';

const MAX_MASK_SIZE = 612;
export const IMAGE_TAG = 'depix-mask';
export const PREVIEW_IMAGE_TAG = IMAGE_TAG + '-preview';

interface DownloadMaskButtonProps {
  name: string;
  toolTip?: string;
  disabled?: boolean;
  buttonText?: string;
  inline?: boolean;
}

const DownloadMaskButton = (props: DownloadMaskButtonProps) => {
  const { t } = useTranslation();
  const segmentation = useSegmentation();
  const instrumentation = useInstrumentation();
  const alert = useAlert();
  const feature = useFeatureFlag();
  const canUseHighResolution = feature.canUseFeature(Feature.HIGH_RESOLUTION);
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const fabric = useFabric();

  const onCloseDialog = () => {
    setShowDownloadDialog(false);
  };

  const onSkipDialog = () => {
    instrumentation.downloadDialogSkipped(EXTRACT_PANEL_NAME);
    onCloseDialog();
  };

  const onShowDialog = () => {
    setShowDownloadDialog(true);
  };

  const download = (image) => {
    if (canUseHighResolution) {
      downloadImage(image, IMAGE_TAG);
    } else {
      resizeFile(image.file, MAX_MASK_SIZE).then((image) => {
        downloadImage({ file: image }, PREVIEW_IMAGE_TAG);
      });
    }
  };

  const getObjectRequestCallback = (object) => {
    if (object.length === 0) return;
    object[0]
      .generateImage()
      .then((image) => {
        download(image);
        onCloseDialog();
        instrumentation.backgroundRemoved(segmentation.segmentationObjectID);
      })
      .catch(() => {
        alert.error(t('error.images.cannotDownload'), t('error.generic.title'));
      });
  };

  const { queryObjects } = useQueryObjects(getObjectRequestCallback);

  const downloadPNG = (isPreview = false) => {
    queryObjects(
      [segmentation.segmentationObjectID],
      isPreview,
      {
        withImage: true,
        withMask: true,
      },
      segmentation.maskId
    );

    instrumentation.imageDownloaded({
      panel: EXTRACT_PANEL_NAME,
      imageId: segmentation.segmentationObjectID,
      backgroundType: 'mask',
      highResolution: !isPreview,
    });
  };

  const resizeBackgroundResolution = ({ width, height }, maxSize) => {
    const newSize = sizeFromMaximum([width, height], maxSize);
    return { width: newSize[0], height: newSize[1] };
  };

  return (
    <>
      {!canUseHighResolution && (
        <DownloadImageDialog
          open={showDownloadDialog}
          onDownloadLowResolution={() => downloadPNG(true)}
          onClose={onCloseDialog}
          onSkip={onSkipDialog}
          lowResolution={resizeBackgroundResolution(fabric.getBackgroundResolution(true), MAX_MASK_SIZE)}
          highResolution={fabric.getBackgroundResolution(false)}
        />
      )}
      <ToolButton onClick={() => (canUseHighResolution ? downloadPNG() : onShowDialog())} {...props}>
        <DownloadIcon />
      </ToolButton>
    </>
  );
};

export default DownloadMaskButton;
