import React, { useCallback, useState } from 'react';

import CutCanvas from '../Canvas/CutCanvas';

import Panel from './Panel';
import PanelContent from './PanelContent';
import ExtractToolBar, { ExtractAction } from '@components/Viewport/ExtractToolBar';
import CutActionButton from '@components/Viewport/Cut/CutActionButton';
import PanelFooter, { FooterHeight } from '@components/Viewport/PanelFooter';
import PasteButton from '@components/Viewport/Cut/PasteButton';

import useFabric from '@hooks/Fabric';
import { UploadImageProvider } from '@contexts/UploadImageContext';
import { Feature, useFeatureFlag } from '@hooks/FeatureFlag';
import { useSegmentation } from '@contexts/Segmentation/UseSegmentation';

import { DepixBackgroundObject } from '@libs/DepixBackgroundObject';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { EXTRACT_PANEL_NAME } from '@libs/ExtractPanelName';
import { EditorPanel } from '@components/Viewport/EditorPanel';

interface ExtractPanelProps {
  className?: string;
  value: string;
  width: string;
  visible: boolean;
  includeTextInToolbar: boolean;
  onExtract: () => void;
}

const ExtractPanel = ({ value, width, visible, onExtract, className, includeTextInToolbar }: ExtractPanelProps) => {
  const fabric = useFabric();
  const segmentation = useSegmentation();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const [isToolbarHidden, setIsToolbarHidden] = useState(true);

  const loadObject = async (depixObject) => {
    fabric.clear();
    const backgroundObject = new DepixBackgroundObject(depixObject);
    fabric.setupBackground(backgroundObject, depixObject.objectID).then(() => {
      segmentation.changeSegmentedObject(depixObject);
    });
  };

  return (
    <UploadImageProvider onImageReady={loadObject} isPreview={true} autoSegment={true}>
      <EditorPanel
        name={EXTRACT_PANEL_NAME}
        value={value}
        width={width}
        visible={visible}
        fullHeight
        className={className}>
        <PanelContent className="cut_canvas">
          <CutCanvas />
        </PanelContent>

        <PanelFooter
          leftFabComponent={<CutActionButton showLabel={isToolbarHidden} />}
          mainComponent={
            <StyledExtractToolBar
              onStateChange={setIsToolbarHidden}
              onExtract={onExtract}
              isSmall={isSmallDevice}
              includeText={includeTextInToolbar}
            />
          }
          rightFabComponent={<PasteButton onPaste={onExtract} />}
        />
      </EditorPanel>
    </UploadImageProvider>
  );
};

const notForwardedProps: PropertyKey[] = ['isSmall'];

const StyledExtractToolBar = styled(ExtractToolBar, {
  shouldForwardProp: (propName: PropertyKey) => !notForwardedProps.includes(propName),
})<{ isSmall: boolean }>`
  height: ${(props) => FooterHeight[props.isSmall ? 'Small' : 'Normal']}px;
`;

export default ExtractPanel;
