import { gql } from '@api';

export const LOGIN_QUERY_NAME = 'login';
export const SIGNUP_QUERY_NAME = 'createUser';
export const RESEND_VERIFICATION_EMAIL_QUERY_NAME = 'resendVerificationEmail';

export const CREATE_GUEST_USER = gql(`
  mutation CreateGuestUser {
    createGuestUser {
      token
    }
  }
`);

export const UPLOAD_IMAGE = gql(`
  mutation NewObject ($image: Upload!, $isPreview: Boolean = true, $autoSegment: Boolean = false) {
    newObject(image: $image) {
      id
      originalMask(isPreview: $isPreview)
      image(isPreview: $isPreview)
      mask(isPreview: $isPreview, autoSegment: $autoSegment) {
        url
        id
      }
      resolution {
        width
        height
      }
    }
  }
`);

export const SEND_FEEDBACK = gql(`
  query SendFeedback ($image: Upload!, $comment: String) {
    sendFeedback(image: $image, comment: $comment)
  }
`);

export const CLONE_IMAGE = gql(`
  mutation CloneObject ($id: ID!, $isPreview: Boolean = true, $withInfillFragment: Boolean = false, $maskId: String) {
    cloneObject(id: $id, maskId: $maskId) {
      id
      image(isPreview: $isPreview)
      mask(isPreview: $isPreview) {
        url
        id
      }
      normals(isPreview: $isPreview)
      residualShading(isPreview: $isPreview)
      albedo(isPreview: $isPreview)
      lighting
      infillFragment(isPreview: $isPreview) @include(if: $withInfillFragment)
    }
  }
`);

export const OBJECTS = gql(`
  query GetObjects (
    $ids: [ID!]!
    $isPreview: Boolean = true
    $withId: Boolean = false
    $withImage: Boolean = false
    $withMask: Boolean = false
    $withOriginalMask: Boolean = false
    $withNormals: Boolean = false
    $withResidualShading: Boolean = false
    $withAlbedo: Boolean = false
    $withLighting: Boolean = false
    $maskId: String
  ) {
    objects(ids: $ids) {
      id @include(if: $withId)
      image(isPreview: $isPreview) @include(if: $withImage)
      mask(isPreview: $isPreview, maskId: $maskId) @include(if: $withMask) {
        url
        id
      }
      originalMask(isPreview: $isPreview) @include(if: $withOriginalMask)
      normals(isPreview: $isPreview) @include(if: $withNormals)
      residualShading(isPreview: $isPreview) @include(if: $withResidualShading)
      albedo(isPreview: $isPreview) @include(if: $withAlbedo)
      lighting @include(if: $withLighting)
    }
  }
`);

export const SEGMENT_OBJECT = gql(`
  mutation SegmentObject ($objectID: String!, $userInput: [SegmentationUserInput!]!, $maskID: String, $isPreview: Boolean = true) {
    applySegmentation(objectID: $objectID, userInput: $userInput, maskID: $maskID) {
      id
      mask(isPreview: $isPreview) {
        url
        id
      }
    }
  }
`);

export const DECOMPOSE_OBJECT = gql(`
  mutation DecomposeObject ($objectID: String!, $isPreview: Boolean = true) {
    imageDecomposition(objectID: $objectID) {
      id
      image(isPreview: $isPreview)
      mask(isPreview: $isPreview) {
        url
        id
      }
      normals(isPreview: $isPreview)
      residualShading(isPreview: $isPreview)
      albedo(isPreview: $isPreview)
      lighting
    }
  }
`);

export const DECOMPOSE_SCENES = gql(`
query DecomposeScenes ($ids: [ID!]!) {
  objects(ids: $ids) {
    cameraElevation
    sceneLighting {
      ambientRGB
      lightSources {
        radius
        positionXYZ
        intensity
      }
    }
  }
}
`);

export const QUERY_STOCK_IMAGES = gql(`
  query QueryStockImages ($keyword: String, $page: Int) {
    queryStockImages(keyword: $keyword, page: $page) {
      totalPages
      imageList {
        id
        apiName
        previewUrl
        width
        height
        downloadUrl
        description
        copyright {
          authorName
          authorLink
          sourceName
          sourceLink
        }
      }
    }
  }
`);

export const LOAD_STOCK_IMAGE = gql(`
  mutation LoadStockImage (
    $apiName: ApiName!
    $url: String!
    $maxSize: Int
    $isPreview: Boolean = true
    $autoSegment: Boolean = false
  ) {
    loadStockImage(apiName: $apiName, downloadUrl: $url, maxSize: $maxSize) {
      id
      image(isPreview: $isPreview)
      mask(isPreview: $isPreview, autoSegment: $autoSegment) {
        url
        id
      }
      resolution {
        width
        height
      }
    }
  }
`);

export const RESEND_VERIFICATION_EMAIL = gql(`
  query ResendVerificationEmail ($email: String!) {
    resendVerificationEmail(email: $email) {
      alreadyVerified
    }
  }
`);

export const USER_PROFILE_SUBSCRIPTIONS_FRAGMENT = gql(`
  fragment SubscriptionFields on SubscriptionResponse {
    __typename
    ... on ResolverError {
      message
    }
    ... on Subscription {
      enablesAccess
      updateUrl
      planId
      nextBillDate
      receiptUrl
      cancelUrl
      status
      pausedAt
      pausedFrom
      cancellationEffectiveDate
      checkoutId
      isInGracePeriod
    }
  }
`);

export const GET_USER_PROFILE = gql(`
  query GetUserProfile {
    userProfile {
      uploadUsage
      uploadQuota
      newUploadAt
      subscriptions {
        ...SubscriptionFields
      }
    }
  }
`);

// Subscriptions
export const LIST_USER_AVAILABLE_PLANS = gql(`
  query ListUserAvailablePlans ($currency: String = null) {
    userProfile {
      availablePlans(currency: $currency) {
        id
        name
        price
        billingType
        currency
        link
      }
    }
  }
`);

export const GET_SUBSCRIPTIONS = gql(`
  query GetSubscriptions {
    userProfile {
      subscriptions {
        ...SubscriptionFields
      }
    }
  }
`);

// AUTH
export const SIGN_UP = gql(`
  mutation createUser($email: String!, $password: String!, $agreeTerms: Boolean!, $consentNewsletter: Boolean!) {
    createUser(email: $email, password: $password, agreeTerms: $agreeTerms, consentNewsletter: $consentNewsletter) {
      ... on SuccessfulUserCreationResponse {
        id
        username
        email
        auth0UserId
      }
    }
  }
`);

export const LOGIN = gql(`
  mutation login($usernameOrEmail: String!, $password: String!) {
    login(usernameOrEmail: $usernameOrEmail, password: $password) {
      ... on SuccessfulLoginResponse {
        accessToken
        expiresIn
      }
    }
  }
`);

export const REFRESH_LOGIN = gql(`
  mutation refresh {
    refresh {
      ... on SuccessfulLoginResponse {
        accessToken
        expiresIn
      }
    }
  }
`);

export const USER_PROFILE_METADATA_FRAGMENT = gql(`
  fragment UserMetadataFields on UserMetadataResponse {
    __typename
    ... on ResolverError {
      message
    }
    ... on UserMetadata {
      audience
      goals
    }
  }
`);

export const GET_USER_METADATA = gql(`
  query GetUserMetadata {
    userProfile {
      metadata {
        ...UserMetadataFields
      }
    }
  }
`);

export const UPDATE_USER_AUDIENCE = gql(`
  mutation updateUserAudience($audience: AudienceType!) {
    updateUserAudience(audience: $audience)
  }
`);

export const UPDATE_USER_GOALS = gql(`
  mutation updateUserGoals($goals: [UserGoals!]!) {
    updateUserGoals(goals: $goals)
  }
`);

export const SHARE_IMAGE = gql(`
  mutation ShareImage ($image: Upload!, $title: String!, $description: String, $backgroundId: String!, $componentIds: [String!], $extension: String!) {
    shareImage(image: $image, title: $title, description: $description, backgroundId: $backgroundId, componentIds: $componentIds, extension: $extension) {
      ref
      imageUrl
    }
  }
`);

export const GET_SHARED_IMAGE = gql(`
  query GetSharedImage ($id: String!, $referrer: String = null) {
    sharedImage(id: $id, referrer: $referrer) {
      ref
      imageUrl
      title
      description
      viewCount
    }
  }
`);

export const GET_TRENDING_IMAGES = gql(`
  query GetTrendingImages ($limit: Int!, $exclude: String) {
    trendingImages(limit: $limit, exclude: $exclude) {
      ref
      imageUrl
      title
      description
      viewCount
    }
  }
`);
