import React, { MouseEvent, TouchEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Box, ClickAwayListener, Popper, useTheme } from '@mui/material';
import CropCornerIcon, { Corner } from '@components/Ui/Icons/CropCornerIcon';
import CropSideIcon, { Side } from '@components/Ui/Icons/CropSideIcon';
import { CropWindow } from '@contexts/CropToolContext';
import { ResolutionDisplay } from '@components/Crop/ResolutionDisplay';
import styled from '@emotion/styled';
import { EditMode } from '@components/Crop/CanvasCropTool';

const BORDER_THICKNESS = 10000;
const CLICK_OUTSIDE_BUFFER = 25;

enum Handle {
  TOP_LEFT = 'tl',
  TOP_RIGHT = 'tr',
  BOTTOM_LEFT = 'bl',
  BOTTOM_RIGHT = 'br',
  TOP_MID = 'mt',
  BOTTOM_MID = 'mb',
  LEFT_MID = 'ml',
  RIGHT_MID = 'mr',
  MOVE = 'move',
  NONE = 'none',
}

export const MIN_CROP_WINDOW_SIZE = 25;

export interface CropBoundingBox {
  width: number;
  height: number;
  x: string;
  y: string;
}

export interface CropOverlayProps {
  boundingBox: CropBoundingBox;
  cropWindow: CropWindow;
  onChange: (newWindow: CropWindow) => void;
  editMode: EditMode;
  onChangeEditMode: (editMode: EditMode) => void;
  onClickOutside: (newWindow: CropWindow) => void;
  resolution?: { width: number; height: number };
}

// CropWindow is WRT the boundingBox.x and boundingBox.y
const CropOverlay = ({
  boundingBox,
  cropWindow,
  onChange,
  editMode,
  onChangeEditMode,
  onClickOutside,
  resolution,
}: CropOverlayProps) => {
  const theme = useTheme();

  const [holdingHandle, setHoldingHandle] = useState(Handle.NONE);
  const [startingPoint, setStartingPoint] = useState(null);
  const [startingWindow, setStartingWindow] = useState(null);
  const [dimension, setDimension] = useState(null);
  const cropBoxRef = useRef(null);
  const topLeftRef = useRef(null);

  const getClickPosition = (event) => {
    let clickX: number, clickY: number;

    if (isTouchEvent(event)) {
      clickX = event.touches[0].clientX;
      clickY = event.touches[0].clientY;
    }

    if (isMouseEvent(event)) {
      clickX = event.clientX;
      clickY = event.clientY;
    }

    return [clickX, clickY];
  };

  const getOffsetCoordinates = (event) => {
    let offsetX: number, offsetY: number;

    if (isTouchEvent(event)) {
      const { left, top } = topLeftRef.current.getBoundingClientRect();
      offsetX = event.nativeEvent.changedTouches[0].pageX - left;
      offsetY = event.nativeEvent.changedTouches[0].pageY - top;
    }

    if (isMouseEvent(event)) {
      offsetX = event.nativeEvent.offsetX;
      offsetY = event.nativeEvent.offsetY;
    }
    return [offsetX, offsetY];
  };

  const isClickInsideCanvas = (event) => !!event.nativeEvent;

  const isClickInsideWindow = (event, cropWindow, boundingBox) => {
    if (!event.nativeEvent) {
      return false;
    }

    const [offsetX, offsetY] = getOffsetCoordinates(event);

    const clickXInsideWindow = _.inRange(offsetX, 0, cropWindow.width * boundingBox.width + CLICK_OUTSIDE_BUFFER);
    const clickYInsideWindow = _.inRange(offsetY, 0, cropWindow.height * boundingBox.height + CLICK_OUTSIDE_BUFFER);

    return clickXInsideWindow && clickYInsideWindow;
  };

  const handleStartMovement = useCallback(
    (handle: Handle, event: MouseEvent | TouchEvent) => {
      if (isClickInsideWindow(event, cropWindow, boundingBox)) {
        const [clickX, clickY] = getClickPosition(event);

        event.stopPropagation();
        setHoldingHandle(handle);
        setStartingPoint({ x: clickX, y: clickY });
        setStartingWindow(cropWindow);
      }
    },
    [cropWindow, boundingBox]
  );

  const handleMovementEvents = (event: MouseEvent | TouchEvent) => {
    if (holdingHandle === Handle.NONE) return;
    const [clickX, clickY] = getClickPosition(event);
    const newCropWindow = applyMovement(holdingHandle, boundingBox, startingPoint, startingWindow, clickX, clickY);
    onChange(newCropWindow);
  };

  const releaseHandle = useCallback(
    (event) => {
      if (
        holdingHandle === Handle.NONE &&
        isClickInsideCanvas(event) &&
        !isClickInsideWindow(event, cropWindow, boundingBox)
      ) {
        onClickOutside(cropWindow);
      }

      setHoldingHandle(Handle.NONE);
      setStartingPoint(null);
      setStartingWindow(null);
    },
    [cropWindow, boundingBox, holdingHandle]
  );

  const handleResolutionEdit = (width: number, height: number) => {
    let newCropWindow = { ...cropWindow };
    if (width) {
      if (width > resolution.width) {
        newCropWindow.width = 1;
      } else if (width > 0) {
        const notToolSmallWidth = width > MIN_CROP_WINDOW_SIZE ? width : MIN_CROP_WINDOW_SIZE;
        newCropWindow.width = notToolSmallWidth / resolution.width;
      }
    }
    if (height) {
      if (height > resolution.height) {
        newCropWindow.height = 1;
      } else if (height > 0) {
        const notToolSmallHeight = height > MIN_CROP_WINDOW_SIZE ? height : MIN_CROP_WINDOW_SIZE;
        newCropWindow.height = notToolSmallHeight / resolution.height;
      }
    }

    if (newCropWindow.x + newCropWindow.width > 1) {
      newCropWindow.x = 1 - newCropWindow.width;
    }
    if (newCropWindow.y + newCropWindow.height > 1) {
      newCropWindow.y = 1 - newCropWindow.height;
    }

    onChange(newCropWindow);
  };

  useLayoutEffect(() => {
    if (!resolution) {
      setDimension(null);
      return;
    }

    setDimension({
      width: Math.round(cropWindow.width * resolution.width),
      height: Math.round(cropWindow.height * resolution.height),
    });
  }, [cropWindow?.width, cropWindow?.height, resolution]);

  // Add events to prevent the default behavior of touch move events.
  useEffect(() => {
    if (cropBoxRef) {
      const cropBoxElement = cropBoxRef.current;
      const preventDefaultBehavior = (e) => e.preventDefault();

      cropBoxElement.addEventListener('touchmove', preventDefaultBehavior, { passive: false });
      cropBoxElement.addEventListener('touchforcechange', preventDefaultBehavior, { passive: false });
      return () => {
        cropBoxElement.removeEventListener('touchmove', preventDefaultBehavior);
        cropBoxElement.removeEventListener('touchforcechange', preventDefaultBehavior);
      };
    }
  }, [cropBoxRef]);

  return (
    <ClickAwayListener onClickAway={releaseHandle}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: theme.zIndex.modal,
          overflow: 'hidden',
        }}
        onMouseUp={releaseHandle}
        onTouchEnd={releaseHandle}
        onMouseMove={handleMovementEvents}
        onTouchMove={handleMovementEvents}
        role="combobox"
        aria-label="Crop Overlay">
        {dimension && (
          <Popper open={!!dimension} anchorEl={topLeftRef?.current} placement="top-start">
            <ResolutionDisplay
              width={dimension.width}
              height={dimension.height}
              maxWidth={resolution.width}
              maxHeight={resolution.height}
              onChange={handleResolutionEdit}
              editMode={editMode}
              onChangeEditMode={onChangeEditMode}
            />
          </Popper>
        )}

        <Box
          ref={cropBoxRef}
          onMouseDown={(e) => handleStartMovement(Handle.MOVE, e)}
          onTouchStart={(e) => handleStartMovement(Handle.MOVE, e)}
          sx={{
            position: 'absolute',
            left: `calc(${cropWindow.x * boundingBox.width}px + ${boundingBox.x})`,
            top: `calc(${cropWindow.y * boundingBox.height}px + ${boundingBox.y})`,
            width: `${cropWindow.width * boundingBox.width + 2 * BORDER_THICKNESS}px`,
            height: `${cropWindow.height * boundingBox.height + 2 * BORDER_THICKNESS}px`,
            transform: `translate(-${BORDER_THICKNESS}px, -${BORDER_THICKNESS}px)`,
            border: `solid ${BORDER_THICKNESS}px rgba(245, 245, 245, 0.5)`,
            cursor: holdingHandle === Handle.NONE ? 'move' : cropWindow.cursor,
          }}>
          {/* top left */}
          <Box
            ref={topLeftRef}
            onMouseDown={(e) => handleStartMovement(Handle.TOP_LEFT, e)}
            onTouchStart={(e) => handleStartMovement(Handle.TOP_LEFT, e)}
            sx={{ position: 'absolute', top: '-5px', left: '0', cursor: 'nwse-resize' }}>
            <CropCornerIcon corner={Corner.TOP_LEFT} color={theme.palette.primary.main} />
          </Box>
          {/* top mid */}
          <Box
            onMouseDown={(e) => handleStartMovement(Handle.TOP_MID, e)}
            onTouchStart={(e) => handleStartMovement(Handle.TOP_MID, e)}
            sx={{ position: 'absolute', top: '-5px', left: 'calc(50% - 6px)', cursor: 'ns-resize' }}>
            <CropSideIcon side={Side.TOP} color={theme.palette.primary.main} />
          </Box>
          {/* top right */}
          <Box
            onMouseDown={(e) => handleStartMovement(Handle.TOP_RIGHT, e)}
            onTouchStart={(e) => handleStartMovement(Handle.TOP_RIGHT, e)}
            sx={{ position: 'absolute', top: '-5px', right: '0', cursor: 'nesw-resize' }}>
            <CropCornerIcon corner={Corner.TOP_RIGHT} color={theme.palette.primary.main} />
          </Box>
          {/* mid left */}
          <Box
            onMouseDown={(e) => handleStartMovement(Handle.LEFT_MID, e)}
            onTouchStart={(e) => handleStartMovement(Handle.LEFT_MID, e)}
            sx={{ position: 'absolute', top: 'calc(50% - 12px)', left: '0', cursor: 'ew-resize' }}>
            <CropSideIcon side={Side.LEFT} color={theme.palette.primary.main} />
          </Box>
          {/* mid right */}
          <Box
            onMouseDown={(e) => handleStartMovement(Handle.RIGHT_MID, e)}
            onTouchStart={(e) => handleStartMovement(Handle.RIGHT_MID, e)}
            sx={{ position: 'absolute', top: 'calc(50% - 12px)', right: '0', cursor: 'ew-resize' }}>
            <CropSideIcon side={Side.RIGHT} color={theme.palette.primary.main} />
          </Box>
          {/* bottom left */}
          <Box
            onMouseDown={(e) => handleStartMovement(Handle.BOTTOM_LEFT, e)}
            onTouchStart={(e) => handleStartMovement(Handle.BOTTOM_LEFT, e)}
            sx={{ position: 'absolute', bottom: '-6px', left: '0', cursor: 'nesw-resize' }}>
            <CropCornerIcon corner={Corner.BOTTOM_LEFT} color={theme.palette.primary.main} />
          </Box>
          {/* bottom mid */}
          <Box
            onMouseDown={(e) => handleStartMovement(Handle.BOTTOM_MID, e)}
            onTouchStart={(e) => handleStartMovement(Handle.BOTTOM_MID, e)}
            sx={{ position: 'absolute', bottom: '-6px', left: 'calc(50% - 6px)', cursor: 'ns-resize' }}>
            <CropSideIcon side={Side.BOTTOM} color={theme.palette.primary.main} />
          </Box>
          {/* bottom right */}
          <Box
            onMouseDown={(e) => handleStartMovement(Handle.BOTTOM_RIGHT, e)}
            onTouchStart={(e) => handleStartMovement(Handle.BOTTOM_RIGHT, e)}
            sx={{ position: 'absolute', bottom: '-6px', right: '0', cursor: 'nwse-resize' }}>
            <CropCornerIcon corner={Corner.BOTTOM_RIGHT} color={theme.palette.primary.main} />
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default CropOverlay;

const isTouchEvent = (e: TouchEvent | MouseEvent): e is TouchEvent => e && 'touches' in e;
const isMouseEvent = (e: TouchEvent | MouseEvent): e is MouseEvent => e && 'screenX' in e;

const applyMovement = (
  handle: Handle,
  boundingBox: CropBoundingBox,
  startingPoint: { x: number; y: number },
  startingWindow: CropWindow,
  newX: number,
  newY: number
) => {
  const initialPxWidth = startingWindow.width * boundingBox.width;
  const initialPxHeight = startingWindow.height * boundingBox.height;
  const initialPxX = startingWindow.x * boundingBox.width;
  const initialPxY = startingWindow.y * boundingBox.height;

  const deltaX = newX - startingPoint.x;
  const deltaY = newY - startingPoint.y;

  const movementXLeft = _.clamp(deltaX, -initialPxX, initialPxWidth - MIN_CROP_WINDOW_SIZE);
  const movementYTop = _.clamp(deltaY, -initialPxY, initialPxHeight - MIN_CROP_WINDOW_SIZE);
  const movementXRight = _.clamp(
    deltaX,
    -initialPxWidth + MIN_CROP_WINDOW_SIZE,
    boundingBox.width - initialPxX - initialPxWidth
  );
  const movementYBottom = _.clamp(
    deltaY,
    -initialPxHeight + MIN_CROP_WINDOW_SIZE,
    boundingBox.height - initialPxY - initialPxHeight
  );

  let newWindow = null;
  switch (handle) {
    case Handle.TOP_LEFT: {
      newWindow = {
        width: initialPxWidth - movementXLeft,
        height: initialPxHeight - movementYTop,
        x: initialPxX + movementXLeft,
        y: initialPxY + movementYTop,
        cursor: 'nwse-resize',
      };
      break;
    }
    case Handle.TOP_RIGHT: {
      newWindow = {
        width: initialPxWidth + movementXRight,
        height: initialPxHeight - movementYTop,
        x: initialPxX,
        y: initialPxY + movementYTop,
        cursor: 'nesw-resize',
      };
      break;
    }
    case Handle.BOTTOM_LEFT: {
      newWindow = {
        width: initialPxWidth - movementXLeft,
        height: initialPxHeight + movementYBottom,
        x: initialPxX + movementXLeft,
        y: initialPxY,
        cursor: 'nesw-resize',
      };
      break;
    }
    case Handle.BOTTOM_RIGHT:
      newWindow = {
        width: initialPxWidth + movementXRight,
        height: initialPxHeight + movementYBottom,
        x: initialPxX,
        y: initialPxY,
        cursor: 'nwse-resize',
      };
      break;
    case Handle.TOP_MID: {
      newWindow = {
        width: initialPxWidth,
        height: initialPxHeight - movementYTop,
        x: initialPxX,
        y: initialPxY + movementYTop,
        cursor: 'ns-resize',
      };
      break;
    }
    case Handle.BOTTOM_MID:
      newWindow = {
        width: initialPxWidth,
        height: initialPxHeight + movementYBottom,
        x: initialPxX,
        y: initialPxY,
        cursor: 'ns-resize',
      };
      break;
    case Handle.LEFT_MID: {
      newWindow = {
        width: initialPxWidth - movementXLeft,
        height: initialPxHeight,
        x: initialPxX + movementXLeft,
        y: initialPxY,
        cursor: 'ew-resize',
      };
      break;
    }
    case Handle.RIGHT_MID:
      newWindow = {
        width: initialPxWidth + movementXRight,
        height: initialPxHeight,
        x: initialPxX,
        y: initialPxY,
        cursor: 'ew-resize',
      };
      break;
    case Handle.MOVE: {
      const x = _.clamp(initialPxX + deltaX, 0, boundingBox.width - initialPxWidth);
      const y = _.clamp(initialPxY + deltaY, 0, boundingBox.height - initialPxHeight);
      newWindow = {
        width: initialPxWidth,
        height: initialPxHeight,
        x,
        y,
        cursor: 'move',
      };
      break;
    }
  }
  if (!newWindow) return startingWindow;
  newWindow.width /= boundingBox.width;
  newWindow.height /= boundingBox.height;
  newWindow.x /= boundingBox.width;
  newWindow.y /= boundingBox.height;

  return newWindow;
};
