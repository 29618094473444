/* eslint-disable no-invalid-this */
/* eslint-disable valid-jsdoc */
/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import { circleImage, greenCircleImage } from '@libs/DepixIcons';
import Vector2D from '@libs/Geometry/Vector2D';
import _ from 'lodash';

// eslint-disable-next-line no-unused-vars
import Perspective from './Filters/Perspective';
import { SHADOW_STATE } from '@libs/DepixObject';
import { loadImage } from '@libs/ImgUtils';

const multiply = fabric.util.multiplyTransformMatrices;
const invert = fabric.util.invertTransform;

class PerspectiveData {
  constructor() {
    this.perspectiveCoord = [];
    this.anchorOffset = [new Vector2D(0, 0), new Vector2D(0, 0), new Vector2D(0, 0), new Vector2D(0, 0)];
    this.offset = [1, 0, 0, 1, 0, 0];
    this.displacementOffset = [1, 0, 0, 1, 0, 0];
  }
}

/**
 * TransformedImage subclass
 * @class fabric.TransformedImage
 * @extends fabric.TransformedImage
 * @return {fabric.TransformedImage} thisArg
 *
 */
fabric.TransformedImage = class extends fabric.Image {
  constructor(parent, initialFlatOffset = 50, options) {
    super(options);
    this.type = 'transformedImage';
    this.repeat = 'no-repeat';
    this.fill = 'transparent';
    this.cornerStyle = 'circle';
    this.paddingRatio = 2;
    this.actionFrequency = 10;

    this.projectionMode = SHADOW_STATE.GROUND;
    // Ground projection mode
    this.groundState = new PerspectiveData();
    this.flatState = new PerspectiveData();
    this.flatState.displacementOffset = [1, 0, 0, 1, initialFlatOffset, -initialFlatOffset];

    this.parent = parent;
    parent.child.push(this);

    this.baseOffset = [1, 0, 0, 1, 0, 0];
    this.displacementOffset = [1, 0, 0, 1, 0, 0];
    this.onGroundChange = () => {};
    this.onGroundRelease = () => {};
    this.cacheProperties = fabric.Image.prototype.cacheProperties.concat('perspectiveCoords');

    if (options) this.setOptions(options);
  }

  static create(parent, depixObject, options) {
    const ret = new fabric.TransformedImage(parent, options);
    return new Promise((resolve) => {
      ret.setAsset(depixObject).then(() => {
        !ret.perspectiveCoords && ret.getInitialPerspective();
        resolve(ret);
      });
    });
  }

  _initialiseImage(image, options) {
    this._initElement(image, options);
    if (this.parent) {
      this.groundState.perspectiveCoord = this.updateCornerFromOffset(this.groundState.anchorOffset);
      this.flatState.perspectiveCoord = this.updateCornerFromOffset(this.flatState.anchorOffset);
    }
    if (this.perspectiveCoords) {
      this.refreshPerspective();
    } else {
      this.setSize(image.width, image.height);
      this.setCoords();
    }
  }

  setAsset(depixObject, options) {
    if (this.depixObject?.objectID === depixObject.objectID) return Promise.resolve();
    this.depixObject = depixObject;
    return new Promise((resolve, reject) => {
      const maskImageUrl = depixObject.getCroppedMask();
      loadImage(maskImageUrl)
        .then((image) => {
          this._initialiseImage(image, options);
          resolve();
        })
        .catch(reject);
    });
  }

  /**
   * @private
   * @param {CanvasRenderingContext2D} ctx Context to render on
   */
  _render(ctx) {
    fabric.util.setImageSmoothing(ctx, this.imageSmoothing);

    if (this.isMoving !== true && this.resizeFilter && this._needsResize()) {
      this.applyResizeFilters();
    }

    this._stroke(ctx);
    this._renderPaintInOrder(ctx);
  }

  /**
   * @private
   * @param {CanvasRenderingContext2D} ctx Context to render on
   */
  _renderFill(ctx) {
    const elementToDraw = this._element;
    if (!elementToDraw) return;

    ctx.save();
    const elWidth = elementToDraw.naturalWidth || elementToDraw.width;
    const elHeight = elementToDraw.naturalHeight || elementToDraw.height;
    const width = this.width;
    const height = this.height;

    ctx.translate(-width / 2, -height / 2);

    // get the scale
    const scale = Math.min(width / elWidth, height / elHeight);
    // get the top left position of the image
    const x = width / 2 - (elWidth / 2) * scale;
    const y = height / 2 - (elHeight / 2) * scale;
    // Make sure that width/height can't be smaller than 1 pixel
    const fWidth = Math.max(1, elWidth * scale);
    const fHeight = Math.max(1, elHeight * scale);

    ctx.drawImage(elementToDraw, x, y, fWidth, fHeight);

    ctx.restore();
  }

  setShadowState(newProjectionMode) {
    this.swapState(newProjectionMode);
    this.setProjectionControls(newProjectionMode);
    this.refreshPerspective();
    this.moveToOffset();
  }

  swapState(newState) {
    const stateChanged = newState !== this.projectionMode;
    if (newState == SHADOW_STATE.FLAT && stateChanged) {
      this.groundState.offset = [...this.baseOffset];
      this.baseOffset = this.flatState.offset;
      this.groundState.displacementOffset = [...this.displacementOffset];
      this.displacementOffset = this.flatState.displacementOffset;
    } else if (newState === SHADOW_STATE.GROUND && stateChanged) {
      this.flatState.offset = [...this.baseOffset];
      this.baseOffset = this.groundState.offset;
      this.flatState.displacementOffset = [...this.displacementOffset];
      this.displacementOffset = this.groundState.displacementOffset;
    }
    this.projectionMode = newState;
  }

  setDisplacement(parent) {
    const childTransform = this.calcTransformMatrix();
    const parentTransform = parent.calcTransformMatrix();
    this.displacementOffset = multiply(invert(this.baseOffset), multiply(invert(parentTransform), childTransform));
  }

  setOffset(parent) {
    const childTransform = this.calcTransformMatrix();
    const parentTransform = parent.calcTransformMatrix();
    this.baseOffset = multiply(invert(this.displacementOffset), multiply(invert(parentTransform), childTransform));
  }

  moveToOffset() {
    const parentTransform = this.parent.calcTransformMatrix();
    const newPose = multiply(parentTransform, multiply(this.baseOffset, this.displacementOffset));
    const decomposition = fabric.util.qrDecompose(newPose);
    this.setDecomposition(decomposition);
  }

  setProjectionControls(state) {
    if (state == SHADOW_STATE.FLAT) {
      this.setControlsVisibility({
        warp: false,
        prs1: true,
        prs2: true,
      });
    } else if (state === SHADOW_STATE.GROUND) {
      this.setControlsVisibility({
        warp: true,
        prs1: false,
        prs2: false,
      });
    }
  }

  lockScalingRotation() {
    this.lockScalingX = true;
    this.lockScalingY = true;
    this.lockRotation = true;
  }

  refreshPerspective() {
    this._resetSizeAndPosition();
    this.setCoords();
    this.applyFilters();
  }

  computeCornerAnchorOffset(perspectiveCoords) {
    const parentCorners = this.parent.getLocalCorners();
    return _.zip(perspectiveCoords, parentCorners).map(([perspectiveCoord, parentCorner]) => {
      const out = this.toGlobal(new Vector2D(perspectiveCoord[0], perspectiveCoord[1]));
      // Bring current point in object local space
      const objectLocalClick = fabric.util.transformPoint(
        out,
        fabric.util.invertTransform(this.parent.calcTransformMatrix())
      );

      return new Vector2D(parentCorner.x - objectLocalClick.x, parentCorner.y - objectLocalClick.y);
    });
  }

  updateCornerFromOffset(anchorOffset) {
    const parentCorners = this.parent.getLocalCorners();
    return _.zip(parentCorners, anchorOffset).map(([parentCorner, offset]) => {
      const position = new Vector2D(parentCorner.x - offset.x, parentCorner.y - offset.y);
      // bring to global
      const finalPosition = fabric.util.transformPoint(position, this.parent.calcTransformMatrix());
      const newPerspective = this.toLocal(finalPosition.x, finalPosition.y);
      return [newPerspective.x, newPerspective.y];
    });
  }

  togglePerspective(mode = true) {
    const renderIcon = (icon, ctx, left, top, size = 18) => {
      const xScale = fabric.document.all?.composeCanvas?.clientWidth / 600 || 1;
      const yScale = fabric.document.all?.composeCanvas?.clientHeight / 600 || 1;

      const screenNormalizedSize = size / window.devicePixelRatio;
      const width = screenNormalizedSize / xScale;
      const height = screenNormalizedSize / yScale;
      ctx.save();
      ctx.translate(left, top);
      ctx.drawImage(icon, -width / 2, -height / 2, width, height);
      ctx.restore();
    };

    if (mode === true) {
      this.controls = this.perspectiveCoords.reduce((acc, coord, index) => {
        const name = `prs${index + 1}`;

        acc[name] = new fabric.Control({
          name,
          x: -0.5,
          y: -0.5,
          // Computes the local coordinate of the point that is dragged by the user
          actionHandler: this._actionWrapper((e, target, x, y) => {
            const localPoint = target.toLocal(x, y);
            const coordToUpdate = this.getPerspectiveCoords();
            coordToUpdate[index][0] = localPoint.x;
            coordToUpdate[index][1] = localPoint.y;
            target._resetSizeAndPosition();
            target.setOffset(target.parent);
            target.setCoords();
            target.applyFilters();
            return true;
          }),
          positionHandler: function (dim, finalMatrix, fabricObject) {
            const zoomX = fabricObject.canvas.viewportTransform[0];
            const zoomY = fabricObject.canvas.viewportTransform[3];
            const scalarX = fabricObject.scaleX * zoomX;
            const scalarY = fabricObject.scaleY * zoomY;

            const point = fabric.util.transformPoint(
              {
                x: this.x * dim.x + this.offsetX + coord[0] * scalarX,
                y: this.y * dim.y + this.offsetY + coord[1] * scalarY,
              },
              finalMatrix
            );
            return point;
          },
          cursorStyleHandler: () => 'cell',
          render: function (ctx, left, top) {
            renderIcon(circleImage, ctx, left, top, 24);
          },
          offsetX: 0,
          offsetY: 0,
          actionName: 'perspective-coords',
        });
        return acc;
      }, {});
      this.controls.warp = new fabric.Control({
        x: -0.5,
        y: -0.5,
        // Computes the local coordinate of the point that is dragged by the user
        actionHandler: this._actionWrapper((e, target, x, y) => {
          const xOffset = target.scaleX * target.displacementOffset[4];
          const yOffset = target.scaleY * target.displacementOffset[5];
          target.onGroundChange(target, x - xOffset, y - yOffset);
          return true;
        }),
        positionHandler: function (dim, finalMatrix, fabricObject) {
          const zoomX = fabricObject.canvas.viewportTransform[0];
          const zoomY = fabricObject.canvas.viewportTransform[3];
          const scalarX = fabricObject.scaleX * zoomX;
          const scalarY = fabricObject.scaleY * zoomY;

          const point = fabric.util.transformPoint(
            {
              x: this.x * dim.x + this.offsetX + fabricObject.groundCoord.x * scalarX,
              y: this.y * dim.y + this.offsetY + fabricObject.groundCoord.y * scalarY,
            },
            finalMatrix
          );
          return point;
        },
        cursorStyleHandler: () => 'cell',
        render: function (ctx, left, top) {
          renderIcon(greenCircleImage, ctx, left, top, 24);
        },
        offsetX: 0,
        offsetY: 0,
        actionName: 'warp-coords',
      });
    } else {
      this.controls = fabric.TransformedImage.prototype.controls;
    }
  }

  _actionWrapper(fn) {
    const frequency = this.actionFrequency;
    return function (eventData, transform, x, y) {
      if (!transform || !eventData) return;
      const target = transform.target;
      let actionPerformed = false;
      // Setup a time threshold for action
      if (!target.lastFilterTime) {
        target.lastFilterTime = new Date();
      } else {
        const time = new Date();
        if (time.getTime() - target.lastFilterTime.getTime() > frequency) {
          actionPerformed = fn(eventData, target, x, y);
          target.lastFilterTime = new Date();
        }
      }
      return actionPerformed;
    };
  }

  getPerspectiveCoords() {
    return this.projectionMode === SHADOW_STATE.GROUND
      ? this.groundState.perspectiveCoord
      : this.flatState.perspectiveCoord;
  }

  getLocalAnchorsOffset() {
    return this.projectionMode === SHADOW_STATE.GROUND ? this.groundState.anchorOffset : this.flatState.anchorOffset;
  }

  /**
   * @description manually reset the bounding box after points update
   *-
   * @see http://fabricjs.com/custom-controls-polygon
   * @param {number} index
   */
  _resetSizeAndPosition() {
    const newPerspectiveCoords = this.getPerspectiveCoords();
    const perspectivePoints = [];
    for (const perspectiveCoord of newPerspectiveCoords) {
      perspectivePoints.push({
        x: perspectiveCoord[0],
        y: perspectiveCoord[1],
      });
    }

    // Offset is the new left/top offset of the object's boundingbox
    const leftOffset = fabric.util.array.min(perspectivePoints, 'x') || 0;
    const topOffset = fabric.util.array.min(perspectivePoints, 'y') || 0;

    // get rotation and scaling only
    const RS = [...this.calcTransformMatrix()];
    RS[4] = 0;
    RS[5] = 0;
    // Transform this offset to global coordinate system
    const newTrans = fabric.util.transformPoint(
      {
        x: leftOffset,
        y: topOffset,
      },
      RS
    );
    // Translate the object globally
    this.left += newTrans.x;
    this.top += newTrans.y;

    // Translate the coordinates locally
    for (const perspectiveCoord of newPerspectiveCoords) {
      perspectiveCoord[0] -= leftOffset;
      perspectiveCoord[1] -= topOffset;
      // drawDebugCircle(perspectiveCoord[0], perspectiveCoord[1], this.canvas, tag, 'green', 3);
    }
    if (this.projectionMode === SHADOW_STATE.GROUND) {
      // make sure that the groundCoord stay in the center of the upper part of the shadow
      this.groundCoord.x = newPerspectiveCoords[0][0] + (newPerspectiveCoords[1][0] - newPerspectiveCoords[0][0]) / 2;
      this.groundCoord.y = newPerspectiveCoords[0][1] + (newPerspectiveCoords[1][1] - newPerspectiveCoords[0][1]) / 2;
    }

    // Compute the size of the BB
    const coords = newPerspectiveCoords.slice().map((c) => ({
      x: c[0],
      y: c[1],
    }));

    const minX = Math.ceil(fabric.util.array.min(coords, 'x')) || 0;
    const minY = Math.ceil(fabric.util.array.min(coords, 'y')) || 0;
    const maxX = Math.ceil(fabric.util.array.max(coords, 'x')) || 0;
    const maxY = Math.ceil(fabric.util.array.max(coords, 'y')) || 0;
    const newWidth = Math.abs(maxX - minX);
    const newHeight = Math.abs(maxY - minY);
    this.setSize(newWidth, newHeight);

    // This will warp the asset to the provided coords
    this.copyToPerspective(newPerspectiveCoords);

    this.groundState.anchorOffset = this.computeCornerAnchorOffset(this.groundState.perspectiveCoord);
    this.flatState.anchorOffset = this.computeCornerAnchorOffset(this.flatState.perspectiveCoord);
  }

  copyToPerspective(array) {
    for (let i = 0; i < 4; ++i) {
      this.perspectiveCoords[i][0] = array[i][0];
      this.perspectiveCoords[i][1] = array[i][1];
    }
  }

  setGroundCoord(center, topRight, topLeft) {
    const localCenter = this.toLocal(center.x, center.y);
    this.groundCoord = fabric.util.transformPoint(localCenter, invert(this.displacementOffset));

    let localTopRight = this.toLocal(topRight.x, topRight.y);
    let localTopLeft = this.toLocal(topLeft.x, topLeft.y);
    localTopRight = fabric.util.transformPoint(localTopRight, this.displacementOffset);
    localTopLeft = fabric.util.transformPoint(localTopLeft, this.displacementOffset);

    const coordToUpdate = this.getPerspectiveCoords();

    coordToUpdate[0][0] = localTopRight.x;
    coordToUpdate[0][1] = localTopRight.y;
    coordToUpdate[1][0] = localTopLeft.x;
    coordToUpdate[1][1] = localTopLeft.y;
    this.refreshPerspective();
    this.setOffset(this.parent);
  }

  toLocal(x, y) {
    // Object dimensions
    const localPoint = this.toLocalPoint(new fabric.Point(x, y), 'left', 'top');
    const polygonBaseSize = this._getNonTransformedDimensions();
    const size = this._getTransformedDimensions(0, 0);
    const lX = (localPoint.x * polygonBaseSize.x) / size.x;
    const lY = (localPoint.y * polygonBaseSize.y) / size.y;
    return new fabric.Point(lX, lY);
  }

  _calculateCurrentDimensions() {
    // Controls dimensions
    const vpt = this.getViewportTransform();
    const dim = this._getTransformedDimensions();
    const p = fabric.util.transformPoint(dim, vpt, true);
    // Handle extra padding around object
    return p.scalarAdd(2 * this.paddingRatio);
  }

  _getNonTransformedDimensions() {
    // Object dimensions
    const p = new fabric.Point(this.width / this.paddingRatio, this.height / this.paddingRatio);
    return p;
  }

  static _nestedCopy(array) {
    return JSON.parse(JSON.stringify(array));
  }

  /**
   * @description generate the initial coordinates for warping, based on image dimensions
   *
   */
  getInitialPerspective() {
    const w = this.getScaledWidth();
    const h = this.getScaledHeight();

    const perspectiveCoords = [
      [0, 0], // top left
      [w, 0], // top right
      [w, h], // bottom right
      [0, h], // bottom left
    ];

    this.groundState.perspectiveCoord = fabric.TransformedImage._nestedCopy(perspectiveCoords);
    this.flatState.perspectiveCoord = fabric.TransformedImage._nestedCopy(perspectiveCoords);

    this.perspectiveCoords = perspectiveCoords;
    this.groundCoord = new Vector2D(this.getSize()[0] / (2 * this.paddingRatio), 0);

    const perspectiveFilter = new fabric.Image.filters.Perspective({
      hasRelativeCoordinates: false,
      paddingRatio: this.paddingRatio,
      perspectiveCoords,
    });
    this.filters.push(perspectiveFilter);
    this.applyFilters();
  }
};

/**
 * Creates an instance of fabric.TransformedImage from its object representation
 * @static
 * @param {Object} object Object to create an instance from
 * @param {Function} callback Callback to invoke when an image instance is created
 */
fabric.TransformedImage.fromObject = function (_object, callback) {
  const object = fabric.util.object.clone(_object);
  object.layout = _object.layout;

  fabric.util.loadImage(
    object.src,
    function (img, isError) {
      if (isError) {
        callback && callback(null, true);

        return;
      }
      fabric.TransformedImage.prototype._initFilters.call(object, object.filters, function (filters) {
        object.filters = filters || [];
        fabric.TransformedImage.prototype._initFilters.call(object, [object.resizeFilter], function (resizeFilters) {
          object.resizeFilter = resizeFilters[0];

          fabric.util.enlivenObjects([object.clipPath], function (enlivedProps) {
            object.clipPath = enlivedProps[0];
            const image = new fabric.TransformedImage(img, object);

            callback(image, false);
          });
        });
      });
    },
    null,
    object.crossOrigin || 'anonymous'
  );
};
