/**
 * @param {number} angle
 * @return {number}
 */
export function toDegrees(angle) {
  return angle * (180 / Math.PI);
}

/**
 * @param {number} angle
 * @return {number}
 */
export function toRadians(angle) {
  return angle * (Math.PI / 180);
}

/**
 * Get max index from array
 * @param {*} array
 * @return {number}
 */
export const argMax = (array) => {
  return [].reduce.call(array, (m, c, i, arr) => (c > arr[m] ? i : m), 0);
};

export const angular2world = (phi, theta, r = 1) => {
  const y = -Math.sin(theta) * Math.sin(phi) * r;
  const z = -Math.sin(theta) * Math.cos(phi) * r;
  const x = Math.cos(theta) * r;
  return [x, y, z];
};

export const world2angular = (x, y, z) => {
  const r = Math.sqrt(x * x + y * y + z * z);
  const theta = Math.acos(x / (r + 0.0000001));
  const phi = Math.atan2(-y, -z);
  return [phi, theta, r];
};
