import React from 'react';

export enum Corner {
  TOP_LEFT = 'tl',
  TOP_RIGHT = 'tr',
  BOTTOM_LEFT = 'bl',
  BOTTOM_RIGHT = 'br',
}

type CropCornerIconProps = {
  corner?: Corner;
  color?: string;
};

const CropCornerIcon = ({ corner = Corner.TOP_LEFT, color }: CropCornerIconProps) => {
  const rotation =
    corner === Corner.TOP_RIGHT ? 90 : corner === Corner.BOTTOM_LEFT ? -90 : corner === Corner.BOTTOM_RIGHT ? 180 : 0;
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8" transform={`rotate(${rotation}, 6, 6)`}>
        <rect width="4" height="12" fill={color ?? 'currentColor'} />
        <rect width="12" height="4" fill={color ?? 'currentColor'} />
      </g>
    </svg>
  );
};

export default CropCornerIcon;
