import React, { ReactNode } from 'react';

import { DialogActions as MuiDialogActions, styled } from '@mui/material';

interface StyledDialogActionsProps {
  children: ReactNode;
  className?: string;
}

export const DialogActions = ({ children, className }: StyledDialogActionsProps) => (
  <StyledDialogActions className={className} disableSpacing>
    {children}
  </StyledDialogActions>
);

const StyledDialogActions = styled(MuiDialogActions)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
