const EMAIL_KEY = 'https://user.com/name';
const PERMISSIONS_KEY = 'https://user.com/permissions'; // first time tokens use this
const PERMISSIONS_KEY_V2 = 'permissions';
const ROLES_KEY = 'https://user.com/roles';
const SESSION_ID_KEY = 'https://user.com/session_id';
const EMAIL_VERIFIED_KEY = 'https://user.com/email_verified';

export enum UserRole {
  DEPIX = 'Depix',
  CHAMPION = 'Champion',
  DEV = 'Dev',
  USER = 'User',
}

export class User {
  static fromToken(token: any): User {
    let permissions = [];
    if (token[PERMISSIONS_KEY]?.length > 0) {
      permissions = token[PERMISSIONS_KEY];
    } else if (token[PERMISSIONS_KEY_V2]?.length > 0) {
      permissions = token[PERMISSIONS_KEY_V2];
    }

    return new User(
      token.sub,
      token[EMAIL_KEY],
      permissions,
      token[ROLES_KEY] || [],
      token[SESSION_ID_KEY],
      token[EMAIL_VERIFIED_KEY],
      token
    );
  }

  constructor(
    public readonly auth0Id: string,
    public readonly email: string,
    public readonly permissions: string[],
    public readonly roles: string[],
    public readonly sessionId: string,
    public readonly isVerified: boolean,
    public readonly rawTokenValues: any
  ) {}

  hasPermissions(permissions: string[] | string): boolean {
    let permissionsArray: string[];
    if (typeof permissions == 'string') {
      permissionsArray = [permissions];
    } else {
      permissionsArray = permissions;
    }

    return permissionsArray.every((permission) => this.permissions.includes(permission));
  }

  hasRole(role: string): boolean {
    return this.roles.some((x) => x.toLowerCase() === role.toLowerCase());
  }
}
