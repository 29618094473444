import { ShareToButtonProps } from '@components/SharedImage/ShareToButtonProps';
import InstagramIcon from '@components/Ui/Icons/InstagramIcon';
import React from 'react';
import { IconButton, styled } from '@mui/material';

interface ShareToInstagramProps extends ShareToButtonProps {}

export const ShareToInstagram = ({ onClick }: ShareToInstagramProps) => {
  return (
    <InstagramIconButton onClick={onClick}>
      <InstagramIcon />
    </InstagramIconButton>
  );
};

const InstagramIconButton = styled(IconButton)`
  background: #8f39ce;
  background: linear-gradient(315deg, #fbe18a 0%, #fcbb45 21%, #f75274 38%, #d53692 52%, #8f39ce 74%, #5b4fe9 100%);

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;
