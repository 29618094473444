/* eslint-disable max-len */
import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DropletMoreIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="4 0 22 22">
    <path d="M11.1843 11L19.1843 11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.1843 7L15.1843 15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15.1803 22.0078C12.2619 22.0078 9.45364 20.8485 7.37346 18.7848C5.29328 16.7212 4.11155 13.9223 4.08817 11.0038L4.00003 -0.000196399L15.004 -0.000197361C17.9225 -0.000197616 20.7307 1.15915 22.8108 3.2228C24.891 5.28646 26.0728 8.08536 26.0962 11.0038C26.1196 13.9223 24.9826 16.7212 22.9355 18.7848C20.8884 20.8485 18.0988 22.0078 15.1803 22.0078Z"
      fill="#5DE2A1"
    />
    <path
      d="M15.1763 21.5078C12.3905 21.5078 9.70985 20.4012 7.72419 18.4313C5.73853 16.4614 4.61049 13.7897 4.58817 11.0038L4.50404 0.499788L15.008 0.499787C17.7939 0.499786 20.4745 1.60646 22.4601 3.57634C24.4458 5.54623 25.5739 8.21796 25.5962 11.0038C25.6185 13.7897 24.5332 16.4614 22.5791 18.4313C20.625 20.4012 17.9621 21.5078 15.1763 21.5078Z"
      stroke="white"
      fill="none"
    />
    <path d="M11.0002 11L19.0002 11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.0002 7L15.0002 15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);
