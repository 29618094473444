import { Box, styled, Tooltip, Typography } from '@mui/material';
import SuccessIcon from '@components/Ui/Icons/SuccessIcon';
import WarningIcon from '@components/Ui/Icons/WarningIcon';
import { utcIsoToDate } from '@libs/TimeConversion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useInstrumentation from '@hooks/UseInstrumentation';
import { Link } from '@components/Ui/Link';
import { Subscription as SubscriptionModel } from '@libs/Subscription';

const iconStyle = {
  color: 'secondary.dark',
  width: '14px',
  height: '14px',
};

interface SubscriptionProps {
  subscription?: SubscriptionModel;
  subscriptionError?: any;
}

const Subscription = ({ subscription, subscriptionError }: SubscriptionProps) => {
  const { t } = useTranslation();
  const { subscriptionCancelClicked, subscriptionUpdateClicked, subscriptionReceiptClicked } = useInstrumentation();

  if (subscriptionError) {
    return (
      <Text>
        <WarningIcon style={iconStyle} />
        {t('profile.subscription.errorLoading')}
      </Text>
    );
  }

  if (!subscription) {
    return <></>;
  }

  let { formatedTime: nextBillDate } = utcIsoToDate(subscription.nextBillDate);
  let { formatedTime: pausedAt } = utcIsoToDate(subscription.pausedAt);
  let { formatedTime: pausedFrom } = utcIsoToDate(subscription.pausedFrom);
  let { formatedTime: cancellationEffectiveDate } = utcIsoToDate(subscription.cancellationEffectiveDate);

  let subscriptionMessage;
  let subscriptionTooltip;
  switch (subscription.status) {
    case 'active':
    case 'trialing':
      break;
    case 'past_due':
      subscriptionMessage = t('profile.subscription.pastDue');
      subscriptionTooltip = t('profile.subscription.pastDueTooltip');
      break;
    case 'paused':
      subscriptionMessage = t('profile.subscription.paused');
      subscriptionTooltip = t('profile.subscription.pausedTooltip', { pausedFrom });
      break;
    case 'deleted':
      if (subscription.enablesAccess) {
        subscriptionMessage = t('profile.subscription.subscribed');
        subscriptionTooltip = t('profile.subscription.activeButCancelledTooltip');
      } else {
        subscriptionMessage = t('profile.subscription.cancelled');
        subscriptionTooltip = t('profile.subscription.cancelledTooltip');
      }
      break;
    default:
      console.error(`Received unknown subscription status: ${subscription.status}`);
      subscriptionMessage = t('profile.subscription.unknown');
      subscriptionTooltip = t('profile.subscription.unknown');
  }

  const subscriptionIcon = subscription.enablesAccess ? (
    <SuccessIcon style={iconStyle} />
  ) : (
    <WarningIcon style={iconStyle} />
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        mt: 1,
        px: 0.25,
        py: 0.5,
      }}>
      {subscription.status === 'active' && nextBillDate && (
        <Text>
          {t('profile.subscription.renewal')}: {nextBillDate}
        </Text>
      )}

      {subscriptionMessage && (
        <Tooltip title={subscriptionTooltip} arrow>
          <Text>
            {subscriptionIcon}
            {subscriptionMessage}
          </Text>
        </Tooltip>
      )}

      {subscription.enablesAccess && pausedAt && (
        <Tooltip title={t('profile.subscription.activeButPausedAtTooltip', { pausedAt })} arrow>
          <Text style={{ fontWeight: 'bold' }}>{t('profile.subscription.activeButPausedAt', { pausedFrom })}</Text>
        </Tooltip>
      )}
      {subscription.enablesAccess && cancellationEffectiveDate && (
        <Text style={{ fontWeight: 'bold' }}>
          {t('profile.subscription.activeButCancelled', { cancellationEffectiveDate })}
        </Text>
      )}
      {subscription.isInGracePeriod && (
        <Text style={{ fontWeight: 'bold' }}>{t('profile.subscription.gracePeriod')}</Text>
      )}
      {subscription.status === 'active' && !subscription.enablesAccess && (
        <Text style={{ fontWeight: 'bold' }}>{t('profile.subscription.activeButNoAccess')}</Text>
      )}
      {subscription.status !== 'deleted' && (
        <>
          <Text>
            {subscription.updateUrl && (
              <Link
                href={subscription.updateUrl}
                target="_blank"
                onClick={() => subscriptionUpdateClicked(subscription)}>
                {t('profile.subscription.update')}
              </Link>
            )}
          </Text>
          <Text>
            {subscription.cancelUrl && (
              <Link
                href={subscription.cancelUrl}
                target="_blank"
                onClick={() => subscriptionCancelClicked(subscription)}>
                {t('profile.subscription.cancel')}
              </Link>
            )}
          </Text>
          <Text>
            {subscription.receiptUrl && (
              <Link
                href={subscription.receiptUrl}
                target="_blank"
                onClick={() => subscriptionReceiptClicked(subscription)}>
                {t('profile.subscription.receipt')}
              </Link>
            )}
          </Text>
        </>
      )}
    </Box>
  );
};

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  wordBreak: 'normal',
  fontWeight: 500,
  fontSize: '0.9rem',
  textAlign: 'left',
  px: 0.5,
}));

export default Subscription;
