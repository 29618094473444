import React, { forwardRef, Ref } from 'react';

import MuiPaper, { PaperProps } from '@mui/material/Paper';
import { styled } from '@mui/material';
import { BorderRadius } from '@root/theme';

type SurfaceProps = PaperProps & {
  radius?: string;
};

const Surface = forwardRef(
  ({ role, radius = BorderRadius.DEFAULT, sx, children, ...props }: SurfaceProps, ref: Ref<HTMLDivElement>) => {
    return (
      <StyledPaper role={role} radius={radius} sx={sx} ref={ref} {...props}>
        {children}
      </StyledPaper>
    );
  }
);

type StyledPaperProps = {
  radius: string;
};

const StyledPaper = styled(MuiPaper)<StyledPaperProps>`
  position: relative;
  background-color: ${(props) => props.theme.palette.secondary.main};
  border-radius: ${(props) => props.theme.borderRadius[props.radius]}px;
  min-height: ${(props) => props.theme.borderRadius[props.radius] * 2}px;
  min-width: ${(props) => props.theme.borderRadius[props.radius] * 2}px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
`;

export default Surface;
