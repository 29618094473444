import React, { ReactNode, useMemo } from 'react';

import './LoadingAnimation.css';
import { Box } from '@mui/material';
import ReactDOM from 'react-dom';
import { styled } from '@mui/material/styles';

export const LOADING_ROOT_ID = 'loading-root';
interface LoadingProps {
  global?: boolean;
  backdrop?: boolean;
  opacity?: number;
  blur?: number;
  color?: string;
  width?: number;
  height?: number;
  loadingMessageComponent?: ReactNode;
  droplet?: boolean;
  className?: string;
}

const Loading = ({
  global = true,
  backdrop = false,
  opacity = 1,
  blur = 0,
  color = '#0032c8',
  width = 96,
  height = 96,
  loadingMessageComponent,
  droplet = false,
  className,
}: LoadingProps) => {
  const widthInPx = `${width}px`;
  const heightInPx = `${height}px`;
  const innerWidth = width / 1.6;
  const innerHeight = height / 1.6;

  const loadingRoot = useMemo(() => document.getElementById(LOADING_ROOT_ID), []);

  if (global) {
    const backgroundColor = backdrop ? `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0)`;
    return ReactDOM.createPortal(
      <>
        <GlobalBackground backgroundColor={backgroundColor} blur={`blur(${blur}px)`} />
        <Box
          className={'preloader ' + (global ? ' fullscreen ' : '') + className}
          role="progressbar"
          sx={{ zIndex: 'loadingSpinner' }}>
          <div
            className="loader"
            style={{
              color,
              height: heightInPx,
              width: widthInPx,
            }}>
            <span
              className={`loader_inner ${droplet ? 'loader_inner--droplet' : ''}`}
              style={{ height: `${innerHeight}px`, width: `${innerWidth}px` }}
            />
          </div>
          {loadingMessageComponent && <LoadingMessageWrapper>{loadingMessageComponent}</LoadingMessageWrapper>}
        </Box>
      </>,
      loadingRoot
    );
  }

  return (
    <>
      <div
        className="preloader"
        role="progressbar"
        style={{
          position: 'static',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: heightInPx,
          width: widthInPx,
        }}>
        <div className="loader" style={{ color, opacity, height: heightInPx, width: widthInPx }}>
          <span
            className={`loader_inner ${droplet ? 'loader_inner--droplet' : ''}`}
            style={{ height: `${innerHeight}px`, width: `${innerWidth}px` }}
          />
        </div>
      </div>
      {loadingMessageComponent && <LoadingMessageWrapper>{loadingMessageComponent}</LoadingMessageWrapper>}
    </>
  );
};

export default Loading;

const GlobalBackground = styled(Box)<{ backgroundColor: string; blur: string }>`
  pointer-events: auto;
  width: 100%;
  height: 100%;
  backdrop-filter: ${(props) => props.blur};
  background-color: ${(props) => props.backgroundColor};
`;

const LoadingMessageWrapper = styled(Box)`
  margin-top: ${(props) => props.theme.spacing(5)};
`;
