import { RadioGroup } from '@mui/material';
import { ResolutionOption } from '@components/Download/ResolutionOption';
import { DownloadResolution, DownloadResolutionType } from '@libs/Download/DownloadResolution';

interface ResolutionSelectProps {
  resolutions: DownloadResolution[];
  selectedResolution?: DownloadResolutionType;
  onChange: (resolution: string) => void;
  className?: string;
}

export const ResolutionSelect = ({ resolutions, selectedResolution, onChange, className }: ResolutionSelectProps) => {
  function handleChange(_, value) {
    onChange(value);
  }

  return (
    <RadioGroup value={selectedResolution} onChange={handleChange} className={className}>
      {resolutions.map((resolution) => (
        <ResolutionOption resolution={resolution} />
      ))}
    </RadioGroup>
  );
};
