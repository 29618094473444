import React, { MouseEvent, ReactNode } from 'react';

import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

interface CanvasContainerProp {
  className?: string;
  transparent?: boolean;
  stopContextMenu?: boolean;
  children: ReactNode;
}

const CanvasContainer = ({ className, transparent = false, stopContextMenu = true, children }: CanvasContainerProp) => {
  const theme = useTheme();

  function onContextMenu(e: MouseEvent<HTMLDivElement>) {
    if (stopContextMenu) {
      if (e) {
        e.preventDefault();
      }
      return false;
    }
  }

  return (
    <Grid
      className={className}
      container
      flexDirection="column"
      justifyContent="center"
      wrap="nowrap"
      flexGrow={1}
      onContextMenu={onContextMenu}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        flex={1}
        onContextMenu={(e) => onContextMenu(e)}
        sx={{
          position: 'relative',
          height: '100%',
          mt: 9,
          background: transparent ? 'transparent' : theme.palette.secondary.lighter,
          borderRadius: transparent ? 0 : 2,
          boxShadow: (theme) => (transparent ? 'none' : theme.elevation?.canvas),
        }}>
        {children}
      </Grid>
    </Grid>
  );
};

export default CanvasContainer;
