import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import ContentTemplate, { ButtonType } from './ContentTemplate';

const AddressInputPage = ({ onClose, onChange, onSubmit }) => {
  const { t } = useTranslation();

  const buttons = [
    { name: t('error.verifyAccount.buttons.confirm'), onClick: onSubmit, type: ButtonType.MAIN },
    { name: t('error.verifyAccount.buttons.back'), onClick: onClose },
  ];

  return (
    <ContentTemplate
      title={t('error.verifyAccount.titles.addressInputPage')}
      description={t('error.verifyAccount.descriptions.addressInputPage')}
      buttons={buttons}
      onInputSubmit={onSubmit}
      onInputChange={onChange}
      inputLabel={t('error.verifyAccount.inputLabel')}
    />
  );
};

AddressInputPage.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddressInputPage;
