import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { keyframes, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const PRODUCT_IMAGES = [
  'pot.png',
  'bowls.png',
  'skate.png',
  'glasses.png',
  'chair.png',
  'tube.png',
  'perfume.png',
];

const animate = keyframes`
  0% {
    transform: translateY(0) rotate(0);
  }
  25% {
    transform: translateY(-40px) rotate(5deg);
  }
  50% {
    transform: translateY(-80px) rotate(0deg);
  }
  75%{
    transform: translateY(-40px) rotate(-5deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
`;

const AnimatedImage = styled(Box)(() => ({
  position: 'absolute',
  animation: `${animate} 12s infinite linear`,
}));

const MIN_ANIMATION_DURATION = 10;
const MAX_ANIMATION_DURATION = 14;

const ProductShowcase = ({ image, flat = false, animate = true, width = 300, left, right, top, bottom }) => {
  const position = { top, right, bottom, left };

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const imageWidth = isSmallDevice ? width * 0.8 : width;
  const shadowOffset = isSmallDevice ? 100 : 150;
  const filter = flat ? '' : `drop-shadow(0px ${shadowOffset}px 20px rgba(0, 0, 0, 0.20))`;

  const animationDuration = animate
    ? Math.random() * (MAX_ANIMATION_DURATION - MIN_ANIMATION_DURATION) + MIN_ANIMATION_DURATION
    : 0;

  return (
    <AnimatedImage
      component="img"
      src={image}
      sx={{
        ...position,
        filter,
        animationDuration: `${animationDuration}s`,
        pointerEvents: 'none',
      }}
      width={imageWidth}
    />
  );
};

ProductShowcase.propTypes = {
  image: PropTypes.string.isRequired,
  flat: PropTypes.bool,
  animate: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ProductShowcase;
