export enum DownloadResolutionType {
  LOW = 'low resolution',
  HIGH = 'high resolution',
}

export interface DownloadResolution {
  type: DownloadResolutionType;
  name: string;
  size: { width: number; height: number };
  premium?: boolean;
}
