import React, { useEffect, useState } from 'react';

import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import ProfileIcon from '../Ui/Icons/ProfileIcon';
import DropdownIcon from '../Ui/Icons/DropdownIcon';
import ProgressBar from '../Ui/ProgressBar';
import Navigation from '../Navigation';
import { useTheme } from '@mui/material/styles';
import { BorderRadius } from '../../theme';
import { useTranslation } from 'react-i18next';
import SubscriptionComponent from '@components/Account/Subscription';
import { Subscription } from '@libs/Subscription';
import { HeaderButtonSurface } from '@components/Header/HeaderButton';
import { SubscribeButton } from '@components/Account/SubscribeButton';
import SubscribeIcon from '@components/Ui/Icons/SubscribeIcon';
import { SubscriptionIncentive } from '@libs/Instrumentation/SubscriptionIncentive';
import { SubscriptionBanner } from '@components/Account/SubscriptionBanner';

export const DropdownState = {
  CLOSED: 'closed-dropdown',
  HOVER: 'hover-dropdown',
  OPEN: 'open-dropdown',
};

const textStyle = {
  color: 'secondary.dark',
  fontWeight: 500,
  fontSize: '0.9rem',
  textAlign: 'left',
  px: 0.5,
};

const iconStyle = {
  color: 'secondary.dark',
  width: '14px',
  height: '14px',
  mx: '1px',
};

const MAX_OPEN_WIDTH = 380;
const OPEN_HEIGHT = 1000;
const TRANSITION_SPEED = 0.3;
const MARGIN_AND_PADDING = 40;
const DELAY_UPLOAD_USAGE_CHANGED_OPEN_MS = 3000;

interface AccountAndSettingsProps {
  uploadLimit?: { uploadUsed?: number; uploadQuota?: number; uploadWaitTime?: string };
  subscription: Subscription | null;
  subscriptionError: any | null;
}

const AccountAndSettings = ({
  uploadLimit = {},
  subscription = null,
  subscriptionError = null,
}: AccountAndSettingsProps) => {
  const { uploadUsed, uploadQuota } = uploadLimit;
  const canSubscribe = false;
  const [uploadUsedChangedTimer, setUploadUsedChangedTimer] = useState(null);

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const RADIUS_OPTION = BorderRadius.MEDIUM;
  const CLOSED_SIZE = theme.borderRadius[RADIUS_OPTION] * 2;
  const [currentState, setCurrentState] = useState(DropdownState.CLOSED);

  const RESPONSIVE_OPEN_WIDTH = innerWidth - (48 + 16 * 2 + 8 * 2);
  const OPEN_WIDTH = MAX_OPEN_WIDTH < RESPONSIVE_OPEN_WIDTH ? MAX_OPEN_WIDTH : RESPONSIVE_OPEN_WIDTH;

  const isOpen = currentState === DropdownState.OPEN;
  const isHover = currentState === DropdownState.HOVER;
  const isClosed = currentState === DropdownState.CLOSED;
  const shouldFloat = isSmallDevice && currentState !== DropdownState.CLOSED;
  const useSmallIcon = isSmallDevice && isClosed;

  const margin = isOpen ? 0 : 24;
  const height = isOpen ? OPEN_HEIGHT : CLOSED_SIZE;
  const width = isClosed ? 0 : OPEN_WIDTH;
  const arrowWidth = isHover ? 24 : 0;
  const progressBarCompact = !isOpen;
  const progressBarHeight = isOpen ? 13 : 12;
  const infoMessageHeight = isClosed || subscription ? 0 : 'auto';
  const cursor = isOpen ? 'auto' : 'pointer';

  const handleHover = () => {
    setCurrentState(DropdownState.HOVER);
  };

  const handleClose = () => {
    setCurrentState(DropdownState.CLOSED);
  };

  const handleClick = () => {
    setCurrentState(DropdownState.OPEN);
  };

  useEffect(() => {
    if (uploadUsed > 0) {
      if (uploadUsedChangedTimer) {
        clearTimeout(uploadUsedChangedTimer);
      }

      setCurrentState(DropdownState.HOVER);
      const timer = setTimeout(() => {
        setCurrentState((oldState) => (oldState == DropdownState.OPEN ? oldState : DropdownState.CLOSED));
        setUploadUsedChangedTimer(null);
      }, DELAY_UPLOAD_USAGE_CHANGED_OPEN_MS);

      setUploadUsedChangedTimer(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadUsed]);

  const navBoxSx = {
    height: isOpen ? 'auto' : 0,
    width: isOpen ? 'auto' : 0,
    mx: isOpen ? 0.5 : 0,
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <StyledHeaderButtonSurface
        useSmallIcon={useSmallIcon}
        maxWidth={OPEN_WIDTH + MARGIN_AND_PADDING}
        isOpen={isOpen}
        shouldFloat={shouldFloat}>
        <Box
          onMouseEnter={handleHover}
          onMouseLeave={handleClose}
          onClick={() => !isOpen && handleClick()}
          sx={{
            position: 'relative',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            pointerEvents: 'auto',
            color: 'secondary.contrastText',
            cursor,
          }}
          role="menubar"
          aria-label={t('profile.settings.aria.label')}
          aria-orientation="vertical"
          aria-expanded={isOpen}>
          {isOpen && (
            <Box role="usage" sx={{ mx: 0.5, py: 0.5 }}>
              <Typography variant="h6" textAlign="left" marginRight={4}>
                {canSubscribe ? t('profile.subscription.freePlan') : t('profile.subscription.subscribed')}
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: 'auto',
              maxHeight: height,
              width,
              maxWidth: width,
              pr: `${margin}px`,
              transition: `${TRANSITION_SPEED}s max-height, ${TRANSITION_SPEED}s width, ${TRANSITION_SPEED}s padding`,
              overflow: 'hidden',
            }}
            aria-hidden={isClosed}>
            <Box
              sx={{
                width: 100,
                maxWidth: arrowWidth,
                transition: `${TRANSITION_SPEED}s ease max-width`,
                overflow: 'hidden',
                display: 'flex',
                alignContent: 'center',
              }}
              role="button"
              aria-label={t('profile.settings.aria.dropdown')}>
              <DropdownIcon />
            </Box>

            <ProgressBar
              label={t('profile.imagesUsed')}
              display={progressBarCompact ? (isSmallDevice ? 'no-label' : 'compact') : 'normal'}
              value={uploadUsed}
              total={uploadQuota}
              maxHeight={progressBarCompact ? 24 : 'auto'}
              height={progressBarHeight}
            />
          </Box>

          {isHover && canSubscribe && (
            <StyledSubscribeButton incentive={SubscriptionIncentive.PROFILE_PREVIEW}>
              <SubscribeIcon />
              {t('profile.subscribeIncentive.preview')}
            </StyledSubscribeButton>
          )}

          {isOpen && canSubscribe && <StyledSubscriptionBanner incentive={SubscriptionIncentive.PROFILE_FULL} />}

          {isOpen && subscription && (
            <Box sx={{ pr: `${margin}px`, mx: 0.5 }}>
              <SubscriptionComponent subscription={subscription} subscriptionError={subscriptionError} />
            </Box>
          )}

          <ProfileIconWrapper role="button" aria-label={t('profile.settings.aria.icon')} useSmallIcon={useSmallIcon}>
            <ProfileIcon sx={{ backgroundColor: 'secondary.main' }} fontSize={useSmallIcon ? 'small' : 'medium'} />
          </ProfileIconWrapper>

          <Box sx={{ overflow: 'hidden', ...navBoxSx }} aria-hidden={!isOpen}>
            <Navigation />
          </Box>
        </Box>
      </StyledHeaderButtonSurface>
    </ClickAwayListener>
  );
};

export default AccountAndSettings;

const notForwardedProps: PropertyKey[] = ['shouldFloat', 'useSmallIcon', 'maxWidth', 'isOpen'];

const StyledHeaderButtonSurface = styled(HeaderButtonSurface, {
  shouldForwardProp: (propName: PropertyKey) => !notForwardedProps.includes(propName),
})<{
  shouldFloat: boolean;
  useSmallIcon: boolean;
  maxWidth: number;
  isOpen: boolean;
}>`
  position: ${(props) => (props.shouldFloat ? 'absolute' : 'relative')};
  margin: ${(props) => props.theme.spacing(0.5, 1)};
  right: ${(props) => (props.shouldFloat ? 0 : undefined)};
  top: ${(props) => (props.shouldFloat ? '8px' : undefined)};
  padding: ${(props) => props.theme.spacing(props.useSmallIcon ? 1 : 1.5)};
  z-index: ${(props) => props.theme.zIndex.modal};
  max-width: ${(props) => props.maxWidth}px;
  width: auto;
  height: auto;
  max-height: 90vh;
  overflow-y: ${(props) => (props.isOpen ? 'auto' : 'hidden')};
  overflow-x: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 0 auto;

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: ${(props) => props.theme.spacing(0.5)};
    width: ${(props) => (props.useSmallIcon ? '36px' : 'auto')};
    height: ${(props) => (props.useSmallIcon ? '36px' : 'auto')};
    min-width: auto;
    min-height: auto;
  }
`;

const ProfileIconWrapper = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => !notForwardedProps.includes(propName),
})<{ useSmallIcon: boolean }>`
  position: absolute;
  top: 0;
  right: ${(props) => (props.useSmallIcon ? '4px' : 0)};
`;

const StyledSubscribeButton = styled(SubscribeButton)`
  width: 100%;
  box-shadow: none;
  margin-top: ${(props) => props.theme.spacing(1)};

  &:hover {
    box-shadow: none;
  }
`;

const StyledSubscriptionBanner = styled(SubscriptionBanner)`
  margin: ${(props) => props.theme.spacing(1, -1.5)};
`;
