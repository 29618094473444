import { DepixObject, MaskOption } from '@libs/DepixObject';
import { BackgroundObject, BackgroundType } from './BackgroundObject';

export class DepixBackgroundObject implements BackgroundObject {
  public updated: boolean;
  public depixObject: DepixObject;

  image: HTMLImageElement;
  type: BackgroundType;
  objectID: string;

  constructor(object: DepixObject) {
    const type = BackgroundType.DEPIX;
    this.image = object.image;
    this.objectID = object.objectID;
    this.type = type;
    this.updated = true;
    this.depixObject = object;
  }

  /**
   * get RGBA image
   */
  getImage(fullResolution = false): Promise<HTMLImageElement> {
    const padding = 1;
    const cropFragment = false;
    const invertMask = false;
    const maskOption = MaskOption.ORIGINAL_MASK;
    return this.depixObject.getAlphaImage(padding, cropFragment, invertMask, maskOption, fullResolution);
  }

  isTransparent(): boolean {
    return !!this.depixObject.mask;
  }

  /**
   * Serialize into json
   * @return {*}
   */
  serialize() {
    return this.depixObject.serialize();
  }

  /**
   * Create object from json
   * @param {*} state
   * @return {Promise}
   */
  static unserialize(state) {
    return new Promise((resolve) => {
      DepixObject.unserialize(state).then((depixObject) => resolve(new DepixBackgroundObject(depixObject)));
    });
  }
}
