import React, { useCallback, useEffect, useState } from 'react';

import { useScene } from '@contexts/SceneContext';
import { useSegmentation } from '@contexts/Segmentation/UseSegmentation';
import useUploadDepixObject from '@hooks/UseUploadDepixObject';
import useInstrumentation from '@hooks/UseInstrumentation';
import { useTranslation } from 'react-i18next';
import useComposeSelectMode from '@hooks/UseComposeSelectMode';
import { DepixObject } from '@libs/DepixObject';

import FooterButton from '@root/Components/Ui/FooterButton';
import ApplyIcon from '@root/Components/Ui/Icons/ApplyIcon';

import { SelectMode } from '@contexts/ComposeSelectModeContext';
import { MeasureType } from '@libs/Instrumentation/MeasureType';
import { DEFAULT_NS } from '@libs/i18n-config';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ApplyComposeSegmentationButton = () => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'tools.compose' });

  const segmentation = useSegmentation();
  const scene = useScene();
  const instrumentation = useInstrumentation();
  const { selectMode, resetSelectMode: onApply } = useComposeSelectMode();
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const addObjectToScene = (depixObject: DepixObject, isFix: boolean, isErased = false) => {
    depixObject.isErased = isErased;
    // set the transform given its position in the background
    const [objectBottomCoord, objectMaxSizeScreenRatio] = depixObject.getBoundingBoxPosition();
    scene.addSceneComponent(depixObject, objectBottomCoord, objectMaxSizeScreenRatio, isFix);
    segmentation.reset();
  };

  const addGroundToScene = (depixObject) => {
    scene.setSceneGround(depixObject);
    segmentation.reset();
  };

  const saveSegmentationCloner = useUploadDepixObject((depixObject) => addObjectToScene(depixObject, true));
  const saveGroundCloner = useUploadDepixObject(addGroundToScene);
  const saveInfillingBackground = useUploadDepixObject((depixObject) => addObjectToScene(depixObject, false));
  const saveEraseObject = useUploadDepixObject((depixObject) => addObjectToScene(depixObject, false, true));

  const segmentGround = () => {
    saveGroundCloner
      .cloneFromObjectId(segmentation.segmentationObjectID, segmentation.maskId, segmentation.preview)
      .then(onApply);
    instrumentation.instrument(MeasureType.GROUND_OBJECT_ADDED);
  };

  const segmentObject = () => {
    saveSegmentationCloner
      .cloneFromObjectId(segmentation.segmentationObjectID, segmentation.maskId, segmentation.preview)
      .then(onApply);
    instrumentation.instrument(MeasureType.OCCLUSION_OBJECT_ADDED);
  };

  const segmentInfillingObject = () => {
    const withInfillFragment = true;

    saveInfillingBackground
      .cloneFromObjectId(
        segmentation.segmentationObjectID,
        segmentation.maskId,
        segmentation.preview,
        withInfillFragment
      )
      .then(onApply);
  };

  const segmentEraseObject = () => {
    const withInfillFragment = true;

    saveEraseObject
      .cloneFromObjectId(
        segmentation.segmentationObjectID,
        segmentation.maskId,
        segmentation.preview,
        withInfillFragment
      )
      .then(onApply);

    instrumentation.instrument(MeasureType.OBJECT_ERASED);
  };

  const handleApply = useCallback(() => {
    if (segmentation.mask) {
      if (selectMode === SelectMode.GROUND) {
        segmentGround();
      } else if (selectMode === SelectMode.OBJECT) {
        segmentObject();
      } else if (selectMode === SelectMode.INFILLING) {
        segmentInfillingObject();
      } else if (selectMode === SelectMode.ERASE) {
        segmentEraseObject();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentation.mask]);

  useEffect(() => {
    setEnabled(segmentation.mask && selectMode != SelectMode.NONE);
  }, [segmentation.mask, selectMode]);

  useEffect(() => {
    setLoading(
      saveGroundCloner.loadingFlag ||
        saveSegmentationCloner.loadingFlag ||
        saveInfillingBackground.loadingFlag ||
        saveEraseObject.loadingFlag
    );
  }, [
    saveGroundCloner.loadingFlag,
    saveSegmentationCloner.loadingFlag,
    saveInfillingBackground.loadingFlag,
    saveEraseObject.loadingFlag,
  ]);

  const theme = useTheme();
  const isMediumSize = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeSize = useMediaQuery(theme.breakpoints.up('lg'));
  const showLabel = isMediumSize || isLargeSize;

  return (
    <>
      {enabled && (
        <FooterButton
          tooltip={t(`tooltips.applySegmentation.${selectMode}`)}
          label={showLabel ? t(`labels.applySegmentation.${selectMode}`) : undefined}
          onClick={handleApply}
          loading={loading}
          icon={<ApplyIcon />}
        />
      )}
    </>
  );
};

export default ApplyComposeSegmentationButton;
