import { useCallback, useState } from 'react';

export type StorageType = string | boolean;
type LocalStorageState = [
  currentValue: StorageType | undefined,
  setValue: (value?: StorageType) => void,
  unset: () => void
];

export const useLocalStorageState = (key: string, initialState: StorageType): LocalStorageState => {
  const storedValueString = localStorage.getItem(key);
  let storedValue: StorageType | undefined = storedValueString;
  if (typeof initialState === 'boolean') {
    if (storedValueString == null) {
      storedValue = initialState;
    } else {
      storedValue = storedValueString === 'true';
    }
  }

  const [currentValue, setCurrentValue] = useState<StorageType | undefined>(
    storedValue === undefined ? initialState : storedValue
  );

  const setValue = useCallback(
    (newValue) => {
      localStorage.setItem(key, newValue);
      setCurrentValue(newValue);
    },
    [setCurrentValue, key]
  );

  const unset = useCallback(() => {
    setCurrentValue(undefined);
    localStorage.removeItem(key);
  }, [key]);

  return [currentValue, setValue, unset];
};
