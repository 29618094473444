import Vector3D from './Vector3D';

/**
 * Define a 3D plane
 */
export default class Plane {
  public origin: Vector3D;
  public normal: Vector3D;

  constructor(planeOrigin: Vector3D, normal: Vector3D) {
    this.origin = planeOrigin;
    this.normal = normal;
  }

  /**
   * Returns a json
   * @return {*}
   */
  serialize() {
    return { origin: this.origin.serialize(), normal: this.normal.serialize() };
  }

  /**
   * Create Plane from json
   * @param {*} state
   * @return {Plane}
   */
  static unserialize(state): Plane {
    return new Plane(Vector3D.unserialize(state.origin), Vector3D.unserialize(state.normal));
  }

  /**
   * Compute intersection of a ray and a plane
   */
  cross(rayOrigin: Vector3D, rayDirection: Vector3D): Vector3D {
    const amp = this.origin.sub(rayOrigin).dot(this.normal);
    const distance = amp / this.normal.dot(rayDirection);
    return rayDirection.mulScalar(distance).add(rayOrigin);
  }
}
