import { Subscription } from '@libs/Subscription';

export interface ProfileUploadLimits {
  uploadLeft: number;
  uploadUsed?: number;
  uploadQuota: number;
  uploadWaitTime?: string;
}

export type SubscriptionError = string;

export class Profile {
  static empty(): Profile {
    return new Profile({ uploadLeft: -1, uploadQuota: -1, uploadWaitTime: null }, []);
  }

  constructor(
    public readonly uploadLimit: ProfileUploadLimits,
    public readonly subscriptions: Subscription[],
    public readonly subscriptionError?: SubscriptionError
  ) {}

  get isSubscribed() {
    return this.subscriptions?.length > 0;
  }
}
