import React, { MouseEvent } from 'react';
import { DialogProps as MuiDialogProps, Dialog as MuiDialog, styled } from '@mui/material';
import CloseIcon from '@components/Ui/Icons/CloseIcon';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';

export interface DialogProps extends MuiDialogProps {
  onClose?: (event?: MouseEvent, reason?: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick') => void;
  closeable?: boolean;
}

export const Dialog = ({ closeable, onClose, children, ...props }: DialogProps) => {
  const { t } = useTranslation();

  function handleCloseButtonClick(e) {
    if (onClose) {
      onClose(e, 'closeButtonClick');
    }
  }

  return (
    <StyledDialog onClose={onClose} {...props}>
      {closeable && (
        <StyledIconButton aria-label={t('general.close')} onClick={(e) => handleCloseButtonClick(e)}>
          <CloseIcon />
        </StyledIconButton>
      )}

      {children}
    </StyledDialog>
  );
};

const StyledDialog = styled(MuiDialog)`
  .MuiPaper-root {
    border-radius: ${(props) => props.theme.borderRadius.default}px;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${(props) => props.theme.spacing(1)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    margin: ${(props) => props.theme.spacing(2)};
  }
`;
