import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';
import { TrendingImages } from '@components/SharedImage/TrendingImages';
import { SharedImage } from '@libs/SharedImage/SharedImage';
import { CallToActions } from '@components/SharedImage/CallToActions';

interface SharedImageErrorProps {
  trendingImages?: SharedImage[];
  className?: string;
}

export const SharedImageError = ({ trendingImages, className }: SharedImageErrorProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'imageSharing.errors' });

  return (
    <Wrapper className={className}>
      <StyledTypography variant="h3">{t('imageNotFound.title')}</StyledTypography>
      <StyledTypography>{t('imageNotFound.description')}</StyledTypography>
      <StyledCallToActions />
      {trendingImages && <StyledTrendingImages images={trendingImages} />}
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  margin-top: ${(props) => props.theme.spacing(4)};
`;

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const ImageCaption = styled(Typography)`
  color: ${(props) => props.theme.palette.secondary.dark};
`;

const StyledCallToActions = styled(CallToActions)`
  margin-top: ${(props) => props.theme.spacing(4)};
`;

const StyledTrendingImages = styled(TrendingImages)`
  margin-top: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-top: ${(props) => props.theme.spacing(4)};
  }
`;
