import Button from '@components/Ui/Button';
import { DialogActions } from '@components/Ui/Dialog/DialogActions';
import { DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: 500;
  font-size: 40px !important;
  text-align: center;
`;

export const StyledDialogContent = styled(DialogContent)`
  text-align: center;
`;

export const StyledDialogActions = styled(DialogActions)`
  display: flex;
  flex-direction: column;
  align-items: space-evenly;
`;

export const CloseButton = styled(Button)`
  color: ${(props) => props.theme.palette.secondary.medium};
`;
