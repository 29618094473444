/* eslint-disable max-len */
import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ProfileIcon = ({ ...props }) => (
  <SvgIcon {...props}>
    <path d="M12 5.14286C11.1524 5.14286 10.3238 5.39421 9.61899 5.86513C8.91421 6.33605 8.36489 7.00539 8.04052 7.7885C7.71614 8.57161 7.63127 9.43332 7.79664 10.2647C7.962 11.096 8.37018 11.8597 8.96955 12.459C9.56891 13.0584 10.3326 13.4666 11.1639 13.6319C11.9952 13.7973 12.857 13.7124 13.6401 13.3881C14.4232 13.0637 15.0925 12.5144 15.5634 11.8096C16.0344 11.1048 16.2857 10.2762 16.2857 9.42857C16.2857 8.29193 15.8342 7.20184 15.0305 6.39811C14.2267 5.59439 13.1366 5.14286 12 5.14286ZM12 12C11.4914 12 10.9943 11.8492 10.5714 11.5666C10.1485 11.2841 9.81894 10.8825 9.62431 10.4126C9.42969 9.94275 9.37876 9.42572 9.47798 8.92691C9.5772 8.4281 9.82211 7.96992 10.1817 7.61029C10.5413 7.25067 10.9995 7.00577 11.4983 6.90655C11.9972 6.80733 12.5142 6.85825 12.984 7.05288C13.4539 7.2475 13.8555 7.57709 14.1381 7.99996C14.4206 8.42283 14.5714 8.91999 14.5714 9.42857C14.5708 10.1103 14.2996 10.764 13.8175 11.2461C13.3354 11.7282 12.6818 11.9993 12 12Z" />
    <path d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.0051994 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C23.9964 8.81851 22.7309 5.76838 20.4813 3.51873C18.2316 1.26908 15.1815 0.00362976 12 0ZM6.85715 20.8946V19.7143C6.85783 19.0325 7.12896 18.3788 7.61105 17.8968C8.09314 17.4147 8.7468 17.1435 9.42858 17.1429H14.5714C15.2532 17.1435 15.9069 17.4147 16.389 17.8968C16.871 18.3788 17.1422 19.0325 17.1429 19.7143V20.8946C15.5821 21.8059 13.8073 22.2861 12 22.2861C10.1927 22.2861 8.41786 21.8059 6.85715 20.8946ZM18.8503 19.6509C18.8332 18.5266 18.3751 17.454 17.5748 16.6642C16.7744 15.8744 15.6958 15.4307 14.5714 15.4286H9.42858C8.30416 15.4307 7.22558 15.8744 6.42522 16.6642C5.62487 17.454 5.1668 18.5266 5.14972 19.6509C3.59535 18.2629 2.4992 16.4356 2.00643 14.4109C1.51367 12.3861 1.64752 10.2594 2.39027 8.31245C3.13301 6.36546 4.44962 4.69 6.16575 3.50791C7.88188 2.32583 9.91658 1.69288 12.0004 1.69288C14.0843 1.69288 16.119 2.32583 17.8351 3.50791C19.5512 4.69 20.8678 6.36546 21.6106 8.31245C22.3533 10.2594 22.4872 12.3861 21.9944 14.4109C21.5017 16.4356 20.4047 18.2629 18.8503 19.6509Z" />
  </SvgIcon>
);

export default ProfileIcon;
