import { styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Dialog } from '@components/Ui/Dialog/Dialog';
import Surface from '@components/Ui/Surface';
import { Trans, useTranslation } from 'react-i18next';
import { StorageType, useLocalStorageState } from '@hooks/useLocalStorageState';
import React, { useState } from 'react';
import { Link } from '@components/Ui/Link';
import { LabeledCheckBox } from '@components/Ui/Inputs/LabeledCheckBox';

const STORAGE_KEY = 'show_shutdown_notice';

const localStorageValueInterpreter = (storageValue: StorageType): boolean => {
  return typeof storageValue == 'boolean' ? storageValue : true;
};

export const ShutdownNoticeDialog = () => {
  const { t } = useTranslation();
  const [showShutdownNotice, setShowShutdownNotice] = useLocalStorageState(STORAGE_KEY, true);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(localStorageValueInterpreter(showShutdownNotice));

  const onClose = () => {
    setOpen(false);
  };

  const onDontShowChanged = (notShow: boolean) => {
    setShowShutdownNotice(!notShow);
  };

  return (
    <Dialog
      maxWidth="lg"
      closeable
      onClose={onClose}
      open={open}
      PaperComponent={StyledSurface}
      aria-label={t('shutdownNotice.aria')}>
      <Title>{t('shutdownNotice.title')}</Title>
      <Message align={isSmallDevice ? 'center' : 'justify'}>
        <Trans
          i18nKey="shutdownNotice.message"
          components={{
            date: <Date component={'a'} />,
            infoLink: <Link href={'mailto:info@depix.ai'} target="_blank" />,
          }}
        />
      </Message>

      <StyledLabeledCheckBox
        size={'small'}
        isChecked={!localStorageValueInterpreter(showShutdownNotice)}
        onChange={onDontShowChanged}
        label={t('shutdownNotice.notShowAgain')}
      />
    </Dialog>
  );
};

const StyledSurface = styled(Surface)`
  padding: ${(props) => props.theme.spacing(4, 6)};
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 800px;
  gap: ${(props) => props.theme.spacing(3)};

  overflow-y: hidden !important;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 800px;
  }
  ${(props) => props.theme.breakpoints.down('lg')} {
    max-height: 800px;
    width: 600px;
  }
  ${(props) => props.theme.breakpoints.down('md')} {
    max-height: 100%;
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(3)};
  }
`;

const Title = styled(Typography)`
  font-size: xx-large;
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
`;

const Message = styled(Typography)`
  font-size: large;
  align-content: justify;
`;

const Date = styled(Typography)<{ component?: any }>`
  font-size: large;
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
`;

const StyledLabeledCheckBox = styled(LabeledCheckBox)`
  width: 100%;
  justify-content: end;
  color: ${(props) => props.theme.palette.primary.main};
`;
