import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import PreviousIcon from '@components/Ui/Icons/PreviousIcon';
import NextIcon from '@components/Ui/Icons/NextIcon';

const Pagination = ({ currentPage, totalPage, onPreviousPage, onNextPage, sx = {} }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 0.5, ...sx }}>
      <IconButton
        sx={{ color: (theme) => theme.palette.primary.main }}
        aria-label={t('pagination.previousPage')}
        disabled={!onPreviousPage || !totalPage > 0 || currentPage <= 1 || currentPage === undefined}
        onClick={onPreviousPage}>
        <PreviousIcon />
      </IconButton>
      <Typography>
        {currentPage}&nbsp;
        <Box component="span" sx={{ color: (theme) => theme.palette.secondary.dark }}>
          -
        </Box>
        &nbsp;
        {totalPage}
      </Typography>
      <IconButton
        sx={{ color: (theme) => theme.palette.primary.main }}
        aria-label={t('pagination.nextPage')}
        disabled={!onNextPage || !totalPage > 0 || currentPage >= totalPage || currentPage === undefined}
        onClick={onNextPage}>
        <NextIcon />
      </IconButton>
    </Box>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  onPreviousPage: PropTypes.func,
  onNextPage: PropTypes.func,
  sx: PropTypes.object,
};

export default Pagination;
