import Vector2D from './Vector2D';
import { createMatrix, matrixDot, matrixT } from './Matrix';

class Transformation2D {
  public matrix: number[][];

  constructor() {
    this.matrix = [
      [1, 0, 0],
      [0, 1, 0],
      [0, 0, 1],
    ];
  }

  get t() {
    return [this.matrix[0][2], this.matrix[1][2]];
  }

  get R() {
    const ret = createMatrix(2, 2, 0);
    for (let i = 0; i < 2; i++) {
      for (let j = 0; j < 2; j++) {
        ret[i][j] = this.matrix[i][j];
      }
    }
    return ret;
  }

  setRotationScale(theta: number, scaleX: number, scaleY: number) {
    this.matrix[0][0] = Math.cos(theta) * scaleX;
    this.matrix[0][1] = -Math.sin(theta) * scaleY;
    this.matrix[1][0] = Math.sin(theta) * scaleX;
    this.matrix[1][1] = Math.cos(theta) * scaleY;
  }

  setR(R: number[][]) {
    for (let i = 0; i < 2; i++) {
      for (let j = 0; j < 2; j++) {
        this.matrix[j][i] = R[j][i];
      }
    }
  }

  sett(t: number[]) {
    this.matrix[0][2] = t[0];
    this.matrix[1][2] = t[1];
  }

  getScale() {
    const sX = new Vector2D(this.matrix[0][0], this.matrix[1][0]).getMagnitude();
    const sY = new Vector2D(this.matrix[0][1], this.matrix[1][1]).getMagnitude();
    return new Vector2D(sX, sY);
  }

  dot(pointHomogeneous) {
    return matrixT(matrixDot(this.matrix, matrixT(pointHomogeneous)));
  }
}

export default Transformation2D;
