/* eslint-disable no-invalid-this */

import { TwoDimensionArray } from '@libs/Geometry/TwoDimensionArray';
import { MarvinImage } from '../Libs/Marvinj';
import ImageBlobReducer from 'image-blob-reduce';
import Pica from 'pica';

/**
 * Load the image and return the minimal bounding box of channel 1 with non zero
 * @param {*} mask mask Image
 * @return {*}
 */
export function getMinimumBoundingbox(mask, hasAlphaChanel = false) {
  let minx = null;
  let miny = null;
  let maxx = null;
  let maxy = null;

  const image = new MarvinImage(mask);
  const width = image.getWidth();
  const height = image.getHeight();

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const value = hasAlphaChanel ? image.getAlphaComponent(x, y) : image.getIntComponent0(x, y);

      // BIG WARNING
      // Normally, this should be if (value === 0). However,
      // for some reason on safari, most zeros are actually ones instead.
      // This means that the bounding box was much larger than it should
      // It's not a bug, it's a feature
      if (value <= 1) continue;

      if (minx == null || x < minx) {
        minx = x;
      }
      if (maxx == null || x > maxx) {
        maxx = x;
      }
      if (miny == null || y < miny) {
        miny = y;
      }
      if (maxy == null || y > maxy) {
        maxy = y;
      }
    }
  }

  return [minx / width, miny / height, maxx / width, maxy / height];
}

/**
 * Load multiple images
 * @param {*} names name tag per image
 * @param {*} files url per image
 * @return {*}
 */
export const loadImages = (names, files) => {
  const promiseList = [];
  for (let i = 0; i < names.length; i++) {
    promiseList.push(loadImage(files[i]));
  }
  return new Promise((resolve, reject) => {
    Promise.all(promiseList)
      .then((results) => {
        const images = {};
        for (let i = 0; i < names.length; i++) {
          images[names[i]] = results[i];
        }
        resolve(images);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const loadImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (err) => {
      console.error(err);
      reject(new Error('Unable to load image'));
    };
    img.src = url;
  });
};

/**
 * Crop image and return src
 * @param {Image} image
 * @param {number} x
 * @param {number} y
 * @param {number} width
 * @param {number} height
 * @param {number} padding // padding factor
 * @return {string} image url
 */
export const cropAndPad = (image, x, y, width, height, padding = 1) => {
  const tmpCanvas = document.createElement('canvas');
  const ctx = tmpCanvas.getContext('2d');
  tmpCanvas.width = width * padding;
  tmpCanvas.height = height * padding;
  ctx.drawImage(
    image,
    x,
    y,
    width,
    height,
    tmpCanvas.width / 2 - width / 2,
    tmpCanvas.height / 2 - height / 2,
    width,
    height
  );
  return tmpCanvas.toDataURL();
};

export const setAlpha = (image, maskImage, invertMask = false) => {
  const imageData = new MarvinImage(image);
  if (maskImage) {
    const maskData = new MarvinImage(maskImage);
    for (let y = 0; y < imageData.getHeight(); y++) {
      for (let x = 0; x < imageData.getWidth(); x++) {
        let value = maskData.getIntComponent0(x, y);
        if (invertMask) {
          value = 255 - value;
        }
        imageData.setAlphaComponent(x, y, value);
      }
    }
    imageData.update();
  }
  return imageData.canvas.toDataURL();
};

export const url2File = async (url, name, type) => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, { type: type });
};

/**
 * Will compute the object screen size given the size of the largest
 * side. Returns [with, height] in screen %
 */
export const computeMinimalObjectSize = (
  objectSize: TwoDimensionArray,
  screenSizeRatio: number,
  objectAspectRatio: number
): TwoDimensionArray => {
  let ret = null;
  const aspectRatioChange = screenSizeRatio / objectAspectRatio;

  if (objectAspectRatio < 1) {
    // If object ratio is landscape, we fit w.r.t height
    ret = [objectSize[1] / aspectRatioChange, objectSize[1]];
  } else {
    // If object ratio is portrait, we fit w.r.t width
    ret = [objectSize[0], objectSize[0] * aspectRatioChange];
  }
  return ret;
};

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs -
  // see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
};

export const getImageBytes = (blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.addEventListener('loadend', function () {
      if (fileReader.error) {
        reject(fileReader.error);
      } else {
        resolve(Buffer.from(fileReader.result as WithImplicitCoercion<string>));
      }
    });

    fileReader.readAsArrayBuffer(blob);
  });
};

/**
 * Generate dataURI raw BMP image
 *
 * @param {Array} color - RGBA color (color = 3 numbers in
 *                 range 0-255; staring from left bottom corner +
 *                 1 number in range 0 - 1 for aplha)
 * @param {number} width - image width (num of pixels)
 * @param {number} height - image height (num of pixels)
 * @return {*} dataURI string
 */
export const generateUniformBackground = (color = 'white', width = 2048, height = 2048) => {
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = width;
  tempCanvas.height = height;
  const ctx = tempCanvas.getContext('2d');
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, width, height);

  const url = tempCanvas.toDataURL();
  tempCanvas.remove();

  return url;
};

export const IMAGE_MAX_SIZE = 1920;
/**
 *
 * @param {File} file
 * @param {number} maxSize
 * @return {Promise}
 */
export const resizeFile = (file: File, maxSize: number = IMAGE_MAX_SIZE): Promise<File> => {
  const pica = Pica();
  return new ImageBlobReducer({ pica }).toBlob(file, { max: maxSize });
};

export const sizeFromMaximum = (currentSize: number[], maxSize: number): number[] => {
  let width = currentSize[0];
  let height = currentSize[1];

  if (width > maxSize) {
    height *= maxSize / width;
    width = maxSize;
  }

  if (height > maxSize) {
    width *= maxSize / height;
    height = maxSize;
  }

  return [Math.floor(width), Math.floor(height)];
};

export const rgbaToColorString = ({ r, g, b, a = 1 }): string => {
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const getBoundingBoxSize = (boundingBox): TwoDimensionArray => {
  if (boundingBox) {
    return [Math.abs(boundingBox[2] - boundingBox[0]), Math.abs(boundingBox[3] - boundingBox[1])];
  }

  return [0, 0];
};

export const getBoundingBoxCenter = (boundingBox: number[]): TwoDimensionArray | undefined => {
  if (boundingBox) {
    const [width, height] = getBoundingBoxSize(boundingBox);
    return [boundingBox[0] + width / 2, boundingBox[1] + height / 2];
  }
  return null;
};

export const cropToAlpha = async (image, padding = 1) => {
  const hasAlphaChanel = true;
  const boundingBox = getMinimumBoundingbox(image, hasAlphaChanel);

  const [cropWidth, cropHeight] = getBoundingBoxSize(boundingBox);

  const [minx, miny] = boundingBox;

  const width = image.width;
  const height = image.height;

  const imageCropSrc = cropAndPad(image, minx * width, miny * height, cropWidth * width, cropHeight * height, padding);

  const cropedImage = await loadImage(imageCropSrc);

  return cropedImage;
};
