/* eslint-disable max-len */

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const LightAndShadowIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M18.5 18H13" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
    <path d="M20.5 15H13" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
    <path d="M21 12H13" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
    <path d="M20.5 9H13" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
    <path d="M18.5 6H13" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
    <path d="M12 21L12 3" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
    <rect x="2.75" y="2.75" width="18.5" height="18.5" rx="9.25" fill="none" stroke="currentColor" strokeWidth="1.5" />
  </SvgIcon>
);
