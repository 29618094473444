import { styled } from '@mui/material/styles';
import { Radio, Typography, useRadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@components/Ui/Form/Select/SelectOption';
import { DownloadResolution } from '@libs/Download/DownloadResolution';

interface ResolutionOptionProps {
  resolution: DownloadResolution;
  className?: string;
}

export const ResolutionOption = ({ resolution, className }: ResolutionOptionProps) => {
  const { t } = useTranslation();
  const radioGroup = useRadioGroup();

  return (
    <SelectOption
      value={resolution.type}
      name={resolution.name}
      description={`${resolution.size.width} x ${resolution.size.height}`}
      aside={
        <Typography
          variant="body2"
          color={resolution.premium ? 'primary.main' : 'greyInfo.main'}
          fontWeight={resolution.premium ? 500 : 400}>
          {t(resolution.premium ? 'download.form.premium' : 'download.form.free')}
        </Typography>
      }
      selected={radioGroup?.value == resolution.type}
      control={<StyledRadio />}
      className={className}
    />
  );
};

const StyledRadio = styled(Radio)`
  padding: 0;
`;
