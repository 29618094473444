import { SubscriptionRedirect } from '@components/Subscribe/SubscriptionRedirect';
import { useAuthV2 } from '@contexts/AuthV2';
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Route, Routes, useLocation } from 'react-router-dom';

import ga4 from 'react-ga4';

import Home from './Components/Pages/Home/Home';

import SubscriptionDialog from '@components/Subscribe/SubscriptionDialog';
import TestersLandingPage from '@pages/Testers/TestersLandingPage';
import ErrorPage from '@pages/ErrorPage';
import AuthenticatedLayout from '@pages/AuthenticatedLayout';
import PublicLayout from '@pages/PublicLayout';
import { useCampaign } from '@hooks/Campaign';
import { OnboardingPage } from '@pages/Onboarding/OnboardingPage';
import useUserProfile from '@hooks/UseUserProfile';
import { ShareImagePage } from '@pages/ShareImage/ShareImagePage';
import Routing from '@root/routes';
import { AuthPage } from '@pages/Auth/AuthPage';
import { ShutdownPage } from '@pages/Shutdown/ShutdownPage';

const isDev = process.env.NODE_ENV === 'development';

const LegacyApp = () => {
  const { user, isAuthenticated, isDepix, isLoading, isGuest, isChampion, refreshUser } = useAuthV2();
  const location = useLocation();
  const userProfile = useUserProfile();
  const { campaign } = useCampaign();
  const backgroundLocation = (location.state as any)?.backgroundLocation;
  const shutdown = true;

  if (isAuthenticated) {
    // Put removebg permission first to avoid truncate by GA
    // https://depix.slack.com/archives/C0217P5GSSV/p1676912996325649
    const permissions = [];
    user.permissions.forEach((el) => {
      if (el === 'use:removebg') {
        permissions.unshift(el);
      } else {
        permissions.push(el);
      }
    });

    const properties = {
      is_guest: isGuest,
      is_dev: isDev,
      is_depix: isDepix,
      is_champion: isChampion,
      user_id: undefined,
      depix_roles: user.roles,
      depix_permissions: permissions,
      depix_audience: undefined,
      depix_goals: undefined,
    };

    if (userProfile.metadata) {
      properties.depix_audience = userProfile.metadata.audience;
      properties.depix_goals = userProfile.metadata.goals;
    }

    if (!isGuest) {
      Sentry.setUser({ id: user.auth0Id });
      properties.user_id = user.auth0Id;
    }

    ga4.set({
      user_properties: properties,
      traffic_type: isDepix ? 'depix' : 'external',
      campaign_id: campaign?.campaignName,
      campaign_name: campaign?.term, // as of 2022-11-21, the term is the ad group (ad name) and not the actual terms
      campaign_content: campaign?.content,
      campaign_term: campaign?.term,
      campaign_medium: campaign?.medium,
      campaign_source: campaign?.source,
    });

    // Hotjar currently disabled, payment plan was disabled
    // if (hotjar.initialized() && !isGuest) {
    //   hotjar.identify(
    //     user.auth0Id,
    //     FlatUserAttributeGenerator({
    //       roles: user.roles,
    //       email: user.email,
    //       permissions: user.permissions,
    //
    //       is_dev: isDev,
    //       is_depix: isDepix,
    //       is_champion: isChampion,
    //
    //       is_guest: isGuest,
    //       audience_type: userProfile.metadata?.audience,
    //       user_goals: userProfile.metadata?.goals,
    //       campaign_name: campaign?.campaignName,
    //       campaign_content: campaign?.content,
    //       campaign_term: campaign?.term,
    //       campaign_medium: campaign?.medium,
    //       campaign_source: campaign?.source,
    //     })
    //   );
    // }
  }

  useEffect(() => {
    // Hotjar currently disabled, payment plan was disabled
    // if (hotjar.initialized()) {
    //   hotjar.stateChange(location.pathname);
    // }

    if (!location.pathname.includes('/s/')) {
      const params = new URLSearchParams(location.search);
      campaign?.campaignName && params.set('utm_id', campaign.campaignName);
      campaign?.source && params.set('utm_source', campaign.source);
      campaign?.medium && params.set('utm_medium', campaign.medium);
      campaign?.term && params.set('utm_term', campaign.term);
      campaign?.content && params.set('utm_content', campaign.content);
      campaign?.term && params.set('utm_campaign', campaign.term); // as of 2022-11-21, the term is the ad group (ad name) and not the actual terms

      let page = location.pathname;
      if (params.toString()) {
        page += `?${params.toString()}`;
      }

      ga4.send({ hitType: 'pageview', page });
    }
  }, [location]);

  useEffect(() => {
    refreshUser();
  }, []);

  return (
    <>
      <Routes location={backgroundLocation || location}>
        {!shutdown && (
          <>
            <Route element={<AuthenticatedLayout />}>
              <Route index element={<Home />} />

              <Route path="/welcome" element={<OnboardingPage />} />
            </Route>
          </>
        )}

        <Route element={<PublicLayout />}>
          {shutdown && (
            <>
              <Route index element={<ShutdownPage />} />
              <Route path="*" element={<ShutdownPage />} />
            </>
          )}
          {!shutdown && (
            <>
              <Route path={Routing.imageSharing.index()} element={<ShareImagePage />} />
              <Route path="/testers" element={<TestersLandingPage />} />
              <Route path="/error" element={<ErrorPage />} />
              <Route path="/auth" element={<AuthPage />} />
            </>
          )}{' '}
        </Route>
      </Routes>

      {backgroundLocation && (
        <Routes>
          <Route path="/welcome" element={<OnboardingPage />} />
          <Route path="/error" element={<ErrorPage />} />
        </Routes>
      )}
    </>
  );
};

export default LegacyApp;
