export default class Vector2D {
  constructor(public x: number = 0, public y: number = 0) {}

  /**
   * Serialize object into json
   */
  serialize(): any {
    return { x: this.x, y: this.y };
  }

  /**
   * Create vector from json
   */
  static unserialize(state: any): Vector2D {
    return new Vector2D(state.x, state.y);
  }

  // get the magnitude of the vector
  getMagnitude(): number {
    // use pythagoras theorem to work out the magnitude of the vector
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }

  getNormalized(): Vector2D {
    const magnitude = this.getMagnitude();
    return new Vector2D(this.x / magnitude, this.y / magnitude);
  }

  dot(vector): Vector2D {
    return new Vector2D(this.x * vector.x, this.y * vector.y);
  }

  mulScalar(scalar): Vector2D {
    return new Vector2D(this.x * scalar, this.y * scalar);
  }

  div(vector): Vector2D {
    return new Vector2D(this.x / vector.x, this.y / vector.y);
  }

  sub(vector): Vector2D {
    return new Vector2D(this.x - vector.x, this.y - vector.y);
  }

  add(vector): Vector2D {
    return new Vector2D(this.x + vector.x, this.y + vector.y);
  }

  array(): number[] {
    return [this.x, this.y];
  }
}
