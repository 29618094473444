import { createMatrix, rodrigues, matrixDot, matrixT, matrixMultScalar, rodriguesInverse } from './Matrix';
import Vector3D from './Vector3D';
import _ from 'lodash';

/**
 * Define a 4x4 affine transform matrix (3D transform)
 */
export default class Transformation3D {
  public matrix: number[][];

  /**
   * Identity construction
   */
  constructor() {
    this.matrix = [
      [1, 0, 0, 0],
      [0, 1, 0, 0],
      [0, 0, 1, 0],
      [0, 0, 0, 1],
    ];
  }

  /**
   * Retrieve a translation vector
   */
  get t() {
    return new Vector3D(this.matrix[0][3], this.matrix[1][3], this.matrix[2][3]);
  }

  /**
   * Retrieve a 3x3 rotation matrix
   */
  get R() {
    const scaleVector = this.getScale();
    const ret = createMatrix(3, 3, 0);
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        ret[i][j] = this.matrix[i][j] / scaleVector.array()[i];
      }
    }
    return ret;
  }

  /**
   * Return a json of the transform
   * @return {*}
   */
  serialize() {
    return { matrix: _.cloneDeep(this.matrix) };
  }

  /**
   * Return a Transformation3D from a json
   * @param {*} state
   * @return {Transformation3D}
   */
  static unserialize(state) {
    const ret = new Transformation3D();
    ret.matrix = state.matrix;
    return ret;
  }

  /**
   * set Translation component of the transform
   * @param {Vector3D} translation
   */
  setTranslation(translation) {
    this.matrix[0][3] = translation.x;
    this.matrix[1][3] = translation.y;
    this.matrix[2][3] = translation.z;
  }

  /**
   * Compute rotation matrix from euler angles
   * @param {number} x
   * @param {number} y
   * @param {number} z
   */
  setRotation(x, y, z) {
    // first make sure we don't lose the scale
    const scaleVector = this.getScale();
    const R = rodrigues(x, y, z);
    this.setR(R);
    this.setScale(scaleVector);
  }

  /**
   * Get euler angles
   * @return {Vector3D}
   */
  getRotation() {
    const [rX, rY, rZ] = rodriguesInverse(this.R);
    return new Vector3D(rX, rY, rZ);
  }

  /**
   * Return a Scale component of each dimensions
   * @return {Vector3D}
   */
  getScale() {
    const sX = new Vector3D(this.matrix[0][0], this.matrix[1][0], this.matrix[2][0]).getMagnitude();
    const sY = new Vector3D(this.matrix[0][1], this.matrix[1][1], this.matrix[2][1]).getMagnitude();
    const sZ = new Vector3D(this.matrix[0][2], this.matrix[1][2], this.matrix[2][2]).getMagnitude();
    return new Vector3D(sX, sY, sZ);
  }

  /**
   * Setup scale component for each dimensions
   * @param {Vector3D} scaleVector
   */
  setScale(scaleVector) {
    const retMatrix = this.R;
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        retMatrix[i][j] *= scaleVector.array()[i];
      }
    }
    this.setR(retMatrix);
  }

  /**
   * Set rotation/scale matrix (will override current Rotation directly)
   * @param {Array} R 3x3 array
   */
  setR(R) {
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        this.matrix[j][i] = R[j][i];
      }
    }
  }

  /**
   * set Translation component of the transform
   * @param {[number, number, number]} t
   */
  sett(t) {
    this.matrix[0][3] = t[0];
    this.matrix[1][3] = t[1];
    this.matrix[2][3] = t[2];
  }

  /**
   * Dot product of transform (combine transformation into a single matrix)
   * @param {Transformation3D} other
   * @return {Transformation3D}
   */
  combine(other) {
    const ret = new Transformation3D();
    ret.matrix = matrixDot(this.matrix, other.matrix);
    return ret;
  }

  /**
   * Computes the inverse of the affine transform
   * @return {Transformation3D}
   */
  inverse() {
    const ret = new Transformation3D();
    const invR = matrixT(this.R);
    const invT = matrixMultScalar(matrixT(matrixDot(invR, matrixT([this.t.array()]))), -1)[0];
    ret.setR(invR);
    ret.sett(invT);
    return ret;
  }

  /**
   * Computes the dot of an array with the matrix
   * @param {Array} pointHomogeneous
   * @return {Array}
   */
  dot(pointHomogeneous) {
    return matrixT(matrixDot(this.matrix, matrixT(pointHomogeneous)));
  }

  /**
   * Deep copy of the matrix
   * @return {Transformation3D}
   */
  clone() {
    const ret = new Transformation3D();
    ret.matrix = this.matrix.map(function (arr) {
      return arr.slice();
    });
    return ret;
  }
}
