/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const UndoIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.14474 12.6894L3.80005 8.34469L8.14474 4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8.14474 18.4823H16.11C17.4543 18.4823 18.7436 17.9483 19.6942 16.9977C20.6448 16.0471 21.1788 14.7579 21.1788 13.4135V13.4135C21.1788 12.0692 20.6448 10.7799 19.6942 9.82935C18.7436 8.87876 17.4543 8.34473 16.11 8.34473H3.80005"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};

export default UndoIcon;
