const SUBSCRIPTION_CATEGORY = 'subscription';
const STORE_NAME = 'Depix';

type PaddleNumber = string | number | undefined;

export class PaddleTelemetry {
  static handleEvent(data, env, dataLayer: DataLayer) {
    switch (data.event) {
      case 'Checkout.Loaded':
        PaddleTelemetry.onLoaded(data, env, dataLayer);
        break;
      case 'Checkout.Location.Submit':
        PaddleTelemetry.onLocationSubmit(data, env, dataLayer);
        break;
      case 'Checkout.PaymentMethodSelected':
        PaddleTelemetry.onPaymentMethodSelected(data, env, dataLayer);
        break;
      case 'Checkout.PaymentComplete':
        PaddleTelemetry.onPaymentComplete(data, env, dataLayer);
        break;
      case 'Checkout.Error':
        PaddleTelemetry.onError(data, env, dataLayer);
        break;
      case 'Checkout.Close':
        PaddleTelemetry.onClose(data, env, dataLayer);
        break;
      case 'Checkout.Coupon.Applied':
        PaddleTelemetry.onCouponApplied(data, env, dataLayer);
        break;
    }
  }

  static onLoaded(data, env, dataLayer: DataLayer) {
    const priceBeforeTax = PaddleTelemetry.getPriceBeforeTax(
      data?.eventData?.checkout?.prices?.customer?.total,
      data?.eventData?.checkout?.prices?.customer?.total_tax
    );
    dataLayer.push({ ecommerce: null, paddle_error: null });
    dataLayer.push({
      event: 'begin_checkout',
      paddle_user_id: data.eventData?.user?.id,
      ecommerce: {
        currency: data.eventData.checkout.prices.customer.currency,
        value: priceBeforeTax,
        tax: data.eventData.checkout.prices.customer.unit_tax,
        items: PaddleTelemetry.paddleProductToEcommerceItems(data),
      },
    });
  }

  static onLocationSubmit(data, env, dataLayer: DataLayer) {
    const priceBeforeTax = PaddleTelemetry.getPriceBeforeTax(
      data?.eventData?.checkout?.prices?.customer?.total,
      data?.eventData?.checkout?.prices?.customer?.total_tax
    );
    dataLayer.push({ ecommerce: null, paddle_error: null });
    dataLayer.push({
      event: 'add_shipping_info',
      paddle_user_id: data.eventData?.user?.id,
      ecommerce: {
        currency: data.eventData.checkout.prices.customer.currency,
        value: priceBeforeTax,
        tax: data.eventData.checkout.prices.customer.unit_tax,
        items: PaddleTelemetry.paddleProductToEcommerceItems(data),
      },
    });
  }

  static onPaymentMethodSelected(data, env, dataLayer: DataLayer) {
    const priceBeforeTax = PaddleTelemetry.getPriceBeforeTax(
      data?.eventData?.checkout?.prices?.customer?.total,
      data?.eventData?.checkout?.prices?.customer?.total_tax
    );
    dataLayer.push({ ecommerce: null, paddle_error: null });
    dataLayer.push({
      event: 'add_payment_info',
      paddle_user_id: data.eventData?.user?.id,
      ecommerce: {
        payment_type: data.eventData.payment_method,
        currency: data.eventData.checkout.prices.customer.currency,
        value: priceBeforeTax,
        tax: data.eventData.checkout.prices.customer.unit_tax,
        items: PaddleTelemetry.paddleProductToEcommerceItems(data),
        coupon: data.eventData?.checkout?.coupon?.coupon_code,
      },
    });
  }

  static onPaymentComplete(data, env, dataLayer: DataLayer) {
    const priceBeforeTax = PaddleTelemetry.getPriceBeforeTax(
      data?.eventData?.checkout?.prices?.customer?.total,
      data?.eventData?.checkout?.prices?.customer?.total_tax
    );

    dataLayer.push({ ecommerce: null, paddle_error: null });
    dataLayer.push({
      event: 'purchase',
      paddle_user_id: data.eventData?.user?.id,
      ecommerce: {
        transaction_id: data.eventData.checkout.id,
        affiliation: STORE_NAME,
        currency: data.eventData.checkout.prices.customer.currency,
        value: priceBeforeTax,
        tax: data.eventData.checkout.prices.customer.unit_tax,
        items: PaddleTelemetry.paddleProductToEcommerceItems(data),
        coupon: data.eventData?.checkout?.coupon?.coupon_code,
      },
    });
  }

  static onError(data, env, dataLayer: DataLayer) {
    dataLayer.push({ ecommerce: null, paddle_error: null });

    dataLayer.push({
      event: 'checkout_error',
      paddle_user_id: data.eventData?.user?.id,
      paddle_error: {
        message: data.eventData?.error,
        method: data.eventData?.error_method,
        type: data.eventData?.error_type,
      },
      ecommerce: {
        transaction_id: data.eventData?.checkout?.id,
        items: PaddleTelemetry.paddleProductToEcommerceItems(data),
        coupon: data.eventData?.checkout?.coupon?.coupon_code,
      },
    });
  }

  static onClose(data, env, dataLayer: DataLayer) {
    dataLayer.push({ ecommerce: null, paddle_error: null });

    dataLayer.push({
      event: 'checkout_close',
      paddle_user_id: data.eventData?.user?.id,
      ecommerce: {
        transaction_id: data.eventData?.checkout?.id,
        items: PaddleTelemetry.paddleProductToEcommerceItems(data),
        coupon: data.eventData?.checkout?.coupon?.coupon_code,
      },
    });
  }

  static onCloseManually(userId: any, productData: any, checkoutData: any) {
    PaddleTelemetry.onClose(
      {
        eventData: {
          user: {
            id: userId,
          },
          checkout: checkoutData,
          product: productData,
        },
      },
      null,
      dataLayer
    );
  }

  static onCouponApplied(data, env, dataLayer: DataLayer) {
    dataLayer.push({ ecommerce: null, paddle_error: null });

    const discountInfo = PaddleTelemetry.getDiscountInfo(data);
    dataLayer.push({
      event: 'select_promotion',
      paddle_user_id: data.eventData?.user?.id,
      ecommerce: {
        creative_name: discountInfo?.creative_name,
        creative_slot: discountInfo?.creative_slot,
        location_id: discountInfo?.location_id,
        promotion_id: discountInfo?.promotion_id,
        promotion_name: discountInfo?.promotion_name,
        items: PaddleTelemetry.paddleProductToEcommerceItems(data),
      },
    });
  }

  private static paddleProductToEcommerceItems(data): EcommerceItem[] {
    if (data?.eventData?.product) {
      const priceBeforeTax = PaddleTelemetry.getPriceBeforeTax(
        data?.eventData?.checkout?.prices?.customer?.total,
        data?.eventData?.checkout?.prices?.customer?.total_tax
      );

      const discountInfo = PaddleTelemetry.getDiscountInfo(data);

      return [
        {
          item_id: data.eventData.product?.id,
          item_name: data.eventData.product?.name,
          currency: data.eventData.checkout?.prices.customer.currency,
          price: priceBeforeTax,
          item_category: SUBSCRIPTION_CATEGORY,
          quantity: data.eventData.product?.quantity,
          discount: discountInfo?.discount,
        },
      ];
    }

    return [];
  }

  private static getDiscountInfo(data): Partial<EcommerceItem> {
    if (data.eventData.checkout?.prices?.customer?.items?.length > 0) {
      const paddleDiscounts = data.eventData.checkout.prices.customer.items[0].discounts;
      if (paddleDiscounts.length > 0) {
        return {
          discount: paddleDiscounts[0].net_discount,
          creative_name: paddleDiscounts[0].code,
          promotion_id: paddleDiscounts[0].code,
          promotion_name: paddleDiscounts[0].description || paddleDiscounts[0].code,
        };
      }
    }

    return null;
  }

  private static getPriceBeforeTax(totalPriceFromPaddle: PaddleNumber, taxesFromPaddle: PaddleNumber) {
    // This could also loop through items and add them all up, then you have `net_` and `gross_` fields to distinguish
    // between the prices with taxes. When dealing with the total price, this information is not present.
    const totalPrice = PaddleTelemetry.parsePaddleNumber(totalPriceFromPaddle);
    const taxes = PaddleTelemetry.parsePaddleNumber(taxesFromPaddle);

    return Math.round((totalPrice - taxes) * 1000) / 1000;
  }

  private static parsePaddleNumber(number: PaddleNumber): number {
    if (number == undefined) {
      return 0;
    }

    if (typeof number == 'number') {
      return number;
    }

    if (typeof number == 'string') {
      return Math.round(Number.parseFloat(number) * 100) / 100;
    }
  }
}
