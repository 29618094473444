/* eslint-disable no-invalid-this */
/* eslint-disable valid-jsdoc */
/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import './LoadableImage';

export const DEPIX_IMAGE_TYPE = 'depixImage';

/**
 * fabric.Image subclass
 * @class fabric.DepixImage
 * @extends fabric.DepixImage
 * @return {fabric.DepixImage} thisArg
 *
 */
fabric.DepixImage = class extends fabric.LoadableImage {
  constructor(depixObject, id, padding, fitToMask, options) {
    const type = 'depixImage';
    super(id, type, depixObject, options);

    if (options) this.setOptions(options);

    this.padding = padding;
    this.fitToMask = fitToMask;
    this.hasBorders = false;
    this.cornerStyle = 'circle';
    this.transparentCorners = false;
  }

  setAsset(depixObject) {
    const hasNewImage = !this.depixObject.hasSameImages(depixObject);
    this.depixObject = depixObject;
    let ret = Promise.resolve();
    if (hasNewImage) {
      ret = this.loadImage({ invertMask: false }).then(() => {
        if (this.child.length === 0) return Promise.resolve();
        const promises = this.child.map((children) => children.setAsset(this.depixObject));
        return Promise.all(promises);
      });
    }
    return ret;
  }

  getAssetID() {
    return this.depixObject.objectID;
  }

  updateUIElements(scene, fabricCtx) {
    for (const uiElement of this.uiElements) {
      uiElement.update(this.name, scene, fabricCtx);
    }
  }

  setUIElementVisibility(isVisible) {
    for (const uiElement of this.uiElements) {
      uiElement.visible = isVisible;
    }
  }

  delete() {
    const canvas = this.canvas;
    if (this.child) {
      for (const childObject of this.child) {
        canvas.remove(childObject);
        childObject.dispose();
      }
    }

    for (const uiElement of this.uiElements) {
      canvas.remove(uiElement);
    }
    canvas.remove(this);
    this.dispose();
    canvas.requestRenderAll();
  }

  isCastingShadow() {
    return false;
  }
};
