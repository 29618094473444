import { IconButton, styled } from '@mui/material';
import { Link } from '@components/Ui/Link';
import { ShareToButtonProps } from '@components/SharedImage/ShareToButtonProps';
import RedditIcon from '@components/Ui/Icons/RedditIcon';
import React from 'react';

interface ShareToRedditProps extends ShareToButtonProps {}

export const ShareToReddit = ({ link, title, description, onClick }: ShareToRedditProps) => {
  return (
    <StyledLink
      href={`https://www.reddit.com/submit?url=${encodeURIComponent(link)}&title=${encodeURIComponent(
        title
      )}&text=${encodeURIComponent(description)}`}
      target="_blank"
      rel="noopener">
      <RedditIconButton onClick={onClick}>
        <RedditIcon />
      </RedditIconButton>
    </StyledLink>
  );
};

const RedditIconButton = styled(IconButton)`
  background: #ff4500;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  border-bottom: none;
`;
