import React from 'react';

import { ComposeSelectModeContext, ComposeSelectModeContextValue } from '@contexts/ComposeSelectModeContext';

const useComposeSelectMode = (): ComposeSelectModeContextValue => {
  const context = React.useContext(ComposeSelectModeContext);

  if (context === undefined) {
    throw new Error('useComposeSelectMode must be used within an ComposeSelectModeContext');
  }

  return context;
};

export default useComposeSelectMode;
