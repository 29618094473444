import React, { Context, createContext, ReactNode, useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from '@hooks/Alert';
import { useTranslation } from 'react-i18next';
import { GRAPHQL_ERROR_CODES } from '@libs/graphql/graphqlErrorHelper';

export enum GraphqlErrorEvent {
  OUT_OF_IMAGES = 'OUT_OF_IMAGES',
}

export interface GraphqlErrorState {
  listeners: GraphqlErrorListener[];
}

export interface GraphqlErrorListener {
  name: string;
  callback: (event: GraphqlErrorEvent) => void;
}

export interface GraphqlErrorAction {
  type: GraphqlErrorActionType;
  [p: string]: any;
}

export enum GraphqlErrorActionType {
  ADD_LISTENER,
  REMOVE_LISTENER,
  USER_RAN_OUT_OF_IMAGES,
}

export const GRAPHQL_ERROR_INITIAL_STATE: GraphqlErrorState = {
  listeners: [],
};

export const graphqlErrorReducer = (state: GraphqlErrorState, action: GraphqlErrorAction): GraphqlErrorState => {
  switch (action.type) {
    case GraphqlErrorActionType.ADD_LISTENER:
      return {
        ...state,
        listeners: state.listeners.concat({ name: action.name, callback: action.callback }),
      };
    case GraphqlErrorActionType.REMOVE_LISTENER:
      return {
        ...state,
        listeners: state.listeners.filter((x) => x.name !== action.name),
      };
    case GraphqlErrorActionType.USER_RAN_OUT_OF_IMAGES:
      state.listeners.forEach((x) => x.callback(GraphqlErrorEvent.OUT_OF_IMAGES));
      return state;
  }
};
