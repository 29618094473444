import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { Userpilot } from 'userpilot';
import { useLocation } from 'react-router-dom';
import { useCampaign } from '@hooks/Campaign';
import { useAuthV2 } from '@contexts/AuthV2';
import useUserProfile from '@hooks/UseUserProfile';

export enum UserPilotToken {
  PRO_TIPS = '1657115921gEvc6266',
  CUT_PROJECTED_SHADOW = '1658181073gAsn3012',
  CREATE_BACKGROUND_LAYER = '1658181070gVhz5196',
  CHANGING_ORDER = '1658181066fEej1098',
  LIGHT_AND_SHADOW = '1658181046hExi4355',
  CUT_OUT = '1658181022lXtw1087',
  FRENETIC_CUTTER_HELPER = '1666036131iZmg929',
}

export enum UserPilotEvent {
  SUBSCRIPTION_OFFER_SHOWN = 'Subscription Offer Shown',
  CUT_UPLOADED = 'Cut Uploaded',
  COMPOSE_UPLOADED = 'Compose Uploaded',
  IMAGE_DOWNLOADED = 'Download Image',
  MASK_DOWNLOADED = 'Download Mask',
  PLAIN_BACKGROUND_DOWNLOADED = 'Download With Plain Background',
  TRANSPARENT_BACKGROUND_DOWNLOADED = 'Download With Transparent Background',
  IMAGE_BACKGROUND_DOWNLOADED = 'Download With Image Background',
  SEGMENTATION_EDIT_SELECTED = 'Segmentation Edit Selected',
  OPEN_APP_CLICKED = 'Open App Button Clicked',
  IMAGE_LINK_SHARED = 'Image Link Shared',
  SUBSCRIBE_CLICKED = 'Subscribe Button Clicked',
}

interface UserPilotContext {
  refresh: () => void;
  trackEvent: (event: UserPilotEvent, data?: object) => void;
  trigger: (token: UserPilotToken) => void;
}

export const UserPilotContext = createContext<UserPilotContext | null>(null);

interface UserPilotProviderProps {
  children?: ReactNode;
}

export const UserPilotProvider = ({ children }: UserPilotProviderProps) => {
  const { user, isAuthenticated, isDepix, isChampion, isGuest } = useAuthV2();
  const [isIdentified, setIsIdentified] = useState(false);
  const location = useLocation();
  const { campaign } = useCampaign();
  const userProfile = useUserProfile();

  const refresh = () => Userpilot.reload();

  const trackEvent = (event: UserPilotEvent, data?: object) => {
    Userpilot.track(event.valueOf(), data);
  };

  const trigger = (token: UserPilotToken) => {
    Userpilot.trigger(token.valueOf());
  };

  useEffect(() => {
    if (isAuthenticated && user && !userProfile.loading && !isIdentified && userProfile.hasAudience()) {
      const userDetails = {
        roles: user.roles,
        email: user.email,
        permissions: user.permissions,
        audience_type: userProfile.metadata?.audience,
        user_goals: userProfile.metadata?.goals,
        isGuest: isGuest,
        isDepix: isDepix,
        isChampion: isChampion,
        campaign_name: campaign?.campaignName,
        campaign_content: campaign?.content,
        campaign_term: campaign?.term,
        campaign_medium: campaign?.medium,
        campaign_source: campaign?.source,
      };

      Userpilot.identify(user.auth0Id, userDetails);

      setIsIdentified(true);
    }
  }, [isAuthenticated, user, userProfile, isIdentified, campaign]);

  useEffect(() => {
    refresh();
  }, [location]);

  const value = { refresh, trackEvent, trigger };

  return <UserPilotContext.Provider value={value}>{children}</UserPilotContext.Provider>;
};

export const useUserPilot = (): UserPilotContext => {
  const userPilot = useContext(UserPilotContext);

  return { ...userPilot };
};
