import ToolButton, { TooltipPlacement } from '@components/Viewport/ToolButton';
import { SelectMode } from '@contexts/ComposeSelectModeContext';
import { EraseObjectIcon } from '@components/Ui/Icons/EraseObjectIcon';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ShareIcon from '@components/Ui/Icons/ShareIcon';
import { ShareDialog } from '@components/Viewport/Paste/ShareComposition/ShareDialog';
import { useShareImage } from '@hooks/UseShareImage';
import Loading from '@components/Loading/Loading';

const SHARE_BUTTON_ID = 'share-image';

interface ShareToolbarButton {
  vertical?: boolean;
}

export const ShareToolbarButton = ({ vertical = false }: ShareToolbarButton) => {
  const { t } = useTranslation();
  const shareRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { shareImage, isLoading } = useShareImage();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!isLoading) {
      setOpen(false);
    }
  }, [isLoading]);

  return (
    <>
      <ToolButton
        name={t(`tools.compose.ariaLabel.shareToPublic`)}
        disableBackground={false}
        toolTip={t('tools.compose.tooltips.shareToPublic')}
        tooltipPlacement={vertical ? TooltipPlacement.LEFT : TooltipPlacement.TOP}
        ref={shareRef}
        id={SHARE_BUTTON_ID}
        onClick={handleOpen}>
        <ShareIcon />
      </ToolButton>
      <ShareDialog open={open} onClose={handleClose} onShare={shareImage} />
      {isLoading && <Loading backdrop={true} />}
    </>
  );
};
