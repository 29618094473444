import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PanelSlider from './PanelSlider';
import { TitleTypography, MarkTypography, PanelTitleTypography } from './PanelText';
import Divider from '../Ui/Divider';
import OptionSwitch from '../Ui/Inputs/OptionSwitch';

import useInstrumentation from '@hooks/UseInstrumentation';
import { SHADOW_STATE } from '@libs/DepixObject';
import { MeasureType } from '@libs/Instrumentation/MeasureType';

import { Box, Grid } from '@mui/material';

export const getMarks = (low, high) => {
  return [
    {
      value: low,
      label: <MarkTypography>low</MarkTypography>,
    },
    {
      value: high,
      label: <MarkTypography>high</MarkTypography>,
    },
  ];
};

/**
 *
 * @param {*} props
 * @return {JSX}
 */
function ShadowPanel(props) {
  const {
    isSmallDevice,
    shadowOpacity = 0.5,
    shadowDiffusion = 0,
    onChange,
    onChangeCommitted,
    disabled,
    shadowState = SHADOW_STATE.GROUND,
    onSetWarpingModeCb,
  } = props;

  const onCommitWrapper = (fn) => {
    return (event, newValue) => {
      onChangeCommitted();
      fn(event, newValue);
    };
  };

  const { t } = useTranslation(null, { keyPrefix: 'tools.enhance.panels.shadow' });

  const instrumentation = useInstrumentation();

  const onShadowOpacityChange = (event, newValue) => {
    onChange(newValue, shadowDiffusion);
  };
  const onShadowDiffusionChange = (event, newValue) => {
    onChange(shadowOpacity, newValue);
  };

  const switchOptions = { left: t('projectionTypes.flat'), right: t('projectionTypes.ground') };
  const onSetWarpingMode = useCallback(
    (checked) => {
      onSetWarpingModeCb(checked);
      instrumentation.instrument(MeasureType.SHADOW_GROUND_PROJECTION_TOGGLED, { enabled: checked });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSetWarpingModeCb]
  );

  const onOptionSwitchSwitched = useCallback(
    (option) => {
      const enableWarpingMode = option === switchOptions.right;
      onSetWarpingMode(enableWarpingMode);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSetWarpingMode]
  );

  const containerSx = { width: '100%' };
  const titleTypographyProps = {
    color: 'dark',
    align: 'left',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        height: '100%',
        py: 3,
      }}>
      <Box sx={{ width: '100%', px: 3 }}>
        <Box sx={{ display: 'flex', width: '100%', justifySelf: 'center' }}>
          <PanelTitleTypography>{t('title')}</PanelTitleTypography>
        </Box>
        <Box sx={{ my: 2, width: '100%' }}>
          <Divider direction="horizontal" />
        </Box>
      </Box>

      <Grid
        container
        spacing={{ xs: 0, sm: 2, md: 0 }}
        sx={{ width: '100%', overflow: 'auto', px: isSmallDevice ? 5 : 3 }}>
        <Grid item xs={12} sm={4} md={12}>
          <Box sx={containerSx}>
            <TitleTypography {...titleTypographyProps}>{t('opacity')}</TitleTypography>
            <PanelSlider
              value={shadowOpacity}
              onChange={onShadowOpacityChange}
              onChangeCommitted={onCommitWrapper(onShadowOpacityChange)}
              ariaLabelledby="continuous-slider"
              variant="depixBlue"
              step={0.01}
              min={0}
              max={1}
              disabled={disabled}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={12}>
          <Box sx={containerSx}>
            <TitleTypography {...titleTypographyProps}>{t('blur')}</TitleTypography>
            <PanelSlider
              value={shadowDiffusion}
              onChange={onShadowDiffusionChange}
              onChangeCommitted={onCommitWrapper(onShadowDiffusionChange)}
              ariaLabelledby="continuous-slider"
              variant="depixBlue"
              step={0.01}
              min={0}
              max={3}
              disabled={disabled}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={12}>
          <Box sx={containerSx} onMouseDown={(e) => e.stopPropagation()}>
            <TitleTypography {...titleTypographyProps}>{t('projection')}</TitleTypography>
            <OptionSwitch
              name="ground-projection"
              options={switchOptions}
              selection={shadowState === SHADOW_STATE.GROUND ? 1 : 0}
              onSwitch={onOptionSwitchSwitched}
              sx={{ width: '100%', height: '36px' }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

ShadowPanel.propTypes = {
  isSmallDevice: PropTypes.bool,
  shadowOpacity: PropTypes.number,
  shadowDiffusion: PropTypes.number,
  onChange: PropTypes.func,
  onChangeCommitted: PropTypes.func,
  disabled: PropTypes.bool,
  shadowState: PropTypes.number,
  onSetWarpingModeCb: PropTypes.func,
};

export default memo(ShadowPanel);
