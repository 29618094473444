import { useMutation } from '@apollo/client';
import { LOAD_STOCK_IMAGE } from '@libs/DepixApi';
import { useGraphqlErrorHandler } from '@hooks/useGraphqlErrorHandler';

/**
 * Detect main object in image and return coordinates
 * @param {func} onCompletedCallback
 * @return {*}
 */
function useLoadStockImage(onCompletedCallback) {
  const { displayError } = useGraphqlErrorHandler();

  const [loadFromStockImage, { loading: isLoading }] = useMutation(LOAD_STOCK_IMAGE, {
    onCompleted: ({ loadStockImage }) => {
      onCompletedCallback(loadStockImage);
    },
    onError(error) {
      displayError(error);
    },
  });

  const loadStockImages = (apiName, url, maxSize, isPreview, autoSegment) => {
    const variables = {
      apiName,
      url,
      maxSize,
      isPreview,
      autoSegment,
    };

    loadFromStockImage({
      variables: variables,
    });
  };

  return { loadStockImages, isLoading };
}

export default useLoadStockImage;
