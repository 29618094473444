import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import { BorderRadius } from '@root/theme';

const InputBar = ({
  onChange,
  onSubmit,
  id = 'input-bar',
  label = 'input bar',
  buttonLabel,
  type,
  sx = {},
  endIcon,
}) => {
  const onKeyPress = (evt) => {
    if (evt.key === 'Enter') {
      onSubmit();
    }
  };

  const handleOnChange = (event) => {
    onChange(event.target.value);
  };

  const endAdornment = () => {
    if (endIcon && onSubmit) {
      return (
        <InputAdornment position="end">
          <IconButton aria-label={buttonLabel} onClick={onSubmit} edge="end">
            {endIcon}
          </IconButton>
        </InputAdornment>
      );
    } else {
      return null;
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', my: 2, mx: 4, ...sx }}>
      <TextField
        id={id}
        label={label}
        size="small"
        autoFocus
        type={type}
        inputProps={{
          maxLength: 100,
          role: 'textbox',
          'aria-label': label,
        }}
        InputProps={{
          sx: { borderRadius: (theme) => theme.borderRadius[BorderRadius.MEDIUM] },
          endAdornment: endAdornment(),
        }}
        onKeyPress={onKeyPress}
        onChange={handleOnChange}
      />
    </Box>
  );
};

InputBar.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sx: PropTypes.object,
  endIcon: PropTypes.node,
};

export default InputBar;
