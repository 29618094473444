export const ErrorCode = {
  UNAUTHENTICATED: 'unauthenticated',
  UNVERIFIED: 'unverified',
};

const Routes = {
  errors: {
    index: () => '/error',
    unauthenticated: () => `/error?${ErrorCode.UNAUTHENTICATED}`,
    unverified: () => `/error?${ErrorCode.UNVERIFIED}`,
  },

  imageSharing: {
    index: () => `/s/:sharedImageId`,
    image: (sharedImageId: string) => `/s/${sharedImageId}`,
  },

  auth: () => '/auth',
  home: () => '/',
  subscribe: (queryParams?: string) => `/subscribe${queryParams || ''}`,
  checkout: (checkoutHash) => `/checkout?checkout=${checkoutHash}`,
  onboarding: () => '/welcome',
};

export default Routes;
