import { Dialog, DialogProps } from '@components/Ui/Dialog/Dialog';
import Button from '@components/Ui/Button';
import { useCallback, useState } from 'react';
import { IconButton, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Surface from '@components/Ui/Surface';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';
import CloseIcon from '@components/Ui/Icons/CloseIcon';

interface ShareDialogProps extends DialogProps {
  onShare: (title: string) => void;
}

export const ShareDialog = ({ onShare, onClose, ...dialogProps }: ShareDialogProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'shareToPublic' });
  const [title, setTitle] = useState('');
  const handleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleSubmit = () => onShare(title);
  const handleSubmitWithDefaultTitle = () => onShare('My Image');

  const handleKeyDown = (e) => {
    if (e.code == 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Dialog {...dialogProps} onClose={onClose} PaperComponent={StyledSurface}>
      <StyledIconButton onClick={onClose}>
        <CloseIcon />
      </StyledIconButton>
      <Typography color={'primary.main'} fontSize={40} fontWeight={500} align={'center'}>
        {t('title')}
      </Typography>
      <StyledTextField variant={'standard'} onChange={handleChange} onKeyDown={handleKeyDown} />
      <StyledConfirmButton variant={'contained'} onClick={handleSubmit}>
        {t('confirm')}
      </StyledConfirmButton>
      <StyleSkipButton variant={'text'} onClick={handleSubmitWithDefaultTitle}>
        <Typography color={'secondary.medium'} fontSize={14}>
          {t('skip')}
        </Typography>
      </StyleSkipButton>
    </Dialog>
  );
};

const StyledSurface = styled(Surface)`
  padding: ${(props) => props.theme.spacing(5)};
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing(3)};
  max-width: 640px;
  padding: ${(props) => props.theme.spacing(10)};

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(3)};
  }
`;

const StyledConfirmButton = styled(Button)`
  padding: ${(props) => props.theme.spacing(2, 12)};
  text-transform: none;
`;

const StyleSkipButton = styled(Button)`
  text-transform: none;
`;

const StyledTextField = styled(TextField)`
  .MuiInput-root {
    font-size: 20px;
    margin: ${(props) => props.theme.spacing(0, 3)};

    & input {
      text-align: center;
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 12px;
  right: 12px;
`;
