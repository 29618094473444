import React, { useCallback, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { useNavigate, useLocation } from 'react-router-dom';

import { ApolloLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { defaultLinks } from '@libs/ApolloClient';

import Routes from '@root/routes';
import { useAuthV2 } from '@contexts/AuthV2';
import { LOGIN_QUERY_NAME, RESEND_VERIFICATION_EMAIL_QUERY_NAME, SIGNUP_QUERY_NAME } from '@libs/DepixApi';

const ApiError = {
  UNAUTHENTICATED: 'UNAUTHENTICATED',
};

const FRESH_TOKEN_HEADER = 'x-depix-fresh-token';

const ConfigureApolloClient = ({ client, links = defaultLinks }) => {
  const { getAccessTokenSilently, updateToken } = useAuthV2();
  const navigate = useNavigate();
  const location = useLocation();

  const handleApolloErrors = useCallback(
    (apolloErrors) => {
      const allErrors = apolloErrors.map((e) => e.extensions);

      const unauthenticatedErrors = allErrors.filter((e) => e.code === ApiError.UNAUTHENTICATED);
      if (unauthenticatedErrors.length > 0) {
        navigate(Routes.errors.unauthenticated(), { state: { backgroundLocation: location } });
      }
    },
    [navigate, location]
  );

  const handleAuthError = useCallback(
    (error) => {
      if (!error?.graphQLErrors) {
        return true;
      }

      if (error instanceof AccountNotVerifiedError) {
        return false;
      }

      const exception = error.graphQLErrors[0].extensions;
      const code = exception?.originalCode || exception.code;
      if (code === 'MUST_VALIDATE_EMAIL') {
        navigate(Routes.errors.unverified(), { state: { backgroundLocation: location } });
        return false;
      }

      return true;
    },
    [navigate, location]
  );

  const authorizeRequests = useCallback(
    async (headers) => {
      try {
        const token = await getAccessTokenSilently();

        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        };
      } catch (e) {
        const canContinue = handleAuthError(e);

        if (canContinue) {
          return {
            headers: {
              ...headers,
            },
          };
        }

        throw e;
      }
    },
    [getAccessTokenSilently, handleAuthError]
  );

  const isCallNeedsAuthentication = (call) => {
    return (
      call.selectionSet.selections[0].name.value !== RESEND_VERIFICATION_EMAIL_QUERY_NAME &&
      call.selectionSet.selections[0].name.value !== LOGIN_QUERY_NAME &&
      call.selectionSet.selections[0].name.value !== SIGNUP_QUERY_NAME
    );
  };

  const configureLinks = useCallback(() => {
    const authLink = setContext(async (request, { headers }) => {
      const needsAuth = request?.query?.definitions.some((call) => isCallNeedsAuthentication(call));
      if (needsAuth) {
        return authorizeRequests(headers);
      }
    });

    const errorLink = onError(({ graphQLErrors }) => {
      return handleApolloErrors(graphQLErrors);
    });

    const saveFreshToken = new ApolloLink((operation, forward) => {
      return forward(operation).map((result) => {
        const newToken = operation?.getContext()?.response?.headers?.get(FRESH_TOKEN_HEADER);
        if (newToken) {
          updateToken(newToken);
        }

        return result;
      });
    });

    client.setLink(from([authLink, errorLink, saveFreshToken, ...links]));
  }, [authorizeRequests, client, handleApolloErrors]);

  useLayoutEffect(() => {
    configureLinks();
  }, [configureLinks]);

  return <></>;
};

ConfigureApolloClient.propTypes = {
  client: PropTypes.shape({ setLink: PropTypes.func.isRequired }),
  links: PropTypes.array,
};

export default ConfigureApolloClient;
