import { ApolloError } from '@apollo/client';
import { FabricLoadableImage } from '@components/FabricJS/LoadableImage';
import React, { createContext, useState, useEffect, ReactNode } from 'react';
import use3DScene, { Depix3DScene } from '@hooks/use3DScene';
import useRelight, { RelightState } from '@hooks/UseRelight';
import { DEFAULT_PROPERTIES, DepixObjectProperties } from '@libs/DepixObject';

import { OBJECT_3D_IMAGE_TYPE } from '@components/FabricJS/Object3DImage';
import { INFILL_IMAGE_TYPE } from '@components/FabricJS/InfillImage';
import { DepixObject } from '@libs/DepixObject';

interface SceneContext {
  activeObject: FabricLoadableImage | null;
  setActiveObject: (object: FabricLoadableImage) => void;
  initialiseRelight: (object: FabricLoadableImage) => void;
  activeObjectProperties: DepixObjectProperties;
  setActiveObjectProperties: (props: DepixObjectProperties) => void;
  scene: Depix3DScene;
  renderRelight: (object: FabricLoadableImage, isPreview?: boolean) => void;
  enableRelight: (object: FabricLoadableImage) => void;
  relightState: RelightState;
  error: ApolloError;
}

// For now we are passing a fabricjs element,
// but this should be an object from our api
const SceneContext = createContext<SceneContext | null>(null);

interface SceneContextProviderProps {
  children: ReactNode;
}

export const SceneContextProvider = ({ children }: SceneContextProviderProps) => {
  const [activeObjectProperties, setActiveObjectProperties] = useState(DEFAULT_PROPERTIES);

  const [activeObject, setActiveObject] = useState(null);
  const scene = use3DScene();
  const { enableRelight, initialiseRelight, renderRelight, relightState, error } = useRelight();

  useEffect(() => {
    if (activeObject) {
      const depixObject = scene.getDepixObject(activeObject.name) as DepixObject;
      setActiveObjectProperties({ ...depixObject.prop });
    }
  }, [activeObject]);

  useEffect(() => {
    if (activeObject) {
      for (const updatedObjectID of scene.objectsUpdated) {
        if (updatedObjectID === activeObject.name) {
          const depixObject = scene.getDepixObject(activeObject.name) as DepixObject;
          setActiveObjectProperties({ ...depixObject.prop });
        }
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [scene.objectsUpdated, activeObject]);

  useEffect(() => {
    if (activeObject && activeObject.type === OBJECT_3D_IMAGE_TYPE) {
      const shouldBeRelighted = activeObject.depixObject.prop.relight;
      const isDecomposed = activeObject.isDecomposed();
      if (shouldBeRelighted && !isDecomposed) {
        initialiseRelight(activeObject);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [activeObject?.depixObject]);

  const value = {
    activeObject,
    setActiveObject,
    initialiseRelight,
    activeObjectProperties,
    setActiveObjectProperties,
    scene,
    renderRelight,
    relightState,
    enableRelight,
    error,
  };

  return <SceneContext.Provider value={value}>{children}</SceneContext.Provider>;
};

const checkContext = (context: SceneContext | null, errorMessage: string): SceneContext => {
  if (context === undefined) {
    throw new Error(errorMessage);
  }
  return context;
};

/**
 * Hook to control active object in scene
 */
export const useActiveObject = () => {
  const context = React.useContext(SceneContext);
  checkContext(context, 'useActiveObject must be used within a SceneContextProvider');

  const { activeObject, setActiveObject, activeObjectProperties, enableRelight, renderRelight, scene } = context;

  const setShadowEnabled = (shadowEnabled) => {
    if (activeObject) {
      scene.setComponentShadow(activeObject.name, shadowEnabled);
    }
  };

  const setRelightEnabled = (relightEnabled) => {
    if (activeObject) {
      scene.setComponentRelight(activeObject.name, relightEnabled);
      const isPreview = false;
      if (relightEnabled) {
        enableRelight(activeObject);
      }
      renderRelight(activeObject, isPreview);
    }
  };

  const setLightProperties = (ambientColor, residualAlpha, ambient, lightIntensity) => {
    if (activeObject) {
      scene.setComponentLightProperties(activeObject.name, ambientColor, residualAlpha, ambient, lightIntensity);
    }
  };

  const setShadowProperties = (diffusion, opacity) => {
    if (activeObject) {
      scene.setComponentShadowProperties(activeObject.name, diffusion, opacity);
    }
  };

  const setShadowState = (state) => {
    if (activeObject) {
      scene.setComponentShadowState(activeObject.name, state);
    }
  };

  const setBlur = (blur) => {
    if (activeObject) {
      scene.setComponentEnhancementProperties(activeObject.name, blur);
    }
  };

  const setAsset = (asset) => {
    if (activeObject) {
      scene.setComponentAsset(activeObject.name, asset);
    }
  };

  const ambient = React.useMemo(() => {
    return DepixObject.computeAmbiant(activeObjectProperties.lighting);
  }, [activeObjectProperties]);

  const lightIntensity = React.useMemo(() => {
    return DepixObject.computeLightIntensity(activeObjectProperties.lighting);
  }, [activeObjectProperties]);

  const deleteObject = () => {
    if (activeObject.type === OBJECT_3D_IMAGE_TYPE) scene.removeSceneComponent(activeObject.name);
    if (activeObject.type === INFILL_IMAGE_TYPE) scene.removeInfillSceneComponent(activeObject.name);
  };

  return {
    activeObject,
    isActiveObject: !!activeObject,
    setActiveObject,
    setRelightEnabled,
    setLightProperties,
    setBlur,
    setAsset,
    setShadowState,
    setShadowEnabled,
    setShadowProperties,
    deleteObject,
    setSceneCommitted: scene.setSceneCommitted,
    shadowEnabled: activeObjectProperties.shadowVisible,
    shadowOpacity: activeObjectProperties.shadowOpacity,
    shadowDiffusion: activeObjectProperties.shadowDiffusion,
    shadowState: activeObjectProperties.shadowState,
    relightEnabled: activeObjectProperties.relight,
    ambient,
    ambientColor: activeObjectProperties.ambientColor,
    lightIntensity,
    blur: activeObjectProperties.blur,
    lowResidualAlpha: activeObjectProperties.lowResidualAlpha,
    highResidualAlpha: activeObjectProperties.highResidualAlpha,
  };
};

/**
 * Hook to control scene
 * @return {*}
 */
export const useScene = (): Depix3DScene => {
  const context = React.useContext(SceneContext);
  checkContext(context, 'useScene must be used within a SceneContextProvider');
  return context.scene;
};

export interface UseSceneRelight
  extends Pick<SceneContext, 'renderRelight' | 'relightState' | 'initialiseRelight' | 'error'> {}

export const useSceneRelight = (): UseSceneRelight => {
  const context = React.useContext(SceneContext);
  checkContext(context, 'useRelight must be used within a SceneContextProvider');
  return {
    renderRelight: context.renderRelight,
    relightState: context.relightState,
    initialiseRelight: context.initialiseRelight,
    error: context.error,
  };
};

export default SceneContextProvider;
