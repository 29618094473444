import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PanelSlider from './PanelSlider';
import { PanelTitleTypography, TitleTypography, MarkTypography } from './PanelText';
import Divider from '../Ui/Divider';
import LightColorPicker from './LightColorPicker';

import { Box, Grid } from '@mui/material';

export const getMarks = (low, high) => {
  return [
    {
      value: low,
      label: <MarkTypography>low</MarkTypography>,
    },
    {
      value: high,
      label: <MarkTypography>high</MarkTypography>,
    },
  ];
};

/**
 *
 * @param {*} props
 * @return {JSX}
 */
function RelightPanel(props) {
  const {
    isSmallDevice,
    ambient = 0,
    ambientColor = { r: 0, g: 0, b: 0, a: 1 },
    lightIntensity = 0,
    lowResidualAlpha = 0,
    highResidualAlpha = 0,
    onChange,
    onChangeCommitted,
    objectFocus,
    onObjectFocus,
    disabled,
  } = props;

  const { t } = useTranslation(null, { keyPrefix: 'tools.enhance.panels.light' });

  const onCommitWrapper = (fn) => {
    return (event, newValue) => {
      onChangeCommitted();
      fn(event, newValue);
    };
  };

  const onAmbientChange = (event, newValue) => {
    onChange(newValue, lightIntensity, ambientColor, { low: lowResidualAlpha, high: highResidualAlpha });
  };

  const onLightIntensityChange = (event, newValue) => {
    onChange(ambient, newValue, ambientColor, { low: lowResidualAlpha, high: highResidualAlpha });
  };

  const onAmbientHSVChange = (event, newValue) => {
    onChange(ambient, lightIntensity, newValue, { low: lowResidualAlpha, high: highResidualAlpha });
  };

  const onLowResidualChange = (event, newValue) => {
    onChange(ambient, lightIntensity, ambientColor, { low: newValue, high: highResidualAlpha });
  };

  const onHighResidualChange = (event, newValue) => {
    onChange(ambient, lightIntensity, ambientColor, { low: lowResidualAlpha, high: newValue });
  };

  const containerSx = { width: '100%' };
  const titleTypographyProps = {
    color: 'dark',
    align: 'left',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        py: 3,
      }}>
      <Box sx={{ width: '100%', px: 3 }}>
        <Box sx={{ display: 'flex', width: '100%', justifySelf: 'center' }}>
          <PanelTitleTypography>{t('title')}</PanelTitleTypography>
        </Box>
        <Box sx={{ my: 2, width: '100%' }}>
          <Divider direction="horizontal" />
        </Box>
      </Box>

      <Grid
        container
        spacing={{ xs: 0, sm: 2, md: 0 }}
        sx={{ width: '100%', overflow: 'auto', px: isSmallDevice ? 5 : 3 }}>
        <Grid item xs={12} sm={4} md={12}>
          <Box sx={containerSx}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                overflow: 'auto',
                cursor: 'pointer',
              }}
              onMouseDown={(e) => e.stopPropagation()}>
              <TitleTypography {...titleTypographyProps}>{t('ambient')}</TitleTypography>

              <LightColorPicker
                titleTypographyProps={titleTypographyProps}
                isRowView={false}
                color={ambientColor}
                onChange={onAmbientHSVChange}
                onChangeCommitted={onCommitWrapper(onAmbientHSVChange)}
              />
            </Box>

            <PanelSlider
              value={ambient}
              onChange={onAmbientChange}
              onChangeCommitted={onCommitWrapper(onAmbientChange)}
              ariaLabelledby="continuous-slider"
              marks={null}
              variant="depixBlue"
              step={0.1}
              min={0}
              max={3}
              disabled={disabled}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={12}>
          <Box sx={containerSx}>
            <TitleTypography {...titleTypographyProps}>{t('spotlight')}</TitleTypography>
            <PanelSlider
              value={lightIntensity}
              onChange={onLightIntensityChange}
              onChangeCommitted={onCommitWrapper(onLightIntensityChange)}
              ariaLabelledby="continuous-slider"
              variant="depixBlue"
              step={0.1}
              min={0.01}
              max={4}
              disabled={disabled}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={12}>
          <Box sx={containerSx}>
            <TitleTypography {...titleTypographyProps}>{t('lowlights')}</TitleTypography>
            <PanelSlider
              value={lowResidualAlpha}
              onChange={onLowResidualChange}
              onChangeCommitted={onCommitWrapper(onLowResidualChange)}
              ariaLabelledby="continuous-slider"
              variant="depixBlue"
              step={0.1}
              min={0.01}
              max={2}
              disabled={disabled}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={12}>
          <Box sx={containerSx}>
            <TitleTypography {...titleTypographyProps}>{t('highlights')}</TitleTypography>
            <PanelSlider
              value={highResidualAlpha}
              onChange={onHighResidualChange}
              onChangeCommitted={onCommitWrapper(onHighResidualChange)}
              ariaLabelledby="continuous-slider"
              variant="depixBlue"
              step={0.1}
              min={0.01}
              max={2}
              disabled={disabled}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={12}>
          <Box sx={containerSx}>
            <TitleTypography {...titleTypographyProps}>{t('focus')}</TitleTypography>
            <PanelSlider
              value={objectFocus}
              onChange={onObjectFocus}
              onChangeCommitted={onCommitWrapper(onObjectFocus)}
              ariaLabelledby="continuous-slider"
              variant="depixBlue"
              step={0.01}
              min={0}
              max={1}
              disabled={disabled}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

RelightPanel.propTypes = {
  isSmallDevice: PropTypes.bool.isRequired,
  ambient: PropTypes.number,
  ambientColor: PropTypes.object,
  lightIntensity: PropTypes.number,
  lowResidualAlpha: PropTypes.number,
  highResidualAlpha: PropTypes.number,
  onChange: PropTypes.func,
  onChangeCommitted: PropTypes.func,
  objectFocus: PropTypes.number,
  onObjectFocus: PropTypes.func,
  disabled: PropTypes.bool,
};

export default memo(RelightPanel);
