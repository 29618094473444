/* eslint-disable */

(function () {
  'use strict';

  var filters = fabric.Image.filters,
    createClass = fabric.util.createClass;

  /**
   * Image Blend filter class
   * @class fabric.Image.filter.SetAlpha
   * @memberOf fabric.Image.filters
   * @extends fabric.Image.filters.BaseFilter
   * @example
   * var filter = new fabric.Image.filters.BlendColor({
   *  color: '#000',
   * });
   *
   * var filter = new fabric.Image.filters.SetAlpha({
   *  image: fabricImageObject,
   *  alpha: 0.5
   * });
   * object.filters.push(filter);
   * object.applyFilters();
   * canvas.renderAll();
   */

  filters.SetAlpha = createClass(
    filters.BaseFilter,
    /** @lends fabric.Image.filters.SetAlpha.prototype */ {
      type: 'SetAlpha',

      /**
       * Color to make the blend operation with. default to a reddish color since black or white
       * gives always strong result.
       **/
      image: null,

      /**
       * alpha value. will add an offset to the alpha value
       **/
      alpha: 0,

      /**
       * invert bool. define if the mask should be inverted.
       * not implemented.
       **/
      invert: false,

      vertexSource:
        'attribute vec2 aPosition;\n' +
        'varying vec2 vTexCoord;\n' +
        'varying vec2 vTexCoord2;\n' +
        'uniform mat3 uTransformMatrix;\n' +
        'void main() {\n' +
        'vTexCoord = aPosition;\n' +
        'vTexCoord2 = (uTransformMatrix * vec3(aPosition, 1.0)).xy;\n' +
        'gl_Position = vec4(aPosition * 2.0 - 1.0, 0.0, 1.0);\n' +
        '}',

      /**
       * Fragment source for the Multiply program
       */
      fragmentSource: {
        mask:
          'precision highp float;\n' +
          'uniform sampler2D uTexture;\n' +
          'uniform sampler2D uImage;\n' +
          'uniform bool invert;\n' +
          'uniform float alpha;\n' +
          'uniform vec4 uColor;\n' +
          'varying vec2 vTexCoord;\n' +
          'varying vec2 vTexCoord2;\n' +
          'void main() {\n' +
          'vec4 color = texture2D(uTexture, vTexCoord);\n' +
          'vec4 color2 = texture2D(uImage, vTexCoord2);\n' +
          'color.a = color.a * (alpha + max(color2.r, max(color2.g, color2.b)));\n' +
          'if(invert){color.a = 1. - color.a;}\n' +
          'gl_FragColor = color;\n' +
          '}',
      },

      /**
       * Retrieves the cached shader.
       * @param {Object} options
       * @param {WebGLRenderingContext} options.context The GL context used for rendering.
       * @param {Object} options.programCache A map of compiled shader programs, keyed by filter type.
       */
      retrieveShader: function (options) {
        var cacheKey = this.type;
        var shaderSource = this.fragmentSource['mask'];
        if (!options.programCache.hasOwnProperty(cacheKey)) {
          options.programCache[cacheKey] = this.createProgram(options.context, shaderSource);
        }
        return options.programCache[cacheKey];
      },

      applyToWebGL: function (options) {
        // load texture to blend.
        var gl = options.context;
        if (this.image) {
          const texture = this.createTexture(options.filterBackend, this.image);
          this.bindAdditionalTexture(gl, texture, gl.TEXTURE1);
        }
        this.callSuper('applyToWebGL', options);
        this.unbindAdditionalTexture(gl, gl.TEXTURE1);
      },

      createTexture: function (backend, image) {
        return backend.getCachedTexture(image.cacheKey, image._element);
      },

      /**
       * Calculate a transformMatrix to adapt the image to blend over
       * @param {Object} options
       * @param {WebGLRenderingContext} options.context The GL context used for rendering.
       * @param {Object} options.programCache A map of compiled shader programs, keyed by filter type.
       */
      calculateMatrix: function () {
        var image = this.image,
          width = image._element.width,
          height = image._element.height;
        return [1 / image.scaleX, 0, 0, 0, 1 / image.scaleY, 0, -image.left / width, -image.top / height, 1];
      },

      /**
       * Apply the Blend operation to a Uint8ClampedArray representing the pixels of an image.
       *
       * @param {Object} options
       * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
       */
      applyTo2d: function (options) {
        if (!this.image) return;
        var imageData = options.imageData,
          resources = options.filterBackend.resources,
          data = imageData.data,
          iLen = data.length,
          width = imageData.width,
          height = imageData.height,
          tr,
          tg,
          tb,
          ta,
          r,
          g,
          b,
          a,
          canvas1,
          context,
          image = this.image,
          blendData;

        if (!resources.SetAlpha) {
          resources.SetAlpha = fabric.util.createCanvasElement();
        }
        canvas1 = resources.SetAlpha;
        context = canvas1.getContext('2d');
        if (canvas1.width !== width || canvas1.height !== height) {
          canvas1.imageWidth = width;
          canvas1.imageHeight = height;
        } else {
          context.clearRect(0, 0, width, height);
        }
        context.setTransform(image.scaleX, 0, 0, image.scaleY, image.left, image.top);
        context.drawImage(image._element, 0, 0, width, height);
        blendData = context.getImageData(0, 0, width, height).data;

        for (var i = 0; i < iLen; i += 4) {
          let alphaValue = blendData[i + 3];
          alphaValue *= this.alpha + Math.max(blendData[i], blendData[i + 1], blendData[i + 2]);
          if (this.invert) {
            alphaValue = 255 - alphaValue;
          }
          data[i + 3] = alphaValue;
        }
      },

      /**
       * Return WebGL uniform locations for this filter's shader.
       *
       * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
       * @param {WebGLShaderProgram} program This filter's compiled shader program.
       */
      getUniformLocations: function (gl, program) {
        return {
          uTransformMatrix: gl.getUniformLocation(program, 'uTransformMatrix'),
          uImage: gl.getUniformLocation(program, 'uImage'),
          alpha: gl.getUniformLocation(program, 'alpha'),
          invert: gl.getUniformLocation(program, 'invert'),
        };
      },

      /**
       * Send data from this filter to its shader program's uniforms.
       *
       * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
       * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
       */
      sendUniformData: function (gl, uniformLocations) {
        gl.uniform1i(uniformLocations.uImage, 1); // texture unit 1.
        gl.uniform1i(uniformLocations.invert, this.invert);
        gl.uniform1f(uniformLocations.alpha, this.alpha);
        if (this.image) {
          var matrix = this.calculateMatrix();
          gl.uniformMatrix3fv(uniformLocations.uTransformMatrix, false, matrix);
        }
      },

      /**
       * Returns object representation of an instance
       * @return {Object} Object representation of an instance
       */
      toObject: function () {
        return {
          type: this.type,
          image: this.image && this.image.toObject(),
        };
      },
    }
  );

  /**
   * Returns filter instance from an object representation
   * @static
   * @param {Object} object Object to create an instance from
   * @param {function} callback to be invoked after filter creation
   * @return {fabric.Image.filters.SetAlpha} Instance of fabric.Image.filters.SetAlpha
   */
  fabric.Image.filters.SetAlpha.fromObject = function (object, callback) {
    fabric.Image.fromObject(object.image, function (image) {
      var options = fabric.util.object.clone(object);
      options.image = image;
      callback(new fabric.Image.filters.SetAlpha(options));
    });
  };
})(typeof exports !== 'undefined' ? exports : this);
