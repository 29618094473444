import React from 'react';
import PropTypes from 'prop-types';

import VideoPlayer, { Videos } from './VideoPlayer';
import { Box, Dialog, Typography, useMediaQuery, useTheme } from '@mui/material';
import Surface from '../Ui/Surface';

const WIDTH_RATIO = 0.66;
const WIDTH_RATIO_SM = 0.5;

const StyledSurface = ({ children }) => <Surface sx={{ p: 2 }}>{children}</Surface>;
StyledSurface.propTypes = {
  children: PropTypes.node,
};

const VideoDialog = ({ videoName, title, open, onClose, maxWidth = '1000px' }) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const widthRatio = isSmallDevice ? WIDTH_RATIO_SM : WIDTH_RATIO;

  return (
    <Dialog open={open} onClose={onClose} PaperComponent={StyledSurface}>
      <Box sx={{ maxWidth: maxWidth, width: `${100 * widthRatio}vw`, display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ fontWeight: 500, fontSize: 30, color: 'primary.main', mb: 1 }}>{title}</Typography>
        <VideoPlayer videoName={videoName} />
      </Box>
    </Dialog>
  );
};

VideoDialog.propTypes = {
  videoName: PropTypes.oneOf(Object.values(Videos)),
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  maxWidth: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
};

export default VideoDialog;
