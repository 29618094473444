import { styled } from '@mui/material/styles';
import { Box, Checkbox, FormGroup, IconButton, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';
import { SelectOption } from '@components/Ui/Form/Select/SelectOption';
import { GoalType } from '@libs/Profile/Goal';
import Button from '@components/Ui/Button';
import { Link } from '@components/Ui/Link';
import { ArrowBack } from '@mui/icons-material';

interface GoalSelectStepProps {
  goals: GoalType[];
  selectedGoals: GoalType[];
  onChange: (selectedGoals: GoalType[]) => void;
  onBack: () => void;
  onSubmit: () => void;
  className?: string;
}

export const GoalSelectStep = ({
  goals,
  selectedGoals,
  onChange,
  onBack,
  onSubmit,
  className,
}: GoalSelectStepProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'onboarding.goalSelect' });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(
      event.target.checked
        ? [...selectedGoals, event.target.value as GoalType]
        : selectedGoals.filter((goal) => goal != event.target.value)
    );
  }

  function handleSubmit() {
    onSubmit();
  }

  return (
    <>
      <BackButton onClick={onBack}>
        <ArrowBack />
      </BackButton>

      <Typography variant="h5" fontWeight={500} textAlign="center">
        {t('title')}
      </Typography>

      <GoalList className={className} onChange={handleChange}>
        {goals.map((goal) => (
          <GoalOption
            key={goal}
            value={goal}
            name={t(`options.${goal}`)}
            selected={selectedGoals.includes(goal)}
            control={<InvisibleCheckbox icon={<></>} checkedIcon={<></>} />}
          />
        ))}
      </GoalList>

      <GoalActions>
        <StyledButton onClick={handleSubmit} variant="contained" disabled={selectedGoals.length == 0}>
          {t('submit')}
        </StyledButton>

        <Typography variant="body2" color="greyInfo.main" textAlign="center">
          <Trans t={t} i18nKey="skip" components={{ skipLink: <Link onClick={handleSubmit} /> }} />
        </Typography>
      </GoalActions>
    </>
  );
};

const GoalList = styled(FormGroup)`
  margin: ${(props) => props.theme.spacing(3, 0)};
  padding: ${(props) => props.theme.spacing(0, 4)};
`;

const GoalOption = styled(SelectOption)`
  padding: ${(props) => props.theme.spacing(2)};
`;

const InvisibleCheckbox = styled(Checkbox)`
  display: none;
`;

const GoalActions = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const BackButton = styled(IconButton)`
  margin-left: ${(props) => props.theme.spacing(-2)};
  margin-top: ${(props) => props.theme.spacing(-2)};

  color: ${(props) => props.theme.palette.secondary.contrastText};

  ${(props) => props.theme.breakpoints.up('md')} {
    display: none;
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    margin-left: ${(props) => props.theme.spacing(-4)};
  }
`;
