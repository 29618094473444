import { useAuthV2 } from '@contexts/AuthV2';
import React, { useEffect, useState } from 'react';

import AccountAndSettings from './AccountAndSettings';

import useUserProfile from '../../Hooks/UseUserProfile';

const AccountAndSettingContainer = () => {
  const { profile } = useUserProfile();
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    // For now, a user can only have 1 subscription
    const sub = profile.subscriptions && profile.subscriptions.length > 0 ? profile.subscriptions[0] : null;
    setSubscription(sub);
  }, [profile, profile.subscriptions]);

  return (
    <AccountAndSettings
      uploadLimit={profile?.uploadLimit}
      subscription={subscription}
      subscriptionError={profile.subscriptionError}
    />
  );
};

export default AccountAndSettingContainer;
