import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import InputBar from './InputBar';
import SearchImageIcon from './Icons/SearchImageIcon';

const SearchBar = ({ onChange, onSubmit, sx = {} }) => {
  const { t } = useTranslation();

  return (
    <InputBar
      onChange={onChange}
      onSubmit={onSubmit}
      id="stock-image-search"
      label={t('gallery.searchLabel')}
      buttonLabel={t('gallery.searchButton')}
      sx={sx}
      endIcon={<SearchImageIcon />}
    />
  );
};

SearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sx: PropTypes.object,
};

export default SearchBar;
