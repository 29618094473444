// noinspection ES6UnusedImports

import Vector2D from '../../Libs/Geometry/Vector2D';
import { dataURItoBlob } from '@libs/ImgUtils';

// eslint-disable no-unused-vars
import SetAlpha from './Filters/SetAlpha';
import GaussianBlurFilter from './Filters/GaussianBlurFilter';
// eslint-enable no-unused-vars

import { INFILL_IMAGE_TYPE } from './InfillImage';
import { OBJECT_3D_IMAGE_TYPE } from './Object3DImage';

/**
 * Scale and Position image to canvas center
 * @param {fabric.Canvas} canvas
 * @param {fabric.Object} fabricObject Object to position
 * @param {number} scale
 * @param {boolean} selectable
 */
export function setImageToCenter(canvas, fabricObject, scale = 1) {
  // warning, takes for granted that canvas is square
  const scaleFactor = getBestFitScaleFactor(canvas.width, canvas.height, fabricObject);
  fabricObject.setScale(scaleFactor * scale, scaleFactor * scale);
}

/**
 *
 * @param {*} width
 * @param {*} height
 * @param {*} object
 * @return {*}
 */
export function getBestFitScaleFactor(width, height, object) {
  const [oWidth, oHeight] = object.getSize();
  let scaleFactor = 1;
  if (oHeight < oWidth) {
    scaleFactor = width / oWidth;
  } else {
    scaleFactor = height / oHeight;
  }
  return scaleFactor;
}

fabric.Object.prototype.getSize = function () {
  let pWidth = this.width;
  let pHeight = this.height;
  if (this.paddingRatio) {
    pWidth -= this.width / this.paddingRatio;
    pHeight -= this.height / this.paddingRatio;
  }
  return [pWidth, pHeight];
};

fabric.Object.prototype.setSize = function (x, y) {
  let padding = 1;
  if (this.paddingRatio) {
    padding = this.paddingRatio;
  }
  this.width = Math.ceil(x * padding);
  this.height = Math.ceil(y * padding);
};

fabric.Object.prototype.getPosition = function () {
  return [this.left, this.top];
};

const multiply = fabric.util.multiplyTransformMatrices;
const invert = fabric.util.invertTransform;

// Shadow and reflection support
fabric.Object.prototype.setPosition = function (x, y, angle) {
  this.left = x;
  this.top = y;
  this.angle = angle;
  this.setCoords();

  // If position is set for a parent, update all child based on their offset
  if (this.child) {
    for (const childObject of this.child) {
      childObject.moveToOffset();
    }
  }
  if (this.parent) {
    this.setOffset(this.parent);
  }
};

fabric.Object.prototype.setScale = function (x, y) {
  this.scaleX = x;
  this.scaleY = y;
};

fabric.Object.prototype.setOffset = function (fabricObject) {
  const childTransform = this.calcTransformMatrix();
  const parentTransform = fabricObject.calcTransformMatrix();

  const offset = multiply(invert(parentTransform), childTransform);
  this.baseOffset = offset;
};

fabric.Object.prototype.moveToOffset = function (offset = null) {
  if (offset) {
    this.baseOffset = offset;
  }
  // refresh its position w.r.t the parent
  if (this.parent) {
    const parentTransform = this.parent.calcTransformMatrix();
    const newPose = multiply(parentTransform, this.baseOffset);
    const decomposition = fabric.util.qrDecompose(newPose);
    this.setDecomposition(decomposition);
  }
};

fabric.Object.prototype.toGlobal = function (coord) {
  const parentTransform = this.calcTransformMatrix();
  return fabric.util.transformPoint(coord, parentTransform);
};

fabric.Object.prototype.getGlobalCorners = function () {
  const corners = this.getLocalCorners();
  return corners.map((corner) => this.toGlobal(corner));
};

fabric.Object.prototype.getLocalCorners = function () {
  const size = this.getSize();
  const corners = [];
  corners.push(new fabric.Point(-size[0] / 2, -size[1] / 2));
  corners.push(new fabric.Point(size[0] / 2, -size[1] / 2));
  corners.push(new fabric.Point(size[0] / 2, size[1] / 2));
  corners.push(new fabric.Point(-size[0] / 2, size[1] / 2));
  return corners;
};

fabric.Object.prototype.setDecomposition = function (decomposition) {
  this.scaleX = decomposition.scaleX;
  this.scaleY = decomposition.scaleY;
  this.angle = decomposition.angle;
  if ('flipX' in decomposition) this.flipX = decomposition.flipX;
  if ('flipY' in decomposition) this.flipY = decomposition.flipY;
  this.setPositionByOrigin(new fabric.Point(decomposition.translateX, decomposition.translateY), 'center', 'center');
  this.setCoords();
  // If position is set for a parent, update all child based on their offset
  if (this.child) {
    for (const childObject of this.child) {
      childObject.moveToOffset();
    }
  }
  if (this.parent) {
    this.setOffset(this.parent);
  }
};

/**
 * Draw a circle at the canvas position (will be replaced if recalled)
 * @param {*} x
 * @param {*} y
 * @param {*} canvas
 * @param {*} tag
 * @param {*} color
 * @param {*} radius
 */
export function drawDebugCircle(x, y, canvas, tag = 'debug', color = 'green', radius = 2) {
  const circle = new fabric.Circle({
    top: y,
    left: x,
    radius: radius,
    fill: '',
    stroke: color,
    strokeWidth: 3,
  });
  circle.name = tag;
  canvas.removeObjectsByName(tag);
  canvas.add(circle);
}

/**
 * Draw a line at the canvas position (will be replaced if recalled)
 * @param {*} x1
 * @param {*} y1
 * @param {*} x2
 * @param {*} y2
 * @param {*} canvas
 * @param {*} tag
 * @param {*} color
 */
export function drawDebugLine(x1, y1, x2, y2, canvas, tag = 'debug', color = 'green') {
  const line = new fabric.Line([x1, y1, x2, y2], {
    stroke: color,
  });
  line.name = tag;
  canvas.removeObjectsByName(tag);
  canvas.add(line);
}

fabric.Canvas.prototype.getItemsByName = function (name) {
  const objectsReturn = [];
  const objects = this.getObjects();

  for (let i = 0, len = this.size(); i < len; i++) {
    if (objects[i].name && objects[i].name === name) {
      objectsReturn.push(objects[i]);
    }
  }
  return objectsReturn;
};

fabric.Canvas.prototype.getOrderedItemsByName = function (nameArray) {
  const objects = this.getObjects();
  const ret = [];
  const tempMap = {};

  // Get all valid objects in a map
  for (let i = 0, len = this.size(); i < len; i++) {
    if (objects[i].type === OBJECT_3D_IMAGE_TYPE || objects[i].type === INFILL_IMAGE_TYPE) {
      tempMap[objects[i].name] = objects[i];
    }
  }

  // Create the list of object with array order
  for (let i = 0; i < nameArray.length; i++) {
    const mapObject = tempMap[nameArray[i]];
    if (mapObject) {
      ret.push(tempMap[nameArray[i]]);
    }
  }

  return ret;
};

fabric.Canvas.prototype.removeObjectsByName = function (name) {
  const tempObjs = this.getItemsByName(name);
  for (const index in tempObjs) {
    if (Object.prototype.hasOwnProperty.call(tempObjs, index)) {
      this.remove(tempObjs[index]);
      if (tempObjs[index].dispose) {
        tempObjs[index].dispose();
      }
    }
  }
};

fabric.Canvas.prototype.addToPosition = function (object, position) {
  this.add(object);
  while (object.getZIndex() > position) {
    this.sendBackwards(object);
  }
};

fabric.Object.prototype.getZIndex = function () {
  return this.canvas.getObjects().indexOf(this);
};

/**
 * Copy transform attribute between objects
 * @param {fabric.Object} obj1
 * @param {fabric.Object} obj2
 */
export function setTransformation(obj1, obj2) {
  obj1.scaleX = obj2.scaleX;
  obj1.scaleY = obj2.scaleY;
  obj1.left = obj2.left;
  obj1.top = obj2.top;
  obj1.angle = obj2.angle;
}

/**
 * Set and apply filter to a fabric image
 * @param {fabric.Object} obj
 * @param {fabric.Object} maskImage
 * @param {fabric.filters.filter} Filter
 * @param {object} filterArgs
 */
export function setFilterToImage(obj, maskImage, Filter, filterArgs = {}) {
  // todo: Check resolution match
  const imageArg = { image: maskImage };
  const filterInstance = new Filter({ ...imageArg, ...filterArgs });
  obj.filters.push(filterInstance);
  obj.applyFilters();
}

/**
 * decompose transformation matrix into rotation/translation/scaling/skew
 * @param {Transformation2D} transformation2D
 * @return {*}
 */
export const decomposeTransform = (transformation2D) => {
  return fabric.util.qrDecompose([
    transformation2D.matrix[0][0],
    transformation2D.matrix[1][0],
    transformation2D.matrix[0][1],
    transformation2D.matrix[1][1],
    transformation2D.matrix[0][2],
    transformation2D.matrix[1][2],
  ]);
};

/**
 * return the relative position of xy w.r.t canvasObject.
 * [Warning] does not support rotated objects
 * @param {Vector2D} coordinate
 * @param {fabric.Object} canvasObject
 * @param {boolean} clip
 * @return {object} Coordinates relative to canvasObject [x, y]
 */
export const getRelativePosition = (coordinate, canvasObject, clip = true) => {
  let xRel = (coordinate.x - canvasObject.left) / canvasObject.scaleX;
  let yRel = (coordinate.y - canvasObject.top) / canvasObject.scaleY;
  if (clip) {
    xRel = xRel < 0 ? 0 : xRel;
    yRel = yRel < 0 ? 0 : yRel;
    xRel = xRel > canvasObject.width ? canvasObject.width : xRel;
    yRel = yRel > canvasObject.height ? canvasObject.height : yRel;
  }
  return new Vector2D(xRel, yRel);
};

/**
 * return the global position of xy w.r.t canvasObject.
 * [Warning] does not support rotated objects
 * @param {Vector2D} coord
 * @param {fabric.Object} canvasObject
 * @return {Vector2D}
 */
export const getGlobalPosition = (coord, canvasObject) => {
  const x = coord.x * canvasObject.scaleX + canvasObject.left;
  const y = coord.y * canvasObject.scaleY + canvasObject.top;
  return new Vector2D(x, y);
};

export const toCanvasCoord = (decomposition, background) => {
  const canvasCoord = getGlobalPosition(new Vector2D(decomposition.translateX, decomposition.translateY), background);
  const canvasSize = [decomposition.scaleX * background.scaleX, decomposition.scaleY * background.scaleY];
  decomposition.translateX = canvasCoord.x;
  decomposition.translateY = canvasCoord.y;
  decomposition.scaleX = canvasSize[0];
  decomposition.scaleY = canvasSize[1];
  return decomposition;
};

export const convertClickFromEvent = (canvas, background, clickEvent) => {
  const RIGHT_CLICK_BUTTON = 3;
  if (canvas.getActiveObject()) return;

  const pointer = canvas.getPointer(clickEvent);
  const backgroundCoord = getRelativePosition(pointer, background);
  const relativeCoordX = backgroundCoord.x / background.width;
  const relativeCoordY = backgroundCoord.y / background.height;
  const alternativeButton = clickEvent.button === RIGHT_CLICK_BUTTON;

  return { x: relativeCoordX, y: relativeCoordY, alt: alternativeButton };
};

export const generateBlobUrl = (data) => {
  const blob = dataURItoBlob(data);
  return URL.createObjectURL(blob);
};

export const isWebGLSupported = () => {
  return fabric.enableGLFiltering && fabric.isWebglSupported && fabric.isWebglSupported(fabric.textureSize);
};
