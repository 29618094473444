import React from 'react';

import { SegmentationContext } from './SegmentationContext';

export const useSegmentation = () => {
  const context = React.useContext(SegmentationContext);

  if (context === undefined) {
    throw new Error('useSegmentation must be used within a SegmentationProvider');
  }

  return context;
};
