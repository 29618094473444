import { Box, styled } from '@mui/material';
import Loading from '@components/Loading/Loading';
import { useParams } from 'react-router-dom';
import Header from '@components/Header/Header';
import { SharedImageView } from '@components/SharedImage/SharedImageView';
import { SharedImageAside } from '@components/SharedImage/SharedImageAside';
import { useSharedImage } from '@hooks/SharedImage/SharedImage';
import { SharedImageError } from '@components/SharedImage/SharedImageError';
import { useEffect } from 'react';
import ga4 from 'react-ga4';
import { Helmet } from 'react-helmet-async';

interface ShareImagePageProps {}

export const ShareImagePage = ({}: ShareImagePageProps) => {
  const { sharedImageId } = useParams();
  const { sharedImage, trendingImages, loading, error } = useSharedImage(sharedImageId);

  const pageTitle = sharedImage ? `${sharedImage.title} — Depix` : 'Depix';

  useEffect(() => {
    if (sharedImage) {
      document.title = pageTitle;
      ga4.send('pageview');
    }
  }, [sharedImage]);

  return (
    <Wrapper>
      <StyledHeader />

      {loading && <Loading global={true} backdrop={true} />}

      <PageContent>
        {!loading && sharedImage && (
          <>
            <Helmet prioritizeSeoTags>
              <title>{pageTitle}</title>
              <meta property="og:title" content={pageTitle} />
              <meta property="og:image" content={sharedImage.imageUrl} />
            </Helmet>

            <StyledSharedImageView sharedImage={sharedImage} />
            <StyledSharedImageAside sharedImage={sharedImage} trendingImages={trendingImages} />
          </>
        )}

        {error && <SharedImageError trendingImages={trendingImages} />}
      </PageContent>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.palette.secondary.light};

  overflow: auto;
`;

const StyledHeader = styled(Header)`
  background-color: ${(props) => props.theme.palette.secondary.light};
`;

const PageContent = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing(6)};
  justify-content: center;

  padding: ${(props) => props.theme.spacing(12, 12, 4)};
  overflow: auto;

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: ${(props) => props.theme.spacing(8, 4, 2)};
    gap: ${(props) => props.theme.spacing(2)};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    justify-content: flex-start;

    padding: ${(props) => props.theme.spacing(4, 2, 1)};
  }
`;

const StyledSharedImageView = styled(SharedImageView)`
  flex-grow: 1;
  flex-basis: auto;
  max-width: 600px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-top: ${(props) => props.theme.spacing(4)};
  }
`;

const StyledSharedImageAside = styled(SharedImageAside)`
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 33%;
  max-width: 600px;
  margin-top: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.down('lg')} {
    margin-top: ${(props) => props.theme.spacing(5)};
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-top: ${(props) => props.theme.spacing(4)};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-top: 0;
  }
`;
