import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import StockImages from './StockImages';
import Divider from '@components/Ui/Divider';
import CloseIcon from '@components/Ui/Icons/CloseIcon';
import IconButton from '@mui/material/IconButton';

const StockImagesDialog = ({ open, onClose, target }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Drawer open={open} onClose={onClose}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: isSmallDevice ? '100%' : 500,
            height: '100%',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}>
          <Toolbar sx={{ flex: '0 0 auto', pt: 3, pb: 2 }}>
            <Typography variant="h4">{t('drawer.stockImages.title')}</Typography>
            <IconButton
              aria-label={t('help.ariaLabel.closeIcon')}
              sx={{ height: 'auto', position: 'absolute', right: 0, top: 0 }}
              onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>

          <StyledDivider direction="horizontal" />

          <StockImages closeGallery={onClose} target={target} sx={{ flex: '1 1 auto' }} />
        </Box>
      </Drawer>
    </>
  );
};

const StyledDivider = styled(Divider)`
  flex: 0 0 auto;
`;

StockImagesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  target: PropTypes.string.isRequired,
};

export default StockImagesDialog;
