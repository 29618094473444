import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useActiveObject } from '@contexts/SceneContext';
import ObjectTools from '@components/Object/ObjectTools';
import useObjectToolbarPosition from '@hooks/UseObjectToolbarPosition';

const ClickToolbarHandler = ({ children }) => {
  const [currentTool, setCurrentTool] = useState(false);
  const [objectToolPanelPos, setObjectToolPanelPos] = useState({ x: 0, y: 0 });

  const { position: objectToolbarPos } = useObjectToolbarPosition();

  const { activeObject } = useActiveObject();

  useEffect(() => {
    setCurrentTool(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject]);

  return (
    <>
      {objectToolbarPos && (
        <ObjectTools
          position={objectToolbarPos}
          currentTool={currentTool}
          onChange={setCurrentTool}
          onPanelMoved={setObjectToolPanelPos}
          panelStartingPos={objectToolPanelPos}
        />
      )}
      {children}
    </>
  );
};

ClickToolbarHandler.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ClickToolbarHandler;
