import React, { ReactNode } from 'react';

import Surface from '../Ui/Surface';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material';

export const HEADER_BUTTON_SURFACE_SX = {
  height: '36px',
  width: '36px',
  minWidth: 'auto',
  minHeight: 'auto',
};

interface HeaderButtonProps {
  onClick: () => void;
  label: string;
  children: ReactNode;
}

const HeaderButton = ({ onClick, label, children }: HeaderButtonProps) => {
  return (
    <HeaderButtonSurface>
      <IconButton sx={{ color: 'secondary.contrastText' }} aria-label={label} onClick={onClick}>
        {children}
      </IconButton>
    </HeaderButtonSurface>
  );
};

export default HeaderButton;

export const HeaderButtonSurface = styled(Surface)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${(props) => props.theme.spacing(0.5, 1)};
  pointer-events: auto;
  z-index: ${(props) => props.theme.zIndex.appBar};

  height: 48px;
  width: 48px;
  min-height: 48px;
  min-width: 48px;

  border-radius: ${(props) => props.theme.borderRadius.medium}px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: ${(props) => props.theme.spacing(0.5, 0.2)};
    height: 36px;
    width: 36px;
    min-width: auto;
    min-height: auto;
  }
`;
