import { SharedImage } from '@libs/SharedImage/SharedImage';
import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';

interface SharedImageViewProps {
  sharedImage: SharedImage;
  className?: string;
}

export const SharedImageView = ({ sharedImage, className }: SharedImageViewProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'imageSharing.sharedImage' });

  return (
    <Wrapper className={className}>
      <StyledTypography variant="h3" fontWeight={500}>
        {sharedImage.title}
      </StyledTypography>
      <StyledTypography>{sharedImage.description}</StyledTypography>
      <StyledImage src={sharedImage.imageUrl} alt={sharedImage.title} />
      <ImageCaption variant="caption">{t('viewCount', { count: sharedImage.viewCount })}</ImageCaption>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const ImageCaption = styled(Typography)`
  color: ${(props) => props.theme.palette.secondary.dark};
`;

const StyledImage = styled('img')`
  width: 100%;
  margin: ${(props) => props.theme.spacing(1, 0)};
  box-shadow: ${(props) => props.theme.elevation.canvas};
`;
