import Camera from '@libs/Geometry/Camera';
import { TwoDimensionArray } from '@libs/Geometry/TwoDimensionArray';
import Transformation2D from './Transformation2D';
import Transformation3D from './Transformation3D';
import Vector3D from './Vector3D';
import { getBoundingBoxSize } from '@libs/ImgUtils';
import { DepixObject } from '@libs/DepixObject';

export default class SceneComponent {
  public isInfill: boolean;
  public isFix: boolean;
  public transform: Transformation3D;
  public dimensions: Vector3D;
  public asset: DepixObject;

  constructor(depixObject, transform, dimensions, isFix = false, isInfill = false) {
    this.isInfill = isInfill;
    this.isFix = isFix;
    this.transform = transform;
    this.dimensions = dimensions;
    this.asset = depixObject;
  }

  serialize() {
    return {
      isInfill: this.isInfill,
      isFix: this.isFix,
      transform: this.transform.serialize(),
      dimensions: this.dimensions.serialize(),
      asset: this.asset.serialize(),
    };
  }

  static unserialize(state: any): Promise<SceneComponent> {
    return new Promise((resolve) => {
      DepixObject.unserialize(state.asset).then((object) => {
        resolve(
          new SceneComponent(
            object,
            Transformation3D.unserialize(state.transform),
            Vector3D.unserialize(state.dimensions),
            state.isFix,
            state.isInfill
          )
        );
      });
    });
  }

  setTransformation(transform: Transformation3D) {
    if (!this.isFix) {
      this.transform = transform;
    }
  }

  setDimensions(dimensions: Vector3D) {
    if (!this.isFix) {
      this.dimensions = dimensions;
    }
  }

  setAsset(depixObject: DepixObject) {
    if (!this.isFix) {
      this.asset = depixObject;
    }
  }

  getAssetSize(): TwoDimensionArray {
    return [this.asset.image.width, this.asset.image.height];
  }

  /**
   * get pixel size of the masked subject
   */
  getMaskFragmentSize(): TwoDimensionArray {
    const [objectWidth, objectHeight] = getBoundingBoxSize(this.asset.boundingBox);
    const [assetWidth, assetHeight] = this.getAssetSize();
    return [objectWidth * assetWidth, objectHeight * assetHeight];
  }

  /**
   * get pixel size of the infill fragment
   */
  getInfillFragmentSize(): TwoDimensionArray {
    const [objectWidth, objectHeight] = getBoundingBoxSize(this.asset.infillBoundingBox);
    const [assetWidth, assetHeight] = this.getAssetSize();
    return [objectWidth * assetWidth, objectHeight * assetHeight];
  }

  /**
   * Computes the projected transformation of the object
   * warning : Currently tailored for 2D images floating in 3D
   */
  transform2D(camera: Camera, fragmentImageWidth: number, fragmentImageHeight: number): Transformation2D {
    // Does not handle warping (X, Y rotation)
    // compute object center pose
    const translation = camera.projectPoint(this.transform.t);
    // get object orientation along z axis
    const rotation = this.transform.getRotation();
    // compute object width (could directly project length?)
    const [screenWidth, screenHeight] = this.getScreenDimensions(camera);
    const ret = new Transformation2D();
    ret.sett([translation.x, translation.y]);

    // we only consider the z rotation on 2D
    ret.setRotationScale(rotation.z, screenWidth / fragmentImageWidth, screenHeight / fragmentImageHeight);
    return ret;
  }

  private get3DCorners(camera: Camera): Vector3D[] {
    const topLeft = [-this.dimensions.x / 2, this.dimensions.y / 2, 0, 1];
    const topRight = [this.dimensions.x / 2, this.dimensions.y / 2, 0, 1];
    const bottomLeft = [-this.dimensions.x / 2, -this.dimensions.y / 2, 0, 1];
    const bottomRight = [this.dimensions.x / 2, -this.dimensions.y / 2, 0, 1];

    // Object is tilted w.r.t the camera orientation (parallel to image plane)
    const cameraTilt = new Transformation3D();
    cameraTilt.setRotation(-camera.getElevation(), 0, 0);
    const finalTransform = this.transform.combine(cameraTilt);
    const points = finalTransform.dot([topLeft, topRight, bottomLeft, bottomRight]);
    const ret = [];
    for (let i = 0; i < 4; ++i) {
      ret.push(new Vector3D(points[i][0], points[i][1], points[i][2]));
    }
    return ret;
  }

  /**
   * Return the screen dimensions in pixels
   */
  getScreenDimensions(camera: Camera): TwoDimensionArray {
    const [topLeft, topRight, bottomLeft] = this.get3DCorners(camera);
    // Compute the screen width by projecting a point on the left and the right of the object
    const startUVX = camera.projectPoint(topLeft);
    const endUVX = camera.projectPoint(topRight);
    const diffX = [startUVX.x - endUVX.x, startUVX.y - endUVX.y];
    const screenWidth = Math.sqrt(Math.pow(diffX[0], 2) + Math.pow(diffX[1], 2));

    // Compute the screen width by projecting a point on the bottom and top of the object
    const startUVY = camera.projectPoint(bottomLeft);
    const endUVY = camera.projectPoint(topLeft);
    const diffY = [startUVY.x - endUVY.x, startUVY.y - endUVY.y];
    const screenHeight = Math.sqrt(Math.pow(diffY[0], 2) + Math.pow(diffY[1], 2));
    return [screenWidth, screenHeight];
  }
}
