import { UploadImageProvider } from '@contexts/UploadImageContext';

import { useSegmentation } from '@contexts/Segmentation/UseSegmentation';
import { useScene } from '@contexts/SceneContext';

import PanelContent from '@components/Viewport/PanelContent';
import ComposeFooter from '@components/Viewport/Paste/ComposeFooter';
import { DepixBackgroundObject } from '@libs/DepixBackgroundObject';
import ComposeSelectModeProvider from '@contexts/ComposeSelectModeContext';
import ClickToolbarHandler from '@components/Viewport/Paste/ClickToolbarHandler';
import PasteCanvas from '@components/Canvas/PasteCanvas';
import CropToolProvider from '@contexts/CropToolContext';
import { COMPOSE_PANEL_NAME } from '@libs/ComposePanelName';
import { ComposeSideBar } from '@components/Viewport/Paste/ComposeSideBar';
import { EditorPanel } from '@components/Viewport/EditorPanel';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

interface ComposePanelProps {
  className?: string;
  value: string;
  width: string;
  visible: boolean;
}

const ComposePanel = ({ value, width, visible, className }: ComposePanelProps) => {
  const scene = useScene();
  const segmentation = useSegmentation();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const loadBackgroundImage = (depixObject) => {
    segmentation.clear();
    segmentation.changeSegmentedObject(depixObject);
    const depixBackgroundObject = new DepixBackgroundObject(depixObject);
    scene.setSceneBackground(depixBackgroundObject);
  };

  return (
    <UploadImageProvider onImageReady={loadBackgroundImage} isPreview={true}>
      <ComposeSelectModeProvider>
        <CropToolProvider>
          <EditorPanel name={COMPOSE_PANEL_NAME} value={value} width={width} visible={visible} className={className}>
            <PanelContent className="compose_canvas">
              <ClickToolbarHandler>
                <PasteCanvas />
              </ClickToolbarHandler>
            </PanelContent>

            <ComposeFooter />
            {isSmallDevice && <ComposeSideBar />}
          </EditorPanel>
        </CropToolProvider>
      </ComposeSelectModeProvider>
    </UploadImageProvider>
  );
};

export default ComposePanel;
