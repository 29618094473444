import { AuthError } from '@hooks/AuthV2Api';
import { DEFAULT_NS } from '@libs/i18n-config';
import { Link, Typography, styled } from '@mui/material';
import {
  AuthField,
  AuthFormErrorMessage,
  AuthFormWrapper,
  AuthSubmitButton,
  AuthTitle,
  EmailAdornment,
  PasswordAdornment,
} from '@pages/Auth/AuthForm';
import React, { useState, MouseEvent, KeyboardEvent, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface SignInFormProps {
  className?: string;
  onSignIn: (usernameOrEmail: string, password: string) => void;
  onResendVerificationEmail: (usernameOrEmail: string) => Promise<void>;
  onForgotPassword: (forgotPassword: boolean, usernameOrEmail: string) => void;
  error: AuthError | null;
  isLoading: boolean;
  prefillEmail: string | null;
  requiresVerification: boolean;
}

type EmailVerificationState = 'not-sent' | 'sending' | 'sent';

export const SignInForm = ({
  className,
  onSignIn,
  error,
  isLoading,
  onForgotPassword,
  onResendVerificationEmail,
  prefillEmail,
  requiresVerification,
}: SignInFormProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'auth.v2.login' });
  const [usernameOrEmail, setUsernameOrEmail] = useState(prefillEmail || '');
  const [password, setPassword] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const [emailVerificationState, setEmailVerificationState] = useState<EmailVerificationState>('not-sent');

  useEffect(() => {
    setCanSubmit(usernameOrEmail.length > 0 && password.length > 0);
    setEmailVerificationState('not-sent');
  }, [usernameOrEmail, password]);

  useEffect(() => {
    if (!usernameOrEmail && prefillEmail) {
      setUsernameOrEmail(prefillEmail);
    }
  }, [usernameOrEmail, prefillEmail]);

  const onSubmit = useCallback(() => {
    onSignIn(usernameOrEmail, password);
    setEmailVerificationState('not-sent');
  }, [usernameOrEmail, password]);

  const submitOnEnter = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key == 'Enter' && canSubmit) {
        onSubmit();
      }
    },
    [onSubmit, canSubmit]
  );

  const onForgotPasswordClicked = (e: MouseEvent) => {
    e.preventDefault();
    onForgotPassword(true, usernameOrEmail);
  };

  const onResendEmailClicked = useCallback(async () => {
    setEmailVerificationState('sending');
    await onResendVerificationEmail(usernameOrEmail);
    setEmailVerificationState('sent');
  }, [usernameOrEmail]);

  return (
    <AuthFormWrapper className={className}>
      <AuthTitle variant="h4">{t('title')}</AuthTitle>

      {error && <AuthFormErrorMessage>{error.message}</AuthFormErrorMessage>}

      {requiresVerification && (
        <>
          {emailVerificationState == 'not-sent' && (
            <Typography>
              {t('didNotReceive')}{' '}
              <Link href="#" onClick={onResendEmailClicked}>
                {t('resend')}
              </Link>
            </Typography>
          )}
          {emailVerificationState == 'sending' && <Typography>{t('sendingVerificationEmail')}</Typography>}
          {emailVerificationState == 'sent' && <Typography>{t('sentVerificationEmail')}</Typography>}
        </>
      )}

      <AuthField
        name="login-usernameOrEmail"
        label={t('label.usernameOrEmail')}
        value={usernameOrEmail}
        onChange={(e) => setUsernameOrEmail(e.target.value)}
        onKeyPress={(e) => submitOnEnter(e)}
        InputProps={{
          endAdornment: <EmailAdornment position="end" />,
        }}
      />

      <AuthField
        name="login-password"
        type="password"
        label={t('label.password')}
        onChange={(e) => setPassword(e.target.value)}
        onKeyPress={(e) => submitOnEnter(e)}
        InputProps={{
          endAdornment: <PasswordAdornment position="end" />,
        }}
      />

      <StyledLink href="#" onClick={onForgotPasswordClicked}>
        {t('forgotPassword')}
      </StyledLink>

      <AuthSubmitButton disabled={!canSubmit || isLoading} onClick={() => onSubmit()}>
        {t('label.submit')}
      </AuthSubmitButton>
    </AuthFormWrapper>
  );
};

const StyledLink = styled(Link)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 0.85em;
  }
`;
