import React from 'react';
import AlertContext, { AlertAction, DEFAULT_AUTO_HIDE_DURATION } from '@contexts/AlertContext';
import { useTranslation } from 'react-i18next';

export type AlertWithMessageAndOptionalAction = (
  message: string,
  title?: string,
  duration?: number,
  action?: AlertAction
) => void;
export type AlertWithoutMessage = (duration?: number) => void;

export interface Alerts {
  success: AlertWithMessageAndOptionalAction;
  info: AlertWithMessageAndOptionalAction;
  warning: AlertWithMessageAndOptionalAction;
  error: AlertWithMessageAndOptionalAction;
  networkError: AlertWithoutMessage;
  invalidImage: AlertWithoutMessage;
}

export const useAlert = (): Alerts => {
  const { t } = useTranslation();
  const context = React.useContext(AlertContext);
  if (context === undefined) {
    throw new Error('useAlert must be used within an AlertContext');
  }

  const { issueAlert } = context;

  const success = (message, title, duration, actions = undefined) => {
    issueAlert('success', title, message, duration, actions);
  };

  const info = (message, title, duration, actions = undefined) => {
    issueAlert('info', title, message, duration, actions);
  };

  const warning = (message, title, duration, actions = undefined) => {
    issueAlert('warning', title, message, duration, actions);
  };

  const error = (message, title, duration, actions = undefined) => {
    issueAlert('error', title, message, duration, actions);
  };

  const networkError = (duration) => {
    error(t('general.genericNoConnectionError'), null, duration);
  };

  const invalidImage = (duration = undefined) => {
    error(t('error.generic.invalidImage'), t('error.generic.title'), duration);
  };

  return { success, info, warning, error, networkError, invalidImage };
};
