import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import UndoIcon from '../Ui/Icons/UndoIcon';
import { useScene } from '@contexts/SceneContext';
import ToolButton from './ToolButton';
import _ from 'lodash';

const MAX_CLICK_RATE_MS = 100;

const UndoButton = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const scene = useScene();

  const onClick = _.throttle(async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    await scene.undo();
    setLoading(false);
  }, MAX_CLICK_RATE_MS);

  return (
    <ToolButton
      name={'undo'}
      toolTip={t('tools.compose.tooltips.undo')}
      disabled={!scene.canUndo || loading}
      onClick={onClick}>
      <UndoIcon />
    </ToolButton>
  );
};

export default UndoButton;
