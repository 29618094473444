import FacebookIcon from '@components/Ui/Icons/FacebookIcon';
import React from 'react';
import { ShareToButtonProps } from '@components/SharedImage/ShareToButtonProps';
import { IconButton, styled } from '@mui/material';

interface ShareToFacebookProps extends ShareToButtonProps {}

export const ShareToFacebook = ({ onClick }: ShareToFacebookProps) => {
  return (
    <FacebookIconButton onClick={onClick}>
      <FacebookIcon />
    </FacebookIconButton>
  );
};

const FacebookIconButton = styled(IconButton)`
  background: #337fff;
`;
