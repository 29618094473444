import React, { createContext, ReactNode, useState } from 'react';

type Props = {
  children: ReactNode;
};

export interface CropWindow {
  width: number;
  height: number;
  x: number;
  y: number;
  cursor: string;
}

export interface CropToolContext {
  enabled: boolean;
  cropWindow: CropWindow;
  applied: boolean;
  setEnabled: (enabled: boolean) => void;
  setCropWindow: (cropWindow: CropWindow) => void;
  setApplied: (applied: boolean) => void;
}

export const CropToolContext = createContext(null as CropToolContext);
const CropToolProvider = ({ children }: Props) => {
  const [enabled, setEnabled] = useState(false);
  const [applied, setApplied] = useState(false);
  const [cropWindow, setCropWindow] = useState({} as CropWindow); // TODO: make this a property of the image

  const value = { enabled, cropWindow, applied, setEnabled, setCropWindow, setApplied };

  return <CropToolContext.Provider value={value}>{children}</CropToolContext.Provider>;
};

export default CropToolProvider;
