import {
  GRAPHQL_ERROR_INITIAL_STATE,
  GraphqlErrorActionType,
  GraphqlErrorEvent,
  graphqlErrorReducer,
} from '@contexts/GraphqlErrorReducer';
import React, { createContext, ReactNode, useCallback, useReducer } from 'react';
import { useAlert } from '@hooks/Alert';
import { useTranslation } from 'react-i18next';
import { GRAPHQL_ERROR_CODES } from '@libs/graphql/graphqlErrorHelper';

export interface GraphqlErrorContextType {
  displayError(error: any): void;
  addListener(name: string, callback: (event: GraphqlErrorEvent) => void): void;
  removeListener(name: string): void;
}

const GraphqlErrorContext = createContext<GraphqlErrorContextType | null>(null);

type Props = {
  children: ReactNode;
};

export const GraphqlErrorProvider = ({ children }: Props) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const [, dispatch] = useReducer(graphqlErrorReducer, GRAPHQL_ERROR_INITIAL_STATE);

  const displayRateLimitError = useCallback(
    (error) => {
      const exception = error.graphQLErrors[0].extensions?.exception;
      if (!exception.nextCall) {
        alert.warning(t('rateLimiting.alerts.noQuota'));
        console.error('User has exceeded the rate limit, but seems to have a limit of 0');
      } else {
        dispatch({ type: GraphqlErrorActionType.USER_RAN_OUT_OF_IMAGES });
      }
    },
    [dispatch]
  );

  function displayTranslatedError(code) {
    const translationKey = `error.api.codes.${code}`;
    const translation = t(translationKey);

    if (translation === translationKey) {
      alert.error(t('error.unknown.description'), t('error.unknown.title'));
    } else {
      alert.error(translation, t('error.generic.title'));
    }
  }

  function displayError(error) {
    if (error.networkError) {
      alert.networkError();
    } else if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      const code = error.graphQLErrors[0].extensions?.code;

      if (code === GRAPHQL_ERROR_CODES.RATE_LIMIT) {
        displayRateLimitError(error);
      } else {
        displayTranslatedError(code);
      }
    } else {
      alert.error(error.message);
      if (process.env.NODE_ENV === 'development') {
        console.log('UnhandledError upload', error);
      }
    }
  }

  const addListener = (name, callback) => {
    dispatch({ type: GraphqlErrorActionType.ADD_LISTENER, name, callback });
  };

  const removeListener = (name) => {
    dispatch({ type: GraphqlErrorActionType.REMOVE_LISTENER, name });
  };

  const value = {
    displayError,
    addListener,
    removeListener,
  };

  return <GraphqlErrorContext.Provider value={value}>{children}</GraphqlErrorContext.Provider>;
};

export default GraphqlErrorContext;
