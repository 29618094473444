import MuiTimelineItem from '@mui/lab/TimelineItem';
import styled from '@mui/material/styles/styled';

const TimelineItem = styled(MuiTimelineItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  ['&::before']: {
    padding: 0,
  },
  ['&.MuiTimelineItem-positionAlternate:nth-of-type(even)']: {
    ['& > .MuiTimelineContent-root']: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      ['& > .MuiTimelineConnector-root:first-of-type']: {
        display: 'none',
      },
    },
  },
  ['&.MuiTimelineItem-positionAlternate:nth-of-type(odd)']: {
    ['& > .MuiTimelineContent-root']: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      ['& > .MuiTimelineConnector-root:last-of-type']: {
        display: 'none',
      },
    },
  },
  ['&:first-of-type > .MuiTimelineSeparator-root > .MuiTimelineConnector-root:first-of-type']: {
    border: 'none',
  },
  ['&:last-of-type > .MuiTimelineSeparator-root > .MuiTimelineConnector-root:last-of-type']: {
    border: 'none',
  },
  ['& > .MuiTimelineContent-root > .MuiTimelineConnector-root']: {
    width: 'auto',
    minWidth: theme.spacing(2),
    maxWidth: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.spacing(4),
    },
  },
  ['&.MuiTimelineItem-positionRight']: {
    ['&::before']: {
      display: 'none',
    },
    ['& > .MuiTimelineContent-root > .MuiTimelineConnector-root']: {
      height: 0,
      width: 'auto',
      ['&:last-of-type']: {
        display: 'none',
      },
    },
    [theme.breakpoints.down('sm')]: {
      ['& .MuiTimelineSeparator-root']: {
        display: 'none',
      },
      ['& .MuiTimelineConnector-root']: {
        display: 'none',
      },
    },
  },
}));

export default TimelineItem;
