import React, { forwardRef, useEffect } from 'react';

import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { Box, Button, Typography, ButtonProps } from '@mui/material';
import Switch from './Switch';
import { styled } from '@mui/material/styles';
import { BoxProps } from '@mui/material/Box';

export enum TooltipPlacement {
  TOP = 'top',
  RIGHT = 'right',
  LEFT = 'left',
}

export type ToolButtonProps = {
  name: string;
  buttonText?: string;
  toolTip?: string;
  tooltipPlacement?: TooltipPlacement;
  activated?: boolean;
  checked?: boolean;
  disabled?: boolean;
  disableBackground?: boolean;
  disableBorder?: boolean;
  disableTooltip?: boolean;
  inline?: boolean;
  onClick: (name: string, event?: React.MouseEvent<HTMLElement>) => void;
  onSwitch?: (e: Event) => void;
  children?: React.ReactNode;
  id?: string;
  className?: string;
};

const ToolButton = forwardRef(
  (
    {
      name,
      toolTip,
      tooltipPlacement = TooltipPlacement.TOP,
      buttonText,
      activated = false,
      disabled = false,
      disableBorder = true,
      disableBackground = true,
      disableTooltip = false,
      onSwitch,
      checked,
      inline = false,
      onClick,
      children,
      id,
      className,
    }: ToolButtonProps,
    ref
  ) => {
    const showBorder = !disableBorder && activated;
    const showBackground = !disableBackground && activated;
    return (
      <>
        <Wrapper showBackground={showBackground} ref={ref} id={id} activated={activated}>
          <OptionalTooltip title={toolTip} placement={tooltipPlacement} enterDelay={500} disable={disableTooltip} arrow>
            <StyledBox showBackground={showBackground} activated={activated} className={className}>
              {onSwitch && <Switch sx={{ mr: 0.5 }} label={name} checked={checked} onChange={onSwitch} />}
              <Button
                disabled={disabled}
                component={disabled ? 'div' : undefined}
                aria-label={name}
                onClick={(event: React.MouseEvent<HTMLElement>) => onClick(name, event)}
                sx={{
                  display: 'flex',
                  flexDirection: inline ? 'row' : 'column',
                  minWidth: 24,
                  minHeight: 24,
                  width: buttonText != undefined ? 'auto' : 24,
                  height: buttonText != undefined ? 'auto' : 24,
                  borderRadius: buttonText != undefined ? '10px' : '50%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'inherit',
                  px: '14px',
                  py: buttonText != undefined ? 0 : '14px',
                  mx: '1px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}>
                <StyledIcon
                  sx={{ mr: inline ? 1 : 0, mb: buttonText && !inline ? 0.25 : 0 }}
                  disableBorder={disableBorder}
                  showBorder={showBorder}>
                  {children}
                </StyledIcon>
                {buttonText && (
                  <ButtonText
                    variant={inline ? 'body2' : 'caption'}
                    textTransform={inline ? 'none' : 'uppercase'}
                    fontWeight={500}
                    color={showBackground ? 'currentColor' : 'secondary.contrastText'}>
                    {buttonText}
                  </ButtonText>
                )}
              </Button>
            </StyledBox>
          </OptionalTooltip>
        </Wrapper>
      </>
    );
  }
);

interface WrapperProps extends BoxProps {
  showBackground: boolean;
  activated: boolean;
}

const notForwardedProps: PropertyKey[] = ['activated', 'showBackground', 'disableBorder', 'showBorder'];

const Wrapper = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => !notForwardedProps.includes(propName),
})<WrapperProps>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.showBackground ? props.theme.palette.primary.main : 'transparent')};

  &:hover {
    background-color: ${(props) =>
      props.activated && props.showBackground
        ? props.theme.palette.primary.dark
        : props.theme.palette.secondary.lightGrey};
  }
`;

const StyledBox = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => !notForwardedProps.includes(propName),
})<{ showBackground: boolean; activated: boolean }>`
  display: flex;
  min-width: 34px;
  min-height: 34px;
  height: 100%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.spacing(0, 0.25)};
  height: 100%;

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: ${(props) => props.theme.spacing(0, 0.15)};
  }

  color: ${(props) =>
    props.activated ? props.theme.palette.secondary.main : props.theme.palette.secondary.contrastText};

  &:hover {
    color: ${(props) =>
      props.activated && props.showBackground
        ? props.theme.palette.secondary.main
        : props.theme.palette.secondary.contrastText};
  }
`;

const StyledIcon = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => !notForwardedProps.includes(propName),
})<{ disableBorder: boolean; showBorder: boolean }>`
  width: ${(props) => (props.disableBorder ? '24px' : '34px')};
  height: ${(props) => (props.disableBorder ? '24px' : '34px')};
  padding: ${(props) => (props.disableBorder ? 0 : '2px')};
  border: ${(props) => (props.disableBorder ? 'none' : '3px solid')};
  border-color: ${(props) => (props.showBorder ? props.theme.palette.primary.main : 'transparent')};

  border-radius: 50%;
`;

const ButtonText = styled(Typography)``;

interface OptionalTooltipProps extends TooltipProps {
  disable?: boolean;
}

const OptionalTooltip = ({ title, children, disable = false, ...props }: OptionalTooltipProps) => {
  if (!title || disable) {
    return <>{children}</>;
  }

  return (
    <Tooltip title={title} {...props}>
      {children}
    </Tooltip>
  );
};

export default ToolButton;
