import React from 'react';
import UserProfileContext from '@contexts/UserProfileContext';

/**
 * User profile information such as its current rate limit
 */
function useUserProfile(): UserProfileContext {
  const context = React.useContext(UserProfileContext);

  if (context === undefined) {
    throw new Error('useProfile must be used within a userProfileContext');
  }

  return context;
}

export default useUserProfile;
