import { ReactNode, useEffect } from 'react';
import Button from '@components/Ui/Button';
import { styled } from '@mui/material';
import { SubscriptionIncentive } from '@libs/Instrumentation/SubscriptionIncentive';
import useInstrumentation from '@hooks/UseInstrumentation';
import { useLocation, useNavigate } from 'react-router-dom';
import Routing from '@root/routes';

interface SubscribeButtonProps {
  incentive: SubscriptionIncentive;
  className?: string;
  children?: ReactNode;
}

export const SubscribeButton = ({ incentive, children, className }: SubscribeButtonProps) => {
  const instrumentation = useInstrumentation();
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    instrumentation.subscribeClicked(incentive);
    navigate(Routing.subscribe(), { state: { backgroundLocation: location } });
  };

  useEffect(() => {
    instrumentation.subscriptionOfferShown(incentive);
  }, []);

  return (
    <StyledButton variant="contained" onClick={handleClick} className={className}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  margin: 0;
  background-color: ${(props) => props.theme.palette.accept.main};
  text-transform: none;

  &:hover {
    background-color: ${(props) => props.theme.palette.accept.dark};
  }
`;
