/* eslint-disable max-len */
import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ErrorIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 52 52">
    <path d="M23.4 13H28.6V31.2H23.4V13ZM23.4 33.8H28.6V39H23.4V33.8Z" />
    <path d="M51.2382 13.7618L38.2382 0.761802C37.9972 0.519892 37.7107 0.328044 37.3952 0.197302C37.0797 0.0665603 36.7415 -0.000492567 36.4 2.72395e-06H15.6C15.2585 -0.000492567 14.9203 0.0665603 14.6048 0.197302C14.2893 0.328044 14.0028 0.519892 13.7618 0.761802L0.761802 13.7618C0.519892 14.0028 0.328044 14.2893 0.197302 14.6048C0.0665603 14.9203 -0.000492567 15.2585 2.72395e-06 15.6V36.4C2.72395e-06 37.0916 0.273003 37.752 0.761802 38.2382L13.7618 51.2382C14.0028 51.4801 14.2893 51.672 14.6048 51.8027C14.9203 51.9334 15.2585 52.0005 15.6 52H36.4C37.0916 52 37.752 51.727 38.2382 51.2382L51.2382 38.2382C51.4801 37.9972 51.672 37.7107 51.8027 37.3952C51.9334 37.0797 52.0005 36.7415 52 36.4V15.6C52.0005 15.2585 51.9334 14.9203 51.8027 14.6048C51.672 14.2893 51.4801 14.0028 51.2382 13.7618ZM46.8 35.3236L35.3236 46.8H16.6764L5.2 35.3236V16.6764L16.6764 5.2H35.3236L46.8 16.6764V35.3236Z" />
  </SvgIcon>
);

export default ErrorIcon;
