import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Button from '@root/Components/Ui/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import InputBar from '@components/Ui/InputBar';
import Box from '@mui/material/Box';

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export const ButtonType = {
  DEFAULT: 'default',
  MAIN: 'main',
};

const Description = styled(Typography)(() => ({
  fontWeight: 500,
}));

const GridItem = ({ children, ...props }) => (
  <Grid item sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }} {...props}>
    {children}
  </Grid>
);

GridItem.propTypes = {
  children: PropTypes.node,
};

const ContentTemplate = ({ title, description, buttons, onInputSubmit, onInputChange, inputLabel = '' }) => {
  const reorderedButtons = buttons.sort((button1, button2) => {
    const value1 = button1.type === ButtonType.MAIN ? 1 : 0;
    const value2 = button2.type === ButtonType.MAIN ? 1 : 0;
    return value1 - value2;
  });

  const buttonElements = reorderedButtons.map((button) => {
    if (button.type === ButtonType.MAIN) {
      return (
        <Button variant="contained" onClick={button.onClick} key={button.name} sx={{ mx: 3 }}>
          {button.name}
        </Button>
      );
    } else {
      return (
        <Button
          onClick={button.onClick}
          key={button.name}
          sx={{
            mx: 3,
            border: null,
            p: (theme) => theme.spacing(1, 3),
            mb: (theme) => theme.spacing(1),
            boxShadow: null,
            textTransform: null,
          }}>
          <Typography color="primary.main" fontWeight={500}>
            {button.name}
          </Typography>
        </Button>
      );
    }
  });

  return (
    <Grid container rowSpacing={3} aria-label={title} role="option">
      <GridItem>
        <Typography color="primary.main" fontWeight={500} fontSize={30}>
          {title}
        </Typography>
      </GridItem>

      {onInputSubmit && onInputChange && (
        <GridItem>
          <InputBar
            onSubmit={onInputSubmit}
            onChange={onInputChange}
            id="verification-email"
            label={inputLabel}
            sx={{ flex: '0 0 auto', justifyContent: 'center', width: '100%' }}
            type="email"
          />
        </GridItem>
      )}

      <GridItem>
        <Description>{description}</Description>
      </GridItem>

      <GridItem>
        <ButtonContainer>{buttonElements}</ButtonContainer>
      </GridItem>
    </Grid>
  );
};

ContentTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  buttons: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, onClick: PropTypes.func })),
  onInputChange: PropTypes.func,
  onInputSubmit: PropTypes.func,
  inputLabel: PropTypes.string,
};

export default ContentTemplate;
