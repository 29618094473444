import { Box, styled, Typography } from '@mui/material';
import { Feature } from '@components/FeatureCarousel/Feature';
import Surface from '@components/Ui/Surface';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';

interface FeatureDemoProps {
  feature: Feature;
  className?: string;
}

export const FeatureDemo = ({ feature, className }: FeatureDemoProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'feature_carousel' });

  return (
    <Wrapper className={className}>
      <Media>
        <StyledImage image={feature.media} />
      </Media>
      <Title variant="h5">
        {feature.icon}
        {t(`features.${feature.id}.title`)}
      </Title>
      <Description>{t(`features.${feature.id}.description`)}</Description>
    </Wrapper>
  );
};

const Wrapper = styled(Surface)`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(1)};
  box-shadow: none;
  width: 100%;
  max-width: 300px;

  padding: ${(props) => props.theme.spacing(1)};

  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 500px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 800px;
  }
`;

const Title = styled(Typography)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${(props) => props.theme.spacing(1)};
  margin-top: ${(props) => props.theme.spacing(1)};

  font-weight: 500;
  color: ${(props) => props.theme.palette.primary.main};

  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 1.125rem;
  }

  ${(props) => props.theme.breakpoints.up('xs')} and (max-height: 670px) {
    font-size: 1rem;
  }
`;

const Description = styled(Typography)`
  ${(props) => props.theme.breakpoints.up('xs')} and (max-height: 670px) {
    font-size: 0.9rem;
  }
`;

const Media = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.secondary.light};
  border-top-left-radius: ${(props) => props.theme.borderRadius.small}px;
  border-top-right-radius: ${(props) => props.theme.borderRadius.small}px;
  overflow: hidden;
  height: 500px;

  ${(props) => props.theme.breakpoints.up('lg')} and (min-height: 900px) {
    height: 400px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} and (max-height: 850px) {
    height: 340px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} and (max-height: 800px) {
    height: 300px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} and (max-height: 750px) {
    height: 240px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} and (max-height: 700px) {
    height: 150px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} and (max-height: 650px) {
    height: 110px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} and (max-height: 600px) {
    height: 100px;
  }

  ${(props) => props.theme.breakpoints.down('lg')} and (min-height: 900px) {
    height: 300px;
  }

  ${(props) => props.theme.breakpoints.down('lg')} and (max-height: 900px) {
    height: 260px;
  }

  ${(props) => props.theme.breakpoints.down('lg')} and (max-height: 850px) {
    height: 240px;
  }

  ${(props) => props.theme.breakpoints.down('lg')} and (max-height: 800px) {
    height: 200px;
  }

  ${(props) => props.theme.breakpoints.down('lg')} and (max-height: 750px) {
    height: 180px;
  }

  ${(props) => props.theme.breakpoints.down('lg')} and (max-height: 700px) {
    height: 150px;
  }

  ${(props) => props.theme.breakpoints.down('lg')} and (max-height: 650px) {
    height: 110px;
  }

  ${(props) => props.theme.breakpoints.down('lg')} and (max-height: 600px) {
    height: 100px;
  }

  ${(props) => props.theme.breakpoints.down('md')} and (min-height: 900px) {
    height: 300px;
  }

  ${(props) => props.theme.breakpoints.down('md')} and (max-height: 900px) {
    height: 260px;
  }

  ${(props) => props.theme.breakpoints.down('md')} and (max-height: 850px) {
    height: 210px;
  }

  ${(props) => props.theme.breakpoints.down('md')} and (max-height: 800px) {
    height: 190px;
  }

  ${(props) => props.theme.breakpoints.down('md')} and (max-height: 750px) {
    height: 180px;
  }

  ${(props) => props.theme.breakpoints.down('md')} and (max-height: 700px) {
    height: 150px;
  }

  ${(props) => props.theme.breakpoints.down('md')} and (max-height: 650px) {
    height: 110px;
  }

  ${(props) => props.theme.breakpoints.down('md')} and (max-height: 600px) {
    height: 100px;
  }
`;

const notForwardedProps: PropertyKey[] = ['image'];

const StyledImage = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => !notForwardedProps.includes(propName),
})<{ image: string }>`
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('${(props) => props.image}');
  width: 100%;
  height: 100%;
`;
