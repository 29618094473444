import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';

const DEFAULT_BAR_HEIGHT = 24;
const FRONT_SIZE = '16PX';
const LINE_HEIGHT = '18PX';

interface ProgressBarProps {
  label: string;
  display: 'normal' | 'compact' | 'no-label';
  value: number;
  total: number;
  height?: number;
  maxHeight?: number | string;
}

const ProgressBar = ({
  label,
  display,
  value,
  total = 0,
  height = DEFAULT_BAR_HEIGHT,
  maxHeight,
}: ProgressBarProps) => {
  const normalisedValue = (value * 100) / total;

  const borderRadius = height / 2;
  const isNormal = display === 'normal';
  const padding = isNormal ? 0.5 : 0;

  const flex = isNormal ? { flexDirection: 'column-reverse' } : { flexDirection: 'row', alignItems: 'center' };

  const isValidValue = Number.isInteger(value);
  const variant = !isValidValue ? 'indeterminate' : 'determinate';
  const titleAlignSelf = isNormal ? 'flex-start' : 'center';

  return (
    <Box sx={{ display: 'flex', flexGrow: 1, mx: 0.5, transition: `0.3s ease width`, maxHeight, ...flex }}>
      {display != 'no-label' && (
        <Typography
          sx={{
            color: 'secondary.dark',
            fontWeight: 500,
            fontSize: FRONT_SIZE,
            alignSelf: titleAlignSelf,
            py: padding,
            px: 0.25,
            lineHeight: LINE_HEIGHT,
          }}>
          {label}
        </Typography>
      )}
      <Box sx={{ position: 'relative', flexGrow: 1, height, mx: isNormal ? 0 : 1, my: isNormal ? 0.5 : 0 }}>
        <LinearProgress
          sx={{
            width: '100%',
            height,
            borderRadius: borderRadius,
            backgroundColor: 'secondary.light',
            '& .MuiLinearProgress-bar': { borderRadius, backgroundColor: 'primary.light' },
          }}
          variant={variant}
          value={normalisedValue}
        />
      </Box>
      <Typography
        sx={{
          py: padding,
          mx: 0.5,
          fontWeight: 500,
          fontSize: FRONT_SIZE,
          lineHeight: LINE_HEIGHT,
          alignSelf: isNormal ? 'flex-end' : 'inherit',
        }}>
        {value || 0}/{total}
      </Typography>
    </Box>
  );
};

export default ProgressBar;
