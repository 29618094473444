import React, { Context, createContext, ReactNode, useCallback, useContext, useLayoutEffect, useState } from 'react';
import { PaddleTelemetry } from '@libs/PaddleTelemetry';
import { Plan } from '@libs/Plan';
import { useAuthV2 } from '@contexts/AuthV2';

interface BilledPrice {
  subtotal: number;
  taxes: number;
  total: number;
  currency: string;
}

interface RecurringPrice {
  total: number;
  currency: string;
  type: string;
}

export interface CheckoutContext {
  showPrices: boolean;
  billedPrice?: BilledPrice;
  recurringPrice?: RecurringPrice;
  userId: string | null;
  rawProductData: any | null;
  rawCheckoutData: any | null;
  startCheckout: (plan: Plan, onSuccess: (checkout: { id: string; completed: boolean }) => void) => void;
}

const CheckoutContext: Context<CheckoutContext> = createContext({} as CheckoutContext);

export const useCheckout = () => {
  const context = useContext(CheckoutContext);

  if (!context) {
    throw new Error('useCheckout must be used within a CheckoutContext');
  }

  return context;
};

interface CheckoutContextProviderProps {
  children: ReactNode;
}

export const CheckoutProvider = ({ children }: CheckoutContextProviderProps) => {
  const { user } = useAuthV2();

  const [showPrices, setShowPrices] = useState<boolean>(false);
  const [billedPrice, setBilledPrice] = useState<BilledPrice>();
  const [recurringPrice, setRecurringPrice] = useState<RecurringPrice>();
  const [userId, setUserId] = useState<string | null>(null);
  const [rawCheckoutData, setRawCheckoutData] = useState<any | null>(null);
  const [rawProductData, setRawProductData] = useState<any | null>(null);

  const updateBilledPrice = useCallback((checkoutData) => {
    const prices = checkoutData?.prices.customer;

    setBilledPrice({
      subtotal: Number((prices.total - prices.total_tax).toFixed(2)),
      taxes: prices.total_tax,
      total: prices.total,
      currency: prices.currency,
    });
  }, []);

  const updateRecurringPrice = useCallback((checkoutData) => {
    const prices = checkoutData?.recurring_prices.customer;
    const interval = checkoutData?.recurring_prices.interval;

    setRecurringPrice({
      total: prices.total,
      currency: prices.currency,
      type: interval.type,
    });
  }, []);

  const updatePrices = useCallback(
    (checkoutData) => {
      if (checkoutData?.prices) {
        updateBilledPrice(checkoutData);
      }

      if (checkoutData?.recurring_prices) {
        updateRecurringPrice(checkoutData);
      }
    },
    [updateBilledPrice, updateRecurringPrice]
  );

  const handlePaddleUpdates = useCallback(
    (data) => {
      setUserId(data?.eventData?.user?.id || null);
      setRawCheckoutData(data?.eventData?.checkout || null);
      setRawProductData(data?.eventData?.product || null);
      updatePrices(data?.eventData?.checkout);

      if (['Checkout.Location.Submit', 'Checkout.Location.Change'].includes(data?.event)) {
        setShowPrices(true);
      }
    },
    [updatePrices]
  );

  const startCheckout = useCallback(
    (plan, onSuccess) => {
      Paddle.Checkout.open({
        method: 'inline',
        email: user?.email,
        marketingConsent: 0,
        override: plan.link,
        successCallback: (result) => onSuccess(result.checkout),
        frameTarget: 'paddle-checkout-root',
        frameInitialHeight: 400,
        frameStyle: 'width:100%; min-width:250px; background-color: transparent; border: none;',
      });

      setShowPrices(false);
      setBilledPrice(null);
      setRecurringPrice(null);
    },
    [user]
  );

  useLayoutEffect(() => {
    Paddle.Setup({
      vendor: Number(process.env.REACT_APP_PADDLE_VENDOR_ID),
      eventCallback: function (data) {
        PaddleTelemetry.handleEvent(data, process.env.NODE_ENV, dataLayer);
        handlePaddleUpdates(data);
      },
    });

    if (process.env.REACT_APP_PADDLE_SANDBOX === 'true') {
      Paddle.Environment.set('sandbox');
    }
  }, [handlePaddleUpdates]);

  const value = { showPrices, billedPrice, recurringPrice, startCheckout, userId, rawCheckoutData, rawProductData };
  return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>;
};
