/* eslint-disable react-hooks/exhaustive-deps */
import { FabricLoadableImage } from '@components/FabricJS/LoadableImage';
import { useState, useCallback, useEffect } from 'react';
import { ApolloError, useMutation } from '@apollo/client';

import { render, loadToTensor } from '@libs/Shading';
import { DECOMPOSE_OBJECT } from '@libs/DepixApi';
import _ from 'lodash';
import { useGraphqlErrorHandler } from '@hooks/useGraphqlErrorHandler';
import { useAlert } from '@hooks/Alert';
import { useTranslation } from 'react-i18next';

export enum RelightState {
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE',
  COMPUTING = 'COMPUTING',
}

const DEBUG = false;

interface UseRelight {
  enableRelight: (object: FabricLoadableImage) => void;
  initialiseRelight: (object: FabricLoadableImage) => void;
  renderRelight: (object: FabricLoadableImage, isPreview?: boolean) => void;
  relightState: RelightState;
  error: ApolloError;
}

/**
 *
 * @return {*}
 */
function useRelight(): UseRelight {
  // Object is a fabric.group object
  const [relightState, setRelightState] = useState(RelightState.DISABLE);
  const [lastApiObject, setLastApiObject] = useState(null);
  const [tensorCache, setTensorCache] = useState(null);
  const [objectToCompute, setObjectToCompute] = useState(null);
  const [error, setError] = useState(null);
  const [lastRenderData, setLastRenderData] = useState(null);
  const { displayError } = useGraphqlErrorHandler();
  const alert = useAlert();
  const { t } = useTranslation();

  useEffect(() => {
    if (!objectToCompute) return;
    applyRender(objectToCompute.object);
  }, [objectToCompute]);

  const [imageDecomposition] = useMutation(DECOMPOSE_OBJECT, {
    onCompleted({ imageDecomposition }) {
      lastApiObject.depixObject
        .update(imageDecomposition)
        .then(() => {
          renderRelight(lastApiObject);
        })
        .catch((err) => {
          console.error('Error while apply lighting: ' + err?.message || err);
          alert.error(t('error.images.cannotApplyLighting'), t('error.generic.title'));
        });
    },
    onError(error) {
      displayError(error);
      setError(error);
    },
  });

  const applyOriginal = (object) => {
    const padding = 2;
    const crop = true;
    object.depixObject
      .getAlphaImage(padding, crop)
      .then((image) => {
        object.updateElement(image);
        object.canvas.requestRenderAll();
      })
      .catch(() => {
        alert.invalidImage();
      });
  };

  const wasPreviouslyComputed = (depixObject) => {
    return (
      lastRenderData?.id === depixObject.objectID && _.isEqual(depixObject.lightingParams, lastRenderData?.lighting)
    );
  };

  const computeRender = (normals, albedo, parametricShading, segmentation, object) => {
    const lightingParams = object.depixObject.lightingParams;
    const lowResidualAlpha = object.depixObject.prop.lowResidualAlpha;
    const highResidualAlpha = object.depixObject.prop.highResidualAlpha;
    const paddingRatio = object.paddingRatio;
    if (!lightingParams || !normals || !albedo || !parametricShading || !segmentation) {
      return;
    }

    if (!wasPreviouslyComputed(object.depixObject)) {
      const canvas = render(
        normals,
        albedo,
        parametricShading,
        segmentation,
        lightingParams,
        lowResidualAlpha,
        highResidualAlpha,
        paddingRatio
      );
      object.updateElement(canvas);
      object.canvas.requestRenderAll();
      setLastRenderData({
        id: object.depixObject.objectID,
        lighting: _.cloneDeep(object.depixObject.lightingParams),
      });
    }
  };

  const checkCache = (id, resolution) => {
    return !!tensorCache && id === tensorCache?.id && resolution === tensorCache?.resolution;
  };

  const getMaxResolution = (boundingBox, width, height) => {
    const [minx, miny, maxx, maxy] = boundingBox;
    return Math.max((maxy - miny) * height, (maxx - minx) * width);
  };

  const getOrLoadTensor = (id, resolution, loadTensorCallback) => {
    if (checkCache(id, resolution)) {
      return tensorCache;
    } else {
      const tensor = { ...loadTensorCallback(), id, resolution };
      updateTensorCache(tensor);
      return tensor;
    }
  };

  const updateTensorCache = (tensor) => {
    clearTensorCache();
    setTensorCache(tensor);
  };

  const clearTensorCache = () => {
    tensorCache?.normals.dispose();
    tensorCache?.albedo.dispose();
    tensorCache?.rShading.dispose();
    tensorCache?.segmentation.dispose();
  };

  const getTensor = ({ depixObject, name }: FabricLoadableImage) => {
    const { normals, albedo, residualShading, mask } = depixObject;
    const boundingBox = depixObject.boundingBox;
    const [minx, miny, maxx, maxy] = boundingBox;
    const resolution = getMaxResolution(boundingBox, depixObject.image.width, depixObject.image.height);
    return getOrLoadTensor(name, resolution, () => {
      const [normalsT, albedoT, rShadingT, segmentationT] = loadToTensor(
        normals,
        albedo,
        residualShading,
        mask,
        minx * depixObject.image.width,
        maxx * depixObject.image.width,
        miny * depixObject.image.height,
        maxy * depixObject.image.height,
        resolution
      );
      return { normals: normalsT, albedo: albedoT, rShading: rShadingT, segmentation: segmentationT };
    });
  };

  const applyRender = (object) => {
    if (object.isDecomposed()) {
      setRelightState(RelightState.ENABLE);
      if (DEBUG) {
        console.time('getTensor');
      }
      const tensors = getTensor(object);
      if (DEBUG) {
        console.timeEnd('getTensor');
        console.time('Render');
      }
      computeRender(tensors.normals, tensors.albedo, tensors.rShading, tensors.segmentation, object);
      if (DEBUG) {
        console.timeEnd('Render');
      }
    }
  };

  const initialiseRelight = (object: FabricLoadableImage) => {
    if (!object.depixObject.isDecomposed()) {
      setRelightState(RelightState.COMPUTING);
      const imageDecompositionRequest = {
        objectID: object.depixObject.objectID,
        isPreview: true,
      };
      setError(null);
      imageDecomposition({ variables: imageDecompositionRequest });
      setLastApiObject(object);
    }
  };

  const enableRelight = useCallback(
    (object: FabricLoadableImage) => {
      if (!object) {
        setRelightState(RelightState.DISABLE);
        return;
      }
      if (object.depixObject.isDecomposed()) {
        setLastRenderData(null);
        setRelightState(RelightState.ENABLE);
      } else {
        initialiseRelight(object);
      }
    },
    [initialiseRelight]
  );

  const renderRelight = (object, isPreview = true) => {
    if (!object.isRelighted()) {
      if (relightState !== RelightState.ENABLE) return;
      setRelightState(RelightState.DISABLE);
      applyOriginal(object);
    } else {
      setObjectToCompute({ object: object, isPreview: isPreview });
    }
  };

  return { enableRelight, initialiseRelight, renderRelight, relightState, error };
}

export default useRelight;
