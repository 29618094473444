import React from 'react';

import MuiSwitch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';

const SWITCH_HEIGHT = 17;
const ColoredSwitch = styled(MuiSwitch)(({ theme }) => ({
  padding: 0,
  borderRadius: SWITCH_HEIGHT / 2,
  height: SWITCH_HEIGHT,
  width: SWITCH_HEIGHT * 2,
  '& .MuiSwitch-switchBase': {
    '& .MuiSwitch-thumb': {
      margin: '2px',
      height: SWITCH_HEIGHT - 4,
      width: SWITCH_HEIGHT - 4,
      boxShadow: 'none',
    },
    padding: 0,
    '&.Mui-checked': {
      transform: 'translateX(100%)',
      color: 'white',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.light, theme.palette.action.hoverOpacity),
      },
      '& + .MuiSwitch-track': {
        backgroundColor: 'theme.palette.primary.main', // 'primary.main'
        opacity: 1,
      },
    },
  },
}));

const Switch = ({ label, ...props }) => <ColoredSwitch {...props} label={label} inputProps={{ 'aria-label': label }} />;

export default Switch;
