import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';
import Button from '@components/Ui/Button';
import { useAlert } from '@hooks/Alert';
import useInstrumentation from '@hooks/UseInstrumentation';
import { ShareLinkIncentive } from '@libs/Instrumentation/ShareLinkIncentive';

interface ShareLinkProps {
  link: string;
  compact?: boolean;
  className?: string;
}

export const ShareLink = ({ link, compact = false, className }: ShareLinkProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'imageSharing.shareActions' });
  const alert = useAlert();
  const instrumentation = useInstrumentation();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link).then(() => alert.success(t('confirmCopy')));
    instrumentation.imageLinkShared(ShareLinkIncentive.COPY_LINK);
  };

  return (
    <Wrapper className={className}>
      {!compact && <LinkText>{link}</LinkText>}
      <StyledButton variant="contained" onClick={handleCopyLink} compact={compact}>
        {t('copyLink')}
      </StyledButton>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;

  border-radius: ${(props) => props.theme.borderRadius.medium}px;

  box-shadow: ${(props) => props.theme.elevation.button};
`;

const LinkText = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 48px;
  padding: ${(props) => props.theme.spacing(0, 2)};
  padding-left: ${(props) => props.theme.spacing(4)};
  background-color: ${(props) => props.theme.palette.secondary.main};

  border-top-left-radius: ${(props) => props.theme.borderRadius.medium}px;
  border-bottom-left-radius: ${(props) => props.theme.borderRadius.medium}px;

  box-shadow: none;

  font-weight: 500;
  font-size: 0.75rem;
`;

const notForwardedProps: PropertyKey[] = ['compact'];

const StyledButton = styled(Button, {
  shouldForwardProp: (propName: PropertyKey) => !notForwardedProps.includes(propName),
})<{ compact: boolean }>`
  height: 48px;
  margin: 0;

  border-top-left-radius: ${(props) => (props.compact ? props.theme.borderRadius.large + 'px' : 0)};
  border-bottom-left-radius: ${(props) => (props.compact ? props.theme.borderRadius.large + 'px' : 0)};

  background: ${(props) => props.theme.palette.primary.main};
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.palette.primary.main} 0%,
    ${(props) => props.theme.palette.accept.main} 75%,
    ${(props) => props.theme.palette.accept.main} 100%
  );

  box-shadow: none;
`;
