import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

const ColorToolIcon = forwardRef(({ sx, color = 'rgba(0 ,0 ,0 , 0)', activated = false }, ref) => {
  return (
    <Box ref={ref} sx={{ borderRadius: '50%', background: `url('/checkboardSquare.png') repeat top left` }}>
      <Box
        sx={{
          ...sx,
          border: 'solid 2px',
          borderColor: activated ? 'primary.main' : 'secondary.medium',
          borderRadius: '50%',
          backgroundColor: color,
        }}
        role="icon"
      />
    </Box>
  );
});

ColorToolIcon.propTypes = {
  sx: PropTypes.object,
  color: PropTypes.string,
  activated: PropTypes.bool,
};

export default ColorToolIcon;
