/* eslint-disable max-len */
import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const WarningIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 52 52">
    <path d="M23.2144 39.7959C23.2144 40.6403 23.5079 41.4501 24.0303 42.0471C24.5527 42.6442 25.2612 42.9796 26 42.9796C26.7388 42.9796 27.4473 42.6442 27.9697 42.0471C28.4921 41.4501 28.7856 40.6403 28.7856 39.7959C28.7856 38.9516 28.4921 38.1418 27.9697 37.5447C27.4473 36.9477 26.7388 36.6122 26 36.6122C25.2612 36.6122 24.5527 36.9477 24.0303 37.5447C23.5079 38.1418 23.2144 38.9516 23.2144 39.7959V39.7959ZM24.143 19.6327V31.8367C24.143 32.1286 24.3519 32.3673 24.6072 32.3673H27.3928C27.6481 32.3673 27.857 32.1286 27.857 31.8367V19.6327C27.857 19.3408 27.6481 19.102 27.3928 19.102H24.6072C24.3519 19.102 24.143 19.3408 24.143 19.6327ZM51.749 48.8163L27.6075 1.06122C27.2477 0.351531 26.6268 0 26 0C25.3733 0 24.7465 0.351531 24.3925 1.06122L0.25101 48.8163C-0.462789 50.2357 0.43091 52 1.85851 52H50.1415C51.5691 52 52.4628 50.2357 51.749 48.8163ZM6.28058 46.9658L26 7.95255L45.7194 46.9658H6.28058V46.9658Z" />
  </SvgIcon>
);

export default WarningIcon;
