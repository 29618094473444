import { Box, ClickAwayListener, styled, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { EditMode } from './CanvasCropTool';
import { MIN_CROP_WINDOW_SIZE } from '@components/Crop/CropOverlay';
import { EditableText } from '@components/Ui/EditableText';

export const CROP_BOX_WIDTH_ID = 'crop-box-width-id';
export const CROP_BOX_HEIGHT_ID = 'crop-box-height-id';

interface ResolutionDisplayProps {
  width: number;
  height: number;
  maxWidth: number;
  maxHeight: number;
  onChange: (width: number, height: number) => void;
  editMode: EditMode;
  onChangeEditMode: (editMode: EditMode) => void;
}

export const ResolutionDisplay = ({
  width,
  height,
  maxWidth,
  maxHeight,
  onChange,
  editMode,
  onChangeEditMode,
}: ResolutionDisplayProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'tools.cropTool.resolution' });
  const [newWidth, setNewWidth] = useState<number>(width);
  const [newHeight, setNewHeight] = useState<number>(height);
  const editingWidth = editMode == EditMode.EDIT_WIDTH;
  const editingHeight = editMode == EditMode.EDIT_HEIGHT;

  const handleWidthChange = (e) => {
    setNewWidth(e.target.value);
  };

  const handleHeightChange = (e) => {
    setNewHeight(e.target.value);
  };

  const applySize = () => {
    onChange(newWidth, newHeight);
  };

  const focusWidth = () => {
    onChangeEditMode(EditMode.EDIT_WIDTH);
    applySize();
  };

  const focusHeight = () => {
    onChangeEditMode(EditMode.EDIT_HEIGHT);
    applySize();
  };

  const handleOnWrapperClick = (e) => {
    e.stopPropagation();
    const wrapperRect = e.target.getBoundingClientRect();
    const relativeClickPosition = (e.clientX - wrapperRect.left) / wrapperRect.width;
    if (relativeClickPosition <= 0.5) {
      focusWidth();
    } else {
      focusHeight();
    }
  };

  const handleOnWidthClick = (e) => {
    e.stopPropagation();
    focusWidth();
  };

  const handleOnHeightClick = (e) => {
    e.stopPropagation();
    focusHeight();
  };

  const handleKeyDown = (e) => {
    const stopEvent = () => {
      e.stopPropagation();
      e.preventDefault();
    };

    if (e.code == 'Enter') {
      stopEvent();
      applySize();
      onChangeEditMode(EditMode.NONE);
    } else if (e.code == 'Escape') {
      stopEvent();
      setNewWidth(width);
      setNewHeight(height);
      onChangeEditMode(EditMode.NONE);
    } else if (e.code == 'Tab') {
      stopEvent();
      if (editingWidth) {
        focusHeight();
      } else if (editingHeight) {
        focusWidth();
      }
    }
  };

  const handleDoubleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onClickAway = () => {
    applySize();
    onChangeEditMode(EditMode.NONE);
  };

  useEffect(() => {
    if (width && width != newWidth) {
      setNewWidth(width);
    }
    if (height && height != newHeight) {
      setNewHeight(height);
    }
  }, [width, height]);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Wrapper onClick={handleOnWrapperClick} onDoubleClick={handleDoubleClick} aria-label={t('label.wrapper')}>
        <Field onClick={handleOnWidthClick} editMode={editMode == EditMode.EDIT_WIDTH}>
          <Label>{t('width')}</Label>
          <EditableText
            id={CROP_BOX_WIDTH_ID}
            type="number"
            label={t('label.widthInput')}
            editing={editingWidth}
            value={editingWidth ? newWidth : width}
            InputProps={{ inputProps: { min: MIN_CROP_WINDOW_SIZE, max: maxWidth } }}
            onKeyDown={handleKeyDown}
            onChange={handleWidthChange}
          />
        </Field>
        <Field onClick={handleOnHeightClick} editMode={editMode == EditMode.EDIT_HEIGHT}>
          <Label>{t('height')}</Label>
          <EditableText
            id={CROP_BOX_HEIGHT_ID}
            type="number"
            label={t('label.heightInput')}
            editing={editingHeight}
            value={editingHeight ? newHeight : height}
            InputProps={{ inputProps: { min: MIN_CROP_WINDOW_SIZE, max: maxHeight } }}
            onKeyDown={handleKeyDown}
            onChange={handleHeightChange}
          />
        </Field>
        <Background />
      </Wrapper>
    </ClickAwayListener>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(1)};
  padding: ${(props) => props.theme.spacing(1, 2)};
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
`;

const Background = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  opacity: 0.8;
  border-radius: ${(props) => props.theme.borderRadius.medium}px;
  z-index: ${(props) => props.theme.zIndex.mobileStepper};
`;

const Field = styled(Box, { shouldForwardProp: (prop) => prop != 'editMode' })<{ editMode: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing(1)};
  border-radius: ${(props) => props.theme.borderRadius.default}px;
  border: 2px solid ${(props) => (props.editMode ? props.theme.palette.primary.main : 'transparent')};
  padding: ${(props) => props.theme.spacing(0, 1)};
  height: 28px;

  opacity: ${(props) => (props.editMode ? 1 : 'inherit')};
  background-color: ${(props) => (props.editMode ? props.theme.palette.secondary.main : 'transparent')};
  z-index: ${(props) => props.theme.zIndex.composeToolbar};
`;

const StyledTypography = styled(Typography)`
  padding-top: 1px;
`;
const LabelTypography = styled(StyledTypography)`
  cursor: inherit;
  padding: 0;
`;
const Label = ({ children, ...props }: TypographyProps) => {
  const theme = useTheme();

  return (
    <LabelTypography {...props} color={theme.palette.primary.main} display="inline" fontSize={20} fontWeight={500}>
      {children}
    </LabelTypography>
  );
};
