import React from 'react';
import Icon from '@mui/icons-material/Crop';

type CropIconProps = {
  color?: string;
  style?: object;
  props?: object;
};

const CropIcon = ({ color, style, ...props }: CropIconProps) => <Icon style={{ color, ...style }} {...props} />;

export default CropIcon;
