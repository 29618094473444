import React from 'react';
import PropTypes from 'prop-types';

import { useActiveObject } from '../Context/SceneContext';

import ShadowPanel from '../Components/Object/ShadowPanel';
import { SHADOW_STATE } from '../Libs/DepixObject';

const ShadowPanelContainer = ({ isSmallDevice }) => {
  const {
    activeObject,
    setShadowProperties,
    setShadowState,
    shadowState,
    shadowDiffusion,
    shadowOpacity,
    shadowEnabled,
    setSceneCommitted,
  } = useActiveObject();

  const handleShadowChange = (opacity, diffusion, commit) => {
    setShadowProperties(diffusion, opacity, commit);
  };

  const handleChangeCommitted = () => {
    setSceneCommitted();
  };

  const handleWarpChange = (checked) => {
    setShadowState(checked ? SHADOW_STATE.GROUND : SHADOW_STATE.FLAT);
  };

  if (!activeObject) return null;

  return (
    <ShadowPanel
      isSmallDevice={isSmallDevice}
      shadowOpacity={shadowOpacity}
      shadowDiffusion={shadowDiffusion}
      onChange={handleShadowChange}
      onChangeCommitted={handleChangeCommitted}
      shadowState={shadowState}
      onSetWarpingModeCb={handleWarpChange}
      disabled={!shadowEnabled}
    />
  );
};

ShadowPanelContainer.propTypes = {
  isSmallDevice: PropTypes.bool.isRequired,
};

export default ShadowPanelContainer;
