import React from 'react';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import CodeIcon from '@mui/icons-material/Code';

import { useTranslation } from 'react-i18next';

const DevToolsButton = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'DevTools-Popover' : undefined;

  return (
    <>
      <Button
        aria-label={t('devTool.ariaLabel.button')}
        variant="compact"
        sx={{ minWidth: 'auto', padding: 0 }}
        startIcon={<CodeIcon sx={{ margin: 0, color: 'secondary.contrastText' }} />}
        onClick={handleClick}
        color="secondary"
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box sx={{ py: 1, px: 4 }}>{children}</Box>
      </Popover>
    </>
  );
};
export default DevToolsButton;
