import { Box, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import Logo from '@components/Header/Logo';
import { Trans } from 'react-i18next';
import { Link } from '@components/Ui/Link';

interface ShutdownPageProps {
  className?: string;
}

export const ShutdownPage = ({ className }: ShutdownPageProps) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Wrapper className={className}>
      <Header>
        <Box
          sx={{
            pointerEvents: 'auto',
          }}>
          <StyledLink href="/">
            <Logo small={isSmallDevice} />
          </StyledLink>
        </Box>
      </Header>
      <BodyWrapper>
        <Message align={'center'} display={'block'}>
          <Trans
            i18nKey="shutdownPage.message"
            components={{
              pageLink: <Link href={'https://www.depix.ai/'} target="_blank" />,
            }}
          />
        </Message>
      </BodyWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: start;
  margin: ${(props) => props.theme.spacing(3, 3, 0, 3)};
`;

const BodyWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  position: relative;
`;

const Message = styled(Typography)`
  font-size: xx-large;
  align-content: center;
  position: absolute;
  top: 30%;
`;

const StyledLink = styled('a')`
  display: flex;
  align-items: center;
  justify-content: center;
`;
