import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// ui
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import CancelIcon from '@mui/icons-material/Cancel';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';

import useFeedbackQuery from '@hooks/UseFeedbackQuery';
import { useAlert } from '@hooks/Alert';
import { useTranslation } from 'react-i18next';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Surface from '../Ui/Surface';
import ToolButton from '@components/Viewport/ToolButton';

const StyledSurface = ({ children }) => (
  <Surface sx={{ p: 4, maxHeight: 'calc(100% - 100px)', display: 'flex', flexDirection: 'column' }}>{children}</Surface>
);
StyledSurface.propTypes = {
  children: PropTypes.node,
};

/**
 * @param {func} getImage Function to retrive image to send to team
 * The return should have the form of:
 * {
 *   file: {blob object},
 *   url: 'url.to.the.image',
 * }
 * @return {*}
 */
export default function SendToTeamButton({ getImage }) {
  const { t } = useTranslation(null, { keyPrefix: 'feedback' });
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [image, setImage] = useState({
    file: null,
    url: '',
  });
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const ButtonBoxDlexDirection = downSm ? 'column' : 'row';
  const ButtonSx = { width: 'fit-content', m: 0.5 };

  const { feedbackRequest, isLoading, isSuccess } = useFeedbackQuery();
  const alert = useAlert();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setComment('');
    setOpen(false);
  };

  const handleClick = () => {
    getImage().then((image) => {
      setImage(image);
      handleOpen();
    });
  };

  useEffect(() => {
    if (isSuccess) {
      if (isSuccess.sendFeedback) {
        handleClose();
        alert.success(t('alerts.success'));
      }
    }
  }, [isSuccess]);

  return (
    <>
      <ToolButton name={t('showToTeam.ariaLabel.rootButton')} toolTip={t('tooltip')} onClick={handleClick}>
        <FeedbackIcon />
      </ToolButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box component={StyledSurface}>
          <Box sx={{ overflowY: 'auto' }}>
            <Typography align="center" fontSize={30} fontWeight={1000}>
              {t('title')}
            </Typography>
            <Box
              sx={{
                overflowY: 'auto',
              }}>
              <Typography fontSize={20} fontWeight={500}>
                {t('imageTitle')}
              </Typography>
              <CardMedia component="img" image={image.url} alt="Shared image" />
              <Typography fontSize={20} fontWeight={500}>
                {t('commentTitle')}
              </Typography>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={3}
                fullWidth
                helperText={t('commentHelp')}
                onChange={(e) => {
                  e.preventDefault();
                  setComment(e.target.value);
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: ButtonBoxDlexDirection,
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <Button
              aria-label={t('showToTeam.ariaLabel.cancelButton')}
              color="primary"
              variant="contained"
              sx={ButtonSx}
              endIcon={<CancelIcon />}
              onClick={handleClose}>
              {t('cancelButton')}
            </Button>
            <LoadingButton
              aria-label={t('showToTeam.ariaLabel.submitButton')}
              color="primary"
              variant="contained"
              sx={ButtonSx}
              loading={isLoading && !isSuccess}
              endIcon={<SendIcon />}
              onClick={() => {
                feedbackRequest(image.file, comment);
              }}>
              {t('sendButton')}
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

SendToTeamButton.propTypes = {
  disabled: PropTypes.bool,
  getImage: PropTypes.func.isRequired,
  issueAlert: PropTypes.func,
};
