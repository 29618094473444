import React from 'react';
import PropTypes from 'prop-types';

import { Trans, useTranslation } from 'react-i18next';
import ContentTemplate, { ButtonType } from './ContentTemplate';
import Link from '@mui/material/Link';
import { useAuthV2 } from '@contexts/AuthV2';
import { Path, useLocation, useNavigate } from 'react-router-dom';
import Routes from '@root/routes';

const VerificationPage = ({ onOpenEmailField, onRetry }) => {
  const { t } = useTranslation();
  const { logout } = useAuthV2();
  const navigate = useNavigate();

  const buttons = [{ name: t('error.verifyAccount.buttons.retry'), onClick: onRetry, type: ButtonType.MAIN }];

  const onLogout = () => {
    logout();
    navigate(Routes.auth(), { replace: true });
  };

  const logoutLink = () => (
    <Link
      href="#"
      role="button"
      aria-label={t('error.verifyAccount.buttons.logout')}
      onClick={onLogout}
      underline="none"
      color="primary.main"
    />
  );
  const resendLink = () => (
    <Link
      href="#"
      role="button"
      aria-label={t('error.verifyAccount.buttons.resend')}
      onClick={onOpenEmailField}
      underline="none"
    />
  );

  return (
    <ContentTemplate
      title={t('error.verifyAccount.titles.verificationPage')}
      description={
        <Trans
          i18nKey="error.verifyAccount.descriptions.verificationPage"
          t={t}
          components={{
            logout: logoutLink(),
            resend: resendLink(),
          }}
        />
      }
      buttons={buttons}
    />
  );
};

VerificationPage.propTypes = {
  onOpenEmailField: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
};

export default VerificationPage;
