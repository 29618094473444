import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ImageIcon from '@mui/icons-material/Image';

import useInstrumentation from '@hooks/UseInstrumentation';
import { BorderRadius } from '@root/theme';

/**
 * @param {*} props
 * @return {*}
 */
export default function DropZone({
  onDrop,
  defaultContent,
  children,
  showBorder = true,
  isShowDefault = true,
  isClickDisabled = false,
  target,
}) {
  const { t } = useTranslation();
  const [isDragging, setDragging] = useState(false);
  const instrumentation = useInstrumentation();

  const handleDragOver = () => {
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDrop = (acceptedFiles, _, e) => {
    e?.preventDefault();
    setDragging(false);

    if (acceptedFiles.length > 0) {
      onDrop(acceptedFiles[0]);
      instrumentation.imageUploaded(target, 'local');
    } else {
      let stockImage = {};
      try {
        stockImage = JSON.parse(e?.dataTransfer?.getData('text') || '{}');
      } catch (_) {
        return;
      }
      if (stockImage?.source && stockImage?.downloadUrl) {
        onDrop(stockImage);
        instrumentation.imageUploaded(target, stockImage.source);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    noClick: isClickDisabled,
    noKeyboard: true,
    accept: 'image/*',
    onDrop: handleDrop,
    onDragOver: handleDragOver,
    onDragLeave: handleDragEnd,
  });

  const defaultDropZoneContent = () => {
    if (defaultContent) {
      return defaultContent;
    }

    return (
      <>
        <div>
          <ImageIcon
            style={{
              fontSize: 80,
            }}
            color="greyInfo"
          />
        </div>
        <Typography variant="dropZoneText">{t('canvas.dropInstruction')}</Typography>
        <Typography variant="dropZoneText">{t('general.or')}</Typography>
        <Typography variant="dropZoneText">{t('canvas.selectInstruction')}</Typography>
      </>
    );
  };

  return (
    <Box
      onDrop={(e) => handleDrop([], [], e)}
      onDragOver={handleDragOver}
      onDragLeave={handleDragEnd}
      onDragEnd={handleDragEnd}
      sx={{
        width: '100%',
        height: '100%',
        borderColor: (theme) => (showBorder ? theme.palette.secondary.dark : ''),
        borderRadius: (theme) => theme.borderRadius[BorderRadius.XSMALL],
        boxShadow: isDragging ? (theme) => theme.elevation?.canvas : 'none',
        outline: 'none',
        overflow: 'hidden',
      }}
      data-testid="drag-and-drop">
      <Box
        {...getRootProps({ className: 'dropzone' })}
        component="div"
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          outline: 'none',
        }}
        role="dropzone">
        <input {...getInputProps()} />
        <Box component="div" sx={{ height: '100%' }}>
          {children}
        </Box>
        {isShowDefault && (
          <Box
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {defaultDropZoneContent()}
          </Box>
        )}
      </Box>
    </Box>
  );
}

DropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  children: PropTypes.node,
  isShowDefault: PropTypes.bool,
  isClickDisabled: PropTypes.bool,
  defaultContent: PropTypes.node,
  showBorder: PropTypes.bool,
  target: PropTypes.string.isRequired,
};
