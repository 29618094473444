export enum BackgroundType {
  DEPIX = 'depix-background',
  PLAIN = 'plain-background',
}

export interface BackgroundObject {
  image: HTMLImageElement;
  type: BackgroundType;
  objectID: string;
  updated: boolean;

  getImage: (fullResolution: boolean) => Promise<HTMLImageElement>;
  isTransparent: () => boolean;

  serialize: () => any;
}
