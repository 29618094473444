import React from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Trans } from 'react-i18next';
import { Link } from '@components/Ui/Link';

interface ReCaptchaTermsProps {
  className?: string;
}

export const ReCaptchaTerms = ({ className }: ReCaptchaTermsProps) => (
  <Wrapper className={className}>
    <StyledText variant="caption">
      <Trans i18nKey="reCaptcha.disclaimer" components={{ termsLink: <Link target="_blank" /> }} />
    </StyledText>
  </Wrapper>
);

const Wrapper = styled(Box)`
  text-align: center;
  color: ${(props) => props.theme.palette.secondary.dark};
`;

const StyledText = styled(Typography)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 0.65em;
  }
`;
