/* eslint-disable react-hooks/exhaustive-deps */
import { DepixObject } from '@libs/DepixObject';
import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { useMutation } from '@apollo/client';
import _ from 'lodash';

import {
  SegmentationActionType,
  SegmentationClick,
  segmentationReducer,
  SegmentationState,
} from './SegmentationReducer';
import { SegmentationMode } from './SegmentationMode';

import { EventName, State } from '@hooks/CanvasEventController';
import useInstrumentation from '@hooks/UseInstrumentation';
import { useGraphqlErrorHandler } from '@hooks/useGraphqlErrorHandler';
import useFabric from '@hooks/Fabric';

import { SEGMENT_OBJECT } from '@libs/DepixApi';
import { MeasureType } from '@libs/Instrumentation/MeasureType';
import { useSegmentationObserver } from '@hooks/UseSegmentationObserver';
import { MaskRecord } from '@libs/Mask';
import useUndoRedoStack from '@hooks/undoRedoStack';

export interface Click {
  x: number;
  y: number;
  alt?: boolean;
}

interface SegmentationContextValue {
  enabled: boolean;
  preview: boolean;
  actionsDisabled: boolean;
  segmentationObjectID: string | null;
  mask: string | null;
  maskId: string | null;
  loading: boolean;
  reset: () => void;
  clear: () => void;
  undo: () => void;
  redo: () => void;
  addClick: (click: Click) => void;
  changeSegmentedObject: (depixObject: DepixObject) => void;
  changeMode: (mode: SegmentationMode | false) => void;
  segmentationMode: SegmentationMode | false;
  setEnabled: (enabled: boolean) => void;
  editSegmentation: () => void;
  clicks: SegmentationClick[];
  canUndo: boolean;
  canRedo: boolean;
  autoSegmented: boolean;
}

export const SegmentationContext = React.createContext({} as SegmentationContextValue);

export const SegmentationProvider = ({ preview = false, enabled = false, debounceTime = 1000, children }) => {
  const initialState: SegmentationState = {
    enabled,
    preview,
    debounceTime,
    mode: false,
    actionsDisabled: false,
    clickStack: [],
    isLoadedRecord: true,
    segmentationObjectID: null,
    mask: null,
    maskId: null,
    loading: false,
  };
  const [state, dispatch] = useReducer(segmentationReducer, initialState, () => initialState);
  const [clickStackBeforeSegment, setClickStackBeforeSegment] = useState();
  const [preRequestState, setPreRequestState] = useState(initialState);

  const dispatchPayload = (type, payload?) => dispatch({ type, payload });
  const { displayError } = useGraphqlErrorHandler();
  const maskHistory = useUndoRedoStack<MaskRecord>();

  const {
    registerListener,
    unregisterListener,
    setCanvasEventState,
    isOutsideBackground,
    viewportToBackground,
    drawMaskOverlay,
    clearMaskOverlay,
  } = useFabric();
  const observer = useSegmentationObserver();
  const instrumentation = useInstrumentation();

  const [applySegmentation, { loading: requestLoading }] = useMutation(SEGMENT_OBJECT, {
    onCompleted(result) {
      const { applySegmentation } = result;

      if (!applySegmentation || clickStackChanged()) return;

      dispatchPayload(SegmentationActionType.MASK_APPLIED, {
        mask: applySegmentation.mask,
      });
    },
    onError(error) {
      dispatchPayload(SegmentationActionType.SEGMENTATION_FAILED);
      displayError(error);
    },
  });

  const clickStackChanged = useCallback(() => {
    return !_.isEqual(clickStackBeforeSegment, state.clickStack);
  }, [clickStackBeforeSegment, state.clickStack]);

  const isClickStackEmpty = useCallback(
    (clicks = state.clickStack) => {
      return clicks.length === 0;
    },
    [state.clickStack]
  );

  const segment = useCallback(
    (state) => {
      if (isClickStackEmpty(state.clickStack) || !state.segmentationObjectID) return;

      setClickStackBeforeSegment(state.clickStack);

      const userInput = state.clickStack.map((click) => {
        return { x: click.x, y: click.y, isForeground: click.isForeground };
      });
      const getMaskRequest = {
        userInput,
        objectID: state.segmentationObjectID,
        refine: false,
        isPreview: state.preview,
        maskID: state.maskId,
      };

      applySegmentation({ variables: getMaskRequest });
      observer.notifySegmentationApplied(state.segmentationObjectID, userInput);
    },
    [observer.notifySegmentationApplied]
  );

  const debouncedSegment = useCallback(_.debounce(segment, state.debounceTime), [segment]);

  const addClick = useCallback(
    (click: Click) => {
      const isForeground = state.mode !== SegmentationMode.BACKGROUND && !click.alt;
      dispatchPayload(SegmentationActionType.CLICK_ADDED, {
        click: { x: click.x, y: click.y, isForeground: isForeground },
      });

      const segmentationType = isForeground ? 'foreground' : 'background';
      instrumentation.segmentationClickAdded(segmentationType, state.segmentationObjectID);
    },
    [state.segmentationObjectID, state.mode]
  );

  const handleClick = useCallback(
    (_, mousePointer) => {
      const originalClick = { ...mousePointer, x: mousePointer.x, y: mousePointer.y };
      if (isOutsideBackground(originalClick)) return;

      const normalize = true;
      const clip = false;
      const click = viewportToBackground(originalClick, normalize, clip);

      addClick({ ...click, alt: mousePointer.alt });

      return {
        stopPropagation: true,
      };
    },
    [isOutsideBackground, state.mode, state.segmentationObjectID, addClick]
  );

  const clear = () => {
    if (!isClickStackEmpty()) {
      dispatchPayload(SegmentationActionType.CLICKS_CLEARED);
    }

    dispatchPayload(SegmentationActionType.MASK_CLEARED);

    clearMaskOverlay();
    instrumentation.segmentationCleared(state.segmentationObjectID);
  };

  const reset = () => {
    dispatchPayload(SegmentationActionType.SEGMENTATION_RESET);
    clearMaskOverlay();
  };

  const undo = useCallback(() => {
    if (maskHistory.canUndo) {
      const maskRecord = maskHistory.undo();
      dispatchPayload(SegmentationActionType.LOAD_MASK, {
        clickStack: maskRecord.clickStack,
        mask: { url: maskRecord.url, id: maskRecord.id },
      });

      instrumentation.segmentationUndo(state.segmentationObjectID);
    }
  }, [maskHistory?.canUndo, maskHistory?.undo, state.segmentationObjectID]);

  const redo = useCallback(() => {
    if (maskHistory.canRedo) {
      const maskRecord = maskHistory.redo();
      dispatchPayload(SegmentationActionType.LOAD_MASK, {
        clickStack: maskRecord.clickStack,
        mask: { url: maskRecord.url, id: maskRecord.id },
      });

      instrumentation.segmentationRedo(state.segmentationObjectID);
    }
  }, [maskHistory?.canUndo, maskHistory?.undo, state.segmentationObjectID]);

  const changeMode = (newMode) => {
    dispatchPayload(SegmentationActionType.MODE_CHANGED, { mode: newMode });
    instrumentation.instrument(MeasureType.SEGMENTATION_MODE_CHANGED, { mode: newMode });
  };

  const changeSegmentedObject = (object) => {
    reset();
    maskHistory.clear();
    const payload = { object: object.objectID, mask: { url: object.mask?.src, id: object.maskId } };
    dispatchPayload(SegmentationActionType.SEGMENTED_OBJECT_CHANGED, payload);
  };

  const editSegmentation = () => {
    instrumentation.segmentationEditSelected();
    dispatchPayload(SegmentationActionType.EDIT_SEGMENTATION);
  };

  useEffect(() => {
    if (!state.isLoadedRecord) {
      maskHistory.push({ clickStack: state.clickStack, id: state.maskId, url: state.mask });
    }
  }, [state.mask]);

  // debounce segmentation if clickStack changes
  useEffect(() => {
    if (!requestLoading && !state.isLoadedRecord) {
      debouncedSegment(state);
    }
  }, [state.clickStack]);

  // Apply segmentation if state changed during request
  useEffect(() => {
    if (requestLoading) {
      setPreRequestState(state);
    } else {
      if (preRequestState.clickStack !== state.clickStack) {
        segment(state);
      }
    }
  }, [requestLoading]);

  useEffect(() => {
    if (!state.mask) {
      clearMaskOverlay();
    } else if (state.enabled) {
      const transparency = state.autoSegmented ? 0 : 0.4;
      drawMaskOverlay(state.mask, transparency);
    }
  }, [state.actionsDisabled, state.mask, state.autoSegmented]);

  useEffect(() => {
    dispatchPayload(SegmentationActionType.TOOLS_DISABLED, { actionsDisabled: !state.enabled || !state.mask });
  }, [state.enabled, state.mask]);

  useEffect(() => {
    const handlerName = 'handleSegmentationClick';
    const state = State.SPECIAL;
    registerListener(EventName.MOUSE_UP, handleClick, handlerName, state);

    return () => {
      unregisterListener(EventName.MOUSE_UP, handlerName);
    };
  }, [registerListener, handleClick]);

  useEffect(() => {
    if (!state.segmentationObjectID || !state.enabled || state.autoSegmented) {
      setCanvasEventState(State.DEFAULT);
    } else {
      setCanvasEventState(State.SPECIAL);
    }
  }, [state.segmentationObjectID, state.autoSegmented, state.enabled]);

  useEffect(() => {
    setCanvasEventState(State.DEFAULT);
  }, []);

  const setEnabled = (enabled) => {
    dispatchPayload(SegmentationActionType.SEGMENTATION_ENABLED, { enabled });
  };

  return (
    <SegmentationContext.Provider
      value={{
        enabled: state.enabled,
        preview: state.preview,
        actionsDisabled: state.actionsDisabled,
        segmentationObjectID: state.segmentationObjectID,
        mask: state.mask,
        maskId: state.maskId,
        loading: state.loading,
        reset,
        clear,
        undo,
        redo,
        addClick,
        changeSegmentedObject,
        changeMode,
        segmentationMode: state.mode,
        setEnabled,
        editSegmentation,
        clicks: state.clickStack,
        canUndo: maskHistory.canUndo,
        canRedo: maskHistory.canRedo,
        autoSegmented: state.autoSegmented,
      }}>
      {children}
    </SegmentationContext.Provider>
  );
};
