import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';
import Box from '@mui/material/Box';

import ToolButton from '@components/Viewport/ToolButton';
import DownloadMaskButton from '@components/Viewport/DownloadMaskButton';
import SwapButton from '@components/Viewport/SwapButton';
import SegmentActions from './SegmentActions/SegmentActions';
import ToolBar from './ToolBar';

import { useSegmentation } from '@contexts/Segmentation/UseSegmentation';
import { styled } from '@mui/material';
import { EditSegmentationIcon } from '@components/Ui/Icons/EditSegmentationIcon';
import Divider from '@components/Ui/Divider';
import { SegmentationModeAction } from '@components/Viewport/SegmentActions/SegmentationModeSelector';
import { SegmentationFlowAction } from '@components/Viewport/SegmentActions/SegmentationFlowController';

export type ExtractAction = SegmentationModeAction | SegmentationFlowAction;

interface ExtractToolBar {
  onStateChange?: (toolbarHidden: boolean) => void;
  onActionChange?: (action: ExtractAction) => void;
  onExtract?: () => void;
  includeText: boolean;
  className?: string;
}

const ExtractToolBar = ({ onStateChange = () => {}, onExtract = () => {}, className, includeText }: ExtractToolBar) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'tools.cut' });
  const { segmentationObjectID, actionsDisabled, autoSegmented, editSegmentation, clear } = useSegmentation();
  const canBeSegmented = !!segmentationObjectID;

  useEffect(() => {
    onStateChange(!canBeSegmented);
  }, [canBeSegmented]);

  return (
    <Wrapper className={className} role="toolbar" aria-label={t('ariaLabel.root')}>
      {!canBeSegmented && <ToolBar />}

      {autoSegmented && (
        <ToolBar name={t('ariaLabel.autoSegmentedToolbar')} label={t('ariaLabel.autoSegmentedToolbar')}>
          <ToolButton
            name={t('labels.editSegmentation')}
            onClick={editSegmentation}
            buttonText={t('labels.editSegmentation')}
            inline>
            <EditSegmentationIcon />
          </ToolButton>

          <Divider />

          <DownloadMaskButton name={t('buttons.download')} buttonText={t('buttons.download')} inline />
        </ToolBar>
      )}

      {!autoSegmented && canBeSegmented && (
        <SegmentActions includeText={includeText} label={t('ariaLabel.segmentToolbar')} onReset={clear}>
          {!includeText && (
            <>
              <SwapButton onClick={onExtract} />

              <DownloadMaskButton
                name="Download"
                toolTip={t('tooltips.download')}
                buttonText={includeText ? t('buttons.download') : undefined}
                disabled={actionsDisabled}
              />
            </>
          )}
        </SegmentActions>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ExtractToolBar;
