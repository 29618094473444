import React from 'react';

import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';

import LogoutIcon from '@components/Ui/Icons/LogoutIcon';
import LoginIcon from '@components/Ui/Icons/LoginIcon';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useAuthV2 } from '@contexts/AuthV2';

const Item = styled(MenuItem)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  color: 'secondary.contrastText',
  fontWeight: 500,
  textAlign: 'left',
  borderRadius: 10,
  '&:hover': {
    backgroundColor: 'inherit',
    color: theme.palette.secondary.dark,
  },
}));

const Navigation = ({}) => {
  const { login, logout, isAuthenticated, isGuest } = useAuthV2();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const AuthIcon = isAuthenticated && !isGuest ? LogoutIcon : LoginIcon;
  const authText = isAuthenticated && !isGuest ? t('general.logout') : t('general.login');
  const onClickCallback = isAuthenticated && !isGuest ? logout : login;

  const onClickAuth = (returnTo) => {
    onClickCallback();
    navigate(returnTo);
  };

  const stopEvents = (e) => {
    e.stopPropagation();
  };

  return (
    <Box onClick={stopEvents} onMouseEnter={stopEvents}>
      <Box sx={{ mx: 0 }}>
        <MenuList aria-label={t('navigation.ariaLabel')} sx={{ p: 0 }}>
          <Item onClick={() => onClickAuth({ returnTo: window.location.origin })}>
            <ListItemText>{authText}</ListItemText>
            <ListItemIcon sx={{ color: 'inherit' }}>
              <AuthIcon />
            </ListItemIcon>
          </Item>
        </MenuList>
      </Box>
    </Box>
  );
};

export default Navigation;
