import MuiTimelineConnector from '@mui/lab/TimelineConnector';
import styled from '@mui/material/styles/styled';

const TimelineConnector = styled(MuiTimelineConnector, {
  shouldForwardProp: (prop) => prop !== 'solid',
})(({ solid, theme }) => ({
  display: 'block',
  width: 0,
  margin: theme.spacing(0, 1),
  backgroundColor: 'transparent',
  borderRadius: 2,
  borderWidth: 2,
  borderStyle: solid ? 'solid' : 'dashed',
  borderColor: theme.palette.secondary.main,
}));

export default TimelineConnector;
