import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Button from '@components/Ui/Button';
import StockImagesDialog from './StockImagesDialog';
import SearchImageIcon from '@components/Ui/Icons/SearchImageIcon';

interface StockImagesButtonProps {
  variant?: string;
  color?: string;
  target: string;
  onClick?: () => void;
}

const StockImagesButton = ({ variant, color, target, onClick }: StockImagesButtonProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    if (onClick) {
      onClick();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} color={color} variant={variant} startIcon={<SearchImageIcon />}>
        {t('gallery.browse')}
      </Button>

      <StockImagesDialog open={open} onClose={handleClose} target={target} />
    </>
  );
};

export default StockImagesButton;
