import { PasteCanvasDebugTools } from '@components/Canvas/PasteCanvasDebugTools';
import React, { useEffect } from 'react';

import Divider from '@components/Ui/Divider';

import DevToolsButton from '@components/WebApp/DevToolsButton';
import SendToTeamButton from '@components/WebApp/SendToTeamButton';

import useFabric from '@hooks/Fabric';
import { Feature, useFeatureFlag } from '@hooks/FeatureFlag';
import useComposeSelectMode from '@hooks/UseComposeSelectMode';
import { useScene } from '@contexts/SceneContext';
import { Box, styled, useTheme } from '@mui/material';

import { useTranslation } from 'react-i18next';
import ToolBar from '../ToolBar';
import UndoButton from '../UndoButton';
import RedoButton from '../RedoButton';
import BackgroundToolbar from '@components/Viewport/Paste/BackgroundToolbar';
import { SegmentationModeAction } from '@components/Viewport/SegmentActions/SegmentationModeSelector';
import { SegmentationFlowController } from '@components/Viewport/SegmentActions/SegmentationFlowController';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SelectMode } from '@contexts/ComposeSelectModeContext';
import { ShareToolbarButton } from '@components/Viewport/Paste/ShareComposition/ShareToolbarButton';
import { useShareImage } from '@hooks/UseShareImage';

export type ComposeAction = SegmentationModeAction;

interface ExtraToolsProps {
  startsWithDivider?: boolean;
}

const ExtraTools = ({ startsWithDivider = true }: ExtraToolsProps) => {
  const fabric = useFabric();
  const featureFlag = useFeatureFlag();
  const isDevMode = featureFlag.canUseFeature(Feature.DEV_TOOLS);
  const { canShare: canShareToPublic } = useShareImage();

  const feature = useFeatureFlag();
  const canShareWithDepix = feature.canUseFeature(Feature.SHARE_WITH_DEPIX);

  const needsDivider = startsWithDivider && (isDevMode || canShareWithDepix || canShareToPublic);

  return (
    <>
      {needsDivider && <Divider />}

      {isDevMode && (
        <DevToolsButton>
          <PasteCanvasDebugTools />
        </DevToolsButton>
      )}
      {canShareWithDepix && <SendToTeamButton getImage={fabric.exportCanvas} />}
      {canShareToPublic && <ShareToolbarButton />}
    </>
  );
};

interface UndoRedoToolsProps {
  startsWithDivider?: boolean;
}

const UndoRedoTools = ({ startsWithDivider = true }: UndoRedoToolsProps) => {
  return (
    <>
      {startsWithDivider && <Divider />}
      <UndoButton />
      <RedoButton />
    </>
  );
};

interface ComposeToolBarProps {
  className?: string;
}

const ComposeToolBar = ({ className }: ComposeToolBarProps) => {
  const { t } = useTranslation();

  const { selectMode, resetSelectMode: onSegmentationCompleted } = useComposeSelectMode();

  const scene = useScene();
  const canUndoRedo = scene.canUndo || scene.canRedo;
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const fabric = useFabric();
  const isCanvasEmpty = fabric.isEmpty();

  useEffect(() => {
    const objectToHide = [...fabric.getAll3DObjects(), ...fabric.getAllInfillObjects()];

    objectToHide.forEach((obj) => {
      obj.setHide(selectMode != SelectMode.NONE);
    });
  }, [selectMode]);

  return (
    <Wrapper className={className} role="toolbar" aria-label={t('tools.compose.ariaLabel.toolbar')}>
      <ToolBar>
        {!isSmallDevice && <BackgroundToolbar />}
        {selectMode == SelectMode.NONE && canUndoRedo && <UndoRedoTools startsWithDivider={!isSmallDevice} />}
        {selectMode != SelectMode.NONE && (
          <>
            {!isSmallDevice && <Divider />}
            <SegmentationFlowController includeText={false} onReset={onSegmentationCompleted} />
          </>
        )}
        {!isCanvasEmpty && (
          <ExtraTools startsWithDivider={canUndoRedo || !isSmallDevice || selectMode != SelectMode.NONE} />
        )}
      </ToolBar>
    </Wrapper>
  );
};

export default ComposeToolBar;

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;
