const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_AUDIENCE = process.env.REACT_APP_AUDIENCE;
const AUTH0_CONNECTION_NAME = process.env.REACT_APP_AUTH0_CONNECTION_NAME || 'Username-Password-Authentication';
const AUTH0_SCOPE = 'openid profile';
const AUTH0_REDIRECT_URI = window.location.origin;

export const config = {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  audience: AUTH0_AUDIENCE,
  scope: AUTH0_SCOPE,
  redirectUri: AUTH0_REDIRECT_URI,
  connection: AUTH0_CONNECTION_NAME,
};

export const resetPassword = async (usernameOrEmail) => {
  const url = `https://${config.domain}/dbconnections/change_password`;
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      client_id: config.clientId,
      email: usernameOrEmail,
      connection: config.connection,
    }),
  };

  return fetch(url, options);
};
