import { DepixObject } from '@libs/DepixObject';
import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useMutation } from '@apollo/client';
import { UPLOAD_IMAGE, CLONE_IMAGE } from '@libs/DepixApi';
import { IMAGE_MAX_SIZE, resizeFile } from '@libs/ImgUtils';
import { dataURItoBlob } from '@libs/ImgUtils';

import useLoadStockImage from './UseLoadStockImage';

import { useGraphqlErrorHandler } from '@hooks/useGraphqlErrorHandler';
import { useAlert } from '@hooks/Alert';
import { useTranslation } from 'react-i18next';

interface UploadDepixObject {
  requestFromURL: (url: string, isPreview?: boolean, autoSegment?: boolean) => void;
  requestFromImage: (imageFile, isPreview?: boolean, resize?: boolean, autoSegment?: boolean) => void;
  requestFromStockImage: (url, apiName, width, isPreview?: boolean, autoSegment?: boolean) => void;
  cloneFromObjectId: (
    depixObjectID,
    maskId?: string,
    isPreview?: boolean,
    withInfillFragment?: boolean
  ) => Promise<any>;
  loadingFlag: boolean;
}

const useUploadDepixObject = (getObjectCallback): UploadDepixObject => {
  const [lastObject, setLastObject] = useState(null);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const { displayError } = useGraphqlErrorHandler();
  const alert = useAlert();
  const { t } = useTranslation();

  const onCompleted = (object) => {
    DepixObject.create(object)
      .then((objectInstance) => {
        setLastObject(objectInstance);
      })
      .catch(() => {
        alert.invalidImage();
      });
  };

  const { loadStockImages, isLoading: isLoadingStockImg } = useLoadStockImage(onCompleted);

  const [newObject, { loading: isLoading }] = useMutation(UPLOAD_IMAGE, {
    onCompleted({ newObject }) {
      onCompleted(newObject);
    },
    onError(error) {
      Sentry.captureException(error);
      setLoadingFlag(false);
      displayError(error);
    },
  });

  const [cloneObject, { loading: isLoadingClone }] = useMutation(CLONE_IMAGE, {
    onCompleted(data) {
      if (data?.cloneObject) {
        onCompleted(data.cloneObject);
      }
    },
    onError(error) {
      setLoadingFlag(false);
      displayError(error);
    },
  });

  const isFileImage = (file) => {
    return file && file['type'].includes('image/');
  };

  useEffect(() => {
    setLoadingFlag(isLoading || isLoadingClone || isLoadingStockImg);
  }, [isLoading, isLoadingClone, isLoadingStockImg]);

  useEffect(() => {
    if (lastObject) {
      getObjectCallback(lastObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastObject]);

  const requestFromImage = (imageFile, isPreview = false, resize = true, autoSegment = false) => {
    const debug = (message: string) => {};

    debug(
      `Request to upload image [name=${imageFile.name},preview=${isPreview},resize=${resize},autoSegment=${autoSegment}]`
    );

    if (isFileImage(imageFile)) {
      if (resize) {
        debug(`Resizing image [name=${imageFile.name},size=${imageFile.size}]...`);

        resizeFile(imageFile, IMAGE_MAX_SIZE)
          .then((resizedImageFile) => {
            debug(`Image resized [name=${imageFile.name},size=${resizedImageFile.size}].`);
            return resizedImageFile;
          })
          .then((resizedImageFile) => {
            debug(`Uploading image [name=${imageFile.name}]...`);

            newObject({ variables: { image: resizedImageFile, isPreview, autoSegment } })
              .then((r) => {
                debug(`Image [name=${imageFile.name}] uploaded!`);
                return r;
              })
              .catch(async (e) => {
                debug(`Failed to upload image [name=${imageFile.name}]`);
                throw e;
              });
          })
          .catch(() => alert.invalidImage());
      } else {
        debug(`Uploading image without resizing [name=${imageFile.name}]...`);

        newObject({ variables: { image: imageFile, isPreview, autoSegment } })
          .then((r) => {
            debug(`Image [name=${imageFile.name}] uploaded`);
            return r;
          })
          .catch((e) => {
            debug(`Failed to upload image [name=${imageFile.name}]`);
            throw e;
          });
      }
    } else {
      alert.error(t('error.api.codes.NEW_OBJECT_INVALID'), t('error.generic.title'));
    }
  };

  const requestFromURL = (url: string, isPreview: boolean = false, autoSegment: boolean = false) => {
    // Temporary, update backend for url support
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.onload = function () {
      const reader = new FileReader();
      reader.readAsDataURL(request.response);
      reader.onload = function (e) {
        const file = dataURItoBlob(e.target.result);
        requestFromImage(file, isPreview, true, autoSegment);
      };
    };
    request.send();
  };

  const requestFromStockImage = (url, apiName, width, isPreview = false, autoSegment = false) => {
    loadStockImages(apiName, url, width, isPreview, autoSegment);
  };

  const cloneFromObjectId = (
    depixObjectID,
    maskId: string = undefined,
    isPreview = false,
    withInfillFragment = false
  ) => {
    return cloneObject({
      variables: {
        id: depixObjectID,
        isPreview,
        withInfillFragment,
        maskId,
      },
    });
  };

  return { requestFromURL, requestFromImage, requestFromStockImage, cloneFromObjectId, loadingFlag };
};

export default useUploadDepixObject;
