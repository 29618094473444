import { AppBar, Box, styled, Toolbar } from '@mui/material';
import Logo from '@components/Header/Logo';
import { Link } from '@components/Ui/Link';

interface AuthHeaderProps {
  className?: string;
}

export const AuthHeader = ({ className }: AuthHeaderProps) => {
  return (
    <AppBar color="transparent" position="fixed" elevation={0} className={className}>
      <Toolbar>
        <StyledLink href="https://depix.ai">
          <Logo />
        </StyledLink>
      </Toolbar>
    </AppBar>
  );
};

const StyledLink = styled(Link)`
  pointer-events: auto;
  border-bottom: none;
`;
