import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SwitchSelector from 'react-switch-selector';
import { Box, Typography, useTheme } from '@mui/material';

const OptionSwitch = ({
  options,
  onSwitch,
  name = undefined,
  selection = 0,
  sx = { width: '198px', height: '36px' },
}) => {
  const theme = useTheme();
  const contrastText = theme.palette.secondary.contrastText;
  const lighGrey = theme.palette.secondary.lighGrey;
  const medium = theme.palette.secondary.medium;
  const { backgroundColor = lighGrey, ...boxSx } = sx;
  const [isLeft, setIsLeft] = useState(selection === 0 ? true : false);

  useEffect(() => {
    setIsLeft(selection === 0 ? true : false);
  }, [selection]);

  const onChange = (option) => {
    setIsLeft(option === options.left ? true : false);
    onSwitch(option);
  };

  const switchOptions = [
    {
      label: (
        <Typography fontWeight={500} color={isLeft ? contrastText : medium}>
          {options.left}
        </Typography>
      ),
      value: options.left,
      selectedBackgroundColor: 'white',
    },
    {
      label: (
        <Typography fontWeight={500} color={isLeft ? medium : contrastText}>
          {options.right}
        </Typography>
      ),
      value: options.right,
      selectedBackgroundColor: 'white',
    },
  ];

  return (
    <Box sx={boxSx}>
      <SwitchSelector
        name={name}
        onChange={onChange}
        options={switchOptions}
        initialSelectedIndex={selection}
        forcedSelectedIndex={selection}
        backgroundColor={backgroundColor}
        selectionIndicatorMargin={4}
      />
    </Box>
  );
};

OptionSwitch.propTypes = {
  options: PropTypes.exact({
    left: PropTypes.string,
    right: PropTypes.string,
  }).isRequired,
  onSwitch: PropTypes.func.isRequired,
  sx: PropTypes.object,
};

export default OptionSwitch;
