import { styled } from '@mui/material/styles';
import { DropletMoreIcon } from '@components/Ui/Icons/DropletMoreIcon';
import { DropletLessIcon } from '@components/Ui/Icons/DropletLessIcon';

export const ICON_SIZE = 22;

export const KeepIcon = styled(DropletMoreIcon)`
  position: relative;
  top: calc(-50%);
  left: 0;
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
`;

export const RemoveIcon = styled(DropletLessIcon)`
  position: relative;
  top: calc(-50%);
  left: 0;
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
`;
