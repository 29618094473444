import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useUserPilot } from '@contexts/UserPilotContext';

const TITLE_WIDTH = 200;

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontWeight: 500,
  fontSize: '0.9rem',
  textAlign: 'left',
  marginLeft: theme.spacing(1),
}));

const Item = styled(MenuItem)(({ theme }) => ({
  color: 'secondary.contrastText',
  fontWeight: 500,
  textAlign: 'left',
  borderRadius: 16,
  '& .MuiMenuItem-root': {
    backgroundColor: 'secondary.lightGrey',
  },
  '&:hover': {
    backgroundColor: 'inherit',
    color: theme.palette.secondary.dark,
  },
}));

export const HelpItem = ({ onClick, userPilotToken, icon, title, description }) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { trigger } = useUserPilot();

  const itemClicked = useCallback(() => {
    if (userPilotToken) {
      trigger(userPilotToken);
    }

    onClick();
  }, [onClick, userPilotToken]);

  return (
    <Item
      onClick={itemClicked}
      sx={{
        display: 'flex',
        flexDirection: downSm ? 'column' : 'row',
        alignItems: 'start',
        backgroundColor: 'secondary.lightGrey',
        px: 1,
      }}
      aria-label={title}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>
        <Typography sx={{ width: TITLE_WIDTH }}>{title}</Typography>
      </Box>
      <DescriptionTypography>{description}</DescriptionTypography>
    </Item>
  );
};

HelpItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
