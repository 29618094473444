import { DepixObject } from '@libs/DepixObject';
import React, { createContext, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useUploadImage } from '@hooks/UseUploadImage';
import { CropWindow } from '@contexts/CropToolContext';

export interface UploadImageContext {
  uploadImageFromFile: (file: File) => void;
  uploadImageFromUrl: (url: string) => void;
  uploadImageFromStock: (apiName: string, url: string, width: number) => void;
  isLoading: boolean;
}

const UploadImageContext = createContext(null as UploadImageContext);

interface UploadImageProviderProps {
  onImageReady: (depixObject: DepixObject) => void;
  isPreview: boolean;
  autoSegment?: boolean;
  children: ReactNode;
}

export const UploadImageProvider = ({
  onImageReady,
  isPreview,
  autoSegment = false,
  children,
}: UploadImageProviderProps) => {
  const { uploadImageFromFile, uploadImageFromUrl, uploadImageFromStock, isLoading } = useUploadImage(
    onImageReady,
    isPreview,
    autoSegment
  );

  const value = {
    uploadImageFromFile,
    uploadImageFromUrl,
    uploadImageFromStock,
    isLoading,
  };

  return <UploadImageContext.Provider value={value}>{children}</UploadImageContext.Provider>;
};

export default UploadImageContext;
