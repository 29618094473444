import React from 'react';

import _ from 'lodash';

import { styled, useTheme } from '@mui/material/styles';

interface LogoProps {
  small?: boolean;
  color?: string;
}

const Logo = ({ small = false, color = '' }: LogoProps) => {
  const variant = _.capitalize(color);
  const logo = `/depixLogo${variant}.png`;

  return <StyledImage src={logo} alt="logo" small={small} />;
};

export default Logo;

const StyledImage = styled('img', { shouldForwardProp: (propName) => propName != 'small' })<{ small: boolean }>`
  margin: ${(props) => props.theme.spacing(1)};
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  height: ${(props) => (props.small ? '30px' : '40px')};
`;
