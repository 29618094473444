import MuiTimelineDot from '@mui/lab/TimelineDot';
import styled from '@mui/material/styles/styled';

const TimelineDot = styled(MuiTimelineDot)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.contrastText,
}));

export default TimelineDot;
