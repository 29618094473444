import React, { ReactNode } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import { styled, useMediaQuery, useTheme } from '@mui/material';

export const FooterHeight = {
  Normal: 76,
  Small: 60,
};

interface PanelFooterProps extends BoxProps {
  leftFabComponent?: ReactNode;
  mainComponent?: ReactNode;
  rightFabComponent?: ReactNode;
  className?: string;
}

const PanelFooter = ({ leftFabComponent, mainComponent, rightFabComponent, className }: PanelFooterProps) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Wrapper className={className} isSmall={isSmallDevice}>
      {leftFabComponent && <LeftFABWrapper>{leftFabComponent}</LeftFABWrapper>}
      {mainComponent}
      {rightFabComponent && <RightFABWrapper>{rightFabComponent}</RightFABWrapper>}
    </Wrapper>
  );
};

export default PanelFooter;

const notForwardedProps: PropertyKey[] = ['isSmall'];

const Wrapper = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => !notForwardedProps.includes(propName),
})<{ isSmall: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${(props) => FooterHeight[props.isSmall ? 'Small' : 'Normal']}px;
`;

const LeftFABWrapper = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const RightFABWrapper = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
`;
