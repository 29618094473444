import MuiTimelineContent from '@mui/lab/TimelineContent';
import styled from '@mui/material/styles/styled';

const TimelineContent = styled(MuiTimelineContent)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 0,
}));

export default TimelineContent;
