import { styled } from '@mui/material/styles';
import { Dialog } from '@components/Ui/Dialog/Dialog';
import { Typography } from '@mui/material';
import Button from '@components/Ui/Button';
import { useTranslation } from 'react-i18next';
import { Link } from '@components/Ui/Link';

interface RetryDialogProp {
  open: boolean;
}

export const RetryDialog = ({ open }: RetryDialogProp) => {
  const { t } = useTranslation();

  return (
    <StyledDialog open={open}>
      <StyledImg alt={t('profile.loading.noGoodImageTitle')} src={'/skateboardBroken.jpg'} />
      <Typography color={'primary'} fontSize={26} fontWeight={500} align={'center'}>
        {t('profile.loading.brokenTitle')}
      </Typography>
      <Typography fontSize={15} align={'center'}>
        {t('profile.loading.brokenMessage')}
      </Typography>
      <StyledLink href="/" underline="none">
        <StyledButton variant="contained">Retry</StyledButton>
      </StyledLink>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .MuiPaper-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${(props) => props.theme.spacing(2)};
    padding: ${(props) => props.theme.spacing(4, 10)};

    ${(props) => props.theme.breakpoints.down('sm')} {
      padding: ${(props) => props.theme.spacing(3, 3)};
    }
  }
`;

const StyledImg = styled('img')`
  width: 100%;
`;

const StyledLink = styled(Link)`
  max-width: 230px;
  width: 100%;
  display: flex;
  justify-content: center;
  border: none;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 45px;
  text-transform: capitalize;
  margin-top: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(4)};

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-bottom: ${(props) => props.theme.spacing(2)};
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
`;
