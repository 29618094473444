import React, { useLayoutEffect, useRef, useEffect, useCallback } from 'react';
import useFabric from '../../Hooks/Fabric';
import PropTypes from 'prop-types';
import { PANEL_CLASSNAME_POSTFIX } from '@components/Viewport/Panel';

const FabricCanvas = ({ id, options }) => {
  const canvasRef = useRef(null);
  const fabric = useFabric();

  // Initialize the canvas
  useEffect(() => {
    fabric.init(canvasRef, options);
  }, [fabric.init, canvasRef, fabric, options]);

  const resize = useCallback(() => {
    fabric.resize(fabric.background, fabric.viewport);
  }, [fabric.resize, fabric.background, fabric.viewport]);

  useLayoutEffect(() => {
    const callback = () => {
      resize();
    };
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resize]);

  useLayoutEffect(() => {
    resize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resize]);

  useLayoutEffect(() => {
    if (!canvasRef?.current) return;
    resize();
  }, [canvasRef?.current?.parentElement?.clientWidth, resize]);

  useLayoutEffect(() => {
    if (canvasRef?.current) {
      document.querySelectorAll(`[id$='${PANEL_CLASSNAME_POSTFIX}']`).forEach((panel) => {
        panel.addEventListener('transitionend', (e) => {
          if (e.target == panel) {
            resize();
          }
        });
      });

      return () => {
        document.querySelectorAll(`[id$='${PANEL_CLASSNAME_POSTFIX}']`).forEach((panel) => {
          panel.removeEventListener('transitionend', resize);
        });
      };
    }
  }, [canvasRef, resize]);

  return <canvas ref={canvasRef} id={`${id}Canvas`} width="600" height="600" />;
};

FabricCanvas.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FabricCanvas;
