import { Dialog } from '@components/Ui/Dialog/Dialog';
import { DialogHeader } from '@components/Ui/Dialog/DialogHeader';
import { DialogTitle } from '@components/Ui/Dialog/DialogTitle';
import { useTranslation } from 'react-i18next';
import { DialogSubtitle } from '@components/Ui/Dialog/DialogSubtitle';
import { styled } from '@mui/material/styles';
import { Box, DialogContent, LinearProgress } from '@mui/material';
import { AudienceSelectStep } from '@components/Onboarding/AudienceSelectStep';
import { AudienceType } from '@libs/Profile/Audience';
import { DEFAULT_NS } from '@libs/i18n-config';
import { useState } from 'react';
import LoadingBackdrop from '@components/WebApp/LoadingBackdrop';
import { GoalSelectStep } from '@components/Onboarding/GoalSelectStep';
import { GoalType } from '@libs/Profile/Goal';
import ProductShowcase, { PRODUCT_IMAGES } from '@components/Ui/ProductShowcase';
import Logo from '@components/Header/Logo';
import { OnboardingStepper } from '@components/Onboarding/OnboardingStepper';
import { OnboardingStep } from '@libs/Onboarding/OnboardingStep';

export interface OnboardingDialogProps {
  open: boolean;
  audiences?: AudienceType[];
  selectedAudience?: AudienceType;
  onSelectAudience: (audience: AudienceType) => Promise<void>;
  goals?: GoalType[];
  selectedGoals?: GoalType[];
  onSelectGoals: (goals: GoalType[]) => Promise<void>;
  onClose: () => void;
  className?: string;
}

export const OnboardingDialog = ({
  open,
  audiences = [],
  selectedAudience,
  onSelectAudience,
  goals = [],
  selectedGoals,
  onSelectGoals,
  onClose,
  className,
}: OnboardingDialogProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'onboarding.dialog' });

  const [loading, setLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<OnboardingStep>(OnboardingStep.SELECT_AUDIENCE);

  const progress = currentStep == OnboardingStep.SELECT_GOAL ? (selectedGoals.length != 0 ? 100 : 50) : 0;

  const handleSelectAudience = (audience: AudienceType) => {
    setLoading(true);

    onSelectAudience(audience)
      .then(() => {
        setCurrentStep(OnboardingStep.SELECT_GOAL);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectGoals = (goals: GoalType[]) => {
    setLoading(true);

    onSelectGoals(goals).finally(() => {
      setLoading(false);
    });
  };

  const handleSubmit = () => {
    onClose();
  };

  const handleBack = () => {
    setCurrentStep(OnboardingStep.SELECT_AUDIENCE);
  };

  return (
    <Dialog open={open} maxWidth="lg" className={className}>
      <Box display="flex" flexDirection="row-reverse" overflow="hidden">
        <DialogMain>
          <StyledDialogHeader>
            <DialogTitle>{t('title')}</DialogTitle>
            <DialogSubtitle size="small">{t('subtitle')}</DialogSubtitle>
          </StyledDialogHeader>

          <StyledProgressBar variant="determinate" value={progress} />

          <StyledDialogContent>
            <LoadingBackdrop isLoading={loading}>
              {currentStep == OnboardingStep.SELECT_AUDIENCE && (
                <AudienceSelectStep
                  audiences={audiences}
                  selectedAudience={selectedAudience}
                  onChange={handleSelectAudience}
                />
              )}

              {currentStep == OnboardingStep.SELECT_GOAL && (
                <GoalSelectStep
                  goals={goals}
                  selectedGoals={selectedGoals}
                  onChange={handleSelectGoals}
                  onSubmit={handleSubmit}
                  onBack={handleBack}
                />
              )}
            </LoadingBackdrop>
          </StyledDialogContent>
        </DialogMain>

        <DialogAside>
          <Box p={2} position="absolute" top={0} left={0}>
            <Logo />
          </Box>

          <Box display="flex" flexGrow={1} flexDirection="row" justifyContent="center" alignItems="center">
            <OnboardingStepper
              currentStep={currentStep}
              steps={[
                { value: OnboardingStep.SELECT_AUDIENCE, completed: selectedAudience != null },
                { value: OnboardingStep.SELECT_GOAL, completed: selectedGoals.length > 0 },
              ]}
              onChange={(step) => setCurrentStep(step)}
            />
          </Box>

          <ProductShowcase image={PRODUCT_IMAGES[0]} animate={false} flat width={80} top={10} right={0} />
          <ProductShowcase image={PRODUCT_IMAGES[2]} animate={false} flat width={200} bottom={20} right={-20} />
        </DialogAside>
      </Box>
    </Dialog>
  );
};

const DialogMain = styled(Box)`
  overflow-y: auto;
`;

const DialogAside = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 250px;

  background-color: ${(props) => props.theme.palette.ternary.main};

  overflow: hidden;

  ${(props) => props.theme.breakpoints.down('md')} {
    display: none;
  }
`;

const StyledDialogHeader = styled(DialogHeader)`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${(props) => props.theme.spacing(4, 6)};

  margin: 0;

  text-align: center;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(2)};
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    background-color: ${(props) => props.theme.palette.ternary.main};
  }
`;

const StyledProgressBar = styled(LinearProgress)`
  width: 100%;

  ${(props) => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: ${(props) => props.theme.spacing(3, 2)};

  ${(props) => props.theme.breakpoints.up('md')} {
    min-width: 470px;
    min-height: 495px;
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props.theme.spacing(4, 6)};
  }
`;
