import React, { ReactNode } from 'react';

import Tooltip from '@mui/material/Tooltip';
import Button from '@components/Ui/Button';
import { styled } from '@mui/material/styles';
import { Box, ButtonProps, useTheme, useMediaQuery } from '@mui/material';
import { FooterHeight } from '@components/Viewport/PanelFooter';

interface FooterButtonProps extends ButtonProps {
  tooltip: string;
  label?: string;
  icon?: ReactNode;
  loading?: boolean;
}

const FooterButton = ({ tooltip, icon, label, children, loading = false, onClick, ...props }: FooterButtonProps) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const showIcon = icon && (!label || !isSmallDevice);

  return (
    <Tooltip title={tooltip} placement="bottom" arrow>
      <FooterButtonContainer isSmall={isSmallDevice} isLeft={false}>
        <StyledButton {...props} onClick={onClick} loading={loading} variant="contained">
          {showIcon && icon}
          {label && label}
        </StyledButton>
      </FooterButtonContainer>
    </Tooltip>
  );
};

export default FooterButton;

const notForwardedProps: PropertyKey[] = ['isSmall', 'isLeft'];

export const FooterButtonContainer = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => !notForwardedProps.includes(propName),
})<{ isSmall: boolean; isLeft: boolean }>`
  position: relative;
  bottom: 0;
  left: ${(props) => (props.isLeft ? 0 : undefined)};
  right: ${(props) => (props.isLeft ? undefined : 0)};
  width: fit-content;
  height: ${(props) => FooterHeight[props.isSmall ? 'Small' : 'Normal']}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.theme.spacing(1)};
  margin-left: ${(props) => props.theme.spacing(1)};
  z-index: ${(props) => props.theme.zIndex.composeToolbar};
`;

const StyledButton = styled(Button)`
  width: auto;
  min-width: 56px;
  height: 56px;
  border-radius: ${(props) => props.theme.borderRadius.large};
  padding: ${(props) => props.theme.spacing(0, 2)};
  font-size: 12;
  margin: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  text-transform: none;
  background-color: ${(props) => props.theme.palette.accept.main};
  &:hover {
    background-color: ${(props) => props.theme.palette.accept.dark};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    min-width: 48px;
    height: 48px;
    padding: ${(props) => props.theme.spacing(0, 0.2)};
  }
`;
