import React, { useState } from 'react';

import useUserProfile from '@hooks/UseUserProfile';
import PanelFooter, { FooterHeight } from '@components/Viewport/PanelFooter';
import DownloadButton from './DownloadButton';
import PasteActionButton from '@components/Viewport/Paste/PasteActionButton';
import ComposeToolBar, { ComposeAction } from '@components/Viewport/Paste/ComposeToolBar';
import ApplyButton from './ApplyComposeSegmentationButton';
import { ApplyCropButton } from '@components/Viewport/Paste/ApplyCropButton';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { useScene } from '@contexts/SceneContext';

interface ComposeFooterProps {
  onActionChange?: (action: ComposeAction) => void;
}

const ComposeFooter = ({ onActionChange }: ComposeFooterProps) => {
  const { isFirstTimeUse } = useUserProfile();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const showLeftFab = !isFirstTimeUse || isSmallDevice;
  const { isPlainBackground } = useScene();
  const tightSpace = window.innerWidth < 700 || (900 <= window.innerWidth && window.innerWidth < 1400);

  return (
    <PanelFooter
      leftFabComponent={showLeftFab ? <PasteActionButton showLabel={isPlainBackground && !tightSpace} /> : undefined}
      mainComponent={<StyledComposeToolBar isSmall={isSmallDevice} />}
      rightFabComponent={
        <>
          <DownloadButton />
          <ApplyButton />
          <ApplyCropButton />
        </>
      }
    />
  );
};

export default ComposeFooter;

const notForwardedProps: PropertyKey[] = ['isSmall'];

const StyledComposeToolBar = styled(ComposeToolBar, {
  shouldForwardProp: (propName: PropertyKey) => !notForwardedProps.includes(propName),
})<{ isSmall: boolean }>`
  height: ${(props) => FooterHeight[props.isSmall ? 'Small' : 'Normal']}px;
`;
