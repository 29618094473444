import { Box, DialogContent, styled, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';
import { SharedImage } from '@libs/SharedImage/SharedImage';
import Button from '@components/Ui/Button';
import useInstrumentation from '@hooks/UseInstrumentation';
import { useNavigate } from 'react-router-dom';
import { useAuthV2 } from '@contexts/AuthV2';
import Routing from '@root/routes';
import { OpenAppIncentive } from '@libs/Instrumentation/OpenAppIncentive';
import React, { useState } from 'react';
import { Dialog } from '@components/Ui/Dialog/Dialog';
import { ShareLink } from '@components/SharedImage/ShareLink';
import Surface from '@components/Ui/Surface';
import { DialogHeader } from '@components/Ui/Dialog/DialogHeader';
import { DialogTitle } from '@components/Ui/Dialog/DialogTitle';
import { DialogActions } from '@components/Ui/Dialog/DialogActions';

interface CallToActionsProps {
  sharedImage?: SharedImage;
  className?: string;
}

export const CallToActions = ({ sharedImage, className }: CallToActionsProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'imageSharing.callToActions' });
  const instrumentation = useInstrumentation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthV2();

  const [showDialog, setShowDialog] = useState<boolean>(false);

  const openApp = () => {
    navigate(isAuthenticated ? Routing.home() : Routing.auth());
  };

  const handleEditThisImage = () => {
    instrumentation.openAppClicked(OpenAppIncentive.EDIT_IMAGE_BUTTON);
    setShowDialog(true);
  };

  const handleEditYourImage = () => {
    instrumentation.openAppClicked(OpenAppIncentive.CREATE_IMAGE_BUTTON);
    openApp();
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <Wrapper className={className}>
      {sharedImage && (
        <EditThisImageButton variant="contained" onClick={handleEditThisImage}>
          {t('editThisImage')}
        </EditThisImageButton>
      )}

      <EditYourImageButton variant="contained" onClick={handleEditYourImage}>
        {t('editYourImage')}
      </EditYourImageButton>

      <Dialog open={showDialog} closeable onClose={handleCloseDialog} PaperComponent={StyledSurface}>
        <StyledDialogHeader>
          <StyledDialogTitle>{t('notImplemented.title')}</StyledDialogTitle>
        </StyledDialogHeader>
        <DialogContent>
          <Trans t={t} i18nKey={'notImplemented.description'} />
        </DialogContent>
        <StyledDialogActions>
          <EditYourImageButton variant="contained" onClick={openApp}>
            {t('editYourImage')}
          </EditYourImageButton>
        </StyledDialogActions>
      </Dialog>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${(props) => props.theme.spacing(2)};
  width: 100%;
`;

const EditThisImageButton = styled(Button)`
  text-transform: none;
  padding: ${(props) => props.theme.spacing(2, 2)};

  ${(props) => props.theme.breakpoints.down('lg')} {
    padding: ${(props) => props.theme.spacing(1, 2)};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(2, 2)};
  }

  ${(props) => props.theme.breakpoints.down(380)} {
    padding: ${(props) => props.theme.spacing(1, 2)};
  }
`;

const EditYourImageButton = styled(Button)`
  text-transform: none;
  background-color: ${(props) => props.theme.palette.accept.main};

  &:hover {
    background-color: ${(props) => props.theme.palette.accept.main};
  }
`;

const StyledSurface = styled(Surface)`
  padding: ${(props) => props.theme.spacing(4, 6)};
  text-align: center;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(2, 2)};
  }
`;

const StyledDialogHeader = styled(DialogHeader)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-bottom: 0;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-bottom: 0;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
`;
