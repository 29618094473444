import { Profile, ProfileUploadLimits } from '@libs/Profile/Profile';
import { Subscription } from '@libs/Subscription';

const mapProfileSubscriptions = (apolloSubscriptions: any): { subscriptions: Subscription[]; error: string } => {
  const subscriptions: Subscription[] = [];
  let error = null;
  apolloSubscriptions?.forEach((subscription) => {
    if (subscription.__typename === 'ResolverError') {
      error = subscription.message;
    } else {
      subscriptions.push({
        updateUrl: subscription.updateUrl,
        cancelUrl: subscription.cancelUrl,
        nextBillDate: subscription.nextBillDate,
        status: subscription.status,
        pausedAt: subscription.pausedAt,
        pausedFrom: subscription.pausedFrom,
        planId: subscription.planId,
        receiptUrl: subscription.receiptUrl,
        checkoutId: subscription.checkoutId,
        cancellationEffectiveDate: subscription.cancellationEffectiveDate,
        enablesAccess: subscription.enablesAccess,
        isInGracePeriod: subscription.isInGracePeriod,
      });
    }
  });

  return { subscriptions, error };
};

function mapUploadLimit(apolloQueryResult: any): ProfileUploadLimits {
  const uploadLimit: ProfileUploadLimits = {
    uploadLeft: -1,
    uploadUsed: -1,
    uploadQuota: -1,
    uploadWaitTime: apolloQueryResult.newUploadAt,
  };

  if (Number.isInteger(apolloQueryResult.uploadQuota)) {
    uploadLimit.uploadQuota = apolloQueryResult.uploadQuota;

    if (Number.isInteger(apolloQueryResult.uploadUsage)) {
      uploadLimit.uploadUsed = apolloQueryResult.uploadUsage;
      uploadLimit.uploadLeft = apolloQueryResult.uploadQuota - apolloQueryResult.uploadUsage;
      uploadLimit.uploadLeft = uploadLimit.uploadLeft < 0 ? 0 : uploadLimit.uploadLeft;
    }
  }

  return uploadLimit;
}

export const mapProfile = (apolloQueryResult: any): Profile => {
  const uploadQuota = mapUploadLimit(apolloQueryResult);

  const { subscriptions, error: subscriptionError } = mapProfileSubscriptions(apolloQueryResult.subscriptions);

  return new Profile(uploadQuota, subscriptions, subscriptionError);
};
