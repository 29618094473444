import React, { useCallback, useState } from 'react';

import { useSegmentation } from '@contexts/Segmentation/UseSegmentation';
import useUploadDepixObject from '@hooks/UseUploadDepixObject';
import { useScene } from '@contexts/SceneContext';
import useInstrumentation from '@hooks/UseInstrumentation';
import FooterButton from '@root/Components/Ui/FooterButton';
import useUserProfile from '@hooks/UseUserProfile';

import { useTranslation } from 'react-i18next';
import { MeasureType } from '@libs/Instrumentation/MeasureType';
import ApplyIcon from '@components/Ui/Icons/ApplyIcon';
import Loading from '@components/Loading/Loading';

interface PasteButtonProps {
  onPaste: (objectId: string, isPreView: boolean) => void;
}

const PasteButton = ({ onPaste = () => {} }: PasteButtonProps) => {
  const { t } = useTranslation();
  const segmentation = useSegmentation();
  const userProfile = useUserProfile();
  const scene = useScene();
  const instrumentation = useInstrumentation();
  const [loading, setLoading] = useState<boolean>(false);

  const enabled = !!segmentation.mask;

  const pasteObjectInScene = (object) => {
    const { bottomCoord, objectSize } = scene.calculateNewObjectInViewport(object);
    scene.addSceneComponent(object, bottomCoord, objectSize);
  };

  const { cloneFromObjectId, loadingFlag } = useUploadDepixObject(pasteObjectInScene);

  const handlePaste = useCallback(() => {
    setLoading(true);

    cloneFromObjectId(segmentation.segmentationObjectID, segmentation.maskId, segmentation.preview)
      .then(() => {
        instrumentation.backgroundRemoved(segmentation.segmentationObjectID);
        instrumentation.instrument(MeasureType.OBJECT_PASTED, {
          objectId: segmentation.segmentationObjectID,
        });

        userProfile.setVisited();
        setLoading(false);

        onPaste(segmentation.segmentationObjectID, segmentation.preview);
      })
      .catch(() => setLoading(false));
  }, [
    cloneFromObjectId,
    segmentation.segmentationObjectID,
    segmentation.preview,
    instrumentation,
    onPaste,
    userProfile,
  ]);

  return (
    <>
      {enabled && (
        <FooterButton
          tooltip={t('canvas.cut.pasteTooltip')}
          className="paste_button"
          onClick={handlePaste}
          icon={<ApplyIcon />}
          label={t('canvas.cut.pasteButton')}
          loading={loadingFlag}></FooterButton>
      )}

      {loading && <Loading global backdrop />}
    </>
  );
};

export default PasteButton;
