import { PlainBackgroundObject } from '@libs/PlainBackgroundObject';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useInstrumentation from '@hooks/UseInstrumentation';
import useFabric from '@hooks/Fabric';
import useQueryObjects from '@hooks/useQueryObjects';
import { Feature, useFeatureFlag } from '@hooks/FeatureFlag';

import { useSegmentation } from '@contexts/Segmentation/UseSegmentation';
import { useScene } from '@contexts/SceneContext';

import { COMPOSE_PANEL_NAME } from '@libs/ComposePanelName';
import FooterButton from '@components/Ui/FooterButton';
import DownloadIcon from '@components/Ui/Icons/DownloadIcon';
import { DownloadImageDialog } from '@components/Download/DownloadImageDialog';

import { BackgroundType } from '@libs/BackgroundObject';
import { downloadImage } from '@libs/DownloadImage';
import useCropTool from '@hooks/UseCropTool';

const DownloadButton = () => {
  const [enabled, setEnabled] = useState(false);
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const instrumentation = useInstrumentation();
  const fabric = useFabric();
  const scene = useScene();
  const segmentation = useSegmentation();
  const { t } = useTranslation();
  const feature = useFeatureFlag();
  const canUseHighResolution = feature.canUseFeature(Feature.HIGH_RESOLUTION);
  const { enabled: cropToolEnabled } = useCropTool();

  const download = (fullResolution = false) => {
    const imageFormat = scene.isBackgroundTransparent() ? 'png' : 'jpeg';
    fabric.exportCanvas(fullResolution, imageFormat).then(downloadImage);

    const stats = scene.getStats();
    instrumentation.imageDownloaded({
      panel: COMPOSE_PANEL_NAME,
      imageId: null,
      backgroundType: stats.backgroundTransparent ? 'transparent' : stats.isBackgroundPlain ? 'plain' : 'image',
      backgroundColor: stats.backgroundColor,
      backgroundColorOpacity: stats.backgroundColorOpacity,
      highResolution: fullResolution,
      infilled: stats.infillComponentsCount > 0,
      erased: stats.erasedComponentsCount > 0,
      componentsCount: stats.componentsCount,
      infillComponentsCount: stats.infillComponentsCount,
      occlusionComponentsCount: stats.occlusionComponentsCount,
      erasedComponentsCount: stats.erasedComponentsCount,
      relighting: stats.hasRelighting,
      shadows: stats.hasShadow,
      groundObject: stats.hasGround,
      isCropped: stats.isCropped,
    });
    onCloseDialog();
  };

  const onCloseDialog = () => {
    setShowDownloadDialog(false);
  };

  const onSkipDialog = () => {
    instrumentation.downloadDialogSkipped(COMPOSE_PANEL_NAME);
    onCloseDialog();
  };

  const onShowDialog = () => {
    // Download all full resolution assets in scene
    setShowDownloadDialog(true);
  };

  const getObjectsRequestCallback = (objects) => {
    for (const obj of objects) {
      const depixObj = fabric.depixObjectFromID(obj.objectID);
      if (depixObj) {
        depixObj.setupFullResolution(obj);
      }
    }
    const fullResolution = true;
    download(fullResolution);
  };

  const { queryObjects } = useQueryObjects(getObjectsRequestCallback);

  const requestHDImagesFromBackend = useCallback(() => {
    const isPreviewResolution = false;
    const ids = scene
      .getDepixObjects()
      .filter((obj) => !(obj instanceof PlainBackgroundObject))
      .map((obj) => obj.objectID);
    queryObjects(ids, isPreviewResolution, {
      withId: true,
      withImage: true,
      withMask: true,
    });
  }, [scene, queryObjects]);

  useEffect(() => {
    setEnabled(!cropToolEnabled && !segmentation.enabled && scene?.containsComponent());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene.containsComponent, segmentation.enabled]);

  return (
    <>
      {!canUseHighResolution && (
        <DownloadImageDialog
          open={showDownloadDialog}
          onDownloadLowResolution={() => {
            download(false);
          }}
          onClose={onCloseDialog}
          onSkip={onSkipDialog}
          lowResolution={fabric.getBackgroundResolution(true)}
          highResolution={fabric.getBackgroundResolution(false)}
          aria-label={t('tools.ariaLabel.downloadDialog')}
        />
      )}
      {enabled && (
        <FooterButton
          aria-label={t('tools.ariaLabel.download')}
          tooltip={t('tools.compose.tooltips.downloadComposition')}
          hidden={!enabled}
          className="download_button"
          onClick={canUseHighResolution ? requestHDImagesFromBackend : onShowDialog}
          icon={<DownloadIcon />}
        />
      )}
    </>
  );
};

export default DownloadButton;
