import './LoadableImage';

/**
 * fabric subclass
 * @class fabric.BackgroundImage
 * @extends fabric.Image
 * @return {fabric.MonochromeImage} thisArg
 *
 */
fabric.BackgroundImage = class extends fabric.LoadableImage {
  constructor(image, id, type, depixObject, options) {
    super(id, type, depixObject, options);

    this.updateElement(image);
  }
};
