import React from 'react';
import { useTranslation } from 'react-i18next';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import useFabric from '@hooks/Fabric';
import { useSegmentation } from '@contexts/Segmentation/UseSegmentation';

import Loading from '@components/Loading/Loading';

import { KeepIcon, ICON_SIZE, RemoveIcon } from './SegmentationIcons';
import { DEFAULT_NS } from '@libs/i18n-config';

const TAG_BORDER = 2;
const TAG_SIZE = TAG_BORDER * 2 + ICON_SIZE * 1.3;

interface ClickProps {
  isForeground: boolean;
  top: string;
  left: string;
  isLoading?: boolean;
}

const Click = ({ isForeground, top, left, isLoading = false }: ClickProps) => {
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'segmentation' });
  const theme = useTheme();

  const Icon = isForeground ? KeepIcon : RemoveIcon;
  const backgroundColor = isForeground ? theme.palette.segmentation.foreground : theme.palette.segmentation.background;
  const label = isForeground ? t('foreground') : t('background');

  if (isLoading) {
    return (
      <Container sx={{ top, left, width: TAG_SIZE, height: TAG_SIZE }}>
        <Loading color={backgroundColor} width={30} height={30} global={false} droplet />
      </Container>
    );
  }

  return (
    <Container
      onContextMenu={(event) => event.preventDefault()}
      role="icon"
      aria-label={label}
      sx={{
        top,
        left,
        boxShadow: (theme) => theme.elevation.tag,
      }}>
      <Icon />
    </Container>
  );
};

const SegmentationClicks = () => {
  const { clicks, loading } = useSegmentation();
  const { normalizeCanvasCoord, unnormalizeBackgroundCoord, backgroundToViewport, viewportToCanvas } = useFabric();

  const clicksToComponent = () =>
    clicks.map((click) => {
      const normalizedCoord = normalizeCanvasCoord(
        viewportToCanvas(backgroundToViewport(unnormalizeBackgroundCoord(click)))
      );

      return (
        <Click
          isForeground={click.isForeground}
          key={`${normalizedCoord.y * 100}% - ${normalizedCoord.x * 100}%`}
          top={`${normalizedCoord.y * 100}%`}
          left={`${normalizedCoord.x * 100}%`}
          isLoading={loading}
        />
      );
    });
  return <>{clicksToComponent()}</>;
};

const Container = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  border: 3,
  borderColor: 'white',
  zIndex: theme.zIndex.appBar,
  borderRadius: '50%',
  opacity: '0.8',
}));

export default SegmentationClicks;
