/* eslint-disable no-invalid-this */
/* eslint-disable valid-jsdoc */
/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import './DepixImage';
import { rotateArrowsImage, perspectiveImage, circleImage } from '@libs/DepixIcons';
import { grey } from '@mui/material/colors';
import { SHADOW_STATE } from '@libs/DepixObject';

import { loadOpenCv, isOpenCVLoaded } from '@libs/openCVUtils';
import { isWebGLSupported } from './FabricUtils';

export const HIDE_OPACITY = 0;
export const OBJECT_3D_IMAGE_TYPE = 'object3DImage';
export const CONTROL_OFFSET = 32;
export const ICON_SIZE = 32;

/**
 * Object3DImage subclass
 * @class fabric.Object3DImage
 * @extends fabric.Object3DImage
 * @return {fabric.Object3DImage} thisArg
 *
 */
fabric.Object3DImage = class extends fabric.DepixImage {
  constructor(depixObject, id, padding, options) {
    super(depixObject, id, padding, true, options);
    this.type = OBJECT_3D_IMAGE_TYPE;
    this.repeat = 'no-repeat';
    this.fill = 'transparent';
    this.angleCache = 0;
    this.isFloating = false;

    this.perPixelTargetFind = true;
    this.paddingRatio = padding;

    this._initControls();
    this._setupBlurFilter();
  }

  /**
   *
   * @param {*} depixObject
   * @return {*}
   */
  static async create(depixObject, id, onShadowChange, onShadowCommit, canvas, padding = 1, options = {}) {
    if (!isWebGLSupported() && !isOpenCVLoaded()) {
      await loadOpenCv();
    }

    return new Promise((resolve, reject) => {
      const object3D = new fabric.Object3DImage(depixObject, id, padding, options);
      createShadow(depixObject, id, onShadowChange, onShadowCommit, canvas, object3D).then(() => {
        object3D
          .loadImage()
          .then(() => {
            object3D.updateProps();
            resolve(object3D);
          })
          .catch(reject);
      });
    });
  }

  _setupBlurFilter() {
    this.focusFilter = new fabric.Image.filters.Blur({
      blur: this.depixObject.prop.blur,
    });
    this.filters.push(this.focusFilter);
  }

  isDecomposed() {
    return this.depixObject?.isDecomposed();
  }

  refresh(otherDepixObject = null) {
    return new Promise((resolve) => {
      if (otherDepixObject) {
        return this.setAsset(otherDepixObject).then(() => {
          this.updateProps();
          resolve();
        });
      } else {
        this.updateProps();
        resolve();
      }
    });
  }

  lockMovement() {
    this.lockMovementX = true;
    this.lockMovementY = true;
    this.lockScalingX = true;
    this.lockScalingY = true;
    this.lockRotation = true;
  }

  updateProps() {
    const originalBlur = this.focusFilter.blur;
    this.focusFilter.blur = this.isRelighted() ? this.depixObject.prop.blur : 0;

    if (originalBlur !== this.focusFilter.blur) {
      this.applyFilters();
    }
    this.enableShadow(this.depixObject.prop.shadowVisible);
  }

  isRelighted() {
    return this.depixObject.prop.relight;
  }

  enableShadow(enabled) {
    const shadow = this.getShadow();

    this.depixObject.prop.shadowVisible = enabled;
    if (enabled) {
      shadow.setShadowState(this.depixObject.prop.shadowState);
      shadow.focusFilter.blur = this.depixObject.prop.shadowDiffusion;
      shadow.opacity = this.depixObject.prop.shadowOpacity;
      shadow.applyFilters();
    }

    if (shadow.visible === enabled) return;
    shadow.visible = enabled;
    if (!this.canvas) return;
    if (enabled) {
      this.canvas.setActiveObject(shadow);
    } else {
      this.canvas.setActiveObject(this);
    }
    this.canvas.requestRenderAll();
  }

  isCastingShadow() {
    return this.depixObject.prop.shadowVisible;
  }

  isCastingShadowOnTheGround() {
    return this.depixObject.prop.shadowState === SHADOW_STATE.GROUND;
  }

  _initControls() {
    const renderIcon = (icon, ctx, left, top, showBackground = true, size = ICON_SIZE) => {
      if (!icon) return;
      const xScale = fabric.document.all?.composeCanvas?.clientWidth / 600 || 1;
      const yScale = fabric.document.all?.composeCanvas?.clientHeight / 600 || 1;

      const screenNormalizedSize = size / window.devicePixelRatio;

      const width = screenNormalizedSize / xScale;
      const height = screenNormalizedSize / yScale;
      const ellipseScale = 1.5;
      ctx.save();
      ctx.translate(left, top);

      if (!showBackground) {
        ctx.beginPath();
        ctx.ellipse(0, 0, (width / 2) * ellipseScale, (height / 2) * ellipseScale, 0, 0, 2 * Math.PI);
        ctx.fillStyle = grey[100];
        ctx.fill();
      }
      ctx.drawImage(icon, -width / 2, -height / 2, width, height);
      ctx.restore();
    };

    this.controls.float = new fabric.Control({
      x: 0.0,
      y: 0.5,
      offsetX: 0,
      offsetY: CONTROL_OFFSET,
      cursorStyle: 'pointer',
      actionHandler: (eventData, transform, x, y) => {
        const fabricObject = transform.target;
        fabricObject.isFloating = true;
        const ret = fabric.controlsUtils.dragHandler(eventData, transform, x, y);
        fabricObject.isFloating = false;
        return ret;
      },
      render: (ctx, left, top) => {
        renderIcon(perspectiveImage, ctx, left, top, false);
      },
    });

    this.controls.mtr.render = (ctx, left, top) => {
      renderIcon(rotateArrowsImage, ctx, left, top, false);
    };
    this.controls.mtr.x = -0.5;
    this.controls.mtr.y = -0.5;
    this.controls.mtr.offsetX = -CONTROL_OFFSET;
    this.controls.mtr.offsetY = -CONTROL_OFFSET;

    const resizeControls = ['tr', 'br', 'bl', 'tl', 'ml', 'mt', 'mr', 'mb'];
    for (const control in this.controls) {
      if (Object.prototype.hasOwnProperty.call(this.controls, control)) {
        if (resizeControls.includes(control)) {
          this.controls[control].render = (ctx, left, top) => {
            renderIcon(circleImage, ctx, left, top, true, ICON_SIZE);
          };
        }
      }
    }
  }

  getShadow() {
    if (this.child) {
      return this.child[0];
    } else {
      return undefined;
    }
  }

  setHide(hide) {
    this.opacity = hide ? HIDE_OPACITY : 1;
    this.selectable = !hide;
    const shadow = this.getShadow();
    shadow.visible = hide ? false : this.isCastingShadow();
  }

  _getNonTransformedDimensions() {
    // Object dimensions
    const p = new fabric.Point(this.width / this.paddingRatio, this.height / this.paddingRatio);
    return p;
  }
};

const createShadow = (depixObject, id, onShadowChange, onShadowCommit, canvas, parent) => {
  return new Promise((resolve) => {
    fabric.TransformedImage.create(parent, depixObject).then((shadow) => {
      shadow.selectable = true;
      shadow.perPixelTargetFind = false;
      shadow.opacity = depixObject.prop.shadowOpacity;
      shadow.topOffset = 0;
      shadow.leftOffset = 0;
      shadow.angleOffset = 0;
      shadow.baseOffset = [1, 0, 0, 1, 0, 0];

      shadow.name = id;
      shadow.canvas = canvas;
      shadow.onGroundChange = onShadowChange;
      shadow.on('mouseup', onShadowCommit);
      shadow.onGroundRelease = onShadowCommit;

      const maskCopy = fabric.util.object.clone(shadow);
      shadow.focusFilter = new fabric.Image.filters.Blur({
        blur: depixObject.prop.shadowDiffusion,
      });

      const blendFilter = new fabric.Image.filters.BlendColor({
        color: '#000000',
        mode: 'multiply',
      });

      const alphaFilter = new fabric.Image.filters.SetAlpha({ image: maskCopy });

      shadow.filters.unshift(alphaFilter);
      shadow.filters.unshift(blendFilter);
      // blur filter is the last to be applied
      shadow.filters.push(shadow.focusFilter);

      // Update the transformation coord
      for (let i = 0; i < shadow.perspectiveCoords.length; i++) {
        const coord = shadow.perspectiveCoords[i];

        coord[0] /= shadow.scaleX;
        coord[1] /= shadow.scaleY;
      }
      shadow.setCoords();
      shadow.applyFilters();

      shadow.visible = false;
      shadow.togglePerspective();

      shadow.lockScalingRotation();

      resolve(shadow);
    });
  });
};
