import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

export const PANEL_CLASSNAME_POSTFIX = '_panel';

const Panel = ({ name, value, width = '100%', visible = true, fullHeight = false, children, ...props }) => {
  const hidden = !visible && name !== value;
  const { t } = useTranslation();

  return (
    <Box
      hidden={hidden}
      sx={{
        position: 'relative',
        display: hidden ? 'none' : 'flex',
        flexDirection: 'column',
        width,
        mt: fullHeight ? -10 : 0,
        pt: fullHeight ? 10 : 0,
      }}
      role="tabpanel"
      aria-label={`${name} ${t('panel.ariaLabel.root')}`}
      className={`${name}${PANEL_CLASSNAME_POSTFIX}`}
      id={`${name}${PANEL_CLASSNAME_POSTFIX}`}
      {...props}>
      {children}
    </Box>
  );
};

Panel.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  visible: PropTypes.bool,
  fullHeight: PropTypes.bool,
  width: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Panel;
