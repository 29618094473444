import { useLazyQuery } from '@apollo/client';
import { QUERY_STOCK_IMAGES } from '../Libs/DepixApi';
import { useGraphqlErrorHandler } from '@hooks/useGraphqlErrorHandler';

/**
 * Detect main object in image and return coordinates
 * @param {func} onCompletedCallback
 * @return {*}
 */
function useQueryStockImages(onCompletedCallback) {
  const { displayError } = useGraphqlErrorHandler();

  const [queryStockImages, { loading: isLoading, error: isError }] = useLazyQuery(QUERY_STOCK_IMAGES, {
    onCompleted({ queryStockImages }) {
      onCompletedCallback(queryStockImages);
    },
    onError(error) {
      displayError(error);
    },
    fetchPolicy: 'no-cache',
  });

  const searchStockImages = (keyword, page) => {
    const variables = {
      keyword: keyword,
      page: page,
    };

    queryStockImages({
      variables: variables,
    });
  };

  return { searchStockImages, isLoading, isError };
}

export default useQueryStockImages;
