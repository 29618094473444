import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useScene } from '@contexts/SceneContext';

import ToolButton, { TooltipPlacement } from '@components/Viewport/ToolButton';
import { EraseObjectIcon } from '@components/Ui/Icons/EraseObjectIcon';
import ObjectIcon from '@components/Ui/Icons/ObjectIcon';
import GroundIcon from '@components/Ui/Icons/GroundIcon';

import useComposeSelectMode from '@hooks/UseComposeSelectMode';
import { SelectMode } from '@contexts/ComposeSelectModeContext';
import { useTranslation } from 'react-i18next';
import { useSegmentation } from '@contexts/Segmentation/UseSegmentation';
import ColorPicker from '@components/ColorPicker/ColorPicker';
import { DEFAULT_PLAIN_BACKGROUND_COLOR, PlainBackgroundObject } from '@libs/PlainBackgroundObject';
import CropIcon from '@components/Ui/Icons/CropIcon';
import useCropTool from '@hooks/UseCropTool';
import { Feature, useFeatureFlag } from '@hooks/FeatureFlag';
import { Popper } from '@mui/material';
import Surface from '@components/Ui/Surface';
import { styled } from '@mui/material/styles';
import { SegmentationModeSelector } from '@components/Viewport/SegmentActions/SegmentationModeSelector';
import ToolBar from '@components/Viewport/ToolBar';
import { DepixBackgroundObject } from '@libs/DepixBackgroundObject';
import useFabric from '@hooks/Fabric';

const ERASE_OBJECT_BUTTON_ID = 'erase-object';
const SELECT_OBJECT_BUTTON_ID = 'select-object';
const SELECT_GROUND_BUTTON_ID = 'select-ground';

interface BackgroundToolbarProps {
  vertical?: boolean;
  children?: ReactNode;
}

const BackgroundToolbar = ({ vertical = false, children }: BackgroundToolbarProps) => {
  const { t } = useTranslation();
  const scene = useScene();
  const { discardActiveObject } = useFabric();
  const { setSelectMode, selectMode } = useComposeSelectMode();
  const segmentation = useSegmentation();
  const feature = useFeatureFlag();
  const cropTool = useCropTool();
  const { resetSelectMode: resetSegmentationSelectMode } = useComposeSelectMode();

  const isPlainBackground = scene?.isPlainBackground;

  const [color, setColor] = useState({
    rgb: isPlainBackground ? (scene.backgroundImage as PlainBackgroundObject)?.color : null,
  });
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const eraseObjectRef = useRef(null);
  const selectObjectRef = useRef(null);
  const selectGroundRef = useRef(null);

  const canUseInfillTool = feature.canUseFeature(Feature.INFILL_TOOL);
  const renderSubToolbar = anchorEl?.clientHeight > 0;
  const subToolbarOpenedForEraseObject = openMenu && selectMode == SelectMode.ERASE;
  const subToolbarOpenedForSelectObject =
    openMenu && (selectMode == SelectMode.INFILLING || selectMode == SelectMode.OBJECT);
  const subToolbarOpenedForSelectGround = openMenu && selectMode == SelectMode.GROUND;

  const changeSelectMode = (mode) => {
    if (mode == selectMode) return;

    if (mode && 'depixObject' in scene.backgroundImage) {
      segmentation.changeSegmentedObject((scene.backgroundImage as DepixBackgroundObject).depixObject);
      segmentation.setEnabled(true);
    }

    setSelectMode(mode);
  };

  const handleOpenColorPicker = () => {
    setDisplayColorPicker(true);
  };

  const handleCloseColorPicker = () => {
    setDisplayColorPicker(false);
  };

  const handleToggleColorPicker = () => {
    if (displayColorPicker) {
      handleCloseColorPicker();
    } else {
      cropTool.setEnabled(false);
      resetSegmentationSelectMode();
      handleOpenColorPicker();
    }
  };

  const handleColorChange = (newColor) => {
    setColor(newColor);
  };

  const onColorChangeComplete = (newColor) => {
    setColor(newColor);
    scene.setPlainBackground(newColor.rgb);
  };

  const handleEraseObjectClick = () => {
    if (selectMode == SelectMode.ERASE) {
      setOpenMenu(!openMenu);
    } else {
      cropTool.setEnabled(false);
      setDisplayColorPicker(false);
      changeSelectMode(SelectMode.ERASE);
    }
  };

  const handleSelectObjectClick = () => {
    if (selectMode == SelectMode.INFILLING || selectMode == SelectMode.OBJECT) {
      setOpenMenu(!openMenu);
    } else {
      cropTool.setEnabled(false);
      setDisplayColorPicker(false);
      changeSelectMode(canUseInfillTool ? SelectMode.INFILLING : SelectMode.OBJECT);
    }
  };

  const handleSelectGroundClick = () => {
    if (selectMode == SelectMode.GROUND) {
      setOpenMenu(!openMenu);
    } else {
      cropTool.setEnabled(false);
      setDisplayColorPicker(false);
      changeSelectMode(SelectMode.GROUND);
    }
  };

  const handleCropClick = () => {
    resetSegmentationSelectMode();
    cropTool.setEnabled(true);
    discardActiveObject();
  };

  useEffect(() => {
    if (selectMode == SelectMode.INFILLING || selectMode == SelectMode.OBJECT) {
      setAnchorEl(selectObjectRef?.current);
      setOpenMenu(true);
    } else if (selectMode == SelectMode.GROUND) {
      setAnchorEl(selectGroundRef?.current);
      setOpenMenu(true);
    } else if (selectMode == SelectMode.ERASE) {
      setAnchorEl(eraseObjectRef?.current);
      setOpenMenu(true);
    } else {
      setAnchorEl(null);
    }
  }, [selectMode]);

  const PlainBackgroundTools = () => (
    <ToolButton
      name={t('tools.compose.ariaLabel.backgroundColorPicker')}
      toolTip={t('tools.compose.tooltips.backgroundColorPicker')}
      tooltipPlacement={vertical ? TooltipPlacement.LEFT : TooltipPlacement.TOP}
      disableBorder={false}
      activated={displayColorPicker}
      disableTooltip={displayColorPicker}
      onClick={handleToggleColorPicker}>
      <ColorPicker
        open={displayColorPicker}
        color={isPlainBackground ? color : DEFAULT_PLAIN_BACKGROUND_COLOR}
        defaultColor={{ rgb: DEFAULT_PLAIN_BACKGROUND_COLOR }}
        sx={{ height: '24px', width: '24px' }}
        onChange={handleColorChange}
        onChangeComplete={onColorChangeComplete}
        activated={false}
        placement={vertical ? 'left' : 'top'}
      />
    </ToolButton>
  );

  return (
    <>
      <PlainBackgroundTools />
      {!isPlainBackground && (
        <>
          {canUseInfillTool && (
            <ToolButton
              name={t(`tools.compose.ariaLabel.eraseObject`)}
              disableBackground={false}
              toolTip={t('tools.compose.tooltips.eraseObject')}
              disableTooltip={subToolbarOpenedForEraseObject}
              tooltipPlacement={vertical ? TooltipPlacement.LEFT : TooltipPlacement.TOP}
              activated={selectMode == SelectMode.ERASE}
              ref={eraseObjectRef}
              id={ERASE_OBJECT_BUTTON_ID}
              onClick={handleEraseObjectClick}>
              <EraseObjectIcon />
            </ToolButton>
          )}
          <ToolButton
            name={t(`tools.compose.ariaLabel.selectObject`)}
            disableBackground={false}
            toolTip={t('tools.compose.tooltips.selectObject')}
            disableTooltip={subToolbarOpenedForSelectObject}
            tooltipPlacement={vertical ? TooltipPlacement.LEFT : TooltipPlacement.TOP}
            activated={selectMode == SelectMode.INFILLING || selectMode == SelectMode.OBJECT}
            ref={selectObjectRef}
            id={SELECT_OBJECT_BUTTON_ID}
            onClick={handleSelectObjectClick}>
            <ObjectIcon />
          </ToolButton>
          <ToolButton
            name={t(`tools.compose.ariaLabel.${SelectMode.GROUND}`)}
            disableBackground={false}
            toolTip={t('tools.compose.tooltips.selectGround')}
            disableTooltip={subToolbarOpenedForSelectGround}
            tooltipPlacement={vertical ? TooltipPlacement.LEFT : TooltipPlacement.TOP}
            activated={selectMode == SelectMode.GROUND}
            ref={selectGroundRef}
            id={SELECT_GROUND_BUTTON_ID}
            onClick={handleSelectGroundClick}>
            <GroundIcon />
          </ToolButton>
          {renderSubToolbar && (
            <Popper
              placement={vertical ? 'left' : 'top'}
              open={openMenu}
              anchorEl={anchorEl}
              modifiers={[
                {
                  name: 'arrow',
                  enabled: true,
                  options: {
                    element: arrowRef,
                  },
                },
                {
                  name: 'preventOverflow',
                  enabled: false,
                },
              ]}>
              <Arrow ref={setArrowRef} direction={vertical ? 'right' : 'down'} />
              <StyledSurface>
                <ToolBar vertical subToolbar name="subToolbar">
                  <StyledSegmentationModeSelector
                    includeText={true}
                    selectText={t(`tools.compose.labels.segmentation.select.${selectMode}`)}
                    selectTooltip={t(`tools.compose.tooltips.segmentation.select.${selectMode}`)}
                    unselectText={t(`tools.compose.labels.segmentation.unselect.${selectMode}`)}
                    unselectTooltip={t(`tools.compose.tooltips.segmentation.unselect.${selectMode}`)}
                    inlineButton
                    tooltipPlacement={TooltipPlacement.RIGHT}
                  />
                </ToolBar>
              </StyledSurface>
            </Popper>
          )}
        </>
      )}
      <ToolButton
        name={t(`tools.compose.ariaLabel.cropTool`)}
        disableBackground={false}
        activated={cropTool.enabled}
        toolTip={t('tools.compose.tooltips.cropTool')}
        tooltipPlacement={vertical ? TooltipPlacement.LEFT : TooltipPlacement.TOP}
        onClick={handleCropClick}>
        <CropIcon />
      </ToolButton>
      {children}
    </>
  );
};

const Arrow = styled('span')<{ direction: 'down' | 'right' }>`
  position: absolute;
  width: 0;
  height: 0;
  z-index: ${(props) => props.theme.zIndex.composeToolbar};

  ${(props) => {
    if (props.direction == 'down') {
      return (
        'border-left: 10px solid transparent;' +
        'border-right: 10px solid transparent;' +
        'border-top: 10px solid white;' +
        'bottom: 0;'
      );
    } else if (props.direction == 'right') {
      return (
        'border-top: 10px solid transparent;' +
        'border-bottom: 10px solid transparent;' +
        'border-left: 10px solid white;' +
        'right: 0;'
      );
    }
  }}
`;

const StyledSurface = styled(Surface)`
  margin: ${(props) => props.theme.spacing(1)};
  box-shadow: ${(props) => props.theme.elevation.modal};
`;

const StyledSegmentationModeSelector = styled(SegmentationModeSelector)`
  justify-content: start;
  align-items: start;
`;

export default BackgroundToolbar;
