import Button from '@components/Ui/Button';
import { Dialog } from '@components/Ui/Dialog/Dialog';
import { DialogActions } from '@components/Ui/Dialog/DialogActions';
import { DialogHeader } from '@components/Ui/Dialog/DialogHeader';
import { DialogTitle } from '@components/Ui/Dialog/DialogTitle';
import Surface from '@components/Ui/Surface';
import { useScene } from '@contexts/SceneContext';
import { useSegmentation } from '@contexts/Segmentation/UseSegmentation';
import useFabric from '@hooks/Fabric';
import { useMultipleDownloads } from '@hooks/UseMultipleDownloads';
import useQueryObjects from '@hooks/useQueryObjects';
import useCutCanvasGrabberHacks from '@hooks/UseCutCanvasGrabberHacks';
import { downloadImage } from '@libs/DownloadImage';
import { EXTRACT_PANEL_NAME } from '@libs/ExtractPanelName';
import { PlainBackgroundObject } from '@libs/PlainBackgroundObject';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React, { useCallback, useState } from 'react';
import _ from 'lodash';

interface DownloadEverythingProps {
  open: boolean;
  onClose: () => void;
}

export const DownloadEverything = ({ open, onClose }: DownloadEverythingProps) => {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const fabric = useFabric();
  const scene = useScene();
  const { queryObjects } = useQueryObjects(_.identity);
  const unified = useCutCanvasGrabberHacks();
  const downloader = useMultipleDownloads();

  const downloadMask = async (isPreview = false): Promise<Blob> => {
    const objects = await queryObjects(
      [unified.cut.segmentationObjectID],
      isPreview,
      {
        withImage: true,
        withMask: true,
      },
      unified.cut.maskId
    );

    if (objects.length === 0) return;
    return objects[0].generateImage().then(({ file }) => file);
  };

  const requestHDImagesFromBackend = useCallback(async (): Promise<Blob> => {
    const isPreviewResolution = false;
    const ids = scene
      .getDepixObjects()
      .filter((obj) => !(obj instanceof PlainBackgroundObject))
      .map((obj) => obj.objectID);

    const objects = await queryObjects(ids, isPreviewResolution, {
      withId: true,
      withImage: true,
      withMask: true,
    });

    for (const obj of objects) {
      const depixObj = fabric.depixObjectFromID(obj.objectID);
      if (depixObj) {
        depixObj.setupFullResolution(obj);
      }
    }

    const imageFormat = scene.isBackgroundTransparent() ? 'png' : 'jpeg';
    return fabric.exportCanvas(true, imageFormat).then(({ file }) => file);
  }, [scene, queryObjects]);

  const onDownloadAll = async () => {
    if (downloader.supportsNativeFs) {
      await onDownloadAllFS();
    } else {
      await onDownloadAllZip();
    }
  };

  const onDownloadAllFS = async () => {
    setLoading(true);
    const cut = await downloadMask(false);
    const paste = await requestHDImagesFromBackend();
    await downloader.downloadToFs(name, [cut, paste]);
    setLoading(false);
  };

  const onDownloadAllZip = async () => {
    setLoading(true);
    const cut = await downloadMask(false);
    const paste = await requestHDImagesFromBackend();
    await downloader.downloadZip(name, [cut, paste]);
    setLoading(false);
  };

  return (
    <StyledDialog
      open={open}
      PaperComponent={Surface}
      aria-label="Download everything dialog"
      maxWidth="sm"
      closeable
      onClose={onClose}>
      <DialogHeader>
        <StyledDialogTitle size="medium">Download everything</StyledDialogTitle>
      </DialogHeader>

      <DialogContent>
        {loading && <Box>Downloading....</Box>}
        <TextField size="small" name="filename" value={name} onChange={(x) => setName(x.target.value)} />
      </DialogContent>

      <StyledDialogActions>
        <StyledButton variant="contained" onClick={onDownloadAll} disabled={name.length == 0}>
          Download all auto
        </StyledButton>
        <StyledButton variant="contained" onClick={onDownloadAllZip} disabled={name.length == 0}>
          Download all zip
        </StyledButton>
        <StyledButton
          variant="contained"
          onClick={onDownloadAllFS}
          disabled={name.length == 0 || !downloader.supportsNativeFs}>
          {downloader.supportsNativeFs ? 'Download all FS' : 'NativeFS not supported'}
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)``;

const StyledDialogTitle = styled(DialogTitle)`
  padding: ${(props) => props.theme.spacing(3, 3, 0)};
  text-align: center;

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props.theme.spacing(6, 6, 0)};
  }
`;

const DialogContent = styled(Box)`
  padding: ${(props) => props.theme.spacing(0, 3)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props.theme.spacing(0, 6)};
  }
`;

const StyledDialogActions = styled(DialogActions)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${(props) => props.theme.spacing(3)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props.theme.spacing(3, 6, 6)};
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  border-radius: ${(props) => props.theme.borderRadius.large}px;
  padding: ${(props) => props.theme.spacing(1, 4)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;
