import { Box, Drawer, styled, SwipeableDrawer, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AuthForm, AuthSubmitButton, FormState } from '@pages/Auth/AuthForm';
import { AuthHeader } from '@pages/Auth/AuthHeader';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from '@libs/i18n-config';
import { Link } from '@components/Ui/Link';
import { ChevronRight } from '@mui/icons-material';
import { FeatureCarousel } from '@root/Components/FeatureCarousel/FeatureCarousel';
import ProductShowcase, { PRODUCT_IMAGES } from '@components/Ui/ProductShowcase';
import { useAuthV2 } from '@contexts/AuthV2';
import Loading from '@components/Loading/Loading';
import { Navigate, Path, useLocation } from 'react-router-dom';
import Routing from '@root/routes';
import { AFTER_LOGIN_REDIRECT_STATE } from '@pages/Auth/AuthDialogV2';

interface AuthPageProps {}

export const AuthPage = ({}: AuthPageProps) => {
  const auth = useAuthV2();
  const location = useLocation();
  const { t } = useTranslation(DEFAULT_NS, { keyPrefix: 'auth.v2.page' });

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [form, setForm] = useState<FormState>('signup');

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  if (auth.isAuthenticated) {
    const state: any = location.state as any;
    const afterLoginRedirect = state ? state[AFTER_LOGIN_REDIRECT_STATE] : undefined;

    return <Navigate to={afterLoginRedirect || Routing.home()} replace={true} />;
  }

  const handleCloseDrawer = () => {
    if (isSmall) {
      setIsDrawerOpen(false);
    }
  };

  const handleOpenDrawer = (form: FormState = 'signup') => {
    setIsDrawerOpen(true);
    setForm(form);
  };

  return (
    <>
      <Wrapper>
        <AuthHeader />

        <Main component="main">
          <Box sx={{ opacity: 0.6 }}>
            <ProductShowcase image={PRODUCT_IMAGES[5]} top="20%" left={-50} width={120} flat animate={false} />
            <ProductShowcase image={PRODUCT_IMAGES[6]} top="0%" right={-10} width={90} flat animate={false} />
            <ProductShowcase image={PRODUCT_IMAGES[2]} bottom="1%" right={-10} width={130} flat animate={false} />
          </Box>

          <Box
            position="relative"
            flexGrow={1}
            maxWidth="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            zIndex={1}>
            <StyledFeatureCarousel />

            {isSmall && (
              <Box>
                <AuthSubmitButton onClick={() => handleOpenDrawer()}>{t('create_account')}</AuthSubmitButton>
                <Typography>
                  {t('already_account')} <Link onClick={() => handleOpenDrawer('login')}>{t('sign_in_link')}</Link>
                </Typography>
              </Box>
            )}
          </Box>

          <Box maxWidth="100%" zIndex={1}>
            <Typography>
              <StyledLink href="https://depix.ai">
                {t('know_more')} <ChevronRight />
              </StyledLink>
            </Typography>
          </Box>
        </Main>

        <Aside component="aside">
          <StyledDrawer
            variant={isSmall ? 'temporary' : 'persistent'}
            anchor={isSmall ? 'bottom' : 'right'}
            open={!isSmall || isDrawerOpen}
            elevation={0}
            onOpen={() => handleOpenDrawer()}
            onClose={handleCloseDrawer}>
            <AuthForm currentForm={form} onChangeForm={setForm} />
          </StyledDrawer>
        </Aside>

        {auth.isLoading && <Loading backdrop global />}
      </Wrapper>
    </>
  );
};

const MAX_DRAWER_WIDTH = 500;
const DRAWER_WIDTH_LG = 40;

const Wrapper = styled(Box)`
  display: flex;
  min-height: 100%;
  max-width: 100%;

  background-color: ${(props) => props.theme.palette.ternary.main};
`;

const Main = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing(4)};

  width: calc(100% - ${DRAWER_WIDTH_LG}%);
  min-width: calc(100% - ${MAX_DRAWER_WIDTH}px);
  padding: ${(props) => props.theme.spacing(4, 2)};
  padding-top: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
    min-width: 100%;
  }
`;

const StyledFeatureCarousel = styled(FeatureCarousel)`
  max-width: 100%;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`;

const Aside = styled(Box)`
  position: relative;
  display: flex;
`;

const StyledDrawer = styled(SwipeableDrawer)`
  & .MuiDrawer-paper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: ${DRAWER_WIDTH_LG}%;
    max-width: ${MAX_DRAWER_WIDTH}px;
    border: none;

    ${(props) => props.theme.breakpoints.down('sm')} {
      width: 100%;
      max-width: 100%;
      height: 90%;

      border-top-left-radius: ${(props) => props.theme.borderRadius.medium}px;
      border-top-right-radius: ${(props) => props.theme.borderRadius.medium}px;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 50%;
      width: 50px;
      height: 5px;
      border-radius: 5px;
      margin: ${(props) => props.theme.spacing(1.5, 0)};
      transform: translateX(-50%);

      background-color: ${(props) => props.theme.palette.secondary.darker};
      content: '';

      ${(props) => props.theme.breakpoints.up('sm')} {
        display: none;
      }
    }
  }
`;
