import { AudienceType } from '@libs/Profile/Audience';
import { GoalType } from '@libs/Profile/Goal';
import {
  AudienceType as GraphqlAudienceType,
  GetUserMetadataQuery,
  UserGoals,
  UserMetadata as GraphqlUserMetadata,
} from '@api/graphql';

export class UserMetadata {
  constructor(private readonly _audience: AudienceType, private readonly _goals: GoalType[]) {}

  get audience(): AudienceType {
    return this._audience;
  }

  get goals(): GoalType[] {
    return this._goals;
  }
}

export function mapMetadata({ userProfile: { metadata } }: GetUserMetadataQuery): UserMetadata | null {
  if (!metadata) {
    return null;
  }

  if (metadata.__typename === 'ResolverError') {
    return null;
  }

  const fragment = metadata as GraphqlUserMetadata;

  return new UserMetadata(
    convertAudience(fragment.audience),
    fragment.goals.map((goal) => convertGoal(goal))
  );
}

export const convertAudience = (audience: GraphqlAudienceType): AudienceType | null => {
  switch (audience) {
    case GraphqlAudienceType.ECommerce:
      return AudienceType.E_COMMERCE;
    case GraphqlAudienceType.SocialMedia:
      return AudienceType.SOCIAL_MEDIA;
    case GraphqlAudienceType.Personal:
      return AudienceType.PERSONAL;
    case GraphqlAudienceType.Creative:
      return AudienceType.CREATIVE;
    case GraphqlAudienceType.Unspecified:
      return AudienceType.UNSPECIFIED;
    default:
      return null;
  }
};

export const convertGoal = (goal: UserGoals): GoalType => {
  switch (goal) {
    case UserGoals.ImageCleanup:
      return GoalType.IMAGE_CLEANUP;
    case UserGoals.Photorealistic:
      return GoalType.PHOTOREALISTIC;
    case UserGoals.RemoveBackground:
      return GoalType.REMOVE_BACKGROUND;
  }
};

export const convertAudienceToGrapqhl = (audience: AudienceType): GraphqlAudienceType => {
  switch (audience) {
    case AudienceType.E_COMMERCE:
      return GraphqlAudienceType.ECommerce;
    case AudienceType.SOCIAL_MEDIA:
      return GraphqlAudienceType.SocialMedia;
    case AudienceType.PERSONAL:
      return GraphqlAudienceType.Personal;
    case AudienceType.CREATIVE:
      return GraphqlAudienceType.Creative;
    case AudienceType.UNSPECIFIED:
      return GraphqlAudienceType.Unspecified;
  }
};

export const convertGoalsToGraphql = (goal: GoalType): UserGoals => {
  switch (goal) {
    case GoalType.REMOVE_BACKGROUND:
      return UserGoals.RemoveBackground;
    case GoalType.IMAGE_CLEANUP:
      return UserGoals.ImageCleanup;
    case GoalType.PHOTOREALISTIC:
      return UserGoals.Photorealistic;
  }
};
