import { GetSharedImageQuery, GetTrendingImagesQuery, SharedImage as GraphqlSharedImage } from '@api/graphql';
import Routes from '@root/routes';

export interface SharedImage {
  ref: string;
  imageUrl: string;
  title: string;
  description?: string;
  viewCount: number;
}

export function generateShareLink(sharedImageId: string) {
  const baseUrl = new URL(process.env.REACT_APP_DEPIX_DOMAIN);
  const uri = Routes.imageSharing.image(sharedImageId);
  return `https://${baseUrl.host}${uri}`;
}

export function mapSharedImage({ sharedImage }: GetSharedImageQuery): SharedImage | null {
  if (!sharedImage) {
    return null;
  }

  return sharedImage as GraphqlSharedImage;
}

export function mapTrendingImages({ trendingImages }: GetTrendingImagesQuery): SharedImage[] | null {
  if (!trendingImages) {
    return null;
  }

  return trendingImages.map((s) => s as GraphqlSharedImage);
}
