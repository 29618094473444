import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Surface from '@components/Ui/Surface';
import ProductShowcase, { PRODUCT_IMAGES } from '@components/Ui/ProductShowcase';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Dialog } from '@components/Ui/Dialog/Dialog';
import { AuthForm } from '@pages/Auth/AuthForm';

export const AFTER_LOGIN_REDIRECT_STATE = 'afterLoginRedirect';

interface AuthDialogProps {
  open?: boolean;
}

export const AuthDialog = ({ open = true }: AuthDialogProps) => {
  const theme = useTheme();
  const isLargeDevice = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Dialog
      PaperComponent={StyledSurface}
      open={open}
      maxWidth="md"
      fullWidth={true}
      PaperProps={{ sx: { overflowY: 'inherit' } }}>
      <AuthForm />

      {isLargeDevice && (
        <>
          <ProductShowcase image={PRODUCT_IMAGES[0]} left="40%" top="-65px" width={150} flat={true} animate={false} />
          <ProductShowcase image={PRODUCT_IMAGES[1]} left="-75px" top="25%" width={150} flat={true} animate={false} />
          <ProductShowcase
            image={PRODUCT_IMAGES[4]}
            left="15%"
            bottom="-75px"
            width={150}
            flat={true}
            animate={false}
          />
        </>
      )}
    </Dialog>
  );
};

const StyledSurface = styled(Surface)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    transform: scale(85%);
  }
`;
